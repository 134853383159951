import React from 'react';
import { useSelector } from 'context';
import { Tooltip } from '@mui/material';
import { Person, Group } from '@mui/icons-material';
import classNames from 'classnames';
import classes from './search-view-count.module.scss';

type SearchViewCountProps = {
  viewed: any[];
  size?: string;
};

const SearchViewCount = ({ viewed, size }: SearchViewCountProps) => {
  const user = useSelector((state) => state.user);
  const viewedByCurrentUser = viewed?.some((view) => view.user === user.email);
  const viewedMinusCurrentUser =
    viewed?.filter((view) => view.user !== user.email) || [];

  const renderTitle = () => {
    if (viewedByCurrentUser && viewedMinusCurrentUser.length)
      return `Read by you and ${viewedMinusCurrentUser.length} others`;
    if (viewedByCurrentUser && viewedMinusCurrentUser.length === 0)
      return 'Read by you';
    if (!viewedByCurrentUser && viewedMinusCurrentUser.length)
      return `Read by ${viewedMinusCurrentUser.length} users`;
    return 'Unread';
  };

  return (
    <Tooltip
      title={renderTitle()}
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes['tooltip-arrow'],
      }}
    >
      <div
        className={classNames(classes.container, {
          [classes[`container--${size}`]]: size,
        })}
      >
        <Person
          fontSize='inherit'
          className={classNames(classes.icon, {
            [classes['icon--active']]: viewedByCurrentUser,
          })}
        />
        <span className={classes.divider} />
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Group
            fontSize='inherit'
            style={{ height: '15px' }}
            className={classNames(classes.icon, {
              [classes['icon--active']]: viewedMinusCurrentUser.length,
            })}
          />
          <span style={{ fontSize: '12px' }}>
            {viewedMinusCurrentUser.length > 0
              ? viewedMinusCurrentUser.length
              : null}
          </span>
        </span>
      </div>
    </Tooltip>
  );
};

export default SearchViewCount;
