import React from 'react';
import { CircularProgress } from '@mui/material';
import classes from './spinner.module.scss';

const Spinner = () => (
  <div className={classes.container}>
    <CircularProgress className={classes.spinner} />
  </div>
);

export default Spinner;
