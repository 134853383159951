export interface AddressObject {
  street: string;
  city: string;
  region: string;
  country: {
    name: string;
    code: string;
  };
  postCode: string;
}

const UNKNOWN = `Unknown`;

/**
 * Converts address to comma separated string representation
 * @param AddressObject
 * @returns comma separated string representing address. Returns UNKNOWN if resultant string is empty
 */
export const convertAddressToString = (address: AddressObject) => {
  const { street, city, region, country = { name: '' }, postCode } = address;
  const addressAsArray = [street, city, region, country.name, postCode]
    .map((item) => (item || '').trim())
    .filter((item) => item.length > 0);
  if (addressAsArray.join('').length === 0) {
    return UNKNOWN;
  }
  return addressAsArray.join(', ');
};

/**
 * Splits string by inputSeparator, capitalises first letter of each word, then combine using outputSeparator
 * @param stringToCapitalise
 * @param inputSeparator
 * @param outputSeparator
 * @returns string with capitalised words
 */
export const capitaliseFirstLetters = (
  stringToCapitalise: string,
  inputSeparator = ' ',
  outputSeparator = ' '
) => {
  return stringToCapitalise
    .split(inputSeparator)
    .map((word) => {
      if (word === '') {
        return word;
      }
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(outputSeparator);
};

export interface NameObject {
  fullName: string;
  type: string;
  givenName?: string;
  languageCode?: {
    code: string;
    name: string;
  };
  lastName?: string;
  originalScript?: string;
  prefix?: string;
  suffix?: string;
}

/**
 * @param nameObject
 * @returns string formatted to `originalScript (fullName)` or `fullName`
 */
export const convertNameObjectToStringForDisplay = (nameObject: NameObject) => {
  const { originalScript, fullName } = nameObject || {
    originalScript: '',
    fullName: '',
  };
  if (originalScript && originalScript !== '' && originalScript !== fullName) {
    return `${originalScript} (${fullName})`;
  }
  return `${fullName}`;
};

/**
 *
 * @param {NameObject[]} array of objects containing
 * @returns
 */
export const PRIMARY_NAME_TYPE = `PRIMARY`;
export const parseNameArrayForRendering = (
  nameObjectArray: NameObject[] = []
) => {
  const primaryName = nameObjectArray.filter(
    (nameObject) => nameObject.type === PRIMARY_NAME_TYPE
  )[0];
  const otherNames = nameObjectArray.filter(
    (nameObject) => nameObject.type !== PRIMARY_NAME_TYPE
  );
  return {
    primaryName,
    otherNames,
  };
};

/**
 * Formats input string to enGbLocaleString. Returns empty string if no input
 * @param timestampString UTC time string
 * @returns {string} formatted to enGB localeDateString
 */
export const formatDate = (timestampString: string) => {
  if (!timestampString || timestampString === '') {
    return '';
  }
  const timestamp = new Date(timestampString);
  return timestamp.toLocaleDateString('en-GB');
};
