import { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'context';
// eslint-disable-next-line import/no-cycle
import ResultsList, { SkeletonList } from 'components/Results';

import { AsyncMessage, useAsyncMessageHandler } from 'hooks/AsyncMessages';
import SimpleBar from 'simplebar-react';
import {
  fetchMoreQueryItems,
  submitTranslationById,
  getProvidersToFetch,
  fetchQueryItemsForGivenProviders,
} from 'hooks/useFetch';
import { find, isEmpty } from 'lodash';
import Loader from 'components/Base/Loader';
import { UPDATE_STATE } from 'constants/actionTypes';
import { v4 as uuid } from 'uuid';
import { useScrollBookmarks } from './useScrollBookmarks';
import SearchResultsPagination from './SearchResultsPagination';
import classes from './search-results.module.scss';
import SearchResultsHeader from './SearchResultsHeader';

const SearchResults = ({ ...props }) => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const article = useSelector((state) => state.state.article);
  const data = useSelector((state) => state.state.data);
  const expecting = useSelector((state) => state.state.expecting);
  const filters = useSelector((state) => state.state.filters);
  const newQuery = useSelector((state) => state.state.newQuery);
  const page = useSelector((state) => state.state.page);
  const project = useSelector((state) => state.state.project);
  const provider = useSelector((state) => state.state.provider);
  const query = useSelector((state) => state.state.query);
  const search = useSelector((state) => state.state.search);
  const translate = useSelector((state) => state.state.translate);

  const listRef = useRef(null);
  const reqId = useRef(0);
  const currFetchQueryItems = useRef([]);
  const [maxPages, setMaxPages] = useState(1);

  // Controlls scroll locations
  const scrollRef = useRef(null);
  useScrollBookmarks(data?.items, scrollRef);

  // TODO - resolve way to access curent provider config (part of core state vars?)
  const collapse = find(config.providers, { id: provider.id })?.dispatch
    ?.collapse_results;
  const noDuplicates = collapse === '!';
  const localFilter = !!find(config.providers, { id: provider.id })?.dispatch
    ?.local_filter;

  const queryItemCounts = data.totalCount;
  const queryItemCountsRelation = data.totalCountRel;
  const queryItemCountsNoDuplicates = data.loadedNoDuplicates;

  const prepareAndFetchItems = useCallback(() => {
    // We call fetchQueryItems() by setting expecting.items = true or
    // data.loadingPassive = true
    // i.e. loading a new page or search, or clicking in UI we expect a result.
    // On occasion we passively refresh, e.g. batch translation as completed

    // Calls must wait for first query to complete (query.processed == true)

    // Basically two modes:
    // 1) pagination only; facets, sort and filtering done as Provider requests
    // 2) local filtering; queries the local database, uses Elastic's
    // buckets/aggs etc.
    // (see (1) and (2) notes below)

    // Results can be collapsed, meaning 3 totals:
    // a) Provider API value in project metadata ("hit_count")
    // b) total in local Elastic
    // c) total in local Elastic with collapsing
    // Also for (2) total count is set dynamically
    // There is also a concept of "hit_count_relation", e.g. "greater than 10k"

    // Caution: totals in local Elastic vary as new results added via pagination
    // and 'get more' requests, plus any collapsing
    const curr = [
      expecting.items,
      page,
      query,
      filters?.changed,
      data.loadingPassive,
    ];
    if (currFetchQueryItems.current === curr) return;
    currFetchQueryItems.current = curr;

    if (!expecting.items && !data.loadingPassive) return;

    const providersToFetch = getProvidersToFetch(provider.id, search.providers);

    const allQueriesProcessed = providersToFetch.every(
      (_provider) => _provider.queries[_provider.queries.length - 1].processed
    );

    if (!allQueriesProcessed) {
      console.log('exiting here in searchResults');
      return;
    }

    let reqFacets = query.facets;
    let reqSort = query?.sort;
    let reqFilter = {};

    if (localFilter) {
      reqFacets = !isEmpty(filters?.facets) ? filters.facets : query.facets;
      reqSort = filters?.sort || query?.sort;
      reqFilter = filters?.filter || {};
    }

    const setReqId = uuid();
    reqId.current = setReqId;

    const fetchQueryItemsParams = {
      projectId: project.id, // The project, all items are in this index
      searchId: search.id,
      providerId: provider.id,
      queryId: query.id, // The result set, all items have this common ID
      queryString: query.query_string, // Currently ignored, query_string is always new provider API request
      availableFacets: reqFacets, // For (1), facets set in provider API response (stored in Project metadata), for (2) empty facets in Project metadata have buckets/aggs added via local filtering
      selectedFacets: reqFilter, // Values for (2), ignored for (1)
      sort: reqSort, // Sort for (2), ignored for (1)
      collapseField: collapse, // (1) and (2), local results from provider collapsed based on Provider config
      page, //  Page number (caution "per page = 100" hard coded in places frontend and backend)
      // localFilter: localFilter, // TRUE if is (2)
      tags: [], // Currently unused, would be part local filtering
      reqId: setReqId, // help context of promises
    };

    fetchQueryItemsForGivenProviders(
      providersToFetch,
      config.providers,
      fetchQueryItemsParams
    ).then((response) => {
      if (!response) {
        console.log('fetchQueryItemsForGivenProviders: invalid response');
        return;
      }

      const { res, filters: filterResult, facets: facetResult } = response;
      console.log('fetchQueryItemsForGivenProviders result', {
        res,
        filterResult,
        facetResult,
      });

      if (
        !res ||
        res?.reqId !== reqId.current ||
        (query.hit_count > 0 && res?.hits?.hits === 0)
      ) {
        console.log('fetchQueryItemsForGivenProviders: exiting', {
          res,
          reqId: reqId.current,
        });
        return;
      }

      // TODO — some of this uses values fetchQueryItems() and current state vars

      const loadedItemsWithDuplicates = res.hits.total.value; // Hit count from local Elastic query (might include collapsed)
      const loadedItems = noDuplicates
        ? loadedItemsWithDuplicates
        : res.aggregations?.total?.value || loadedItemsWithDuplicates;
      const loadedPages = Math.ceil(loadedItems / 100) || 1;
      // TODO - why do we re-assign noDuplicates? // A: loadedNoDuplicates to be var name consistent below
      const loadedNoDuplicates = noDuplicates;
      console.log({
        loadedItems,
        loadedItemsWithDuplicates,
        loadedPages,
        loadedNoDuplicates,
        res,
      });

      let totalCount = query.hit_count; // Hit count returned by provider
      let totalCountRel = ['gte', 'gt'].includes(query.hit_count_relation)
        ? '+'
        : '';
      if (localFilter) {
        // Use filtered totals rater than Project metadata values (query["hit_count"] etc.)
        if (filters && filters?.changed) {
          // only filter has been applied
          totalCount = loadedItems;
          totalCountRel = '';
        }
      }

      // This is used to calculate how many pagination pages should be active. We only want users
      // to be able to paginate 1 page forward at a time.
      setMaxPages(loadedPages + 1);

      const translatedCount = res.hits.hits.filter(
        (item) =>
          !!item._source.headline_trans && item._source.language !== 'en'
      ).length;
      const translatedReqCount = res.hits.hits.filter(
        (item) => item._source.language !== 'en'
      ).length;

      console.log('setting filtersValues', { filterResult, facetResult });
      const filtersValues = {
        facets: facetResult,
        filter: filterResult,
      };

      const noResults = totalCount === 0;
      if (noResults) {
        if (process.env.NODE_ENV === 'development') {
          console.log({ SEARCH_RESULTS: 'no results' });
        }
        dispatch({
          type: UPDATE_STATE,
          payload: {
            data: { loading: false },
            article: { loading: false },
            expecting: { items: false, article: false },
            retrieving: false,
            filters: filtersValues,
          },
        });
        return;
      }

      const isViewingUnauthorisedPage =
        loadedItems > 0 && page > Math.ceil(totalCount / 100);
      if (isViewingUnauthorisedPage) {
        dispatch({
          type: UPDATE_STATE,
          payload: {
            page: 1,
            expecting: { items: false, article: false },
            retrieving: false,
            filters: filtersValues,
          },
        });
        return;
      }

      const isMissingItems =
        page > loadedPages &&
        page > 1 &&
        !(loadedItemsWithDuplicates >= totalCount);

      if (isMissingItems) {
        if (process.env.NODE_ENV === 'development') {
          console.log({ SEARCH_RESULTS: 'missing items' });
        }

        // We fetch more items here and then listen for the "search.page_inserted" event
        // to be emitted. Once this event is received, we fetch the latest requests.
        fetchMoreQueryItems({
          projectId: project.id,
          searchId: search.id,
          queryId: query.id,
          page,
        });
        return;
      }

      const isMissingTranslations =
        translate.items && translatedCount < translatedReqCount;
      if (isMissingTranslations) {
        if (process.env.NODE_ENV === 'development') {
          console.log({ SEARCH_RESULTS: 'missing translations' });
        }
        submitTranslationById({
          projectId: project.id,
          providerId: provider.id,
          docs: res.hits.hits
            .filter(
              (item) =>
                !item._source.headline_trans && item._source.language !== 'en'
            )
            .map((item) => item._id),
        });
      }

      if (process.env.NODE_ENV === 'development') {
        console.log({ SEARCH_RESULTS: 'final result' });
      }

      // Persist or reload article
      if (
        data.loadingPassive &&
        article.id &&
        !article.loading &&
        find(res.hits.hits, { _id: article.id })
      ) {
        dispatch({
          type: UPDATE_STATE,
          payload: {
            expecting: { article: false, items: false },
            data: {
              items: res.hits.hits,
              loadedPages,
              loadedItems,
              loadedItemsWithDuplicates,
              loadedNoDuplicates,
              totalCount,
              totalCountRel,
            },
            filters: filtersValues,
          },
        });
      } else {
        dispatch({
          type: UPDATE_STATE,
          payload: {
            expecting: {
              items: false,
              article:
                res.hits?.hits?.length > 0 ? res.hits.hits[0]._id : false,
            },
            data: {
              items: res.hits.hits,
              loadedPages,
              loadedItems,
              loadedItemsWithDuplicates,
              loadedNoDuplicates,
              totalCount,
              totalCountRel,
            },
            filters: filtersValues,
            article:
              res.hits?.hits?.length > 0 && res.hits.hits[0]._id
                ? { id: res.hits.hits[0]._id, loading: true }
                : { loading: false },
            retrieving: false,
          },
        });
      }
    });
  }, [
    expecting.items,
    page,
    query,
    filters,
    data.loadingPassive,
    provider.id,
    search.providers,
    search.id,
    localFilter,
    project.id,
    collapse,
    config.providers,
    noDuplicates,
    translate.items,
    article.id,
    article.loading,
    dispatch,
  ]);

  useEffect(() => {
    prepareAndFetchItems();
  }, [
    expecting?.items,
    page,
    query.id,
    provider.id,
    project.id,
    prepareAndFetchItems,
    dispatch,
  ]);

  const pageInsertedCallback = useCallback(
    (message: AsyncMessage) => {
      if (
        message.attributes.query === query?.id &&
        page <= message.attributes.page + 1
      ) {
        prepareAndFetchItems();
      }
    },
    [query, page, prepareAndFetchItems]
  );

  useAsyncMessageHandler('search.page_inserted', pageInsertedCallback);

  return (
    <div className={classes.container}>
      <SearchResultsHeader
        counts={{
          queryItemCounts,
          queryItemCountsNoDuplicates,
          queryItemCountsRelation,
        }}
      />
      <SimpleBar
        id='results-list'
        ref={listRef}
        scrollableNodeProps={{ ref: scrollRef }}
        style={{ minWidth: '100%', minHeight: '100%', maxHeight: '100%' }}
      >
        <Loader
          loading={!(!project.loading && !data?.loading && !newQuery)}
          component={<div className={classes.borders} />}
        >
          {!project.loading && !data?.loading && !newQuery ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ResultsList {...props} parentRef={listRef} />
          ) : (
            <SkeletonList />
          )}
        </Loader>
      </SimpleBar>
      <SearchResultsPagination
        disabled={!data?.items}
        totalResults={queryItemCounts}
        loading={data.loading}
        page={page}
        maxPages={maxPages}
        setPage={(p) =>
          dispatch({
            type: UPDATE_STATE,
            payload: {
              page: p,
              expecting: { items: true, article: true },
              data: {
                totalCount: data.totalCount,
                totalCountRel: data.totalCountRel,
                loadedNoDuplicates: data.loadedNoDuplicates,
                loading: true,
              },
              article: { loading: true },
            },
          })
        }
      />
    </div>
  );
};

export default SearchResults;
