import axios from 'axios';
import { apiHost } from 'hooks/useFetch';

export const authGetRedirect = async (): Promise<string | boolean> => {
  try {
    const result = await axios.get(`${apiHost}/api/v1/auth/redirect`, {});
    return result.data.URI;
  } catch (err) {
    return false;
  }
};

export default authGetRedirect;
