import ReactDOM from 'react-dom/client';
import { setupAxiosIntercept } from './api/setupAxiosIntercept';
import App from './App';
import * as serviceWorker from './serviceWorker';

const { NODE_ENV } = process.env;
if (NODE_ENV && (NODE_ENV === 'production' || NODE_ENV === 'staging')) {
  window.console.assert = () => {};
  window.console.dir = () => {};
  window.console.dirxml = () => {};
  window.console.error = () => {};
  window.console.info = () => {};
  window.console.log = () => {};
  window.console.table = () => {};
  window.console.trace = () => {};
  window.console.warn = () => {};
}

setupAxiosIntercept();

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
