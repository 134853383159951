import classes from './icons-base.module.scss';

// <i className="fas fa-angle-down"></i>
// https://fontawesome.com/v5.15/icons/angle-down
// SVGs in download from https://fontawesome.com/download

const IconAngleDown = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fal'
      data-icon='angle-down'
      className={classes['fa-inline-icon--angle-arrow']}
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <path
        fill='currentColor'
        d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'
      />
    </svg>
  );
};

export default IconAngleDown;
