import Chip from 'components/Base/Chip';

type Props = {
  content: {
    pep: boolean;
    sanctioned: boolean;
  };
};

export const SayariHighlightChips = ({ content }: Props) => {
  return (
    <>
      {content.pep && (
        <Chip
          key='item-text-tag--pep'
          label='Politically exposed'
          size='small'
          variant='grey'
        />
      )}
      {content.sanctioned && (
        <Chip
          key='item-text-tag--sanctioned'
          label='Sanctioned'
          size='small'
          variant='grey'
        />
      )}
    </>
  );
};

export default SayariHighlightChips;
