import { useSelector } from 'context';
import Chip from 'components/Base/Chip';
import classes from './results.module.scss';
import { ResultsItemHeader } from './ResultsItemHeader';
import { ResultsItemSubtitle } from './ResultsItemSubtitle';

const getProviderWithId = (providers, id) => {
  return providers.filter((provider) => provider?.id === id)?.[0];
};

const CombinedComplianceResultsItem = ({
  id,
  title,
  titleTags,
  text,
  textTags,
  queryString,
  tags,
  viewed,
}) => {
  const providers = useSelector((state) => state.config.providers);
  const providerLabels = titleTags.map((providerId) => {
    const provider = getProviderWithId(providers, providerId);
    if (provider) {
      return provider.label;
    }
    // fallback to providerId if provider not found.
    return providerId;
  });
  return (
    <>
      <ResultsItemHeader
        id={id}
        title={title}
        queryString={queryString}
        isSaved={tags.includes('Finding')}
        viewed={viewed}
      />

      <div className={classes['item-details--compliance']}>
        <ResultsItemSubtitle highlight={text} tags={[]} />
        {textTags ? (
          <span className={classes['item-text-tags']}>
            {providerLabels.map((providerLabel) => (
              <Chip
                key={`item-text-tag--${providerLabel}`}
                label={providerLabel}
                size='small'
                classes={{ root: classes.chip }}
              />
            ))}
          </span>
        ) : (
          ''
        )}
      </div>
      <div>
        Found in {providerLabels.length}{' '}
        {providerLabels.length === 1 ? `provider` : `providers`}
      </div>
    </>
  );
};

export default CombinedComplianceResultsItem;
