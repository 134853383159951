import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFavouriteAdverseTermsThunk } from 'store/thunks/fetchFavouriteAdverseTerms';
import { fetchAdverseTermsThunk } from 'store/thunks/fetchAdverseTerms';
import { fetchAdverseTermCategoriesThunk } from 'store/thunks/fetchAdverseTermCategories';
import { fetchAdverseTermLanguagesThunk } from 'store/thunks/fetchAdverseTermLanguages';
import { fetchAdverseTermProvidersThunk } from 'store/thunks/fetchAdverseTermProviders';

const fetchAllAdverseTermsDataThunk = createAsyncThunk(
  'adverseTerms/fetchAllAdverseTermsData',
  async (_, { dispatch }) => {
    return Promise.all([
      dispatch(fetchAdverseTermsThunk()),
      dispatch(fetchFavouriteAdverseTermsThunk()),
      dispatch(fetchAdverseTermCategoriesThunk()),
      dispatch(fetchAdverseTermLanguagesThunk()),
      dispatch(fetchAdverseTermProvidersThunk()),
    ]);
  }
);

export default fetchAllAdverseTermsDataThunk;
