import { useCallback } from 'react';
import { UPDATE_RELATED_ITEMS, UPDATE_STATE } from 'constants/actionTypes';
import { useDispatch } from 'context';
import { fetchRelatedItems } from './useFetch';

export default function useHandleRelatedItems() {
  const dispatch = useDispatch();

  return useCallback(
    (projectId, searchId) => {
      fetchRelatedItems(projectId, searchId)
        .then((res) => {
          const items = !res?.data
            ? []
            : res.data.map((item) => {
                return {
                  id: item.id,
                  label: item.label,
                  relatedUrl: item.related_url,
                };
              });
          dispatch({
            type: UPDATE_RELATED_ITEMS,
            payload: {
              items,
            },
          });
        })
        .catch(() => {
          dispatch({
            type: UPDATE_STATE,
            payload: {
              relatedItems: [],
            },
          });
        });
    },
    [dispatch]
  );
}
