import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const fetchRelatedItems = (projectId, searchId) => {
  const request = axios.get(`${apiHost}/api/v1/projects/${projectId}/related`, {
    headers: apiHeaders(),
    params: {
      active: 'yes',
      limit: 30,
      search: searchId,
    },
  });

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default fetchRelatedItems;
