import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const deleteProject = (project_id) => {
  const request = axios.delete(`${apiHost}/api/v1/projects/${project_id}`, {
    headers: apiHeaders(),
  });
  console.log({ store });
  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default deleteProject;
