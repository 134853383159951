import { Subject } from 'components/SubjectSearch/types';

const constructQueries = ({
  projectId,
  subjects,
  providers,
}: {
  projectId: string;
  subjects: Subject[];
  providers: string[];
}) => {
  const queries = subjects.reduce(
    (acc, subject) => (subject.name ? [...acc, subject.name] : acc),
    []
  );

  // Universal/shared subject search filters that apply to the majority of providers
  const filters = subjects.map((subject) => {
    const { country, type, dob, gender } = subject;
    return {
      entity_type: type,
      ...(dob ? { date_of_birth: { full_date: dob } } : {}),
      ...(country ? { country: [country] } : {}),
      ...(gender ? { gender } : {}),
      gender,
    };
  });

  // Provider specific query structs
  const queryStructs = subjects.map((subject) => {
    const { termId } = subject;
    return termId
      ? [
          {
            type: 'terms',
            value: termId.toString(),
          },
        ]
      : [];
  });

  return {
    project: projectId,
    providers,
    query: queries,
    filter: filters,
    is_subject_search: true,
    query_structs: queryStructs,
  };
};

export default constructQueries;
