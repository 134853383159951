import { useCallback } from 'react';
import {
  FETCH_FAILURE_TRANSLATE,
  FETCH_FAILURE_TRANSLATE_ITEMS,
} from 'constants/actionTypes';
import { AsyncMessage } from '../types';
import useMultiAsyncMessageHandler from '../useMultiAsyncMessageHandler';

const TranslateErrorHandler = (articleId, translate, data, dispatch) =>
  useMultiAsyncMessageHandler(
    ['translate.processing.error', 'translate.error'],
    useCallback(
      (message: AsyncMessage) => {
        if (
          articleId &&
          message.attributes?.es_ids?.indexOf(articleId) !== -1 &&
          translate.article
        ) {
          dispatch({
            type: FETCH_FAILURE_TRANSLATE,
            payload: {
              error: {
                message: 'No content could be translated.',
                detail: message?.attributes?.message,
              },
            },
          });
        }

        if (
          message.attributes?.es_ids?.length > 0 &&
          translate.items &&
          data?.items
        ) {
          const ids = data.items
            .filter(
              (item) =>
                !item._source.headline_trans && item._source.language !== 'en'
            )
            .map((item) => item._id);

          const x = ids.some(
            (id) => message.attributes?.es_ids?.indexOf(id) !== -1
          );
          if (x) {
            dispatch({
              type: FETCH_FAILURE_TRANSLATE_ITEMS,
              payload: {
                error: {
                  message: 'No content could be translated.',
                  detail: message?.attributes?.message,
                },
              },
            });
          }
        }
      },
      [articleId, translate, data, dispatch]
    )
  );

export default TranslateErrorHandler;
