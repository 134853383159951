import { isEmpty, find } from 'lodash';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'context';
import WorldCheckStructuredData from 'components/Article/ArticleTemplates/ComplianceArticlesTemplate/WorldCheck';
import { SayariStructuredData } from 'components/Article/ArticleTemplates/CorporateRecordsArticleTemplate/Sayari';
import parseContentToStructuredData from 'components/Article/ArticleTemplates/utils/parseContentToStructuredData';
import RecordsTable from '../Article/ContentTypes/RecordsTable';
import ComplianceStructuredData from '../Article/ArticleTemplates/ComplianceArticlesTemplate/DowJonesStructuredData';
import ComplianceStructuredData2 from '../Article/ArticleTemplates/ComplianceArticlesTemplate/WorldComplianceStructuredData';
import classes from './export.module.scss';

const formatDate = (raw_date) => {
  const date = moment(raw_date);
  return `${date.format('Do MMM YYYY')}`;
};

export const SkeletonExportArticle = () => {
  return (
    <div className={classes.article}>
      <div className={classes.content} style={{ marginBottom: '5em' }}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton style={{ width: '40%' }} />
      </div>
    </div>
  );
};

export const FullExportArticle = (props) => {
  const providers = useSelector((state) => state.config.providers);
  const { item } = props;
  const renderItemUrl = () =>
    // eslint-disable-next-line no-nested-ternary
    isEmpty(item.end_url) ? (
      !isEmpty(item.url) ? (
        <p className={classes.url}>
          <a href={item.url} target='_blank' rel='noreferrer'>
            {item.url}
          </a>
        </p>
      ) : (
        ''
      )
    ) : (
      <p className={classes.url}>
        <a href={item.end_url} target='_blank' rel='noreferrer'>
          {item.end_url}
        </a>
      </p>
    );

  const renderContent = () => {
    switch (item.provider) {
      case 'compliance.dowjones':
        return <ComplianceStructuredData data={item} printView />;
      case 'compliance.worldcompliance':
        return <ComplianceStructuredData2 data={item} printView />;
      case 'compliance.worldcheck':
        return <WorldCheckStructuredData data={item} printView />;
      case 'corporate_records.sayari':
        return (
          <SayariStructuredData
            data={parseContentToStructuredData(item.contentJSON)}
            printView
          />
        );
      case 'records.landreg':
        return (
          <>
            {!isEmpty(item.content) ? (
              <RecordsTable data={item} printView />
            ) : (
              ''
            )}
            {renderItemUrl()}
          </>
        );
      default:
        return (
          <>
            {!isEmpty(item.content) ? (
              <p className={classes.content}>{item.content.trim()}</p>
            ) : (
              ''
            )}
            {renderItemUrl()}
          </>
        );
    }
  };

  switch (item.provider) {
    case 'records.landreg':
      return (
        <div className={classes.article}>
          <h1 className={classes.title}>{item.title}</h1>
          <div className={classes.detail}>
            <span className={classes.date}>
              Date retrieved: {formatDate(item.raw_date)}
            </span>
          </div>

          {!isEmpty(item.content) ? <RecordsTable data={item} /> : ''}
          {renderItemUrl()}
        </div>
      );
    case 'compliance.dowjones':
    case 'compliance.worldcompliance':
      // eslint-disable-next-line no-case-declarations
      const providerLabel = find(providers, { id: item.provider })?.label;
      return (
        <div className={classes.article}>
          <h1 className={classes.title}>{item.title}</h1>
          <div className={classes.detail}>
            <span className={classes.date} style={{ marginLeft: 0 }}>
              Date retrieved: {formatDate(item.raw_date)}
            </span>
            <br />
            <span className={classes.sourceName}>{providerLabel}</span>
          </div>
          {renderContent()}
        </div>
      );
    case 'corporate_records.sayari':
      return (
        <div className={classes.article}>
          <h1 className={classes.title}>{item.title}</h1>
          <div className={classes.detail}>
            <span className={classes.date}>{formatDate(item.raw_date)}</span>
          </div>
          {renderContent()}
        </div>
      );
    default:
      return (
        <div className={classes.article}>
          <h1 className={classes.title}>{item.title}</h1>
          <div className={classes.detail}>
            <span className={classes.sourceName}>{item.category}</span>–
            <span className={classes.date}>{formatDate(item.raw_date)}</span>
          </div>
          {renderContent()}
        </div>
      );
  }
};
