import { ListSubheader, MenuItem } from '@mui/material';
import { IAdverseTerm, IAdverseTermCategory } from 'appSrc/types/adverseTerms';
// because MUI doesn't support React.FC for the options we need to use a function to render the options with subheaders
const AdverseTermsOptions = ({
  terms,
  category,
  indent = 0,
}: {
  terms: IAdverseTerm[];
  category: IAdverseTermCategory;
  indent?: number;
}) => {
  const items = [
    <ListSubheader key={category.id} style={{ marginLeft: `${indent * 10}px` }}>
      {category.name}
    </ListSubheader>,
  ];
  category.subcategories?.forEach((subcat) => {
    const subItems = AdverseTermsOptions({
      terms,
      category: subcat,
      indent: indent + 1,
    });
    items.push(...subItems);
  });
  items.push(
    ...terms
      .filter((term) => term.category?.id === category.id)
      .map((term) => (
        <MenuItem
          id='matomo-adverse-term'
          key={term.id}
          value={term.id}
          style={{ marginLeft: `${indent * 10}px` }}
        >
          {term.name}
        </MenuItem>
      ))
  );
  return items;
};
export default AdverseTermsOptions;
