import { uniqueId } from 'lodash';
import Chip from 'components/Base/Chip';
import { removeSnakeCase } from 'helpers/formatting';
import classes from '../../../article.module.scss';
import { Table } from '../../Table';
import { SayariAttribute } from './SayariAttribute';
import { TableList } from '../../TableList';
import { RelationshipDropdowns } from './RelationshipDropdowns';
// eslint-disable-next-line import/no-cycle
import { SayariRecordData } from './SayariRecordData';

const Header = ({ name, type, pep, dob, sanctioned }) => {
  return (
    <div className={classes['image-wrapper']}>
      <div className={classes['image-inner']}>
        <span style={{ textTransform: 'capitalize' }}>{type}</span>
        <h4>{name}</h4>

        {dob && <p>DOB: {dob}</p>}

        <div className={classes.tags}>
          {pep && (
            <Chip
              label='Politcally exposed'
              size='small'
              variant='grey'
              key={`${name}_image-icon--pep`}
            />
          )}
          {sanctioned && (
            <Chip
              label='Sanctioned'
              size='small'
              variant='grey'
              key={`${name}_image-icon--sanctioned`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const SayariStructuredData = ({
  data,
  expanded,
  handleLinkRelated,
  printView = false,
}) => {
  console.log(data);
  const {
    label = '',
    type,
    pep,
    date_of_birth: dateOfBirth,
    sanctioned,
    addresses = [],
    risk,
    identifiers = [],
    relationships,
    attributes,
    referenced_by: referencedBy,
    related_type: relatedType,
    source_count: sourceCount,
  } = data;
  if (relatedType && relatedType === 'record') {
    return <SayariRecordData data={data} expanded={expanded} />;
  }

  const sources = Object.keys(sourceCount || {}).map((key) => sourceCount[key]);

  return (
    <div
      className={printView ? classes['media-print'] : classes['media-screen']}
    >
      <Header
        name={label}
        type={type}
        pep={pep}
        dob={dateOfBirth}
        sanctioned={sanctioned}
      />

      {addresses.length > 0 && (
        <TableList
          title='Addresses'
          rows={addresses.map((address) => {
            return <p key={`key-${address}`}>{address}</p>;
          })}
        />
      )}

      {risk && (
        <div className={classes.structure}>
          <h5 className={classes['structure-sub-title']}>Risk</h5>
          <Table
            headCells={['Type', 'Value']}
            rows={Object.keys(risk).map((key) => [
              removeSnakeCase(key).toUpperCase(),
              risk[key].value ?? 'N/A',
            ])}
          />
        </div>
      )}

      {identifiers.length > 0 && (
        <div className={classes.structure}>
          <h5 className={classes['structure-sub-title']}>Identifiers</h5>
          <Table
            headCells={['Type', 'Value']}
            rows={identifiers.map((row) => [
              row.label.toUpperCase(),
              row.value,
            ])}
          />
        </div>
      )}

      {sources.length > 0 && (
        <div className={classes.structure}>
          <h5 className={classes['structure-sub-title']}>Sources</h5>
          <Table
            headCells={['Source', 'Count']}
            rows={sources.map((row) => [row.label, row.count])}
          />
        </div>
      )}

      {relationships?.data.length > 0 && (
        <RelationshipDropdowns
          title='Relationships'
          expanded={expanded}
          data={relationships.data}
          handleLinkRelated={handleLinkRelated}
        />
      )}

      {attributes &&
        Object.keys(attributes).map((key) => {
          // We don't need to identifier attribute, as it is returned in a better format from the summary
          if (key === 'identifier') {
            return false;
          }

          return (
            <SayariAttribute
              key={key}
              title={key}
              expanded={expanded}
              attribute={attributes[key]}
            />
          );
        })}

      {referencedBy?.data.length > 0 && (
        <div className={classes.structure}>
          <h5 className={classes['structure-sub-title']}>Referenced by</h5>
          <Table
            headCells={['Source', 'Date']}
            rows={referencedBy.data.map((item) => [
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <span
                key={`${item.record?.id || uniqueId()}`}
                className={classes['related-link']}
                onClick={() =>
                  handleLinkRelated([item.record?.id, 'record'], true)
                }
              >
                {item.record?.label ?? 'Unknown'}
              </span>,
              item.record?.publication_date ?? 'Unknown',
            ])}
          />
        </div>
      )}
    </div>
  );
};

export default SayariStructuredData;
