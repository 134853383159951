import axios from 'axios';
import { IAdverseTerm } from 'types/adverseTerms';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitSearch = ({
  projectId,
  query,
  adverseTerm,
  providers,
}: {
  projectId: string;
  query: string[];
  adverseTerm: IAdverseTerm;
  providers: any;
}) => {
  const queryStruct = query.map(() =>
    adverseTerm
      ? [
          {
            type: 'terms',
            value: adverseTerm.id.toString(),
          },
        ]
      : []
  );

  const data = JSON.stringify({
    query,
    providers,
    query_structs: queryStruct,
  });

  const request = axios.post(
    `${apiHost}/api/v1/projects/${projectId}/searches`,
    data,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitSearch;
