import { favouriteAdverseTermThunk } from 'store/thunks/favouriteAdverseTerm';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { selectIsFavouriteTerm } from 'store/slices/adverseTerms';
import { ListItemIcon, Tooltip } from '@mui/material';
import { IAdverseTerm } from 'types/adverseTerms';
import StarIcon from '@mui/icons-material/Star';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { unfavouriteAdverseTermThunk } from 'store/thunks/unfavouriteAdverseTerm';
import { yellow } from '@mui/material/colors';

interface IProps {
  term: IAdverseTerm;
}
const AdverseTermsFavourite: React.FC<IProps> = ({ term }) => {
  const isFavourite = useAppSelector((state) =>
    selectIsFavouriteTerm(state, term.id)
  );
  const dispatch = useAppDispatch();

  return !isFavourite ? (
    <ListItemIcon
      onClick={(e) => {
        e.stopPropagation();
        dispatch(favouriteAdverseTermThunk(term));
      }}
    >
      <Tooltip title='Add to favourite'>
        <StarOutlinedIcon />
      </Tooltip>
    </ListItemIcon>
  ) : (
    <ListItemIcon
      onClick={() => {
        dispatch(unfavouriteAdverseTermThunk(term));
      }}
    >
      <Tooltip title='Remove from favourite'>
        <StarIcon sx={{ color: yellow[600] }} />
      </Tooltip>
    </ListItemIcon>
  );
};
export default AdverseTermsFavourite;
