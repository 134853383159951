import { useCallback } from 'react';
import { UPDATE_STATE } from 'constants/actionTypes';
import { useDispatch } from 'context';
import { fetchTags } from './useFetch';

export default function useHandleAvailableTags() {
  const dispatch = useDispatch();

  return useCallback(
    (projectId) => {
      const status = { ok: false };
      fetchTags(projectId)
        .then((res) => {
          if (res?.data?.aggregations?.tags?.buckets) {
            status.ok = true;
            dispatch({
              type: UPDATE_STATE,
              payload: {
                availableTags: res?.data?.aggregations?.tags?.buckets.map(
                  (tag) => tag.key
                ),
              },
            });
          }
        })
        .finally(() => {
          if (!status.ok)
            dispatch({
              type: UPDATE_STATE,
              payload: { availableTags: [] },
            });
        });
    },
    [dispatch]
  );
}
