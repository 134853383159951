import React from 'react';
import { IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useModals } from 'hooks/ModalManager/useModals';
import { IAdverseTerm } from 'types/adverseTerms';
import classes from '../adverse-terms.module.scss';

const EditTerm = ({ term }: { term: IAdverseTerm }) => {
  const { openAdverseTermsModal } = useModals();

  return (
    <IconButton
      className={`${classes.blackButton}`}
      onClick={() => openAdverseTermsModal(term)}
    >
      <EditOutlinedIcon style={{ color: 'black' }} />
    </IconButton>
  );
};

export default EditTerm;
