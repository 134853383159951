import Button from 'components/Base/Button';
import IconAngleDoubleDown from 'components/Base/Icons/IconAngleDoubleDown';
import React, { useState } from 'react';
import { submitItemFetch } from 'hooks/useFetch';
import classes from '../../../article.module.scss';

type Props = {
  projectId: string;
  providerId: string;
  providerItemId: string;
};

export const SayariFetch = ({
  projectId,
  providerId,
  providerItemId,
}: Props) => {
  const [retrieving, setRetrieving] = useState(false);

  return (
    <div className={classes['no-content']}>
      <p>
        The above data is just a summary of the entity, use the link below if
        you require the full dataset.
      </p>
      <div className={classes['content-links']}>
        <Button
          variant='secondary'
          size='small'
          onClick={() => {
            setRetrieving(true);
            submitItemFetch(projectId, providerItemId, providerId);
          }}
          loading={retrieving}
          label={
            <>
              <IconAngleDoubleDown /> Retrieve full entity
            </>
          }
        />
      </div>
      {retrieving && (
        <div className={classes['content-links-caption']}>
          Retrieving content from remote source, this might take a few moments…
        </div>
      )}
    </div>
  );
};

export default SayariFetch;
