import { Box, TextField } from '@mui/material';
import { Categories, Languages } from 'appSrc/constants';
import CategorySelector from './CategorySelector';
import LanguageSelector from './LanguageSelector';

const AdverseTermsFiltersBar = ({
  categories,
  onCategoryChange,
  onLanguageChange,
  onSearchChange,
  selectedCat,
  selectedLang,
  languages,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        bgcolor: 'background.paper',
        padding: '10px',
        gap: '10px',
      }}
    >
      <TextField
        size='small'
        id='adverse_terms_search'
        label='Search'
        variant='outlined'
        placeholder='search for adverse terms'
        onChange={(e) => onSearchChange(e.target.value)}
      />
      <CategorySelector
        selected={selectedCat}
        categories={categories}
        otherCategories={[
          { id: Categories.ALL, name: 'All' },
          { id: Categories.PERSONAL, name: 'Personal' },
          { id: Categories.FAVOURITES, name: 'Favourite' },
        ]}
        onChange={(e) => onCategoryChange(e.target.value)}
      />
      <LanguageSelector
        selected={selectedLang}
        languages={languages}
        onChange={(e) => onLanguageChange(e.target.value)}
        otherLanguages={[{ id: Languages.ALL, name: 'All', code: '' }]}
      />
    </Box>
  );
};
export default AdverseTermsFiltersBar;
