import { TableCell, TableCellActions, TableRow } from 'components/Base/Table';
import moment from 'moment';
import { useDispatch, useSelector } from 'context';
import { find } from 'lodash';
import { CircularProgress, Tooltip } from '@mui/material';
import {
  calculateProviderStatus,
  ProviderStatus as Status,
} from 'helpers/providers/status';
import { isCombinedProvider } from 'helpers/providers/providers';
import IconButton from 'components/Base/Button/IconButton';
import { SHOW_MODAL } from 'constants/actionTypes';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { SearchHistoryTableProvider } from './SearchHistoryTableProvider';
import classes from './search-history-table.module.scss';
import { RetryButton } from '../../../components/RetryButton';
import { SearchTermSubTitle } from './styled';

export const SearchHistoryTableRow = ({
  search,
  deleteSearch,
  retrySearch,
}) => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const project = useSelector(
    (state) => state.state.project || { loading: true }
  );
  const urlPrefix = `/projects/${project.id}/searches/${search.id}/providers`;

  // Track the status of providers across the whole row, this is calculated based on the "search"
  // state variable, so it doesn't need to be its own state variable.
  const status = calculateProviderStatus(search.providers);

  const getStatusIcon = (currentStatus) => {
    switch (currentStatus) {
      case Status.NONE:
      case Status.LOADING:
        return (
          <CircularProgress
            size={20}
            color='inherit'
            className={classes.spinner}
          />
        );

      case Status.ERROR:
        return (
          <RetryButton
            className={classes['status-icon']}
            retryHandler={retryFailedProviders}
            tooltipText='An error occurred while performing this search, click to retry.'
          />
        );
      default:
        return null;
    }
  };

  const handleDelete = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        variant: 'alert',
        name: 'search',
        type: 'search',
        handleSubmit: () => deleteSearch(search.id),
      },
    });
  };

  const retryFailedProviders = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const provider of search.providers) {
      const query = provider.queries[provider.queries.length - 1];

      // Only retry processed and errored queries.
      if (!query.processed || query.status !== 'search.error') {
        // eslint-disable-next-line no-continue
        continue;
      }

      retrySearch(search.id, provider.id, query.id);
    }
  };

  // Builds and categorises our provider data ready to be displayed.
  const providerData = search.providers
    ?.filter((provider) => {
      return !isCombinedProvider(provider.id);
    })
    .reduce(
      (previousObject, provider) => {
        const category = provider.id.split('.')[0];
        const data = find(config.providers, { id: provider.id });
        return {
          ...previousObject,
          [category]: [...previousObject[category], { ...data, ...provider }],
        };
      },
      { news: [], compliance: [], records: [], corporate_records: [] } // initialObject
    );

  const filters = search?.providers[0]?.queries[0]?.filter || {};
  const subtextData = [
    filters.entity_type,
    filters.country,
    filters.date_of_birth?.full_date,
    filters.gender,
  ]
    .filter(Boolean)
    .join(', ');
  const isSubjectSearch = search?.is_subject_search;

  return (
    // @ts-ignore
    <TableRow className={classes.row}>
      <TableCell>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ minHeight: '3rem' }}>
            <TuneOutlinedIcon
              sx={{
                opacity: isSubjectSearch ? '1' : '0',
                fontSize: '1rem',
                marginRight: '10px',
                marginTop: '9px',
                color: '#6f839e',
              }}
            />
          </div>
          <div>
            <div>
              <Tooltip
                title={search.name}
                disableHoverListener={search.name.length < 45}
              >
                <div className={classes['row--name']}>{search.name}</div>
              </Tooltip>
            </div>
            {isSubjectSearch && (
              <SearchTermSubTitle>
                <div>{subtextData}</div>
              </SearchTermSubTitle>
            )}
          </div>
        </div>
      </TableCell>

      <TableCell>
        <div className={classes.cell}>
          {providerData.news.length
            ? providerData.news.map((item, i) => (
                <SearchHistoryTableProvider
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${search.id}news${i}`}
                  provider={item}
                  prefix={urlPrefix}
                  retrySearch={(providerId, searchId) =>
                    retrySearch(search.id, providerId, searchId)
                  }
                />
              ))
            : '-'}
        </div>
      </TableCell>

      <TableCell>
        <div className={classes.cell}>
          {providerData.compliance.length
            ? providerData.compliance
                .filter((provider) => !isCombinedProvider(provider.id))
                .map((item, i) => (
                  <SearchHistoryTableProvider
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${search.id}compliance${i}`}
                    provider={item}
                    prefix={urlPrefix}
                    retrySearch={(providerId, searchId) =>
                      retrySearch(search.id, providerId, searchId)
                    }
                  />
                ))
            : '-'}
        </div>
      </TableCell>

      <TableCell>
        <div className={classes.cell}>
          {providerData.records.length
            ? providerData.records.map((item, i) => (
                <SearchHistoryTableProvider
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${search.id}records${i}`}
                  provider={item}
                  prefix={urlPrefix}
                  retrySearch={(providerId, searchId) =>
                    retrySearch(search.id, providerId, searchId)
                  }
                />
              ))
            : '-'}
        </div>
      </TableCell>

      <TableCell>
        <div className={classes.cell}>
          {providerData.corporate_records.length
            ? providerData.corporate_records.map((item, i) => (
                <SearchHistoryTableProvider
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${search.id}corporate_records${i}`}
                  provider={item}
                  prefix={urlPrefix}
                  retrySearch={(providerId, searchId) =>
                    retrySearch(search.id, providerId, searchId)
                  }
                />
              ))
            : '-'}
        </div>
      </TableCell>

      <TableCell>
        {moment(search.providers[0].queries[0].when).format(
          'DD MMM YYYY hh:mma'
        )}
      </TableCell>

      <TableCell className={classes.statusColumn}>
        {getStatusIcon(status)}
      </TableCell>

      <TableCell>
        <TableCellActions>
          <IconButton onClick={handleDelete} variant='delete' />
        </TableCellActions>
      </TableCell>
    </TableRow>
  );
};

export default SearchHistoryTableRow;
