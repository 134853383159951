import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-root {
    input {
      padding: 8.5px 14px !important;
    }
    &.Mui-focused {
      fieldset {
        border-color: #526988;
      }
    }
  }
`;

export default StyledDatePicker;
