import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { StructuredRow } from '../StructuredRow';
import classes from '../../../article.module.scss';

export const MatchInformationSection = ({
  matchScore,
  matchStrength,
  matchedNameType,
}) => {
  return (
    <Accordion className={classes.structure}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <h4 className={classes['structure-title']}>Match Information</h4>
      </AccordionSummary>
      <AccordionDetails>
        <StructuredRow
          label='Match Confidence'
          value={`${matchScore}% (${matchStrength})`}
        />
        <StructuredRow label='Matched Name Type' value={matchedNameType} />
      </AccordionDetails>
    </Accordion>
  );
};

export default MatchInformationSection;
