import { useEffect, useState, useCallback, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { isEmpty } from 'lodash';
import { ERROR_DISPLAYED } from 'constants/actionTypes';
import { useDispatch, useSelector } from 'context';
import classes from './error.module.scss';

const Alert = forwardRef(function Alert(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Flash = () => {
  const [open, setOpen] = useState(false);
  const error = useSelector((state) => state.error);

  const dispatch = useDispatch();

  const handleClose = useCallback(
    (_, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
      dispatch({ type: ERROR_DISPLAYED });
    },
    [dispatch]
  );

  useEffect(() => {
    setOpen(!isEmpty(error));
    const timeout = setTimeout(() => handleClose(), 8000);
    return () => clearTimeout(timeout);
  }, [error, handleClose]);

  const renderError = () => {
    if (typeof error === 'string') return error;
    if (typeof error?.message === 'string' && typeof error?.detail === 'string')
      return (
        <>
          <div>{error.message}</div>
          <small className={classes['error-detail']}>{error.detail}</small>
        </>
      );
    if (typeof error?.message === 'string') return error?.message;
    if (typeof error?.[0] === 'string') return error?.[0];
    if (typeof error?.error?.reason === 'string') return error.error.reason;
    if (Array.isArray(error?.message) && typeof error?.message[0] === 'string')
      return error.message[0];
    return 'An error occurred during that process.';
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        classes={{ root: classes.snackbar }}
      >
        <Alert onClose={handleClose} severity='error'>
          {/* The `open &&` prevents flash of default message on close */}
          {open && renderError()}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Flash;
