import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProjectItem } from 'hooks/useFetch';
import {
  SkeletonExportArticle,
  FullExportArticle,
} from 'components/Export/ExportArticle';
import { serialiseData } from 'hooks/useSerialiser';

const ExportArticle = () => {
  const { project_id: projectId, item_id: itemId } = useParams();
  const [data, setData] = useState({ processing: true });

  useEffect(() => {
    fetchProjectItem(projectId, itemId).then((res) => {
      const item = serialiseData({
        item: res.hits.hits[0],
      });
      setData(item);
    });
  }, [itemId, projectId]);

  return (
    <div className='print-theme'>
      {data.processing ? (
        <SkeletonExportArticle />
      ) : (
        <FullExportArticle item={data} />
      )}
    </div>
  );
};

export default ExportArticle;
