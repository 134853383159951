import { useAppSelector } from 'hooks/storeHooks';

import {
  selectFavouriteAdverseTerms,
  selectTermsForCategory,
} from 'store/slices/adverseTerms';
import { IAdverseTerm, IAdverseTermCategory } from 'types/adverseTerms';
import { Languages } from 'appSrc/constants';
import AdverseTermItem from './AdverseTermItem';

const AdverseTermsList = ({
  category,
  selectedLang,
  searchedTerms,
  isFavouriteSelected,
}: {
  category: IAdverseTermCategory;
  selectedLang: number | null;
  searchedTerms: IAdverseTerm[];
  isFavouriteSelected: boolean;
}) => {
  const categoryTerms = useAppSelector((state) =>
    selectTermsForCategory(state, category.id)
  );
  const favouriteTerms = useAppSelector(selectFavouriteAdverseTerms);
  const filteredTerms = categoryTerms.filter((t) => {
    const matchesFavourite =
      !isFavouriteSelected ||
      favouriteTerms.data.some((favouriteTerm) => t.id === favouriteTerm.id);
    const matchesLanguage =
      selectedLang === Languages.ALL || t.language.id === selectedLang;
    const matchesSearch =
      searchedTerms?.length > 0
        ? searchedTerms.some((searchedTerm) => t.id === searchedTerm.id)
        : true; // searched terms are there and we filter by name otherwise always true for initial state
    return matchesFavourite && matchesLanguage && matchesSearch;
  });

  return (
    <div>
      {filteredTerms.map((term) => (
        <AdverseTermItem key={term.id} term={term} />
      ))}
    </div>
  );
};
export default AdverseTermsList;
