import { Table } from '../../Table';
import classes from '../../../article.module.scss';

export const LocationsSection = ({ addresses }) =>
  addresses ? (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Addresses</h4>
      <Table
        headCells={['Street', 'City', 'Region', 'Country', 'Postcode']}
        rows={addresses.map((address) => {
          const { street, city, region, country, postcode } = address;
          return [street, city, region, country.code, postcode];
        })}
      />
    </div>
  ) : null;

export default LocationsSection;
