import React from 'react';
import classNames from 'classnames';
import classes from './fields-base.module.scss';

export type CheckboxProps = {
  variant?: string;
  size?: string;
  label: string;
  fullWidth?: boolean;
  checked?: boolean;
  onClick: (value: any) => void;
  value?: string;
  className?: string;
  labelClassName?: string;
  style?: any;
};

const Checkbox = ({
  variant,
  size,
  label,
  fullWidth,
  checked,
  className,
  labelClassName,
  onClick,
  style,
  ...props
}: CheckboxProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={classNames(
        classes['checkbox-wrapper'],
        classes[`checkbox-wrapper--${size}`],
        classes[`checkbox-wrapper--${variant}`],
        className
      )}
      style={style}
    >
      <input
        type='checkbox'
        className={classes.checkbox}
        checked={checked}
        onChange={onClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <span className={classes.checkmark} />
      <span
        className={classNames(classes['checkbox-label'], labelClassName, {
          [classes['checkbox-label--full-width']]: fullWidth,
        })}
      >
        {typeof label === 'string' || typeof label === 'object' ? label : '-'}
      </span>
    </label>
  );
};

export default Checkbox;
