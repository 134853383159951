import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { defaultState, useDispatch, useSelector } from 'context';
import Layout from 'layout/Layout';
// eslint-disable-next-line import/no-cycle
import SearchHeader from 'components/SearchHeader';
import Spinner from 'components/Base/Spinner';
import { isEmpty, reverse } from 'lodash';
import FindingsResultsGrid from 'components/FindingsResultsGrid';
import { appRoutingPaths } from 'router';
import { reverse as url } from 'named-urls';
import useHandleAvailableTags from 'hooks/useHandleAvailableTags';
import { fetchProject } from 'hooks/useFetch';
import { UPDATE_STATE } from 'constants/actionTypes';
import FindingsEmitterContainer from './FindingsEmitterContainer';

const Search = ({ ...props }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const project = useSelector((state) => state.state.project);
  const searches = useSelector((state) => state.state.searches);
  const search = useSelector((state) => state.state.search);
  const provider = useSelector((state) => state.state.provider);
  const queryId = useSelector((state) => state.state.queryId);
  const query = useSelector((state) => state.state.query);
  const findings = useSelector((state) => state.state.findings);
  const { project_id: projectId } = params;
  const handleAvailableTags = useHandleAvailableTags();
  const runOnce = useRef(false);

  useEffect(() => {
    if (runOnce.current) return;
    runOnce.current = true;

    if (project?.id !== projectId || !searches) {
      const status = { ok: false };
      fetchProject(projectId)
        .then((res) => {
          if (!res?.id) return;
          status.ok = true;
          dispatch({
            type: UPDATE_STATE,
            payload: {
              ...defaultState,
              project: { ...res, loading: false },
              searches: reverse(res?.searches) || [],
            },
          });
        })
        .finally(() => {
          if (!status.ok) navigate(url(appRoutingPaths.projects.all));
        });

      return;
    }

    dispatch({
      type: UPDATE_STATE,
      payload: {
        ...defaultState,
        findings,
        query,
        queryId,
        project,
        search,
        searches,
        provider,
        tags: [],
      },
    });
  }, [
    dispatch,
    findings,
    navigate,
    project,
    projectId,
    provider,
    query,
    queryId,
    search,
    searches,
  ]);

  // Available Tags
  useEffect(() => {
    handleAvailableTags(projectId);
  }, [projectId, handleAvailableTags]);

  return (
    <Layout
      project={project?.name}
      title={project?.name}
      header=''
      variant='findings'
      isScrollable={false}
    >
      {!isEmpty(project) && !project?.loading ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FindingsEmitterContainer {...props}>
          <SearchHeader variant='findings' />
          {searches ? <FindingsResultsGrid /> : <Spinner />}
        </FindingsEmitterContainer>
      ) : (
        <Spinner />
      )}
    </Layout>
  );
};

export default Search;
