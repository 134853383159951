import { useDispatch, useSelector } from 'context';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { getTranslatedCount, getTranslatedReqCount } from 'utils';
import { UPDATE_STATE } from 'constants/actionTypes';
import { submitTranslationById } from './useFetch';

export default function useHandleTranslation(variant) {
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.state.project);
  const data = useSelector((state) => state.state.data);
  const translate = useSelector((state) => state.state.translate);
  const findingsFilter = useSelector((state) => state.state.findingsFilter);

  return () => {
    if (!translate.items) {
      trackEvent({
        category: 'search',
        action: 'translate_search_results',
      });

      dispatch({
        type: UPDATE_STATE,
        payload: {
          translate: { ...translate, items: true },
        },
      });

      if (getTranslatedCount(data.items) < getTranslatedReqCount(data.items)) {
        // Chunking submits because Findings lists can have mixed providers

        const providers = {};
        data.items.filter((item) => {
          if (item._source?.headline_trans || item._source.language === 'en')
            return false;
          if (providers?.[item._source.provider.id])
            providers[item._source.provider.id].push(item._id);
          else providers[item._source.provider.id] = [item._id];
          return true;
        });

        Object.keys(providers).map((k) => {
          submitTranslationById({
            projectId: project.id,
            providerId: k,
            docs: providers[k],
          }).then((res) => {
            if (res?.status === 204) {
              dispatch({
                type: UPDATE_STATE,
                payload:
                  variant === 'findings'
                    ? {
                        findingsFilter: {
                          ...findingsFilter,
                          apply: Math.random(),
                        },
                      }
                    : { data: { ...data, loadingPassive: true } },
              });
            }
          });
          return true;
        });
      }
    } else {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          translate: { ...translate, items: false },
        },
      });
    }
  };
}
