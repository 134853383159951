/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IAdverseTermCategoryDraft,
  IAdverseTermsSlice,
} from 'types/adverseTerms';
import createAdverseTermCategory from 'api/adverseTerms/createAdverseTermCategory';

export const createAdverseTermCategoryThunk = createAsyncThunk(
  'adverseTerms/createAdverseTermCategory',
  async (category: IAdverseTermCategoryDraft) => {
    return createAdverseTermCategory(category);
  }
);

export const createAdverseTermCategoryExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(createAdverseTermCategoryThunk.fulfilled, (state, action) => {
    if (action.payload.data) {
      state.categories.data.push(action.payload.data);
    }
  });
};
