import { ProviderData, ProviderId, ProviderType } from 'constants/providers';

export const isNewsProvider = (providerId: string): boolean => {
  return providerId.split('.')[0].toLocaleLowerCase() === 'news';
};

export const isComplianceProvider = (providerId: string): boolean => {
  return providerId.split('.')[0].toLocaleLowerCase() === 'compliance';
};

export const isCombinedProvider = (providerId: string): boolean => {
  return providerId.split('.')[1].toLocaleLowerCase() === 'combined';
};

export const getProviderLabel = (providerId: ProviderId): string => {
  return ProviderData[providerId].label;
};

export const getProviderType = (providerId: ProviderId): ProviderType => {
  return ProviderData[providerId]?.providerType || null;
};
