/* eslint-disable import/no-cycle */
import React, { FunctionComponent } from 'react';
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import LoginVerify from 'pages/LoginVerify';
import Logout from 'pages/Logout';
import Search from 'pages/Search';
import Projects from 'pages/Projects/Projects';
import Project from 'pages/Project/Project';
import ExportArticle from 'pages/ExportArticle';
import Findings from 'pages/Findings';
import AdverseTerms from 'pages/AdverseTerms';
import ExportFindings from 'pages/ExportFindings';
import { useSelector } from 'context';
import ErrorBoundary from 'pages/ErrorBoundary';
import ModalManagerProvider from 'hooks/ModalManager/ModalManagerProvider';
import { include } from 'named-urls';
import Login from '../pages/Login';
import Matomo from '../containers/Matomo';
import { ProtectedRoute } from './ProtectedRoute';

export const appRoutingPaths = {
  start: '/',
  login: '/login',
  logout: '/logout',
  login_verify: '/login/verify',
  projects: include('/projects', {
    all: '',
    project: include(':project_id/', {
      show: '',
      findings: 'findings',
      findings_export: 'findings/export',
      searches: include('searches/', {
        all: '',
        search: include(':search_id/', {
          show: '',
          provider: include('providers/:provider_id/', {
            show: '',
            query: include('queries/:query_id/', {
              show: '',
              item: 'items/:item_id/',
              export: 'items/:item_id/export',
            }),
          }),
        }),
      }),
    }),
  }),
  settings: include('settings/', {
    adverse_terms: 'adverse-terms',
  }),
};

export const SwanRouter: FunctionComponent = () => {
  const user = useSelector((state) => state.user);

  const router = createBrowserRouter([
    {
      path: appRoutingPaths.login_verify,
      element: <LoginVerify />,
    },
    {
      path: appRoutingPaths.login,
      element: <Login />,
    },
    {
      element: (
        <ModalManagerProvider>
          <Matomo user={user} />
        </ModalManagerProvider>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: appRoutingPaths.logout,
              element: <Logout />,
            },
            {
              path: appRoutingPaths.projects.all,
              element: <Projects />,
            },
            {
              path: appRoutingPaths.projects.project.show,
              element: <Project />,
            },
            {
              path: appRoutingPaths.projects.project.searches.search.show,
              element: <Search />,
            },
            {
              path: appRoutingPaths.projects.project.searches.search.provider
                .query.show,
              element: <Search />,
            },
            {
              path: appRoutingPaths.projects.project.searches.search.provider
                .query.item,
              element: <Search />,
            },
            {
              path: appRoutingPaths.projects.project.searches.search.provider
                .query.export,
              element: <ExportArticle />,
            },
            {
              path: appRoutingPaths.projects.project.findings,
              element: <Findings />,
            },
            {
              path: appRoutingPaths.settings.adverse_terms,
              element: <AdverseTerms />,
            },
            {
              path: appRoutingPaths.projects.project.findings_export,
              element: <ExportFindings />,
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Navigate to='/projects' />
        </ProtectedRoute>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default SwanRouter;
