import { handleError } from 'hooks/useFetch';
import apiClient from 'api/apiClient';
import { IAdverseTerm, IAdverseTermDraft } from 'types/adverseTerms';

const upsertAdverseTerm = async (term: IAdverseTermDraft) => {
  try {
    return await apiClient<IAdverseTerm>({
      method: 'POST',
      endpoint: 'terms',
      version: 'v2',
      data: term,
    });
  } catch (err) {
    handleError(err);
    return { data: null, error: err };
  }
};

export default upsertAdverseTerm;
