/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'context';
import { find } from 'lodash';
import { Fade } from '@mui/material';
import { Done, ArrowForwardIos } from '@mui/icons-material';
import Table, { TableCell, TableRow } from 'components/Base/Table';
import moment from 'moment';

import classes from './search-dropdown.module.scss';

const SearchDropdown = ({ handleRedirect }) => {
  const [show, setShow] = useState(false);
  const config = useSelector((state) => state.config);
  const project = useSelector((state) => state.state.project);
  const search = useSelector((state) => state.state.search);

  const providersReduced = (providers) =>
    providers?.reduce(
      (acc, provider) => {
        const category = provider.id.split('.')[0];
        const data = find(config.providers, { id: provider.id });
        return {
          ...acc,
          [category]: [
            ...((acc[category] && acc[category]) || []),
            { ...data, ...provider },
          ],
        };
      },
      { news: [], compliance: [], records: [] }
    );

  return (
    <div>
      <div className={classes.button} onClick={() => setShow(!show)}>
        <ArrowForwardIos style={{ fontSize: '12px' }} />
        <span>{search.name}</span>
      </div>
      {show ? (
        <>
          <div className={classes.overlay} onClick={() => setShow(false)} />{' '}
          <Fade in={show} mountOnEnter unmountOnExit>
            <div className={classes.modal}>
              {/* @ts-ignore */}
              <Table
                headCells={[
                  '',
                  'Search Term',
                  'News',
                  'Compliance',
                  'Corporate Registries',
                  'Created on',
                ]}
                // rows={[]}
                rows={project.searches?.map(
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  ({ name, providers, ...search }) => {
                    if (!providers) return null;
                    const reduced = providersReduced(providers);
                    return (
                      <TableRow
                        onClick={() => {
                          setShow(false);
                          handleRedirect({
                            queryId:
                              providers[0].queries[
                                providers[0].queries.length - 1
                              ].id,
                            providerId: providers[0].id,
                            searchId: search.id,
                            useProjectSearches: true,
                          });
                        }}
                        key={`key-${search.id}`}
                      >
                        <TableCell>
                          {search?.id === search.id ? (
                            <Done fontSize='inherit' />
                          ) : null}
                        </TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>
                          {reduced.news.length
                            ? reduced.news.map((item) => item.label).join(', ')
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {reduced.compliance.length
                            ? reduced.compliance
                                .map((item) => item.label)
                                .join(', ')
                            : '-'}
                        </TableCell>

                        <TableCell>
                          {reduced.records.length
                            ? reduced.records
                                .map((item) => item.label)
                                .join(', ')
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {moment(providers[0].queries[0].when).format(
                            'DD MMM YYYY hh:mma'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                paginate={false}
              />
            </div>
          </Fade>
        </>
      ) : null}
    </div>
  );
};

export default SearchDropdown;
