import React from 'react';
import { ButtonBase, CircularProgress } from '@mui/material';

import classNames from 'classnames';
import classes from './button.module.scss';

type ButtonProps = {
  label: string | JSX.Element;
  removeLabel?: string | JSX.Element;
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  type?: string;
  variant?: string;
  className?: string;
  size?: any;
  // TODO - think about removing these props
  minWidth?: string;
  margin?: string;
  fullWidth?: boolean;
  target?: any;
  style?: any;
  href?: string;
  id?: string;
};

const Button = ({
  id,
  label,
  removeLabel,
  onClick,
  disabled,
  loading,
  type,
  variant,
  className,
  size,
  fullWidth,
  target,
  href,
  style,
  ...props
}: ButtonProps) => {
  return (
    <ButtonBase
      id={id}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={classNames(
        classes.button,
        classes[`button--${variant}`],
        {
          [classes[`button--${size}`]]: size,
          [classes['button--full-width']]: fullWidth,
        },
        className
      )}
      target={target}
      href={href}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={style}
    >
      <span
        className={classNames(classes.label, {
          [classes['label--loading']]: loading,
        })}
        // style={{ visibility: loading ? "hidden" : "visible" }}
      >
        {label}
      </span>
      {removeLabel ? (
        <span className={classNames(classes.label, classes.remove)}>
          {removeLabel}
        </span>
      ) : null}
      {loading && (
        <CircularProgress
          size={14}
          color='inherit'
          className={classes.spinner}
        />
      )}
    </ButtonBase>
  );
};

export default Button;
