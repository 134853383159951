import { IconButton, SvgIcon } from '@mui/material';
import { PlaylistAdd, PlaylistAddCheck } from '@mui/icons-material';
import classNames from 'classnames';
import classes from './results.module.scss';

export const FindingControl = ({ id, isSaved, tagFinding }) => {
  if (isSaved)
    return (
      <IconButton
        className={classes['findings-button']}
        size='small'
        onTouchStart={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          tagFinding({ item: id });
        }}
      >
        <PlaylistAddCheck className={classes['findings-icon']} />
        <SvgIcon
          className={classNames(
            classes['findings-icon'],
            classes['findings-icon--unsave']
          )}
        >
          <path
            d='M2 6v2h12V6H2m0 4v2h9v-2H2m12.17.76l-1.41 1.41L15.59 15l-2.83 2.83l1.41 1.41L17 16.41l2.83 2.83l1.41-1.41L18.41 15l2.83-2.83l-1.41-1.41L17 13.59l-2.83-2.83M2 14v2h9v-2H2z'
            fill='currentColor'
          />
        </SvgIcon>
      </IconButton>
    );
  return (
    <IconButton
      className={classNames(
        classes['findings-button'],
        classes['findings-button--unsaved']
      )}
      size='small'
      onTouchStart={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        tagFinding({ item: id });
      }}
    >
      <PlaylistAdd className={classes['findings-icon']} />
    </IconButton>
  );
};

export default FindingControl;
