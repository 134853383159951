import { DialogActions, IconButton, styled } from '@mui/material';

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 10px;
`;

export const DialogueContentContainer = styled('div')`
  height: 800px;
`;

export const DialogueActionsContainer = styled(DialogActions)`
  padding: 20px !important;
`;
