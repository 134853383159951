/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classes from '../article.module.scss';

const RawJSONData = ({ data, raw = false }) => {
  return (
    <div className={classes['raw-json-data']}>
      <span
        onClick={(e) =>
          navigator.clipboard.writeText(e.target.nextElementSibling.textContent)
        }
      >
        Copy
      </span>
      <pre
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            // eslint-disable-next-line no-nested-ternary
            raw
              ? data
              : typeof data.contentJSON === 'object'
              ? data.contentJSON
              : JSON.parse(data.contentJSON),
            null,
            2
          ),
        }}
      />
    </div>
  );
};

export default RawJSONData;
