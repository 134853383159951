import classNames from 'classnames';
import classes from '../article.module.scss';
import { Table } from './Table';

type Props = {
  title?: string;
  rows: string[];
  className?: string;
};

export const TableList = ({ title, rows, className }: Props) => {
  return (
    <div className={classNames(classes.structure, className)}>
      {title && <h4 className={classes['structure-title']}>{title}</h4>}

      <Table rows={rows.map((r) => [r])} />
    </div>
  );
};

export default TableList;
