import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { IAdverseTerm } from 'types/adverseTerms';

interface AdverseTermCells extends IAdverseTerm {
  actions: string;
}
interface HeadCell {
  id: keyof AdverseTermCells;
  label: string;
  disableSorting?: boolean;
}
interface AdverseTermRowProps {
  order: 'asc' | 'desc'; // desc asc
  orderBy: string | symbol | number; // column name
  onSort: (property: keyof AdverseTermCells) => void;
}
const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    label: 'Adverse Term',
  },
  {
    id: 'language',
    label: 'Language',
  },
  {
    id: 'queries',
    label: 'Terms',
    disableSorting: true,
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'actions',
    label: 'Actions',
    disableSorting: true,
  },
];

const AdverseTermHead: React.FC<AdverseTermRowProps> = ({
  order,
  orderBy,
  onSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) =>
          headCell.disableSorting ? (
            <TableCell key={headCell.id}>{headCell.label}</TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};
export default AdverseTermHead;
