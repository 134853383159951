/* eslint-disable react/jsx-props-no-spreading */
import FindingsFacets from '../FindingsFacets';
// eslint-disable-next-line import/no-cycle
import FindingsResults from '../FindingsResults';
import SearchArticle from '../Article';
import classes from './search-results-grid.module.scss';

const FindingsResultsGrid = ({ ...props }) => {
  return (
    <div className={classes.container}>
      <FindingsFacets />
      <FindingsResults {...props} />
      {/* @ts-ignore */}
      <SearchArticle disableRelated {...props} />
    </div>
  );
};

export default FindingsResultsGrid;
