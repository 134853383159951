// eslint-disable-next-line import/extensions
import { Emitter, EmitterMessage } from 'emitter-io';
import {
  AsyncMessage,
  FilterFunc,
  HandlerFunc,
  WebsocketConfig,
} from './types';

interface MessageHandlerMap<T> {
  [Key: string]: Array<T>;
}

interface MessageHandlerEntry {
  id: string;
  func: HandlerFunc;
}

interface MessageFilterEntry {
  id: string;
  func: FilterFunc;
}

class AsyncMessageProcessor {
  messageFilterFunctions: Array<MessageFilterEntry>;

  messageHandlerMap: MessageHandlerMap<MessageHandlerEntry>;

  env: string;

  wsClient: Emitter;

  wsClientConnected: boolean;

  constructor(env) {
    this.wsClient = null;
    this.wsClientConnected = false;
    this.messageFilterFunctions = [];
    this.messageHandlerMap = {};
    this.env = env;
  }

  handleMessage = (message: AsyncMessage): void => {
    const { status } = message;
    console.log(`message with status ${status} received`, message);
    const handlers = this.messageHandlerMap[status] || [];
    if (handlers.length === 0) {
      if (['development', 'integration', 'staging'].includes(this.env)) {
        console.log('Unhandled emitter message:', message.status);
      }
    }
    if (
      this.messageFilterFunctions.every(({ func: filterFn }) =>
        filterFn(message)
      )
    ) {
      handlers.forEach((handler) => handler.func(message));
    }
  };

  registerHandler = (
    status: string,
    handlerId: string,
    handlerFunction: HandlerFunc
  ): void => {
    const existingHandlersForStatus = this.messageHandlerMap[status] || [];
    this.messageHandlerMap[status] = [
      ...existingHandlersForStatus,
      {
        id: handlerId,
        func: handlerFunction,
      },
    ];
  };

  deregisterHandler = (status, handlerId): void => {
    this.messageHandlerMap[status] = this.messageHandlerMap[status].filter(
      (handler) => handler.id !== handlerId
    );
  };

  registerMessageFilter = (filterId: string, filterFunc: FilterFunc) => {
    this.messageFilterFunctions = [
      ...this.messageFilterFunctions,
      {
        id: filterId,
        func: filterFunc,
      },
    ];
  };

  deregisterMessageFilter = (filterId: string) => {
    const filtersWithoutRemovedValue = this.messageFilterFunctions.filter(
      (filter) => filter.id !== filterId
    );
    this.messageFilterFunctions = filtersWithoutRemovedValue;
  };

  setupClient = (websocketConfig: WebsocketConfig) => {
    const { host, port, secure, key, channel } = websocketConfig;
    console.log('setting up websocket client with config');

    const client = new Emitter();
    const connect = () => {
      console.log('attempting to connect to websocket');
      client.connect({
        host,
        port,
        keepalive: 30,
        secure,
      });
    };
    connect();
    client.on('connect', () => {
      this.wsClientConnected = true;
      console.log('websocket connected and subscribing to channel');
      client.subscribe({
        key,
        channel,
      });
    });

    client.on('error', (error) => {
      console.error(`websocket error: ${error}`);
    });

    client.on('disconnect', () => {
      console.error('websocket disconnected');
      this.wsClientConnected = false;
    });

    client.on('message', (msg: EmitterMessage) => {
      console.log('Calling handleMessage now');
      const parsedMessage: AsyncMessage = JSON.parse(msg.asString());
      this.handleMessage(parsedMessage);
    });

    this.wsClient = client;
  };
}

export default AsyncMessageProcessor;
