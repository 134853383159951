import { sortBy } from 'lodash';
import { NA_VAL } from './constants';

const createFacetBucketEntry = (key, count) => ({
  value: key,
  display: key,
  count,
});

const createFacetBucketArray = (
  missingBucketValue = 0,
  presentBuckets = [],
  filteredBucketKeys = []
) => {
  const missingValueBucketEntry = missingBucketValue
    ? [createFacetBucketEntry(NA_VAL, missingBucketValue)]
    : [];

  const aggregateBucketEntries = presentBuckets.map((entry) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { key, doc_count } = entry;
    return createFacetBucketEntry(key, doc_count);
  });

  const filteredBucketEntries = filteredBucketKeys
    .filter((filteredKey) => filteredKey.slice(-1) === 'x')
    .map((filteredKey) => {
      const filteredKeyWithoutExChar = filteredKey.slice(0, -1);
      return createFacetBucketEntry(filteredKeyWithoutExChar, -1);
    });

  return [
    ...missingValueBucketEntry,
    ...aggregateBucketEntries,
    ...filteredBucketEntries,
  ];
};

export const addBucketValuesToAvailableFacets = (
  selectedFacets,
  availableFacets,
  facetBuckets = [],
  facetMissingBuckets = {}
) => {
  /*
   *  Transform results buckets arrays into { [key] : { bucket }, ...} for instant lookup.
   */
  const presentBucketsLookup = facetBuckets.reduce((lookup, presentBuckets) => {
    return {
      ...lookup,
      [presentBuckets.key]: presentBuckets.facets.values.buckets,
    };
  }, {});
  const missingBucketLookup = Object.keys(facetMissingBuckets).reduce(
    (lookup, key) => {
      const missingDocumentCount = facetMissingBuckets[key].doc_count;
      return {
        ...lookup,
        [key]: missingDocumentCount,
      };
    },
    {}
  );

  const facetsWithBucketValues = availableFacets.map((facet) => {
    const facetKey = facet.provider_control;
    const buckets = createFacetBucketArray(
      missingBucketLookup[facetKey],
      presentBucketsLookup[facetKey],
      selectedFacets[facetKey]
    );
    if (facetKey === 'year_of_birth') {
      return {
        ...facet,
        buckets: sortBy(buckets, ['display']),
      };
    }
    return {
      ...facet,
      buckets,
    };
  });

  return facetsWithBucketValues;
};

export default addBucketValuesToAvailableFacets;
