import classes from 'components/SearchBar/search-bar.module.scss';
import Chip from 'components/Base/Chip';
import IconButton from 'components/Base/Button/IconButton';
import { showAdverseTermsPopout } from 'store/slices/ui';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import {
  clearSelectedAdverseTerm,
  selectSelectedAdverseTerm,
} from 'store/slices/adverseTerms';

const AdverseTermChip = () => {
  const dispatch = useAppDispatch();
  const { term, parentCategory } = useAppSelector(selectSelectedAdverseTerm);

  const termsLang = term?.language?.code.toUpperCase() || '';

  return (
    <div className={classes['provider-item__terms']}>
      Adverse Terms:{' '}
      {term ? (
        <div className={classes.terms}>
          <div className={classes.term}>
            <span>
              {parentCategory && `${parentCategory.name} / `}
              {term.category && `${term.category.name} / `}
              <strong>{term?.name}</strong>{' '}
              <Chip label={termsLang} variant='grey' size='small' />
              <IconButton
                variant='close'
                onClick={() => {
                  dispatch(clearSelectedAdverseTerm());
                }}
              />
            </span>
          </div>
        </div>
      ) : (
        <IconButton
          variant='add'
          onClick={() => dispatch(showAdverseTermsPopout())}
        />
      )}
    </div>
  );
};

export default AdverseTermChip;
