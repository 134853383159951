// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { styled } from '@mui/material/styles';
import MUIChip from '@mui/material/Chip';

const StyledChip = styled(MUIChip)`
  &.MuiChip-filled {
    border: 1px solid transparent;
    background-color: white;

    .MuiChip-label {
      color: black;
    }
    .MuiChip-deleteIcon {
      color: rgba(255, 255, 255, 0.8);
    }
  }
`;

export default StyledChip;
