import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Chip from 'components/Base/Chip';
import { IAdverseTermLanguage } from 'types/adverseTerms';
import React from 'react';

interface IProps {
  languages: IAdverseTermLanguage[];
  selected: number | null;
  onChange: (event) => void;
  otherLanguages?: IAdverseTermLanguage[];
  [x: string]: any;
}

const LanguageSelector: React.FC<IProps> = ({
  languages,
  selected,
  onChange,
  otherLanguages = [],
  ...props
}) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 130 }} size='small' {...props}>
      <InputLabel id='adverse_terms_language'>Language</InputLabel>
      <Select
        labelId='adverse_terms_language'
        id='adverse_terms_language'
        value={selected}
        label='Language'
        onChange={onChange}
      >
        {[...otherLanguages, ...languages].map((lang: IAdverseTermLanguage) => {
          return (
            <MenuItem key={lang.id} value={lang.id}>
              <span style={{ marginRight: '10px' }}>{lang.name} </span>{' '}
              {lang.code !== '' && (
                <Chip
                  label={lang.code.toUpperCase()}
                  size='small'
                  variant='grey'
                  onClick={null}
                />
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default LanguageSelector;
