import { styled } from '@mui/material';

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 25px;
`;

export const HeaderText = styled('h1')`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const SubheaderText = styled('h2')`
  color: #8e8e8e;
`;
