import { v4 as uuidv4 } from 'uuid';
import { Subject } from '../types';

export const getDefaultSubject = (): Subject => ({
  type: '',
  name: '',
  country: '',
  dob: '',
  gender: '',
  id: uuidv4(),
  isValid: false,
  termId: 0,
});

export default getDefaultSubject;
