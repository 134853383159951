/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { IAdverseTerm, IAdverseTermsSlice } from 'types/adverseTerms';
import favouriteAdverseTerm from 'api/adverseTerms/favouriteAdverseTerm';

export const favouriteAdverseTermThunk = createAsyncThunk(
  'adverseTerms/favouriteAdverseTerm',
  async (term: IAdverseTerm) => {
    return favouriteAdverseTerm(term);
  }
);

export const favouriteAdverseTermExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(favouriteAdverseTermThunk.fulfilled, (state, action) => {
    const term = action.payload.data;
    if (term && !state.favourites.data.some((t) => t.id === term.id)) {
      state.favourites.data.push(term);
    }
  });
};
