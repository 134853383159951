import React from 'react';
import { capitaliseWords, removeSnakeCase } from 'helpers/formatting';
import { DropdownRow } from 'components/Base/DropdownRow';
import { TableList } from '../../TableList';
import articleClasses from '../../../article.module.scss';
import { KeyValueTable } from '../../../ContentTypes/KeyValueTable';

type Props = {
  title: string;
  attribute: any;
  expanded: boolean;
};

export const SayariAttribute = ({ title, attribute, expanded }: Props) => {
  if (!attribute.Data) {
    return null;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const DropdownAttribute = ({ type, properties }) => {
    const count = Object.keys(properties).length;

    const getTitle = () => {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'address':
          if (properties.x && properties.y) {
            // y = latitude, x = longitude
            return (
              <a
                href={`https://www.google.com/maps/place/${properties.y},${properties.x}`}
                rel='noopener noreferrer'
                target='_blank'
                className={articleClasses['data-link']}
              >
                {properties.value}
              </a>
            );
          }
          break;

        case 'country':
          if (properties.context) {
            return (
              <p>{`${capitaliseWords(removeSnakeCase(properties.context))}: ${
                properties.value
              }`}</p>
            );
          }
          return properties.value;

        case 'gender':
          return <p>{capitaliseWords(properties.value)}</p>;

        case 'name':
          if (properties.context) {
            return <p>{`${properties.value} (${properties.context})`}</p>;
          }
          break;
      }

      return (
        <p>{properties.value ?? properties.Description ?? properties.type}</p>
      );
    };

    return (
      <DropdownRow title={getTitle()} count={count} expanded={expanded}>
        <KeyValueTable data={properties} />
      </DropdownRow>
    );
  };

  return (
    <TableList
      title={removeSnakeCase(title)}
      rows={attribute.Data.map((row, index) => {
        return (
          <DropdownAttribute
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            type={title}
            properties={row.properties}
          />
        );
      })}
    />
  );
};

export default SayariAttribute;
