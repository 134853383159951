/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
import moment from 'moment';
import Chip from 'components/Base/Chip';
import classes from '../../article.module.scss';
import { Table } from '../Table';
import parseContentToStructuredData from '../utils/parseContentToStructuredData';

const Row = ({ showEmpty, label, value }) => {
  if (!showEmpty && !value) return null;
  return (
    <div className={classes['structure-row']}>
      <span>{label}</span>
      <span>{value || '-'}</span>
    </div>
  );
};

const ComplianceHeader = ({ structuredData, name, icons }) => {
  return (
    <div className={classes['image-wrapper']}>
      <div className={classes['image-unavailable']}>No image</div>
      <div className={classes['image-inner']}>
        <span>{structuredData?.EntityDetails?.EntityType}</span>
        <h4>{name}</h4>
        <div className={classes.tags}>
          {icons &&
            icons?.map((icon) => (
              <Chip
                key={`Item-${icon}`}
                label={icon}
                size='small'
                variant='grey'
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const ComplianceNotAvailable = () => (
  <span className={classes['structure-mute']}>Not available</span>
);

const CompliancePrimaryName = ({ data }) => {
  const names = data
    ?.filter(
      (item) =>
        item?.Type === 'AKA' &&
        item?.Name?.Full &&
        item?.Category === 'None' &&
        item?.Comments?.indexOf('ID: ') !== 0
    )
    ?.map((item) => item.Name.Full);
  return names?.length ? (
    <div className={classes['structure-row']}>
      <span>Primary Name</span>
      <span>
        {names.map((name) => (
          <span key={`primary-name-${name}`}>
            {name}
            <br />
          </span>
        ))}
      </span>
    </div>
  ) : null;
};

const ComplianceAliases = ({ showEmpty, data }) => {
  const names = data
    ?.filter(
      (item) =>
        item?.Type === 'AKA' &&
        item?.Name?.Full &&
        item?.Category === 'None' &&
        item?.Comments?.indexOf('ID: ') === 0
    )
    ?.map((item) => item.Name.Full);
  const list = names?.length
    ? names.map((name, i) => (
        <span key={`aliases-${i}`}>
          {name}
          <br />
        </span>
      ))
    : null;
  if (!list?.length && !showEmpty) return null;
  return (
    <div className={classes['structure-row']}>
      <span>Aliases</span>
      <span>{list || ComplianceNotAvailable()}</span>
    </div>
  );
};

const ComplianceNicknames = ({ showEmpty, data }) => {
  const names = data
    ?.filter(
      (item) =>
        item?.Type === 'AKA' && item?.Name?.Full && item?.Category === 'Weak'
    )
    ?.map((item) => item.Name.Full);
  const list = names?.length
    ? names.map((name) => (
        <span key={`nicknames-${name}`}>
          {name}
          <br />
        </span>
      ))
    : null;
  if (!list?.length && !showEmpty) return null;
  return (
    <div className={classes['structure-row']}>
      <span>Nicknames</span>
      <span>{list || ComplianceNotAvailable()}</span>
    </div>
  );
};

const CompliancePrimaryCountry = ({ showEmpty, data }) => {
  const country = data
    ?.filter((addr) => addr?.Country)
    ?.map((addr) => addr.Country)
    ?.shift();
  if (!country?.length && !showEmpty) return null;
  return (
    <div className={classes['structure-row']}>
      <span>Primary Country</span>
      <span>{country || ComplianceNotAvailable()}</span>
    </div>
  );
};

const ComplianceId = ({ showEmpty, data, type, label }) => {
  const text = data
    ?.filter((id) => id?.Type === type && id?.Number)
    ?.map((id) => id.Number)
    .join(', ');
  if (!text?.length && !showEmpty) return null;
  return (
    <div className={classes['structure-row']}>
      <span>{label}</span>
      <span>{text || ComplianceNotAvailable()}</span>
    </div>
  );
};

const ComplianceCatSubCatLevel = ({ showEmpty, data }) => {
  const parts = data?.split(':') || [];
  let lev = '';
  let cat = '';
  let sub = '';
  if (parts.length === 2) {
    cat = parts[0];
    sub = parts[1];
  } else if (parts.length === 3) {
    lev = parts[0];
    cat = parts[1];
    sub = parts[2];
  }
  if (!cat?.length && !lev?.length && !sub?.length && !showEmpty) return null;
  return (
    <>
      {!cat && !showEmpty ? null : (
        <div className={classes['structure-row']}>
          <span>Category</span>
          <span>{cat || ComplianceNotAvailable()}</span>
        </div>
      )}
      {!sub && !showEmpty ? null : (
        <div className={classes['structure-row']}>
          <span>Sub Category</span>
          <span>{sub || ComplianceNotAvailable()}</span>
        </div>
      )}
      {!lev && !showEmpty ? null : (
        <div className={classes['structure-row']}>
          <span>Level</span>
          <span>{lev || 'N/A'}</span>
        </div>
      )}
    </>
  );
};

const ComplianceDataSource = ({ showEmpty, data }) => {
  const text = data || '';
  const val = text.split(',').pop();
  if (!val?.length && !showEmpty) return null;
  return <Row label='Data Source' value={val || ComplianceNotAvailable()} />;
};

const CompliancePosition = ({ showEmpty, data }) => {
  const text =
    typeof data === 'string'
      ? data
      : data
          .filter((item) => item?.Type === 'Occupation' && item?.Value)
          ?.map((item) => item.Value)
          ?.join(', ');
  if (!text?.length && !showEmpty) return null;
  return text ? <Row label='Position' value={text} /> : null;
};

const ComplianceCase = ({ showEmpty, data }) => {
  const text = data || '';
  if (!text?.length && !showEmpty) return null;
  return text ? <Row label='Case' value={text} /> : null;
};

const ComplianceAdditionalInfo = ({ showEmpty, data }) => {
  if (!data?.length && !showEmpty) return null;
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Additional Information</h4>
      <div className={classes.structure}>
        <div className={classes['structure-text']}>
          {data || ComplianceNotAvailable()}
        </div>
      </div>
    </div>
  );
};

const ComplianceAddress = ({ showEmpty, data }) => {
  if (
    data &&
    data?.length &&
    data?.[0] &&
    !data[0].Street1 &&
    !data[0].City &&
    !data[0].City &&
    !data[0].PostalCode &&
    data[0].Country
  )
    data.shift();
  if (!data?.length && !showEmpty) return null;
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Reported Addresses</h4>
      {data ? (
        <Table
          headCells={[
            'Address',
            'City',
            'State/Province',
            'Country',
            'Postal Code',
          ]}
          rows={data.map((addr) => [
            addr?.Street1,
            addr?.City,
            addr?.StateProvinceDistrict,
            addr?.Country,
            addr?.PostalCode,
          ])}
        />
      ) : (
        <div className={classes['structure-text']}>
          <div className={classes['structure-row--source']}>
            {ComplianceNotAvailable()}
          </div>
        </div>
      )}
    </div>
  );
};

const ComplianceIndividualSources = ({ showEmpty, data }) => {
  const list = data || [];
  if (!list?.length && !showEmpty) return null;
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Individual Sources</h4>
      {list?.length ? (
        list.map((item, i) => (
          <div key={i} className={classes['structure-row--source']}>
            {item.type ? <b>{item.type}: </b> : null}
            <span className={classes['structure-text']}>{item.text}</span>
            <small className={classes['structure-mute']}>{item.link}</small>
            {item.badges.map((badge, i) => (
              <Chip key={i} label={badge} size='small' />
            ))}
          </div>
        ))
      ) : (
        <div className={classes['structure-row--source']}>
          {ComplianceNotAvailable()}
        </div>
      )}
    </div>
  );
};

const ComplianceAdditionalInfoItem = ({ showEmpty, data, type, label }) => {
  const dates = data
    ?.filter((item) => item?.Type === type && item?.Value)
    ?.map((item) => item.Value)
    ?.join(', ');
  if (!dates?.length && !showEmpty) return null;
  return <Row label={label} value={dates || ComplianceNotAvailable()} />;
};

const ComplianceSources = ({ showEmpty, data }) => {
  if (!data?.length) return null;
  let docs = data
    .filter(
      (item) =>
        item?.Type === 'Other' &&
        item?.Value === 'Sources of Record Information' &&
        item?.Comments?.length
    )
    .map((item) => item.Comments);
  docs = docs
    .join(' | ')
    .split(' | ')
    .filter((link) => link);
  if (!docs?.length && !showEmpty) return null;
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Source Links</h4>
      {docs ? (
        docs.map((link, i) => (
          <div key={`link-${i}`} className={classes['structure-row--source']}>
            <a
              href={link}
              className={classes['data-link']}
              target='_blank'
              rel='noreferrer'
            >
              {link}
            </a>
          </div>
        ))
      ) : (
        <div className={classes['structure-row--source']}>
          {ComplianceNotAvailable()}
        </div>
      )}
    </div>
  );
};

const ComplianceDocs = ({ showEmpty, data }) => {
  //     if (!data?.length) return null;
  //     let docs = data.filter(item => (
  //       item?.Type === "Other" &&
  //       item?.Value === "Sources of Record Information" &&
  //       item?.Comments?.length
  //     )).map(item => item.Comments);
  //     docs = docs.join(" | ").split(" | ").filter(link => link);
  const docs = null;
  if (!docs?.length && !showEmpty) return null;
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Documents</h4>
      {docs ? (
        docs.map((link) => (
          <div
            key={`link-${link}`}
            className={classes['structure-row--source']}
          >
            <a
              href={link}
              className={classes['data-link']}
              target='_blank'
              rel='noreferrer'
            >
              {link}
            </a>
          </div>
        ))
      ) : (
        <div className={classes['structure-row--source']}>
          {ComplianceNotAvailable()}
        </div>
      )}
    </div>
  );
};

const ComplianceRelationships = ({ showEmpty, handleProfileLink, data }) => {
  const list = data || [];
  if (!list?.length && !showEmpty) return null;
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Relationships</h4>
      {list?.length ? (
        list.map((item, i) => (
          <div key={`link-${i}`} className={classes['structure-row--source']}>
            {item.type ? <b>{item.type}: </b> : ''}
            {item.link ? (
              <span
                className={classes['data-link']}
                onClick={() => handleProfileLink(item.link)}
              >
                {item.text}
              </span>
            ) : (
              <span>{item.text}</span>
            )}
            {item.badges.map((badge, i) => (
              <Chip key={i} label={badge} size='small' />
            ))}
          </div>
        ))
      ) : (
        <div className={classes['structure-row--source']}>
          {ComplianceNotAvailable()}
        </div>
      )}
    </div>
  );
};

const formatDate = (date) => {
  if (typeof date === 'object') {
    if (date.day)
      return moment({ ...date, month: date.month - 1 }).format('DD/MM/YYYY');
    if (date.year) return date.year;
  } else if (typeof date === 'string' && date.length) {
    return date;
  }
  return null;
};

const WorldComplianceStructuredData = ({
  showEmpty,
  handleLinkRelated,
  data,
  printView = false,
}) => {
  const structuredData = parseContentToStructuredData(data.contentJSON);

  const getStructuredDataFromComments = (d) => {
    if (!d) return {};
    const data = {};
    (structuredData?.EntityDetails?.Comments?.split(' || ') || []).forEach(
      (item) => {
        const keys = item.match(/^[a-z][a-z ]+[a-z]: /i);
        if (keys && keys?.length) {
          const key = keys[0]
            .substr(0, keys[0].length - 2)
            .toLowerCase()
            .replace(' ', '_');
          item = item.substr(keys[0].length);
          if (key === 'associations') {
            if (item.indexOf('| ') === 0) {
              item = item.substr(2);
            }
            const list = item.split(' | ').map((item) => {
              const id = item.match(/, [0-9]+$/);
              let link = null;
              if (id) {
                link = id[0].substr(2);
                item = item.substr(0, item.length - id[0].length);
              }
              const cat = item.match(/^([a-z\- ]+) (\([^)]+\)): +/i);
              let type = null;
              let badges = [];
              if (cat && cat.length === 3) {
                badges = cat[2].substr(1, cat[2].length - 2).split(':');
                type = cat[1];
                item = item.substr(cat[0].length);
              }
              return { text: item, link, type, badges };
            });
            data[key] = list;
          } else if (key === 'individual_sources') {
            if (item.indexOf('| ') === 0) {
              item = item.substr(2);
            }
            const list = item.split(' | ').map((item) => {
              const id = item.match(/, [0-9]+$/);
              let link = null;
              if (id) {
                link = id[0].substr(2);
                item = item.substr(0, item.length - id[0].length);
              }
              const parts = item.match(/^([^:]+): (.+)$/i);
              let type = null;
              const badges = [];
              if (parts && parts.length === 3) {
                type = parts[1];
                item = parts[2];
              }
              return { text: item, link, type, badges };
            });
            data[key] = list;
          } else {
            data[key] = item;
          }
        }
      }
    );

    return data;
  };

  const handleProfileLink = (profileId) => {
    if (profileId === undefined) return;
    const link = `https://members.worldcompliance.com/metawatch2.aspx?ProprietaryUID=${profileId}`;
    if (handleLinkRelated) handleLinkRelated(link);
  };

  const commentsData = getStructuredDataFromComments(
    structuredData?.EntityDetails?.Comments
  );

  switch (structuredData?.EntityDetails?.EntityType) {
    case 'Individual':
      return (
        <div
          className={
            printView ? classes['media-print'] : classes['media-screen']
          }
        >
          <div className={classes.structure}>
            <ComplianceHeader name={structuredData.EntityName} />
          </div>
          <div className={classes.structure}>
            <h4 className={classes['structure-title']}>Entity</h4>
            {!structuredData?.EntityDetails?.Name?.Title?.length &&
            !showEmpty ? null : (
              <Row
                label='Title'
                value={
                  structuredData?.EntityDetails?.Name?.Title?.length
                    ? structuredData.EntityDetails.Name.Title
                    : ComplianceNotAvailable()
                }
              />
            )}
            <CompliancePrimaryName data={structuredData?.EntityDetails?.AKAs} />
            {!structuredData?.EntityDetails?.Name?.First?.length &&
            !showEmpty ? null : (
              <Row
                label='First Name'
                value={
                  structuredData?.EntityDetails?.Name?.First?.length
                    ? structuredData.EntityDetails.Name.First
                    : ComplianceNotAvailable()
                }
              />
            )}
            {!structuredData?.EntityDetails?.Name?.Last?.length &&
            !showEmpty ? null : (
              <Row
                label='Last Name'
                value={
                  structuredData?.EntityDetails?.Name?.Last?.length
                    ? structuredData.EntityDetails.Name.Last
                    : ComplianceNotAvailable()
                }
              />
            )}
            {!structuredData?.EntityDetails?.Name?.Generation?.length &&
            !showEmpty ? null : (
              <Row
                label='Suffix'
                value={
                  structuredData?.EntityDetails?.Name?.Generation?.length
                    ? structuredData.EntityDetails.Name.Generation
                    : ComplianceNotAvailable()
                }
              />
            )}
            <ComplianceAliases
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.AKAs}
            />
            <ComplianceNicknames
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.AKAs}
            />
            {!structuredData?.EntityDetails?.Gender && !showEmpty ? null : (
              <Row
                label='Gender'
                value={
                  structuredData?.EntityDetails?.Gender
                    ? structuredData.EntityDetails.Gender
                    : ComplianceNotAvailable()
                }
              />
            )}
            <ComplianceCatSubCatLevel
              showEmpty={showEmpty}
              data={structuredData?.ReasonListed}
            />
            <ComplianceDataSource
              showEmpty={showEmpty}
              data={commentsData?.source}
            />
            {!showEmpty ? null : (
              <Row label='Designation' value={ComplianceNotAvailable()} />
            )}
            <ComplianceAdditionalInfoItem
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.AdditionalInfo}
              type='PlaceOfBirth'
              label='Place of Birth'
            />
            <ComplianceAdditionalInfoItem
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.AdditionalInfo}
              type='DOB'
              label='Date(s) of Birth'
            />
            <CompliancePrimaryCountry
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.Addresses}
            />
            <CompliancePosition
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.AdditionalInfo}
            />
            <ComplianceCase
              showEmpty={showEmpty}
              data={commentsData?.offense}
            />
            <ComplianceId
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.IDs}
              type='National'
              label='National ID'
            />
            <ComplianceId
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.IDs}
              type='Other'
              label='Other ID'
            />
            <ComplianceId
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.IDs}
              type='Passport'
              label='Passport #'
            />
            <Row
              label='Last Updated'
              value={formatDate(structuredData?.DateModified)}
            />
            <Row
              label='Entity ID'
              value={structuredData?.EntityDetails?.ListReferenceNumber}
            />
          </div>
          <ComplianceAdditionalInfo
            showEmpty={showEmpty}
            data={commentsData?.profile_notes}
          />
          <ComplianceAddress
            showEmpty={showEmpty}
            data={structuredData?.EntityDetails?.Addresses}
          />
          <ComplianceIndividualSources
            showEmpty={showEmpty}
            data={commentsData?.individual_sources}
          />
          <ComplianceRelationships
            showEmpty={showEmpty}
            handleProfileLink={handleProfileLink}
            data={commentsData?.associations}
          />
          <ComplianceSources
            showEmpty={showEmpty}
            data={structuredData?.EntityDetails?.AdditionalInfo}
          />
          <ComplianceDocs
            showEmpty={showEmpty}
            data={structuredData?.EntityDetails?.AdditionalInfo}
          />
        </div>
      );
    case 'Business':
      return (
        <div
          className={
            printView ? classes['media-print'] : classes['media-screen']
          }
        >
          <div className={classes.structure}>
            <ComplianceHeader name={structuredData.EntityName} />
          </div>
          <div className={classes.structure}>
            <h4 className={classes['structure-title']}>Entity</h4>
            <Row
              label='Name'
              value={structuredData?.EntityDetails?.Name?.Full}
            />
            <ComplianceAliases
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.AKAs}
            />
            <ComplianceCatSubCatLevel
              showEmpty={showEmpty}
              data={structuredData?.ReasonListed}
            />
            <ComplianceDataSource
              showEmpty={showEmpty}
              data={commentsData?.source}
            />
            <Row label='Designation' value={ComplianceNotAvailable()} />
            <CompliancePrimaryCountry
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.Addresses}
            />
            <ComplianceCase
              showEmpty={showEmpty}
              data={commentsData?.offense}
            />
            <ComplianceId
              showEmpty={showEmpty}
              data={structuredData?.EntityDetails?.IDs}
              type='Other'
              label='Other ID'
            />
            <Row
              label='Last Updated'
              value={formatDate(structuredData?.DateModified)}
            />
            <Row
              label='Entity ID'
              value={structuredData?.EntityDetails?.ListReferenceNumber}
            />
          </div>
          <ComplianceAdditionalInfo
            showEmpty={showEmpty}
            data={commentsData?.profile_notes}
          />
          <ComplianceAddress showEmpty={showEmpty} />
          <ComplianceIndividualSources
            showEmpty={showEmpty}
            data={commentsData?.individual_sources}
          />
          <ComplianceRelationships
            showEmpty={showEmpty}
            data={commentsData?.associations}
            handleProfileLink={handleProfileLink}
          />
          <ComplianceSources
            showEmpty={showEmpty}
            data={structuredData?.EntityDetails?.AdditionalInfo}
          />
          <ComplianceDocs
            showEmpty={showEmpty}
            data={structuredData?.EntityDetails?.AdditionalInfo}
          />
        </div>
      );
    default:
      return <div>No data to display.</div>;
  }
};

export default WorldComplianceStructuredData;
