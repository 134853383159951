import { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Close, Notes } from '@mui/icons-material';
import { fetchFindingsCounts } from 'hooks/useFetch';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import { reverse as url } from 'named-urls';
import { UPDATE_NAV, UPDATE_STATE } from 'constants/actionTypes';
import { useSelector, useDispatch } from 'context';
import SubjectSearchBar from 'components/SearchBar/SubjectSearchBar';
import { SearchMode } from 'appSrc/constants';
import { useAppSelector } from 'hooks/storeHooks';
import { selectSearchMode } from 'appSrc/store/slices/ui';
import classes from './search-header.module.scss';
import SearchBar from '../SearchBar';

type SearchHeaderProps = {
  variant?: string;
  projectCtrls?: any;
};

const SearchHeader = ({ variant, projectCtrls }: SearchHeaderProps) => {
  const navigation = useSelector((state) => state.navigation);
  const project = useSelector((state) => state.state.project || {});
  const queryId = useSelector((state) => state.state.navigation);
  const findings = useSelector((state) => state.state.findings);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchMode = useAppSelector(selectSearchMode);

  useEffect(() => {
    if (variant === 'projects') return;
    if (!project?.id) return;
    fetchFindingsCounts(project.id).then((res) => {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          findings: res?.hits?.total?.value || 0,
        },
      });
    });
  }, [project?.id, queryId, variant, dispatch]);

  if (variant === 'projects')
    return (
      <div className={classes.container}>
        <div className={classes.project}>
          <h4 className={classes.title}>New Project</h4>
        </div>
        {searchMode === SearchMode.SUBJECT_SEARCH ? (
          <SubjectSearchBar />
        ) : (
          <SearchBar />
        )}
        <div className={classes.link} />
      </div>
    );
  if (variant === 'findings')
    return (
      <div className={classes['container--findings']}>
        <div className={classes.project}>
          <h4 className={classes.title}>Project</h4>
          <h5 className={classes.subtitle}>
            <Link
              to={url(appRoutingPaths.projects.project.show, {
                project_id: project.id,
              })}
            >
              {project.name}
            </Link>
          </h5>
        </div>

        <div className={classes.findings}>
          <div className={classes.heading}>
            <Notes />
            <h2>Findings</h2>{' '}
            {typeof findings === 'number' && (
              <span className={classes.count}>{findings}</span>
            )}
          </div>
          <div>
            <Link
              to={navigation?.returnTo ?? `/projects/${project.id}`}
              onClick={() => {
                navigate(navigation?.returnTo ?? `/projects/${project.id}`);
                dispatch({
                  type: UPDATE_STATE,
                  payload: {
                    expecting: { items: true, article: true },
                    data: { loading: true },
                    article: { loading: true },
                  },
                });
              }}
            >
              <span className={classes.exit}>
                <Close style={{ fontSize: '18px' }} />
                Exit
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  return (
    // search
    <div className={classes.container}>
      <div className={classes.project}>
        <h4 className={classes.title}>Project</h4>
        <h5 className={classes.subtitle}>
          <Link
            to={url(appRoutingPaths.projects.project.show, {
              project_id: project.id,
            })}
          >
            {project.name}
          </Link>
          {projectCtrls ? projectCtrls() : ''}
        </h5>
      </div>
      {searchMode === SearchMode.SUBJECT_SEARCH ? (
        <SubjectSearchBar />
      ) : (
        <SearchBar />
      )}
      <div className={classes.link}>
        <Link
          to={url(appRoutingPaths.projects.project.findings, {
            project_id: project.id,
          })}
          onClick={(e) => {
            dispatch({
              type: UPDATE_STATE,
              payload: {
                data: { loading: true },
                article: { loading: true },
              },
            });
            dispatch({
              type: UPDATE_NAV,
              payload: { returnTo: location.pathname },
            });
          }}
        >
          <div className={classes.heading}>
            <Notes />
            <h2>Findings</h2>{' '}
            {typeof findings === 'number' && (
              <span className={classes.count}>{findings}</span>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SearchHeader;
