import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiHeaders, apiHost, fetchFailure } from '..';
import { addProviderLabels, makeRequestWithRetries } from './helpers';

export const fetchConfig = async () => {
  const headers = apiHeaders();
  const request = () =>
    axios.get(`${apiHost}/api/v1/config`, {
      headers,
    });
  try {
    const data = await makeRequestWithRetries(request);
    return addProviderLabels(data);
  } catch (err) {
    console.log('config fetch error', err);
    return fetchFailure(err);
  }
};

export default fetchConfig;
