import { v4 as uuid } from 'uuid';
import { useContext, useEffect } from 'react';
import { AsyncMessageContext } from './AsyncMessageProvider';
import { HandlerFunc } from './types';

const useMultiAsyncMessageHandler = (
  messageStatusses: Array<string>,
  handlerFunc: HandlerFunc
): void => {
  const context = useContext(AsyncMessageContext);
  if (!context) {
    throw new Error(
      `useAsyncMessageHandler called from outside AsyncMessageProvider`
    );
  }

  useEffect(() => {
    const handlerIds = messageStatusses.map((messageStatus) => {
      const handlerId = uuid();
      context.registerHandler(messageStatus, handlerId, handlerFunc);
      return handlerId;
    });
    return () => {
      handlerIds.forEach((handlerId, index) =>
        context.deregisterHandler(messageStatusses[index], handlerId)
      );
    };
  }, [context, messageStatusses, handlerFunc]);
};

export default useMultiAsyncMessageHandler;
