import { useModals } from 'hooks/ModalManager/useModals';
import { Modals } from 'appSrc/constants';
import { Search as SearchIcon } from '@mui/icons-material';
import React from 'react';
import classes from './subject-search-bar.module.scss';

const SubjectSearchBar = () => {
  const { open } = useModals();

  return (
    <button
      type='button'
      id='matomo-subject-search-modal-btn'
      className={classes.searchbar}
      onClick={() => open(Modals.SUBJECT_SEARCH)}
    >
      <span className={classes.adornment}>
        <SearchIcon fontSize='inherit' style={{ marginTop: '2px' }} />
      </span>

      <p>New subject search</p>
    </button>
  );
};

export default SubjectSearchBar;
