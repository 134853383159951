import { useEffect, useState } from 'react';
import { useContext } from 'use-context-selector';
import { find, isEmpty, noop } from 'lodash';
import { UPDATE_STATE } from '../../constants/actionTypes';
import AppContext from '../../context';

class Bookmark {
  constructor(queryId, page = 1, scroll = 0, filter = {}) {
    this.queryId = queryId;
    this.page = page;
    this.scroll = scroll;
    this.filter = filter;
  }
}

export const useScrollBookmarks = (hasItems, scrollRef) => {
  const { state: appState, dispatch } = useContext(AppContext);
  const { config } = appState;
  const {
    bookmarks = [],
    filters = {},
    page,
    provider,
    query,
  } = appState.state;
  const localFilter = !!find(config.providers, { id: provider.id })?.dispatch
    ?.local_filter;
  const queryId = query.id;
  const [scrollY, setScrollY] = useState(0);

  // Adds and removes scroll event to given ref
  useEffect(() => {
    const scrollingElement = scrollRef.current;
    const handleScrollEvent = () => {
      setScrollY(scrollingElement.scrollTop);
    };
    if (scrollingElement) {
      scrollingElement.addEventListener('scroll', handleScrollEvent);
    }
    return () => {
      if (scrollingElement) {
        scrollingElement.removeEventListener('scroll', handleScrollEvent);
      }
    };
  }, [queryId, scrollRef]);

  useEffect(() => {
    const localFilters =
      localFilter && !isEmpty(filters?.facets)
        ? {
            facets: filters.facets || [],
            sort: filters?.sort || '',
            filter: filters?.filter || {},
            changed: filters?.changed,
          }
        : {};
    const index = bookmarks.map((bookmark) => bookmark.query).indexOf(queryId);
    const bookmark = index > -1 ? bookmarks[index] : new Bookmark(queryId);
    if (page !== -1) {
      // if is not first load and is bookmark
      // set bookmark
      if (bookmark.page !== page) bookmark.scroll = 0;
      bookmark.page = page;
      if (localFilter) bookmark.filter = localFilters;
    }
    if (bookmark.page !== page || bookmarks[0].query !== bookmark.query) {
      const bookmarksCopy = [...bookmarks];
      if (index > -1) {
        bookmarksCopy.splice(index, 1);
      }
      bookmarksCopy.unshift(bookmark);
      const payload = {
        page: bookmark.page,
        bookmarks: bookmarksCopy.slice(0, 50),
        filters: localFilter ? bookmark.filter : undefined,
      };

      dispatch({
        type: UPDATE_STATE,
        payload,
      });
    }
  }, [
    bookmarks,
    page,
    queryId,
    localFilter,
    filters?.filter,
    filters?.changed,
    filters.facets,
    filters?.sort,
    dispatch,
  ]);

  // Remember scrolled to position
  useEffect(() => {
    const existingBookmark = bookmarks.find((b) => b.queryId === queryId);
    if (!queryId || !scrollRef.current) return noop;
    if (scrollY === 0 && existingBookmark?.scroll === 0) return noop;
    if (!bookmarks[0]) return noop;
    if (bookmarks[0].queryId !== queryId) return noop;
    if (bookmarks[0].scroll === scrollY) return noop;
    const bm = new Bookmark(queryId, page, scrollY, bookmarks[0].filter);
    const t = setTimeout(() => {
      bookmarks[0] = bm;
      dispatch({
        type: UPDATE_STATE,
        payload: { bookmarks },
      });
    }, 200);
    return () => clearTimeout(t);
  }, [queryId, scrollRef, scrollY, hasItems, page, bookmarks, dispatch]);

  // Set bookmarked scroll on first load
  useEffect(() => {
    const y = scrollRef.current.scrollTop;

    if (y > 0) return;
    if (queryId && scrollRef.current && hasItems && bookmarks[0]) {
      if (bookmarks[0].queryId === queryId && bookmarks[0].ppage === page) {
        scrollRef.current.scrollTo(0, bookmarks[0].scroll);
      }
    }
  }, [queryId, scrollRef, hasItems, bookmarks, page]);
};

export default useScrollBookmarks;
