import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { store } from 'context';
import { fetchConfig } from './fetchConfig';
import { FETCH_FAILURE, LOAD_CONFIG } from '../../../constants/actionTypes';

export const useFetchConfig = (providers, isAllowed) => {
  const { dispatch } = store;

  useEffect(() => {
    if (isEmpty(providers) && isAllowed) {
      fetchConfig().then((providerFetchResult) => {
        if (providerFetchResult.type === FETCH_FAILURE) {
          dispatch(providerFetchResult);
        }
        dispatch({ type: LOAD_CONFIG, payload: providerFetchResult });
      });
    }
  }, [providers, isAllowed, dispatch]);
};

export default useFetchConfig;
