import Spinner from 'components/Base/Spinner';
import { IdentitySection } from './IdentitySection';
import { EventsSection } from './EventsSection';
import { LocationsSection } from './LocationsSection';
import { CountryLinksSection } from './CountryLinksSection';
import { PepDetailsSection } from './PepDetailsSection';
import { WatchlistSection } from './WatchlistSection';
import { AssociatesSection } from './AssociatesSection';
import { WeblinksSection } from './WeblinksSection';
import { FurtherInformationSection } from './FurtherInformationSection';
import { MatchInformationSection } from './MatchInformationSection';
import parseContentToStructuredData from '../../utils/parseContentToStructuredData';

const WorldCheckStructuredData = ({
  showEmpty,
  data,
  handleLinkRelated,
  printView = false,
}) => {
  const structuredData = parseContentToStructuredData(data.contentJSON);
  const {
    hasFetched,
    primaryName,
    gender,
    names,
    category,
    entityType,
    events,
    addresses,
    countryLinks,
    matchScore,
    matchStrength,
    matchedNameType,
    mappedSources,
    details,
    associates,
    weblinks,
    pepStatusDetail,
  } = structuredData;

  return hasFetched ? (
    <>
      <IdentitySection
        {...{ showEmpty, primaryName, gender, names, category, entityType }}
      />
      <FurtherInformationSection {...{ showEmpty, details }} />
      <EventsSection {...{ showEmpty, events }} />
      <LocationsSection {...{ showEmpty, addresses }} />
      <CountryLinksSection {...{ showEmpty, countryLinks }} />
      <PepDetailsSection {...{ showEmpty, pepStatusDetail }} />
      <WatchlistSection {...{ showEmpty, mappedSources }} />
      <AssociatesSection {...{ showEmpty, associates, handleLinkRelated }} />
      <WeblinksSection {...{ showEmpty, weblinks }} />
      <MatchInformationSection
        {...{ showEmpty, matchScore, matchStrength, matchedNameType }}
      />
    </>
  ) : (
    <Spinner />
  );
};

export default WorldCheckStructuredData;
