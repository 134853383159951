import { v4 as uuid } from 'uuid';
import { useEffect, useContext } from 'react';
import { AsyncMessageContext } from './AsyncMessageProvider';
import { FilterFunc } from './types';

const useAsyncMessageFilter = (filterFunc: FilterFunc) => {
  const context = useContext(AsyncMessageContext);

  if (!context) {
    throw new Error(
      `useAsyncMessageHandler called from outside AsyncMessageProvider`
    );
  }

  useEffect(() => {
    const filterId = uuid();
    context.registerMessageFilter(filterId, filterFunc);
    return () => context.deregisterMessageFilter(filterId);
  }, [context, filterFunc]);
};

export default useAsyncMessageFilter;
