import authGetRedirect from 'api/auth/authGetRedirect';

const signinRedirect = (): void => {
  const loginRedirect = process.env.REACT_APP_OID_LOGIN_REDIRECT;

  authGetRedirect().then((res) => {
    if (typeof res === 'string') {
      if (loginRedirect) {
        window.location.href = res.replace(
          /(redirect_uri=).*?(&)/,
          `$1${loginRedirect}$2`
        );
      } else {
        window.location.href = res;
      }
    }
  });
};

export default signinRedirect;
