import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import classes from './search-navigation.module.scss';

export const SearchRelatedItemTab = ({
  id,
  label,
  relatedUrl,
  selected,
  handleSetRelated,
  handleSetRelatedInactive,
}) => {
  const viewItem = (e) => {
    e.preventDefault();
    if (!selected) {
      handleSetRelated({ id, relatedUrl });
    }
  };

  const removeItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSetRelatedInactive({ id, relatedUrl });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={viewItem}
      className={classNames(classes.provider, {
        [classes['provider--active']]: selected,
      })}
    >
      <span className={classes.label}>
        <span>{label === '' ? 'Loading...' : label}</span>
      </span>

      {id !== '' && (
        <span className={classes.label}>
          <IconButton
            onClick={removeItem}
            style={{
              height: '1rem',
              width: '1rem',
              margin: 'auto',
              marginBottom: '1.5px',
            }}
          >
            <CloseIcon style={{ height: '1rem' }} />
          </IconButton>
        </span>
      )}
    </div>
  );
};

export default SearchRelatedItemTab;
