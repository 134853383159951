import { Button, Divider, List, ListItem, ListItemText } from '@mui/material';
import { useAppDispatch } from 'hooks/storeHooks';
import { selectAdverseTerm } from 'appSrc/store/slices/adverseTerms';
import { hideAdverseTermsPopout } from 'appSrc/store/slices/ui';
import AdverseTermItem from './AdverseTermItem';

const AdverseTermModalFooter = ({ term }) => {
  const dispatch = useAppDispatch();
  return (
    <List>
      <Divider />
      <AdverseTermItem term={term} />
      <Divider />
      <ListItem
        style={{ padding: '30px' }}
        secondaryAction={
          <ListItemText>
            <Button
              id='adverse_terms_close_btn'
              variant='outlined'
              color='inherit'
              size='small'
              style={{ marginRight: '20px' }}
              onClick={() => {
                dispatch(selectAdverseTerm(null));
                dispatch(hideAdverseTermsPopout());
              }}
            >
              Close
            </Button>
            <Button
              id='adverse_terms_apply_btn'
              variant='contained'
              color='primary'
              size='small'
              onClick={() => {
                dispatch(hideAdverseTermsPopout());
              }}
            >
              Apply
            </Button>
          </ListItemText>
        }
      />
    </List>
  );
};
export default AdverseTermModalFooter;
