import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const fetchTags = (projectId) => {
  const request = axios.get(`${apiHost}/api/v2/projects/${projectId}/tags`, {
    headers: apiHeaders(),
  });

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default fetchTags;
