/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Chip from 'components/Base/Chip';
import { capitaliseWords, removeSnakeCase } from 'helpers/formatting';
import { DropdownRow } from 'components/Base/DropdownRow';
import React from 'react';
import { AddToSearch } from 'components/Base/AddToSearch';
import { TableList } from '../../TableList';
import { KeyValueTable } from '../../../ContentTypes/KeyValueTable';
import styles from '../../../article.module.scss';

export const RelationshipDropdowns = ({
  title,
  expanded,
  data,
  handleLinkRelated,
}) => {
  const getTitle = (item, index) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {item.target?.label && <AddToSearch term={item.target.label} />}

        <span
          className={styles['related-link']}
          onClick={() => handleLinkRelated([item.target.id, 'entity'])}
        >
          {item.target?.label ?? 'Unknown'}
        </span>

        {item.target?.type && (
          <Chip
            label={capitaliseWords(item.target.type)}
            size='small'
            variant='grey'
            key={`${index}_image-icon--type`}
          />
        )}

        {item.target?.pep && (
          <Chip
            label='PEP'
            size='small'
            variant='grey'
            key={`${index}_image-icon--pep`}
          />
        )}

        {item.target?.sanctioned && (
          <Chip
            label='Sanctioned'
            size='small'
            variant='grey'
            key={`${index}_image-icon--sanctioned`}
          />
        )}
      </div>
    );
  };

  return (
    <TableList
      title={title}
      rows={data.map((item, itemIndex) => {
        const typeKeys = Object.keys(item.types);

        return (
          <DropdownRow
            key={itemIndex}
            title={getTitle(item, itemIndex)}
            count={typeKeys.length}
            expanded={expanded}
          >
            {typeKeys.map((key, keyIndex) => (
              <TableList
                key={keyIndex}
                rows={item.types[key].map((type, typeIndex) => {
                  const attributeKeys = Object.keys(type.attributes);
                  const dateKeys = Object.keys(type).filter(
                    (k) => k !== 'record' && k !== 'attributes'
                  );
                  const dates = dateKeys.reduce(
                    (prev, curr) => ({ ...prev, [curr]: type[curr] }),
                    {}
                  );

                  return (
                    <DropdownRow
                      key={typeIndex}
                      title={capitaliseWords(removeSnakeCase(key))}
                      count={attributeKeys.length}
                      expanded={expanded}
                      noIndent
                    >
                      <TableList
                        className={styles['light-tablelist']}
                        rows={[
                          <div key={`${typeIndex}_record`}>
                            <p className={styles['light-tabletitle']}>Record</p>
                            <KeyValueTable data={dates} />
                          </div>,
                        ]}
                      />

                      <TableList
                        className={styles['light-tablelist']}
                        rows={attributeKeys.map(
                          (attributeKey, attributeIndex) => {
                            let attributes = {};

                            // eslint-disable-next-line no-restricted-syntax, @typescript-eslint/no-shadow
                            for (const item of type.attributes[attributeKey]) {
                              attributes = { ...attributes, ...item };
                            }

                            return (
                              <div key={attributeIndex}>
                                <p className={styles['light-tabletitle']}>
                                  {capitaliseWords(
                                    removeSnakeCase(attributeKey)
                                  )}
                                </p>
                                <KeyValueTable data={attributes} />
                              </div>
                            );
                          }
                        )}
                      />
                    </DropdownRow>
                  );
                })}
              />
            ))}
          </DropdownRow>
        );
      })}
    />
  );
};

export default RelationshipDropdowns;
