import { styled } from '@mui/material';

export const NoItemsPlaceholder = styled('div')`
  display: flex;
  justify-content: center;
  background: #fff;
  height: 100vh;
  padding-top: 100px;
  font-size: 1.3rem;
  font-weight: 700;
  color: #cdcdcd;
`;

export const SearchTermCell = styled('div')`
  display: flex;
  align-items: center;
`;

export const SearchTermSubTitle = styled('div')`
  color: #8e8e8e;
  font-size: 0.7rem;
`;

export default NoItemsPlaceholder;
