import { StructuredRow } from '../StructuredRow';
import classes from '../../../article.module.scss';

export const StandardSchemaSection = ({ entityType, name, countries }) => {
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Identity</h4>
      <StructuredRow label='Type' value={entityType} />
      <StructuredRow label='Name' value={name} />
      <StructuredRow
        label='Countries'
        value={countries.map((country) => {
          const { country_code: code, country_name: countryName } = country;
          return <span key={`country-${code}`}>{countryName}</span>;
        })}
      />
    </div>
  );
};

export default StandardSchemaSection;
