import useProviderConfig from 'hooks/useProviderConfig';
import classes from '../../../article.module.scss';
import getProviderLabel from '../../utils/getProviderLabel';

const ArticleLinkRow = ({ label, value, handleArticleNavigate }) => {
  return (
    <div className={classes['structure-row']}>
      <div className={classes['structure-row-label']}>{label}</div>
      <button
        type='button'
        className={classes['related-link']}
        onClick={(e) => {
          e.preventDefault();
          handleArticleNavigate(value);
        }}
      >
        View Article
      </button>
    </div>
  );
};

export const ProviderRecords = ({ records, handleArticleNavigate }) => {
  const providerConfig = useProviderConfig();
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Found in Providers</h4>
      {records.map((record) => {
        const providerLabel = getProviderLabel(
          record.provider_id,
          providerConfig
        );
        return (
          <ArticleLinkRow
            key={`provider-${record.provider_id}`}
            label={providerLabel}
            value={record.source_id}
            handleArticleNavigate={handleArticleNavigate}
          />
        );
      })}
    </div>
  );
};

export default ProviderRecords;
