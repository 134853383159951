import axios from 'axios';
import { apiHost, apiHeaders, handleError } from 'hooks/useFetch';

const getLogRemoveArticleApiEndpoint = (
  projectId: string,
  articleId: string
): string => {
  const urlObj = new URL(
    `api/v1/projects/${projectId}/items/${articleId}/log-remove-article-finding`,
    apiHost
  );
  return urlObj.toString();
};

const logRemoveArticleFromFindings = async (
  projectId: string,
  articleId: string,
  provider: string,
  publisher: string,
  articleUrl: string
): Promise<void> => {
  const url = getLogRemoveArticleApiEndpoint(projectId, articleId);

  try {
    const bodyAsString = JSON.stringify({
      provider,
      publisher,
      article_url: articleUrl,
    });
    const result = await axios.post(url.toString(), bodyAsString, {
      headers: apiHeaders(),
    });

    if (result.status !== 202) {
      handleError(
        new Error(
          `Unexpected response code when logging finding: ${result.status} (${result.statusText})`
        )
      );
    }
  } catch (err) {
    handleError(new Error(`Could not log finding addition: ${err.message}`));
  }
};

export default logRemoveArticleFromFindings;
