import { IAdverseTerm } from 'types/adverseTerms';
import { createContext, useContext } from 'react';

interface ModalContextType {
  open: (key: string, props?: any) => void;
  close: () => void;
  openAdverseTermsModal: (term: IAdverseTerm) => void;
}

export const ModalContext = createContext<ModalContextType>({
  open: () => {},
  close: () => {},
  openAdverseTermsModal: () => {},
});

export const useModals = () => useContext<ModalContextType>(ModalContext);
