import countriesData from 'constants/countries.json';

export const getCountryOptions = () => {
  const { countries } = countriesData;
  const countryOptions = countries.map((country) => ({
    label: country.name,
    value: country.code,
  }));

  return countryOptions;
};

export default getCountryOptions;
