import { useState, useEffect, useCallback } from 'react';
import { defaultState, useDispatch, useSelector } from 'context';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { find, isEmpty } from 'lodash';
import { reverse as url } from 'named-urls';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import { submitRelatedInactive } from 'hooks/useFetch/';
import { UPDATE_SET_RELATED, UPDATE_STATE } from 'constants/actionTypes';
import { isCombinedProvider } from 'helpers/providers/providers';
import SearchProviderItem from './SearchProviderItem';
import { SearchRelatedItemTab } from './SearchRelatedItemTab';
import SearchDropdown from '../SearchDropdown';
import classes from './search-navigation.module.scss';
import { SearchNavigationHeaderTab } from './SearchNavigationHeaderTab';

const SearchNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const search = useSelector((state) => state.state.search);
  const provider = useSelector((state) => state.state.provider);
  const project = useSelector((state) => state.state.project);
  const relatedItems = useSelector((state) => state.state.relatedItems);
  const related = useSelector((state) => state.state.related);
  const data = useSelector((state) => state.state.data);
  const article = useSelector((state) => state.state.article);
  const expecting = useSelector((state) => state.state.expecting);
  const [selected, setSelected] = useState(provider?.id);

  const handleSetRelated = useCallback(
    ({ id, relatedUrl }) => {
      setSelected('related');
      if (related === id && id === article.id) return;
      dispatch({
        type: UPDATE_SET_RELATED,
        payload: {
          related: id || relatedUrl, // when first loading use relatedUrl
          id,
        },
      });
    },
    [article.id, dispatch, related]
  );

  const handleRedirect = useCallback(
    ({ queryId, providerId, searchId, useProjectSearches }) => {
      if (provider.id === providerId && selected === 'related') {
        setSelected(provider.id);
      }

      const selSearch = useProjectSearches
        ? find(project.searches, { id: searchId })
        : search;

      // If provider is not in provider list, assume we're looking for a category instead.
      const selProvider = find(selSearch.providers, {
        id: providerId,
      }) || { id: providerId };
      const selQuery = find(selProvider.queries, { id: queryId }) || '';
      const path = url(
        appRoutingPaths.projects.project.searches.search.provider.query.show,
        {
          project_id: project.id,
          search_id: searchId,
          provider_id: providerId,
          query_id: queryId,
        }
      );

      dispatch({
        type: UPDATE_STATE,
        payload: {
          ...defaultState,
          page: -1, // special -1 for first load bookmark handling
          query: selQuery,
          // TODO - params not updating on navigate, this fix for now
          queryId: selQuery.id,
          provider: selProvider,
          providerId,
          relatedItems,
          search: selSearch,
          searchId,
          project,
        },
      });
      navigate(path);
    },
    [dispatch, navigate, project, provider.id, relatedItems, selected, search]
  );

  useEffect(() => {
    setSelected(provider.id);
  }, [provider]);

  useEffect(() => {
    const loading = find(relatedItems, { id: '' });
    if (loading) handleSetRelated(loading);
    if (related) {
      const rel = find(relatedItems, (o) => {
        return o.id === related || o.relatedUrl === related;
      });
      if (rel) handleSetRelated(rel);
    }
  }, [relatedItems, handleSetRelated, related]);

  console.log(search.providers);
  const providers = search.providers.reduce(
    (acc, _provider) => {
      const category = _provider.id.split('.')[0];
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const _data = find(config.providers, { id: _provider.id });
      return {
        ...acc,
        [category]: [
          ...((acc[category] && acc[category]) || []),
          { ..._data, ..._provider },
        ],
      };
    },
    { news: [], compliance: [], records: [] }
  );

  const handleSetRelatedInactive = ({ id }) => {
    submitRelatedInactive(project.id, id).then(() => {
      const rels = relatedItems?.filter((item) => item.id !== id) || [];
      let rel = related;
      if (rel !== '') {
        if (!rels.length) rel = '';
        else if (!find(rels, { id })) rel = rels[0].id;
      }
      if (rel) {
        handleSetRelated({ id: rel, relatedUrl: '' });
        dispatch({
          type: UPDATE_STATE,
          payload: {
            relatedItems: rels,
          },
        });
      } else if (!related)
        dispatch({
          type: UPDATE_STATE,
          payload: {
            related: rel,
            relatedItems: rels,
          },
        });
      else {
        setSelected(data?.items?.[0]._source.provider.id);
        dispatch({
          type: UPDATE_STATE,
          payload: {
            related: rel,
            relatedItems: rels,
            article: { id: data?.items?.[0]._id, loading: true },
            expecting: { ...expecting, article: true },
          },
        });
      }
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.search}>
        <SearchDropdown handleRedirect={handleRedirect} />
      </div>
      <div className={classes.providers}>
        {Object.keys(providers).map((category) => {
          if (!providers[category].length) return null;
          const providerSelected = selected.indexOf(category) === 0;
          const tabsNClassName =
            providers[category].length > 8
              ? 'tab--many'
              : `tab--n${providers[category].length}`;
          return (
            <div
              className={classNames(
                classes.tab,
                classes[`tab--${category}`],
                classes[tabsNClassName],
                {
                  [classes['tab--active']]: providerSelected,
                  [classes['tab--child-active']]:
                    providerSelected && provider.id.includes(category),
                }
              )}
              key={category}
            >
              <SearchNavigationHeaderTab
                selected={providerSelected && category === selected}
                providerType={category}
                providers={providers}
                handleRedirect={handleRedirect}
              />
              <div className={classes.subtabs}>
                {providers[category]
                  .filter((_provider) => !isCombinedProvider(_provider.id))
                  .map((_provider) => {
                    const { id, label, queries } = _provider;
                    return (
                      // @ts-ignore
                      <SearchProviderItem
                        id={id}
                        label={label}
                        selected={providerSelected && _provider.id === selected}
                        queries={queries}
                        provider={_provider}
                        handleRedirect={handleRedirect}
                        key={_provider.id}
                      />
                    );
                  })}
              </div>
            </div>
          );
        })}
        {(() => {
          if (isEmpty(relatedItems)) return '';
          const relatedSelected = selected === 'related';
          const tabsNClassName =
            relatedItems.length > 8
              ? 'tab--many'
              : `tab--n${relatedItems.length}`;
          return (
            <div
              className={classNames(
                classes.tab,
                classes[`tab--related`],
                classes[tabsNClassName],
                {
                  [classes['tab--active']]: relatedSelected,
                  [classes['tab--child-active']]:
                    relatedSelected &&
                    relatedItems?.filter(
                      (rel) => rel.id === related || rel.relatedUrl === related
                    )?.length,
                }
              )}
            >
              <span className={classes['tab-header']}>Related</span>
              <div className={classes.subtabs}>
                {relatedItems.map((item, i) => {
                  return (
                    <SearchRelatedItemTab
                      id={item.id}
                      label={item.label}
                      relatedUrl={item.relatedUrl}
                      handleSetRelated={handleSetRelated}
                      handleSetRelatedInactive={handleSetRelatedInactive}
                      selected={
                        relatedSelected &&
                        (item.id === related || item.relatedUrl === related)
                      }
                      // eslint-disable-next-line react/no-array-index-key
                      key={`related-${i}`}
                    />
                  );
                })}
              </div>
            </div>
          );
        })()}
      </div>
    </div>
  );
};

export default SearchNavigation;
