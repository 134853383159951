/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Returns the max of 2 numbers, defaulting to Number.NEGATIVE_INFINITY for undefined inputs.
 * If both inputs are undefined, returns undefined.
 * @param {Number} a
 * @param {Number} b
 * @returns
 */
export const max = (a, b) => {
  if (a === undefined && b === undefined) {
    return undefined;
  }
  if ((a || Number.NEGATIVE_INFINITY) >= (b || Number.NEGATIVE_INFINITY)) {
    return a;
  }
  return b;
};

/**
 * Function that merges two arrays of objects that have the form {key : "", doc_count: 0}.
 * Buckets that have the same key have their doc_counts added together
 * @param {Array} a Array containing objects in {key: "", doc_count: 0}
 * @param {Array} b Array containing objects in {key: "", doc_count: 0}
 * @returns
 */
export const mergeShallowBuckets = (a = [], b = []) => {
  if (a.length === 0) {
    return b;
  }
  if (b.length === 0) {
    return a;
  }

  const mergedList = [...a, ...b];
  const mergedLookup = mergedList.reduce((acc, bucketEntry) => {
    const { key, doc_count } = bucketEntry;
    const existingEntryCount = acc[key] || 0;
    return {
      ...acc,
      [key]: existingEntryCount + doc_count,
    };
  }, {});
  return Object.keys(mergedLookup).map((key) => ({
    key,
    doc_count: mergedLookup[key],
  }));
};

/**
 * Merges two lists of buckets from ElasticSearch Response.
 * Named deep because of depth of the properties that are being merged.
 * @param {Array} a
 * @param {Array} b
 * @returns merged list of [...a, ...b], with keys that are duplicated appearing only once with relevant values summed
 */
export const mergeDeepBuckets = (a = [], b = []) => {
  const mergedLookup = [...a, ...b].reduce((acc, bucketEntry) => {
    const { key, doc_count, facets } = bucketEntry;
    const hasNoExistingEntry = !acc[key];
    if (hasNoExistingEntry) {
      return {
        ...acc,
        [key]: bucketEntry,
      };
    }

    const existingEntry = acc[key];

    // Calculated here for smaller lines as part of return statement
    const buckets = mergeShallowBuckets(
      existingEntry.facets.values.buckets,
      facets.values.buckets
    );
    const dom_count_error_upper_bound =
      existingEntry.facets.values.dom_count_error_upper_bound +
      facets.values.dom_count_error_upper_bound;
    const sum_other_doc_count =
      existingEntry.facets.values.sum_other_doc_count +
      facets.values.sum_other_doc_count;

    return {
      ...acc,
      [key]: {
        doc_count: existingEntry.doc_count + doc_count,
        key,
        facets: {
          doc_count: existingEntry.facets.doc_count + facets.doc_count,
          values: {
            buckets,
            dom_count_error_upper_bound,
            sum_other_doc_count,
          },
        },
      },
    };
  }, {});
  return Object.values(mergedLookup);
};

/**
 * Merges facets_missing portion of the elasticsearch response
 * Both inputs are in the shape { [facet_key] : {doc_count : number} }
 * @param {Object} a
 * @param {Object} b
 * @returns merged object, with the doc_count summed for duplicate values
 */
export const mergeFacetsMissingBuckets = (a = {}, b = {}) => {
  return Object.keys(b).reduce(
    (mergedBucket, bucketKey) => {
      if (mergedBucket[bucketKey]) {
        return {
          ...mergedBucket,
          [bucketKey]: {
            doc_count:
              mergedBucket[bucketKey].doc_count + b[bucketKey].doc_count,
          },
        };
      }
      return {
        ...mergedBucket,
        [bucketKey]: b[bucketKey],
      };
    },
    { ...a }
  );
};

/**
 * Combines an array of elasticsearch fetch results into a single object.
 * @param {Array} resultArrayToCombine array of elasticsearch results
 * @returns single object elasticsearch result
 */
export const combineResults = (resultArrayToCombine) => {
  const requestIds = resultArrayToCombine.map((result) => result.reqId);
  if (!requestIds.every((requestId) => requestId === requestIds[0])) {
    throw new Error('requestId mismatch in multi-search');
  }
  return resultArrayToCombine.reduce(
    (combinedResult, result) => {
      const { took, timed_out, hits, aggregations, reqId } = result;
      console.log(
        'result',
        { took, timed_out, hits, aggregations },
        { result }
      );

      // Calculated here for smaller lines as part of return statement
      const aggregationFacetBuckets = mergeDeepBuckets(
        combinedResult.aggregations.facets.facets.buckets,
        aggregations?.facets?.facets.buckets || []
      );
      const dom_count_error_upper_bound =
        combinedResult.aggregations.facets.dom_count_error_upper_bound +
          aggregations?.facets?.dom_count_error_upper_bound || 0;
      const sum_other_doc_count =
        combinedResult.aggregations.facets.sum_other_doc_count +
          aggregations?.facets?.sum_other_doc_count || 0;

      const resultFacetsMissing = aggregations?.facets_missing || {
        buckets: {},
      };
      const mergedFacetsMissingBucket = mergeFacetsMissingBuckets(
        combinedResult.aggregations.buckets,
        resultFacetsMissing.buckets
      );

      return {
        reqId,
        took: max(combinedResult.took, took),
        timed_out: combinedResult.timed_out && timed_out,
        hits: {
          hits: [...combinedResult.hits.hits, ...hits.hits],
          max_score: max(combinedResult.hits.max_score, hits.max_score),
          total: {
            value: combinedResult.hits.total.value + hits.total.value,
            relation: combinedResult.hits.total.relation || hits.total.relation,
          },
        },
        aggregations: {
          facets: {
            doc_count: combinedResult.aggregations.facets.doc_count,
            facets: {
              buckets: aggregationFacetBuckets,
              dom_count_error_upper_bound,
              sum_other_doc_count,
            },
          },
          facets_missing: {
            buckets: mergedFacetsMissingBucket,
          },
        },
      };
    },
    {
      reqId: '',
      took: Number.NEGATIVE_INFINITY,
      timed_out: false,
      hits: {
        hits: [],
        max_score: 0,
        total: {
          value: 0,
          relation: '',
        },
      },
      aggregations: {
        facets: {
          doc_count: 0,
          facets: {
            buckets: [],
            dom_count_error_upper_bound: 0,
            sum_other_doc_count: 0,
          },
        },
        facets_missing: {
          buckets: {},
        },
        translated: {
          doc_count: 0,
        },
      },
    }
  );
};

/**
 * Merges arrays that contain objects in shape {display : "", value :"", count :0}
 * If an item has the same value property as another in the array, the counts are summed.
 * @param {Array} a
 * @param {Array} b
 * @returns array containing merged items
 */
export const mergeFacetBuckets = (a = [], b = []) => {
  if (!a || !b) {
    return a || b;
  }
  const rawMergedArray = [...a, ...b];
  if (
    rawMergedArray.length === a.length ||
    rawMergedArray.length === b.length
  ) {
    // One of the lists is empty. No need to check for duplicates.
    return rawMergedArray;
  }
  // Combine the counts of facet buckets that have the same `value` property
  const mergedBucketObj = rawMergedArray.reduce(
    (mergedBuckets, bucketToMerge) => {
      const { display, value, count } = bucketToMerge;
      const existingCount = mergedBuckets[value]?.count || 0;
      return {
        ...mergedBuckets,
        [value]: {
          value,
          display,
          count: existingCount + count,
        },
      };
    },
    {}
  );
  return Object.values(mergedBucketObj);
};

/**
 * Merges array of arrays of facet values into a single array.
 * Expects objects with 'provider_control' property, and 'bucket' array.
 * @param {Array<Array>} facetsToCombine
 * @returns array of facet objects
 */
export const combineFacets = (facetsToCombine = []) => {
  const mergedFacets = facetsToCombine.flat().reduce((merged, facets) => {
    return {
      ...merged,
      [facets.provider_control]: {
        ...facets,
        buckets: mergeFacetBuckets(
          merged[facets.provider_control]?.buckets,
          facets.buckets
        ),
      },
    };
  }, {});
  return Object.values(mergedFacets);
};
