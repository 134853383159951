import classes from '../projects-table.module.scss';

const ClearFilters = ({ onChange }) => {
  const handleClearFilters = () => {
    onChange({
      date: { s: '', e: '' },
      dateActive: false,
      owner: { owner: '', owner_name: '' },
      ownerActive: false,
      page: 0,
      name: null,
    });
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classes['clear-filters']}
      size='small'
      onClick={handleClearFilters}
      style={{ marginLeft: 'auto', color: '#90929a' }}
    >
      Clear filters
    </div>
  );
};

export default ClearFilters;
