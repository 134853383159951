import React, { Dispatch, useReducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
// eslint-disable-next-line import/no-cycle
import reducer from './reducer';

export type TState = any;

export type TDispatch = { type: string; payload: { [key: string]: any } };

const AppContext = createContext<{
  dispatch?: Dispatch<TDispatch>;
  state?: TState;
}>({});

const initialState: TState = {
  user: { processing: true },
  auth: { isAuthUser: !!window.localStorage.getItem('jwt') },
  config: { processing: true },
  navigation: {},
  error: null,
  toast: null,
  modal: null,
  projectsFilter: {
    shouldFetch: false,
    date: { s: '', e: '' },
    owner: { owner: '', owner_name: '' },
    order: 'desc',
    orderBy: '',
    sort: '-created',
    page: 0,
    limit: 10,
    name: '',
    total: 0,
  },
  state: {},
};

export const store = {
  isReady: false,
  dispatch: (params: any) => {
    console.error('store not ready');
  },
};

export const AppProvider = ({
  children,
  ...props
}: {
  children: JSX.Element;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { state, dispatch };

  if (!store.isReady) {
    store.isReady = true;
    store.dispatch = (params: any) => dispatch(params);
    Object.freeze(store);
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const defaultState = {
  article: { loading: true },
  data: { items: [], loadeditems: 0, loading: true },
  expecting: { items: true, article: true },
  filters: {},
  loading: {},
  newQuery: false,
  page: 1,
  project: { loading: true },
  provider: {},
  query: {},
  queryId: '',
  related: '', // _id of current related article (loaded as article{})
  retrieving: false,
  search: {},
  searches: [],
  newSearches: [],
  newSearchTerms: [],
  tags: [],
  translate: { items: false, article: false },
  findingsFilter: {
    tags: [],
    sources: [],
    searches: [],
    date: {},
    sort: 'when-desc',
    apply: 0,
  },
};

export const defaultInitState = {
  ...defaultState,
  findings: null,
  availableTags: [],
  relatedItems: [], // _id, headline list of {type: related, active: true} docs in this index
};

export const useSelector = (selector): any =>
  useContextSelector(AppContext, (c) => selector(c.state));
export const useDispatch = (): any =>
  useContextSelector(AppContext, (c) => c.dispatch);

export default AppContext;
