interface StructuredData {
  [key: string]: unknown;
}

const parseContentToStructuredData = (
  content: undefined | object | string
): null | StructuredData => {
  if (!content) {
    return null;
  }
  if (typeof content === 'object') {
    return content as StructuredData;
  }
  try {
    return JSON.parse(content) as StructuredData;
  } catch (err) {
    console.error(`failed to parse JSON content: '${content}' ${err}`);
    return null;
  }
};

export default parseContentToStructuredData;
