import classes from '../projects-table.module.scss';

const projectTableHeaders = [
  {
    id: 'name',
    label: 'Project',
    className: classes['name-cell'],
  },
  {
    id: 'owner',
    label: 'Created by',
    className: classes['owner-cell'],
  },
  {
    id: 'shared',
    label: 'Shared With',
    className: classes['owner-cell'],
  },
  {
    id: 'created',
    label: 'Created date',
    className: classes['created-cell'],
  },
];

export default projectTableHeaders;
