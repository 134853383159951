import { handleError } from 'hooks/useFetch';
import apiClient from 'api/apiClient';
import { IAdverseTerm } from 'types/adverseTerms';

const favouriteAdverseTerm = async (term: IAdverseTerm) => {
  try {
    await apiClient<IAdverseTerm>({
      method: 'POST',
      endpoint: `terms/${term.id}/add-favourite`,
      version: 'v2',
    });

    return { data: term, error: null };
  } catch (err) {
    handleError(err);
    return { error: err };
  }
};

export default favouriteAdverseTerm;
