import { UPDATE_STATE } from 'constants/actionTypes';
import { useDispatch, useSelector } from 'context';
import {
  useAsyncMessageFilter,
  useAsyncMessageHandler,
  useMultiAsyncMessageHandler,
} from 'hooks/AsyncMessages';
import {
  FetchDocumentsUpdatedHandler,
  FetchErrorHandler,
  RetrieveDocumentsUpdatedHandler,
  RetrieveErrorHandler,
  TranslateDocumentsUpdatedHandler,
  TranslateErrorHandler,
} from 'hooks/AsyncMessages/commonHandlers';
import RelatedPageInsertedHandler from 'hooks/AsyncMessages/commonHandlers/RelatedPageInsertedHandler';
import fetchSearch from 'hooks/useFetch/searches/fetchSearch';
import { find } from 'lodash';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const SearchEmitterContainer = ({ children, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const article = useSelector((state) => state.state.article);
  const data = useSelector((state) => state.state.data);
  const provider = useSelector((state) => state.state.provider);
  const page = useSelector((state) => state.state.page);
  const translate = useSelector((state) => state.state.translate);
  const relatedItems = useSelector((state) => state.state.relatedItems);
  const {
    provider_id: providerId,
    project_id: projectId,
    search_id: searchId,
  } = useParams();
  const articleId = article?.id;

  useAsyncMessageFilter(
    useCallback(
      (message) => {
        if (!message.status) {
          return false;
        }

        if (
          message?.attributes?.project &&
          message.attributes.project !== projectId
        ) {
          return false;
        }

        if (
          message?.attributes?.search &&
          message.attributes.search !== searchId
        ) {
          return false;
        }

        if (message?.attributes?.page) {
          const msgPage = message.attributes.page + 1;
          if (msgPage === page) {
            // accept this page
            return true;
          }
          if (
            data?.loadedPages &&
            msgPage > page &&
            msgPage <= page - data.loadedPages + page
          ) {
            // accept special collapsed case
            // e.g. total 800 but collapsed to 672...
            // to get "page 7" might need msgPage = 9 or higher
            return true;
          }
          return false;
        }
        return true;
      },
      [data?.loadedPages, page, projectId, searchId]
    )
  );

  FetchDocumentsUpdatedHandler(articleId, dispatch);
  FetchErrorHandler(articleId, dispatch);
  RetrieveDocumentsUpdatedHandler(articleId, dispatch);
  RetrieveErrorHandler(articleId, dispatch);
  TranslateDocumentsUpdatedHandler(articleId, translate, data, dispatch);
  TranslateErrorHandler(articleId, translate, data, dispatch);
  RelatedPageInsertedHandler(projectId, searchId, relatedItems, dispatch);

  const searchResultReturnedCallback = useCallback(
    (message) => {
      console.log('searchResultReturnedCallback', message);
      fetchSearch(message.attributes.project, message.attributes.search).then(
        (fetchedSearch) => {
          console.log('fetchedSearch', fetchedSearch);
          if (!fetchedSearch?.id) {
            return;
          }

          const payload = {
            search: fetchedSearch,
            providers: fetchedSearch.providers,
          };

          // Update the state provider if no other provider is set OR we have fetched results
          // for the provider tab the user is currently viewing (providerId is fetched from URL params)
          if (!provider?.id || message.attributes.provider === providerId) {
            const foundProvider = find(fetchedSearch.providers, {
              id: providerId,
            });
            // @ts-ignore
            payload.data = {};
            // @ts-ignore
            payload.provider = foundProvider || fetchedSearch.providers[0];

            if (foundProvider.queries?.length) {
              // @ts-ignore
              payload.query =
                foundProvider.queries[foundProvider.queries.length - 1];
            }
          }

          dispatch({
            type: UPDATE_STATE,
            payload,
          });
        }
      );
    },
    [dispatch, provider?.id, providerId]
  );

  useMultiAsyncMessageHandler(
    ['search.error', 'search.results_received', 'deduplication.started'],
    searchResultReturnedCallback
  );

  useMultiAsyncMessageHandler(
    ['deduplication.completed', 'deduplication.error'],
    (message) => {
      // Small delay to fetching project information to allow any elastic document locks to be released
      setTimeout(() => {
        searchResultReturnedCallback(message);
      }, 1000);
    }
  );

  const noResultsCallback = useCallback(() => {
    const pathName =
      props.location.pathname.charAt(props.location.pathname.length - 1) === '/'
        ? props.location.pathname.substr(0, props.location.pathname.length - 1)
        : props.location.pathname;
    dispatch({
      type: UPDATE_STATE,
      payload: {
        article: {},
        data: {},
        expecting: { items: false, article: false },
        newQuery: false,
      },
    });
    navigate(pathName);
  }, [dispatch, navigate, props.location.pathname]);

  useAsyncMessageHandler('search.noresults', noResultsCallback);

  return children;
};
export default SearchEmitterContainer;
