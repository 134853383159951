/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { IAdverseTerm, IAdverseTermsSlice } from 'types/adverseTerms';
import favouriteAdverseTerm from 'api/adverseTerms/favouriteAdverseTerm';

export const unfavouriteAdverseTermThunk = createAsyncThunk(
  'adverseTerms/unfavouriteAdverseTerm',
  async (term: IAdverseTerm) => {
    return favouriteAdverseTerm(term);
  }
);

export const unfavouriteAdverseTermExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(unfavouriteAdverseTermThunk.fulfilled, (state, action) => {
    const term = action.payload.data;
    state.favourites.data = state.favourites.data.filter(
      (t) => t.id !== term.id
    );
  });
};
