/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { IAdverseTermDraft, IAdverseTermsSlice } from 'types/adverseTerms';
import upsertAdverseTerm from 'api/adverseTerms/upsertAdverseTerm';

export const upsertAdverseTermThunk = createAsyncThunk(
  'adverseTerms/upsertAdverseTerm',
  async (term: IAdverseTermDraft) => {
    return upsertAdverseTerm(term);
  }
);

export const upsertAdverseTermExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(upsertAdverseTermThunk.fulfilled, (state, action) => {
    const term = action.payload.data;
    if (term) {
      state.terms.data = state.terms.data.find((t) => t.id === term.id)
        ? state.terms.data.map((t) => (t.id === term.id ? term : t))
        : [...state.terms.data, term];
    }
  });
};
