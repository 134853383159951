/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import classNames from 'classnames';
import IconAngleDown from 'components/Base/Icons/IconAngleDown';
import IconAngleRight from 'components/Base/Icons/IconAngleRight';
import classes from './treeview.module.scss';
import Checkbox from '../Fields/Checkbox';

const TreeView = ({
  label,
  options,
  collapsible,
  isExpanded = false,
  handleChange,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isExpanded);

  return (
    <div className={classes.treeview}>
      <div
        className={classes['treeview-label']}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <span className={classes['treeview-label__icon']}>
          {collapsible && (
            <i onClick={() => setIsCollapsed(!isCollapsed)}>
              {!isCollapsed ? <IconAngleRight /> : <IconAngleDown />}
            </i>
          )}
        </span>
        {label}
      </div>
      {isCollapsed &&
        options?.map((item) => {
          if (item.collapsible)
            return (
              <TreeView
                key={item.id || item.label}
                label={item.label}
                options={item.options}
                collapsible={item.collapsible}
                handleChange={handleChange}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            );
          return (
            <TreeItem
              key={item.id || item.label}
              item={item}
              handleChange={handleChange}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          );
        })}
    </div>
  );
};

const TreeItem = ({ item, handleChange, isSelected, isDisabled }) => {
  const disabled = isDisabled && isDisabled(item.id);

  return (
    <div
      className={classNames(classes['tree-item'], {
        [classes['tree-item--disabled']]: disabled,
      })}
    >
      <div>
        <Checkbox
          label={item.label}
          value={item.id}
          name={item.name}
          size='small'
          fullWidth
          onClick={handleChange}
          checked={isSelected && isSelected(item.id)}
          disabled={isDisabled && isDisabled(item.id)}
        />
      </div>
    </div>
  );
};

export default TreeView;
