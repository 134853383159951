import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

let cancelToken;

export const fetchProjectItem = (projectId, articleId) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel();
  }
  cancelToken = axios.CancelToken.source();

  const request = axios.get(
    `${apiHost}/api/v2/projects/${projectId}/items/${articleId}`,
    {
      headers: apiHeaders(),
      cancelToken: cancelToken.token,
    }
  );

  return request
    .then((res) => {
      console.log('fetchProjectItem', res);
      return res.data;
    })
    .catch((err) => {
      if (err.message) store.dispatch(fetchFailure(err));
    });
};

export default fetchProjectItem;
