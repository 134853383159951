import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const deleteUsersShared = (projectId, userId) => {
  const data = JSON.stringify({
    user_shared: userId,
  });

  const req = axios.delete(`${apiHost}/api/v1/projects/${projectId}/share`, {
    headers: apiHeaders(),
    data,
  });

  return req
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default deleteUsersShared;
