/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from 'react';
import Table from 'components/Base/Table';
import { Refresh } from '@mui/icons-material';
import { Button, Fade } from '@mui/material';
import { useParams } from 'react-router-dom';
import { fetchSearch } from 'hooks/useFetch/searches/fetchSearch';
import { deleteSearch, submitQuery } from 'hooks/useFetch';
import { useSelector } from 'context';
import { retryQueries } from 'hooks/useFetch/queries/retryQueries';
import classes from './search-history-table.module.scss';
import SearchHistoryTableAsyncMessageHandler from './SearchHistoryTableAsyncMessageHandler';
import SearchHistoryTableRow from './SearchHistoryTableRow';
import NoItemsPlaceholder from './styled';

export const SearchHistoryTable = ({ initialSearches }) => {
  const project = useSelector((state) => state.state.project);
  const newSearches = useSelector((state) => state.state.newSearches);
  const [searches, setSearches] = useState(initialSearches);
  const [hasFailedQueries, setHasFailedQueries] = useState(false);
  const { project_id: projectId } = useParams();

  const handleDeleteSearch = (searchId) => {
    deleteSearch(projectId, searchId).then(() => {
      setSearches(searches.filter((s) => s.id !== searchId));
    });
  };

  const handleRetrySearch = (searchId, providerId, queryId) => {
    const search = searches.find((s) => s.id === searchId);
    const provider = search.providers.find((p) => p.id === providerId);
    const query =
      provider.queries[provider.queries.findIndex((q) => q.id === queryId)];
    query.processed = false;

    // Set loading
    setSearches(searches.map((s) => (s.id === search.id ? search : s)));

    submitQuery(
      project.id,
      search.id,
      provider.id,
      query.query_string,
      query.query_facets
    ).then(() => {
      fetchSearch(project.id, search.id).then((_search) => {
        setSearches(searches.map((s) => (s.id === _search.id ? _search : s)));
      });
    });
  };

  const handleRetryAll = () => {
    const retryItems = {};

    for (const s of searches) {
      const queries = [];
      const search = { ...s };

      for (const provider of search.providers) {
        const query = provider.queries?.length
          ? provider.queries[(provider.queries?.length || 0) - 1]
          : null;

        if (query && query.processed && query.status === 'search.error') {
          query.processed = false;
          queries.push({
            provider_id: provider.id,
            query_id: query.id,
          });
        }
      }

      if (queries?.length > 0) {
        retryItems[s.id] = queries;
        setSearches(
          searches.map((_search) =>
            _search.id === search.id ? search : _search
          )
        );
      }
    }

    for (const searchId of Object.keys(retryItems)) {
      const queries = {
        queries: retryItems[searchId],
      };

      // No need to fetch retried searches here as that is handled by the emitter event listeners.
      retryQueries(projectId, searchId, queries);
    }
  };

  useEffect(() => {
    if (newSearches && newSearches?.length > 0) {
      setSearches((_searches) => [...newSearches, ..._searches]);
    }
  }, [newSearches]);

  useEffect(() => {
    if (searches) {
      for (const search of searches) {
        if (search.providers) {
          for (const provider of search.providers) {
            const query = provider.queries?.length
              ? provider.queries[(provider.queries?.length || 0) - 1]
              : null;

            if (query && query.processed && query.status === 'search.error') {
              setHasFailedQueries(true);
              return;
            }
          }
        }
      }

      setHasFailedQueries(false);
    }
  }, [searches]);

  SearchHistoryTableAsyncMessageHandler(projectId, searches, setSearches);

  const activeSearches = searches?.filter((s) => !!s.providers);

  return (
    <Fade in timeout={250}>
      <div>
        <div className={classes['heading-container']}>
          {hasFailedQueries ? (
            <Button
              onClick={handleRetryAll}
              className={classes['retry-button']}
            >
              <Refresh />
              Retry failed searches
            </Button>
          ) : null}
        </div>

        <Table
          className={classes.container}
          headCells={[
            'Search Term',
            'News',
            'Compliance',
            'Records',
            'Corporate Records',
            'Created on',
            'Status',
            '',
          ]}
          rows={searches?.map((search) => {
            if (!search.providers) return '';

            return (
              <SearchHistoryTableRow
                key={search.id}
                search={search}
                deleteSearch={handleDeleteSearch}
                retrySearch={handleRetrySearch}
              />
            );
          })}
          paginate={false}
        />
        {!activeSearches?.length && (
          <NoItemsPlaceholder>No searches done yet</NoItemsPlaceholder>
        )}
      </div>
    </Fade>
  );
};

export default SearchHistoryTable;
