// import { useState, useReducer, useContext } from "react";
// import AppContext from "context";
// import { find, findIndex, isEmpty, uniq } from "lodash";
// import { useMatomo } from "@datapunt/matomo-tracker-react";
// import useEmitter from "hooks/useEmitter";

// const dataReducer = (state, action) => {
//   switch (action.type) {
//     case "SET":
//       return { ...action.payload };
//     case "UPDATE_ITEMS":
//       return { ...state, ...action.payload };
//     case "UPDATE_PROJECT":
//       return { ...state, ...action.payload };
//     case "ERROR_RESULTS":
//       return {
//         ...state,
//         loadedItems: 0,
//         items: [],
//         loading: false,
//         query: {
//           ...state.query,
//           status: action.status,
//           status_message: action.status_message,
//         },
//       };
//     case "NO_RESULTS":
//       return { ...state, loadedItems: 0, items: [], loading: false };
//     default:
//       return { ...state, ...action.payload };
//   }
// };

export const emptyContentMsg =
  'The data provider supplied no content for this item.';

export default emptyContentMsg;
