import { Drawer, Typography } from '@mui/material';

import IconButton from 'components/Base/Button/IconButton';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';

import { hideAdverseTermsPopout } from 'appSrc/store/slices/ui';
import {
  selectAdverseTermCategories,
  selectAdverseTermLanguages,
  selectAdverseTerms,
  selectSelectedAdverseTerm,
} from 'store/slices/adverseTerms';
import { useState } from 'react';
import { Categories, Languages } from 'appSrc/constants';
import { IAdverseTerm } from 'types/adverseTerms';
import classes from './search-bar.module.scss';
import AdverseTermCategory from './AdverseTermCategory';
import AdverseTermsFiltersBar from './AdverseTermsFiltersBar';
import AdverseTermModalFooter from './AdverseTermModalFooter';

const AdverseTermsPopout = ({ showTerms }) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectAdverseTermCategories);
  const languages = useAppSelector(selectAdverseTermLanguages);
  const terms = useAppSelector(selectAdverseTerms);

  const [selectedCatId, setSelectedCatId] = useState(Categories.ALL);
  const [selectedLangId, setSelectedLangId] = useState(Languages.ALL);

  const selectedTerm = useAppSelector(selectSelectedAdverseTerm);
  const [isFavouriteSelected, setIsFavouriteSelected] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState<IAdverseTerm[]>([]);
  const filteredCategories = [
    { id: Categories.PERSONAL, name: 'Personal' },
    ...categories.data.filter((cat) => {
      return (
        selectedCatId === Categories.ALL ||
        selectedCatId === Categories.FAVOURITES ||
        cat.id === selectedCatId
      );
    }),
  ];

  return (
    <Drawer
      id='adverse_terms_modal'
      anchor='right'
      open={showTerms}
      onClose={() => {
        dispatch(hideAdverseTermsPopout());
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ overflow: 'auto' }}>
          <div className={classes['terms-header']}>
            <div>
              <Typography variant='h6'> Adverse Terms</Typography>
              <h6 className={classes['terms-header__subtitle']}>
                {/* {showTerms.label} */}
              </h6>
            </div>
            <div>
              <IconButton
                id='adverse_terms_modal_close_btn'
                variant='clear'
                onClick={() => {
                  dispatch(hideAdverseTermsPopout());
                }}
              />
            </div>
          </div>
          <AdverseTermsFiltersBar
            categories={categories.data}
            languages={languages.data}
            selectedCat={selectedCatId}
            selectedLang={selectedLangId}
            onCategoryChange={(value) => {
              if (value === Categories.ALL || value === Categories.PERSONAL) {
                setIsFavouriteSelected(false);
                setSelectedCatId(value);
                return;
              }
              if (value === Categories.FAVOURITES) {
                setSelectedCatId(Categories.FAVOURITES);
                setIsFavouriteSelected(true);
                return;
              }
              const category = categories.data.find((cat) => cat.id === value);
              setIsFavouriteSelected(false);
              setSelectedCatId(category.id);
            }}
            onLanguageChange={(val) => {
              if (val === Languages.ALL) {
                setSelectedLangId(Languages.ALL);
                return;
              }
              const language = languages.data.find((cat) => cat.id === val);
              setSelectedLangId(language.id);
            }}
            onSearchChange={(val) => {
              const searchedTerms = terms.data.filter(
                (t) =>
                  t.name.includes(val) ||
                  t.name.toLowerCase().includes(val) ||
                  t.name.toUpperCase().includes(val)
              );
              setSearchedTerm(searchedTerms);
            }}
          />
          <AdverseTermCategory
            filteredCategories={filteredCategories}
            selectedLang={selectedLangId}
            searchedTerm={searchedTerm}
            isFavouriteSelected={isFavouriteSelected}
          />
        </div>
        {selectedTerm.term && (
          <AdverseTermModalFooter term={selectedTerm.term} />
        )}
      </div>
    </Drawer>
  );
};
export default AdverseTermsPopout;
