import React, { useState } from 'react';
import { TextField, Autocomplete, Chip, SvgIcon } from '@mui/material';

import Button from 'components/Base/Button';
import { submitTag, submitAllTags, deleteTag } from 'hooks/useFetch';
import classes from '../article.module.scss';

const ArticleTagsControl = ({
  projectId,
  docId,
  tags = [],
  availableTags,
  updateTags,
  isSaved,
}) => {
  const [showTagsControl, setShowTagsControl] = useState(false);

  const handleDelete = (tag) => {
    deleteTag(projectId, docId, tag);
    const arr = tags.filter((item) => item !== tag);
    updateTags(arr);
  };

  return showTagsControl ? (
    <Autocomplete
      disableClearable
      autoFocus
      blurOnSelect
      classes={{
        root: classes.dropdown,
        paper: classes.paper,
      }}
      freeSolo
      multiple
      getOptionLabel={(option) => option}
      options={availableTags.filter((tag) => tag !== 'Finding')}
      defaultValue={tags.filter((tag) => tag !== 'Finding')}
      size='small'
      ChipProps={{ classes: { root: classes.chip } }}
      renderInput={(params) => (
        <TextField
          autoFocus
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          size='small'
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.input,
              underline: {
                '&&&:before': {
                  borderBottom: 'none',
                },
                '&&:after': {
                  borderBottom: 'none',
                },
              },
            },
          }}
        />
      )}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          submitTag(projectId, docId, newValue);
          updateTags([newValue]);
        } else if (!isSaved) {
          submitAllTags(projectId, docId, newValue);
          updateTags(newValue);
        } else {
          // eslint-disable-next-line no-param-reassign
          newValue = [...newValue, 'Finding'];
          submitAllTags(projectId, docId, newValue);
          updateTags(newValue);
        }
        setShowTagsControl(false);
      }}
    />
  ) : (
    <>
      {tags &&
        tags
          .filter((tag) => tag !== 'Finding')
          .map((tag) => (
            <Chip
              key={`search-article--${tag}`}
              classes={{ root: classes.chip }}
              label={tag}
              size='small'
              onDelete={() => handleDelete(tag)}
            />
          ))}
      <Button
        label={
          <>
            <SvgIcon className={classes.icon}>
              <path d='M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.67 0 1.27-.33 1.63-.84L22 12l-4.37-6.16z' />
            </SvgIcon>
            &nbsp;Add tag
          </>
        }
        variant='secondary'
        size='small'
        onClick={() => setShowTagsControl(true)}
      />
    </>
  );
};

export default ArticleTagsControl;
