/* eslint-disable react/jsx-no-useless-fragment */
import { StructuredRow } from '../StructuredRow';
import { Table } from '../../Table';
import classes from '../../../article.module.scss';

export const PepDetailsSection = ({ pepStatusDetail }) => {
  const { pepStatus, roleDetails, updatedDates } = pepStatusDetail || {};
  const pepStatusDated =
    updatedDates.pepStatusDated !== ''
      ? `${pepStatus} (last updated ${updatedDates.pepStatusUpdated})`
      : `${pepStatus}`;
  return (
    <>
      {pepStatusDetail ? (
        <div className={classes.structure}>
          <h4 className={classes['structure-title']}>PEP Details</h4>
          <StructuredRow label='Pep status' value={pepStatusDated} />
          {roleDetails && roleDetails.length > 0 ? (
            <Table
              headCells={['Title', 'Start', 'End', 'Status']}
              rows={roleDetails.map((role) => {
                const { title, status, roleTermStartDate, roleTermEndDate } =
                  role;
                return [title, roleTermStartDate, roleTermEndDate, status];
              })}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PepDetailsSection;
