/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiLoadingStatus } from 'types/api';
import { IAdverseTermsSlice } from 'types/adverseTerms';
import fetchAdverseTermLanguages from 'api/adverseTerms/fetchAdverseTermLanguages';

export const fetchAdverseTermLanguagesThunk = createAsyncThunk(
  'adverseTerms/fetchAdverseTermLanguages',
  async () => {
    return fetchAdverseTermLanguages();
  }
);

export const fetchAdverseTermLanguagesExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(fetchAdverseTermLanguagesThunk.pending, (state) => {
    state.languages.status = ApiLoadingStatus.Loading;
    state.languages.error = null;
  });
  builder.addCase(fetchAdverseTermLanguagesThunk.fulfilled, (state, action) => {
    state.languages.status = ApiLoadingStatus.Succeeded;
    state.languages.data = action.payload.data;
    state.languages.error = action.payload.error;
  });
  builder.addCase(fetchAdverseTermLanguagesThunk.rejected, (state, action) => {
    state.languages.status = ApiLoadingStatus.Failed;
    state.languages.error = action.error.message;
    state.languages.data = [];
  });
};
