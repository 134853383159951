/* eslint-disable react/jsx-props-no-spreading */
import { isEmpty } from 'lodash';
import RecordsTable from '../ContentTypes/RecordsTable';
import ArticleHeader from '../ArticleSections/ArticleHeader';
import { SkeletonArticleBody } from '../SkeletonArticle';
import classes from '../article.module.scss';

const RecordsArticleTemplate = ({ data, ...props }) => {
  return (
    <div>
      {/* @ts-ignore */}
      <ArticleHeader data={data} {...props} />
      <div className={classes.content}>
        {!isEmpty(data.contentJSON) ? (
          <RecordsTable data={data} />
        ) : (
          <SkeletonArticleBody />
        )}
      </div>
    </div>
  );
};

export default RecordsArticleTemplate;
