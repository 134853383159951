import { useState } from 'react';
import { find } from 'lodash';
import {
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SvgIcon,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from 'components/Base/Chip';
import classNames from 'classnames';
import classes from '../facets.module.scss';

const IncludeExcludeFacet = ({
  className,
  name,
  control,
  options = [],
  selected,
  handleChange,
  handleInclude,
  handleReset,
  handleExclude,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpansion = () => {
    setIsExpanded(true);
  };
  const handleHide = () => {
    setIsExpanded(false);
  };

  const includedFacets = selected.filter(
    (item) => !item.isExcluded && item.providerControl === control
  );
  const excludedFacets = selected.filter(
    (item) => item.isExcluded && item.providerControl === `${control}x`
  );

  const availableFacets = options.filter(
    (bucket) =>
      !!(
        !find(selected, {
          providerControl: control,
          value: bucket.value,
        }) &&
        !find(selected, {
          providerControl: `${control}x`,
          value: bucket.value,
        })
      )
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const AccordionControls = () => {
    return (
      <div className={classes['checkbox-controls']}>
        {options.length > 9 &&
          (!isExpanded ? (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              className={classes['checkbox-control']}
              onClick={handleExpansion}
            >
              <p>Show more</p>
            </div>
          ) : (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div className={classes['checkbox-control']} onClick={handleHide}>
              <p>Show less</p>
            </div>
          ))}
        {includedFacets.length > 0 && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={classes['checkbox-control']}
            onClick={() => {
              handleReset(control);
            }}
          >
            <span>Reset all</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classNames(classes.facets, className)}>
      <Accordion className={classes.accordion} defaultExpanded>
        <AccordionSummary
          className={classes['accordion-summary']}
          expandIcon={<ExpandMoreIcon />}
          // eslint-disable-next-line react/no-children-prop
          children={
            <FormLabel className={classes.heading} component='legend'>
              {name}
            </FormLabel>
          }
        />
        <AccordionDetails className={classes['accordion-details']}>
          {includedFacets.length > 0 && (
            <div className={classes.selected}>
              <span className={classes['facet-label']}>Included</span>
              {includedFacets.map((option) => {
                const data = find(options, {
                  value: option.value,
                });
                return (
                  <ActiveFacet
                    key={option.value}
                    option={data}
                    handleChange={handleChange}
                    handleExclude={handleExclude}
                  />
                );
              })}
            </div>
          )}{' '}
          {excludedFacets.length > 0 && (
            <div className={classes.selected}>
              <span className={classes['facet-label']}>Excluded</span>
              {excludedFacets.map((option) => {
                return (
                  <ActiveFacet
                    key={option.value}
                    isExcluded
                    option={option}
                    control={control}
                    excluded={option}
                    handleChange={handleChange}
                    handleExclude={handleExclude}
                  />
                );
              })}
            </div>
          )}
          {isExpanded ? (
            <>
              {availableFacets.map((option) => {
                return (
                  <Facet
                    key={option.value}
                    option={option}
                    control={control}
                    handleInclude={handleInclude}
                    handleExclude={handleExclude}
                  />
                );
              })}
              <AccordionControls />
            </>
          ) : (
            <>
              {availableFacets.slice(0, 9).map((option) => {
                return (
                  <Facet
                    key={option.value}
                    option={option}
                    control={control}
                    handleInclude={handleInclude}
                    handleExclude={handleExclude}
                  />
                );
              })}
              <AccordionControls />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const Facet = ({ option, handleInclude, control, handleExclude }) => {
  return (
    <div className={classes.facet}>
      <SvgIcon
        color='primary'
        className={classes['facet-button']}
        onClick={() => handleInclude(control, option.value)}
      >
        <path d='M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
      </SvgIcon>
      <SvgIcon
        color='primary'
        className={classes['facet-button']}
        onClick={() => handleExclude(control, option.value)}
      >
        <path d='M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
      </SvgIcon>
      <span className={classes.label}>
        <span className={classes['label-display']}>{option.display} </span>{' '}
        {option.count && option.count > 0 && (
          <span style={{ display: 'inline-block' }}>
            {' '}
            <Chip size='small' variant='grey' label={option.count} />
          </span>
        )}
      </span>
    </div>
  );
};

const ActiveFacet = ({ option, control, handleChange, isExcluded }) => {
  return (
    <div className={classes.facet}>
      <SvgIcon
        style={{ color: '#C1C3C7' }}
        className={classes['facet-button']}
        onClick={() => handleChange(control, option.value)}
      >
        <path d='M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
      </SvgIcon>
      <span className={classes.label}>
        <span
          className={classes['label-display']}
          style={{ textDecoration: isExcluded && 'line-through' }}
        >
          {option?.display || option?.value || ''}{' '}
        </span>{' '}
        <span style={{ display: 'inline-block' }}>
          {option?.count && option.count > 0 ? (
            <Chip size='small' variant='grey' label={option.count} />
          ) : (
            ''
          )}
        </span>
      </span>
    </div>
  );
};

export default IncludeExcludeFacet;
