import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Toggle from 'components/Base/Fields/Toggle';
import Button from 'components/Base/Button';
import { useHighlighter } from 'hooks/useHighlighter';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { getProviderVariant } from 'utils';
import { useSelector } from 'context';
import IconPrint from 'components/Base/Icons/IconPrint';
import { PlaylistAdd, PlaylistAddCheck } from '@mui/icons-material';
import classes from '../article.module.scss';
import SearchViewCount from '../../SearchViewCount';
import CopyReference from './CopyReference';
import TagsControl from './TagsControl';
import { SayariHighlightChips } from '../ArticleTemplates/CorporateRecordsArticleTemplate/Sayari';

const ArticleHeader = ({
  data,
  project,
  handleArticleTranslate,
  highlight,
  isSaved,
  handleSave,
  availableTags,
  updateTags,
}) => {
  const { trackEvent } = useMatomo();
  const navigate = useNavigate();
  const variant = getProviderVariant(data.provider);
  const translate = useSelector((state) => state.state.translate);

  const getDateSubtitle = () => {
    switch (data.provider) {
      case 'records.landreg':
        return `Date retrieved: ${data.date}`;

      case 'corporate_records.sayari':
        return <SayariHighlightChips content={JSON.parse(data.category)} />;

      default:
        return `${data.category} - ${data.date}`;
    }
  };

  return (
    <div className={classes.header}>
      <div className={classes['header-controls']}>
        <SearchViewCount viewed={data.viewed} />
        {variant === 'news' && (
          <Toggle
            id='matomo-translate-article-btn'
            toggleLabel='Translate article to English'
            size='small'
            onClick={handleArticleTranslate}
            checked={translate.article}
          />
        )}
      </div>
      <Typography
        className={classes.title}
        dangerouslySetInnerHTML={{
          __html: useHighlighter({
            query: highlight,
            content:
              translate.article && data.translatedTitle
                ? data.translatedTitle
                : data.title,
          }),
        }}
      />
      <div className={classes.headerDetails}>
        <div className={classes.subtitle}>
          <div>
            <span>{getDateSubtitle()}</span>
          </div>
          <div className={classes['primary-controls']}>
            <Button
              onClick={() => {
                trackEvent({ category: 'article', action: 'print_article' });
                navigate(
                  `/projects/${project.id}/searches/${
                    data.search_id || 'related'
                  }/providers/${data.provider}/queries/${data.query_id}/items/${
                    data.id
                  }/export`
                );
              }}
              variant='secondary'
              size='small'
              label={
                <>
                  <IconPrint />
                  &nbsp; Print version
                </>
              }
            />
            <CopyReference data={data} />
          </div>
        </div>
        <div className={classes['secondary-controls']}>
          <Button
            id={
              isSaved
                ? 'matomo-remove-from-findings-btn'
                : 'matomo-add-to-findings-btn'
            }
            onClick={() => handleSave()}
            style={{
              minWidth: '70px',
              margin: '0 6px 0 0',
            }}
            label={
              isSaved ? (
                <>
                  <PlaylistAddCheck style={{ fontSize: '18px' }} />
                  &nbsp;In Findings
                </>
              ) : (
                <>
                  <PlaylistAdd style={{ fontSize: '18px' }} />
                  &nbsp;Add to Findings
                </>
              )
            }
            variant='secondary'
            size='small'
          />
          <TagsControl
            isSaved={isSaved}
            projectId={project.id}
            docId={data.id}
            tags={data.tags}
            availableTags={availableTags}
            updateTags={updateTags}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleHeader;
