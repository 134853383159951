import React, { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'context';
import SimpleBar from 'simplebar-react';
import Select from 'components/Base/Fields/Select';
import Toggle from 'components/Base/Fields/Toggle';
import Button from 'components/Base/Button';
import IconPrint from 'components/Base/Icons/IconPrint';
import { fetchFindingQueryItems, submitTranslationById } from 'hooks/useFetch';
import { find } from 'lodash';
import useHandleTranslation from 'hooks/useHandleTranslation';
import { UPDATE_STATE } from 'constants/actionTypes';
import classes from './findings-results.module.scss';
import ResultsPagination from '../Results/ResultsPagination';
// eslint-disable-next-line import/no-cycle
import ResultsList, { SkeletonList } from '../Results';

const FindingsResults = (props) => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const project = useSelector((state) => state.state.project);
  const page = useSelector((state) => state.state.page);
  const data = useSelector((state) => state.state.data);
  const article = useSelector((state) => state.state.article);
  const translate = useSelector((state) => state.state.translate);
  const findingsFilter = useSelector(
    (state) =>
      state.state.findingsFilter || {
        tags: [],
        sources: [],
        searches: [],
        date: {},
        sort: 'when-desc',
        apply: 0,
      }
  );

  const reqId = useRef(0);
  const currFilterReq = useRef(-1);

  const setSort = (val) => {
    // eslint-disable-next-line no-param-reassign
    if (val === undefined) val = 'when-desc';
    if (findingsFilter.sort !== val) {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          findingsFilter: {
            ...findingsFilter,
            sort: val,
            change: Math.random(),
          },
        },
      });
    }
  };

  const fetchFindingQueryItemsCallback = useCallback(() => {
    const setReqId = Math.random();
    reqId.current = setReqId;
    fetchFindingQueryItems({
      projectId: project.id,
      sort: findingsFilter?.sort || '',
      page,
      date: findingsFilter?.date?.from
        ? [findingsFilter?.date?.from, findingsFilter?.date?.to]
        : [],
      // eslint-disable-next-line no-unsafe-optional-chaining
      tags: findingsFilter?.tags ? [...findingsFilter?.tags] : [],
      // eslint-disable-next-line no-unsafe-optional-chaining
      sources: findingsFilter?.sources ? [...findingsFilter?.sources] : [],
      // eslint-disable-next-line no-unsafe-optional-chaining
      searches: findingsFilter?.searches ? [...findingsFilter?.searches] : [],
      reqId: setReqId,
    }).then((res) => {
      if (!res) return;
      if (res?.reqId !== reqId.current) return;

      // TODO - sync common parts with fetchQueryItems() e.g. translate counts

      const result = !!res?.hits?.hits?.[0];
      const translatedCount = res?.hits?.hits?.filter(
        (item) =>
          !!item._source.headline_trans && item._source.language !== 'en'
      );
      const translatedReqCount = res?.hits?.hits?.filter(
        (item) => item._source.language !== 'en'
      );

      const isMissingTranslations =
        translate.items && translatedCount.length < translatedReqCount.length;

      if (isMissingTranslations) {
        if (process.env.NODE_ENV === 'development') {
          console.log({ SEARCH_RESULTS: 'missing translations' });
        }
        config.providers.forEach((provider) => {
          const items = res.hits.hits
            .filter(
              (item) =>
                !item._source.headline_trans &&
                item._source.language !== 'en' &&
                item._source.provider.id === provider.id
            )
            .map((item) => item._id);
          if (items.length)
            submitTranslationById({
              projectId: project.id,
              providerId: provider.id,
              docs: items,
            });
        });
      }

      if (result) {
        // Persist or reload article
        if (
          article.id &&
          !article.loading &&
          find(res.hits.hits, { _id: article.id })
        ) {
          dispatch({
            type: UPDATE_STATE,
            payload: {
              expecting: { article: false, items: false },
              data: {
                totalCount: res.hits.total.value,
                items: res.hits.hits,
                loading: false,
              },
            },
          });
        } else {
          dispatch({
            type: UPDATE_STATE,
            payload: {
              expecting: { items: false, article: true },
              data: {
                totalCount: res.hits.total.value,
                items: res.hits.hits,
                loading: false,
              },
              article: {
                id: res.hits.hits[0]._id,
                loading: true,
              },
            },
          });
        }
        return;
      }
      if (!result) {
        dispatch({
          type: UPDATE_STATE,
          payload: {
            article: { loading: false },
            data: { items: [], loading: false },
          },
        });
      }
    });
  }, [
    page,
    article.id,
    article.loading,
    config.providers,
    dispatch,
    findingsFilter?.date?.from,
    findingsFilter?.date?.to,
    findingsFilter?.searches,
    findingsFilter?.sort,
    findingsFilter?.sources,
    findingsFilter?.tags,
    project.id,
    translate.items,
  ]);

  useEffect(() => {
    if (currFilterReq.current === findingsFilter?.apply) return;
    currFilterReq.current = findingsFilter.apply;
    fetchFindingQueryItemsCallback();
  }, [page, findingsFilter?.apply, fetchFindingQueryItemsCallback]);

  return (
    <div className={classes.results}>
      <FindingsResultsHeader
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        project={project}
        totalResults={data?.totalCount}
        page={page}
        findingsFilter={findingsFilter}
        setSort={setSort}
        translate={translate}
      />
      <SimpleBar style={{ maxHeight: '100%' }}>
        {!project.loading && !data?.loading ? (
          <ResultsList
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            message='No items to display. Try tagging some findings or adjusting the filters.'
            isFindings
          />
        ) : (
          <SkeletonList />
        )}
      </SimpleBar>
      <ResultsPagination
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        totalResults={data?.totalCount}
        page={page}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        setPage={(page) =>
          dispatch({
            type: UPDATE_STATE,
            payload: {
              page,
              expecting: { items: true, article: true },
              data: {
                totalCount: data?.totalCount,
                loading: true,
              },
              article: { loading: true },
            },
          })
        }
      />
    </div>
  );
};

const FindingsResultsHeader = (props) => {
  const navigate = useNavigate();

  const { setSort, page, project, translate, totalResults, findingsFilter } =
    props;
  const handleTranslation = useHandleTranslation('findings');

  const handleQuerySort = (value) => {
    setSort(value);
  };

  return (
    <div className={classes.header}>
      <div>
        <Select
          variant='grey-uppercase'
          value={findingsFilter.sort || 'when-desc'}
          options={[
            {
              value: 'when-desc',
              label: 'Newest First',
              onClick: () => handleQuerySort('when-desc'),
            },
            {
              value: 'when-asc',
              label: 'Oldest First',
              onClick: () => handleQuerySort('when-asc'),
            },
            {
              value: 'provider-asc',
              label: 'Provider',
              onClick: () => handleQuerySort('provider-asc'),
            },
          ]}
        />
      </div>
      <div>
        <Toggle
          id='matomo-translate-results-btn'
          toggleLabel='Translate results to English'
          size='small'
          checked={translate?.items}
          onClick={(e) => {
            e.preventDefault();
            handleTranslation();
          }}
        />
      </div>
      <div>
        {totalResults ? (
          <Button
            onClick={() =>
              navigate(
                `/projects/${project.id}/findings/export?` +
                  `page=${page}&sort=${findingsFilter.sort}` +
                  `&tags=${findingsFilter.tags}` +
                  `&sources=${findingsFilter.sources}` +
                  `&searches=${findingsFilter.searches}` +
                  `&from=${findingsFilter.date.from}` +
                  `&to=${findingsFilter.date.to}`
              )
            }
            variant='secondary'
            size='small'
            label={
              <>
                <IconPrint />
                &nbsp; Print version
              </>
            }
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default FindingsResults;
