import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiHeaders, apiHost, handleError } from '../..';
import { combineFacets, combineResults } from './mergeResults';
import { addBucketValuesToAvailableFacets } from './helpers';

/**
 * Function that filters the given array to only providers that contain the 'selectedProvider' as a substring in their id.
 * Matches are not case sensitive
 * @param {string} selectedProvider
 * @param {array} providers
 * @returns array of provider objects
 */
export const getProvidersToFetch = (selectedProvider, providers) => {
  const normalisedSelectedProvider = selectedProvider.toLowerCase();
  const providersToFetch = providers.filter((provider) => {
    const normalisedProviderId = provider.id.toLowerCase();
    return normalisedProviderId.startsWith(normalisedSelectedProvider);
  });
  return providersToFetch;
};

export const fetchQueryItems = async (
  {
    projectId,
    searchId,
    providerId,
    queryId,
    selectedFacets = {}, // list of facets
    availableFacets = [], // filter values
    sort = '',
    collapseField,
    page = 1,
    localFilter, // two modes: localFilter (facets + aggs etc) or just pagination (facets and filtering done as provider requests)
    reqId,
  },
  ...axiosAdditionalArgs
) => {
  const perPage = 100;
  const from = perPage * page - 100;

  const dataObject = {
    size: perPage,
    from: from > 0 ? from : 0,
    sort,
    localFilter,
    selectedFacets,
    collapseField,
    availableFacets: availableFacets
      .map((facetId) => facetId?.provider_control)
      .filter((facetId) => facetId),
  };

  const dataAsString = JSON.stringify(dataObject);
  const requestUrl = `${apiHost}/api/v2/projects/${projectId}/searches/${searchId}/providers/${providerId}/queries/${queryId}/items`;

  try {
    const request = await axios.post(
      requestUrl,
      dataAsString,
      {
        headers: apiHeaders(),
      },
      ...axiosAdditionalArgs
    );

    request.data.reqId = reqId;
    return request.data;
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const fetchQueryItemsForGivenProviders = async (
  providersToFetch,
  providerConfigs,
  sharedFetchParams,
  signal = new AbortController()
) => {
  const resultsArray = await Promise.all(
    providersToFetch.map(async (provider) => {
      const providerId = provider.id;
      const { facets } = provider.queries[provider.queries.length - 1];
      const { selectedFacets, queryId } = sharedFetchParams;

      const currentProviderConfig =
        providerConfigs.filter(
          (providerConfig) => providerConfig?.id === providerId
        )[0] || {};
      const localFilter = !!currentProviderConfig?.dispatch?.local_filter;
      const collapse = currentProviderConfig?.dispatch?.collapse_results;

      const { projectId, searchId, sort, page, reqId } = sharedFetchParams;

      const availableFacets = facets ? [...facets] : [];

      const fetchParameters = {
        projectId,
        searchId,
        providerId,
        queryId,
        selectedFacets,
        availableFacets,
        sort,
        collapseField: collapse,
        page,
        localFilter,
        reqId,
      };

      const fetchResults = await fetchQueryItems(fetchParameters, {
        signal,
      });

      console.log('fetchQueryItems', {
        parameters: fetchParameters,
        results: fetchResults,
      });

      // If the fetch failed because of an api error, it returns null so exit early
      if (!fetchResults) {
        return null;
      }

      const hasAggregateBuckets =
        typeof fetchResults?.aggregations?.facets?.facets?.buckets === 'object';
      if (hasAggregateBuckets) {
        const facetsWithBucketValues = addBucketValuesToAvailableFacets(
          selectedFacets,
          availableFacets,
          fetchResults.aggregations.facets.facets.buckets,
          fetchResults.aggregations.facets_missing?.buckets
        );

        return {
          fetchResults,
          facets: facetsWithBucketValues,
          filter: selectedFacets,
        };
      }

      return {
        fetchResults,
        facets: availableFacets,
        filter: selectedFacets,
      };
    })
  );

  const { results, filters, facets } = resultsArray.reduce(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ results, filters, facets }, current) => {
      return {
        results: [...results, current.fetchResults],
        filters: [...filters, current.filter],
        facets: [...facets, current.facets],
      };
    },
    {
      results: [],
      filters: [],
      facets: [],
    }
  );

  return {
    res: combineResults(results),
    filters: filters[0], // Temporary
    facets: combineFacets(facets),
  };
};
