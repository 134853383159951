/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import rootInitialState, { IRootInitialState } from 'store/rootInitialState';
import { IAdverseTerm } from 'types/adverseTerms';
import { fetchAdverseTermsExtraReducers } from 'store/thunks/fetchAdverseTerms';
import { fetchFavouriteAdverseTermsExtraReducers } from 'store/thunks/fetchFavouriteAdverseTerms';
import { favouriteAdverseTermExtraReducers } from 'store/thunks/favouriteAdverseTerm';
import { unfavouriteAdverseTermExtraReducers } from 'store/thunks/unfavouriteAdverseTerm';
import { fetchAdverseTermCategoriesExtraReducers } from 'store/thunks/fetchAdverseTermCategories';
import { fetchAdverseTermLanguagesExtraReducers } from 'store/thunks/fetchAdverseTermLanguages';
import { upsertAdverseTermExtraReducers } from 'store/thunks/upsertAdverseTerm';
import { createAdverseTermCategoryExtraReducers } from 'store/thunks/createAdverseTermCategory';
import { deleteAdverseTermExtraReducers } from 'store/thunks/deleteAdverseTerm';
import { fetchAdverseTermProvidersExtraReducers } from 'store/thunks/fetchAdverseTermProviders';

export const adverseTermsSlice = createSlice({
  name: 'adverseTerms',
  initialState: rootInitialState.adverseTerms,
  reducers: {
    selectAdverseTerm: (state, action: PayloadAction<IAdverseTerm | null>) => {
      const term = action.payload;
      state.selectedTerm.term = term;
      if (term?.category) {
        state.selectedTerm.parentCategory = state.categories.data.find(
          (c) => c.id === term.category.parent_id
        );
      }
    },
    clearSelectedAdverseTerm: (state) => {
      state.selectedTerm.term = null;
    },
  },
  extraReducers: (builder) => {
    fetchAdverseTermsExtraReducers(builder);
    fetchFavouriteAdverseTermsExtraReducers(builder);
    favouriteAdverseTermExtraReducers(builder);
    unfavouriteAdverseTermExtraReducers(builder);
    fetchAdverseTermCategoriesExtraReducers(builder);
    fetchAdverseTermProvidersExtraReducers(builder);
    fetchAdverseTermLanguagesExtraReducers(builder);
    upsertAdverseTermExtraReducers(builder);
    createAdverseTermCategoryExtraReducers(builder);
    deleteAdverseTermExtraReducers(builder);
  },
});

export const { selectAdverseTerm, clearSelectedAdverseTerm } =
  adverseTermsSlice.actions;

export const selectSelectedAdverseTerm = (state: IRootInitialState) =>
  state.adverseTerms.selectedTerm;

export const selectAdverseTerms = (state: IRootInitialState) =>
  state.adverseTerms.terms;

export const selectFavouriteAdverseTerms = (state: IRootInitialState) =>
  state.adverseTerms.favourites;

export const selectIsFavouriteTerm = (
  state: IRootInitialState,
  termId: number
) => state.adverseTerms.favourites.data.some((t) => t.id === termId);

export const selectAdverseTermCategories = (state: IRootInitialState) =>
  state.adverseTerms.categories;

export const selectAdverseTermLanguages = (state: IRootInitialState) =>
  state.adverseTerms.languages;

export const selectAdverseTermProviders = (state: IRootInitialState) =>
  state.adverseTerms.providers;

export const selectTermsForCategory = (
  state: IRootInitialState,
  catId: number
) =>
  state.adverseTerms.terms.data.filter((term) => term.category?.id === catId);

export const selectAdverseTermCategoryById = (
  state: IRootInitialState,
  id: number
) => {
  if (state.adverseTerms.categories.data.length <= 0) {
    return null;
  }

  for (let i = 0; i < state.adverseTerms.categories.data.length; i += 1) {
    const cat = state.adverseTerms.categories.data[i];
    if (cat.id === id) {
      return cat;
    }
    if (cat.subcategories) {
      const subcategory = cat.subcategories.find((subcat) => subcat.id === id);
      if (subcategory) {
        return subcategory;
      }
    }
  }

  return null;
};

export const selectAdverseTermProviderByKey = (
  state: IRootInitialState,
  key: string
) => state.adverseTerms.providers.data.find((p) => p.name === key);

export default adverseTermsSlice.reducer;
