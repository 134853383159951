import { MenuItem, Select, styled } from '@mui/material';

const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    &.Mui-focused {
      fieldset {
        border-color: #526988;
      }
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    &.Mui-selected {
      background-color: #dae6f5 !important;
    }
  }
`;

export default StyledSelect;
