import Button from 'components/Base/Button';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import isUrl from 'is-url';
import IconExternalLink from 'components/Base/Icons/IconExternalLink';
import classes from '../article.module.scss';
import ArticleContent from '../ArticleSections/ArticleContent';
import ArticleHeader from '../ArticleSections/ArticleHeader';

const GenericArticleTemplate = ({
  data,
  project,
  translate,
  highlight,
  retrieving,
  ...props
}) => {
  const { trackEvent } = useMatomo();
  return (
    <div>
      {/* @ts-ignore */}
      <ArticleHeader
        data={data}
        project={project}
        // @ts-ignore
        translate={translate}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {/* @ts-ignore */}
      <ArticleContent
        // providers={providers}
        data={data}
        highlight={highlight}
        translate={translate}
        retrieving={retrieving}
        // setRetrieving={setRetrieving}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {isUrl(data.end_url) && (
        <div className={classes['content-footer']}>
          <p className={classes.subtitle}>{data.end_url}</p>
          <div className={classes['content-links']}>
            <Button
              onClick={() =>
                trackEvent({
                  category: 'article',
                  action: 'visit_original_source',
                })
              }
              href={data.end_url}
              target='_blank'
              variant='secondary'
              size='small'
              margin='0 6px 0 0'
              label={
                <>
                  <IconExternalLink />
                  &nbsp; Visit original source
                </>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GenericArticleTemplate;
