import { Button, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledButton = styled(Button)`
  &.MuiButton-contained {
    border-radius: 0;
    background-color: #526988;
    box-shadow: none;

    &:hover {
      background-color: #6f839e;
      box-shadow: none;
    }

    &:disabled {
      background-color: #d6d5d5;
      box-shadow: none;
    }
  }

  &.MuiButton-text {
    color: #6f839e;
    line-height: 0;
    padding: 0.6rem;
  }
`;

export const StyledLoadingButton = styled(LoadingButton)`
  &.MuiButton-contained {
    border-radius: 0;
    background-color: #526988;
    box-shadow: none;

    &:hover {
      background-color: #6f839e;
      box-shadow: none;
    }

    &:disabled {
      background-color: #d6d5d5;
      box-shadow: none;
    }
  }

  &.MuiButton-text {
    color: #6f839e;
    line-height: 0;
    padding: 0.6rem;
  }
`;
export default StyledButton;
