import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitRetrieveContent = async (
  project_id,
  doc_id,
  provider_id
) => {
  const data = JSON.stringify({
    provider: provider_id,
    doc_ids: [doc_id],
  });

  await axios
    .post(`${apiHost}/api/v1/projects/${project_id}/retrieve`, data, {
      headers: apiHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitRetrieveContent;
