/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { defaultState, useDispatch, useSelector } from 'context';
import Layout from 'layout/Layout';
// eslint-disable-next-line import/no-cycle
import SearchHeader from 'components/SearchHeader';
import Spinner from 'components/Base/Spinner';
import { find, noop } from 'lodash';
import { fetchProject } from 'hooks/useFetch';
import SearchNavigation from 'components/SearchNavigation';
import { appRoutingPaths } from 'router';
import { reverse as url } from 'named-urls';
import useHandleAvailableTags from 'hooks/useHandleAvailableTags';
import useHandleRelatedItems from 'hooks/useHandleRelatedItems';
import { fetchSearch } from 'hooks/useFetch/searches/fetchSearch';
import { UPDATE_STATE } from 'constants/actionTypes';
import SearchRelatedItem from 'components/SearchRelatedItem';
import SearchResultsGrid from 'components/SearchResultsGrid';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { selectAdverseTermsPopoutVisible } from 'appSrc/store/slices/ui';
import AdverseTermsPopout from 'components/SearchBar/AdverseTermsPopout';
import fetchAllAdverseTermsDataThunk from 'appSrc/store/thunks/fetchAllAdverseTerms';
import SearchEmitterContainer from './SearchEmitterContainer';

const Search = ({ ...props }) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const showTerms = useAppSelector(selectAdverseTermsPopoutVisible);
  // TODO - are these default values done by defaultState now?
  const project = useSelector(
    (state) => state.state.project || { loading: true }
  );
  const search = useSelector((state) => state.state.search);
  const query = useSelector((state) => state.state.query);
  // _id of current related article
  const related = useSelector((state) => state.state.related);
  // TODO - params not updating on navigate, this fix for now
  const newQuery = useSelector(
    (state) =>
      state.state.newQuery ||
      new URLSearchParams(location.search).get('new_query')
  );
  const queryId = useSelector(
    (state) => state.state.queryId || params.query_id
  );

  const {
    project_id: projectId,
    // query_id: queryId, // See queryId state var above
    search_id: searchId,
    provider_id: providerId,
  } = params;
  const handleAvailableTags = useHandleAvailableTags();
  const handleRelatedItems = useHandleRelatedItems();
  const currFetchProj = useRef([]);

  // Sometimes the emitter doesn't make a connection which means we miss the events
  // emitted from the processing service. We have a fallback fetch that retries
  // every 5 seconds to help prevent this.
  const fallbackMaxRetries = 12; // 60 seconds of retrying
  const fallbackRetries = useRef(0);

  const fetchProjectCallback = useCallback(() => {
    fetchProject(projectId).then((response) => {
      // result error, go back to list
      if (!response?.id) {
        navigate(url(appRoutingPaths.projects.all));
        return;
      }

      const search = find(response?.searches, ['id', searchId]);
      const isProviderGroup = search.providers.some(
        (provider) =>
          provider.id.startsWith(providerId) && !(provider.id === providerId)
      );
      const provider = isProviderGroup
        ? { id: providerId }
        : find(search?.providers, ['id', providerId]);
      const query = isProviderGroup
        ? { id: `all-${providerId}-queries` }
        : find(provider?.queries, ['id', queryId]);
      console.log('fetchProjectCallback', {
        response,
        search,
        provider,
        query,
      });
      // couldn't find query, 404
      if (!query) {
        console.log('exiting here');
        return;
      }

      // set state
      dispatch({
        type: UPDATE_STATE,
        payload: {
          ...defaultState,
          page: -1, // special for first load bookmark handling
          query,
          queryId,
          provider,
          search,
          searches: response.searches,
          project: { ...response, loading: false },
          expecting: { items: true, article: true },
        },
      });
    });
  }, [queryId, dispatch, navigate, projectId, providerId, searchId]);
  useEffect(() => {
    appDispatch(fetchAllAdverseTermsDataThunk());
  }, [appDispatch]);
  // Project + Query
  useEffect(() => {
    // only for select cases
    const [currentQueryId, currentQuery] = currFetchProj.current;
    if (currentQueryId === queryId && currentQuery === newQuery) {
      return;
    }

    // required
    if (!queryId) {
      return;
    }

    // nothing new to get for this query
    if (query?.processed) {
      console.log('exiting here search query processed');
      return;
    }
    console.log({ queryId, currFetchProj });
    currFetchProj.current = [queryId, newQuery];
    fetchProjectCallback();
  }, [
    queryId,
    newQuery,
    location.search,
    query?.processed,
    fetchProjectCallback,
  ]);

  // Available Tags
  useEffect(() => {
    if (!projectId) return noop;
    dispatch({
      type: UPDATE_STATE,
      payload: { availableTags: [] },
    });
    const timeout = setTimeout(() => handleAvailableTags(projectId), 100);
    return () => clearTimeout(timeout);
  }, [projectId, dispatch, handleAvailableTags]);

  // Related Items
  useEffect(() => {
    if (!projectId || !searchId) return noop;
    dispatch({
      type: UPDATE_STATE,
      payload: {
        relatedItems: [],
      },
    });
    const timeout = setTimeout(
      () => handleRelatedItems(projectId, searchId),
      30
    );
    return () => clearTimeout(timeout);
  }, [searchId, projectId, dispatch, handleRelatedItems]);

  // Fallback fetching for providers if an emitter event hasn't been received correctly
  useEffect(() => {
    if (!search || !search.providers || !providerId) {
      return;
    }

    const provider = search.providers.find((p) => p.id === providerId);
    if (!provider) {
      return;
    }

    const query = provider.queries[provider.queries.length - 1];
    if (query.processed) {
      return;
    }

    if (fallbackRetries.current >= fallbackMaxRetries) {
      // todo: maybe do something better here? redirect? mark as error?
      console.log('max retry attempt reached');
      return;
    }

    const timeout = setTimeout(() => {
      console.log('fetchSearch', { searchId, projectId, providerId });
      fallbackRetries.current += 1;
      // eslint-disable-next-line consistent-return
      fetchSearch(projectId, search.id).then((fetchedSearch) => {
        if (!fetchedSearch?.id) {
          return false;
        }

        const foundProvider = fetchedSearch.providers.find(
          (p) => p.id === providerId
        );
        const provider = foundProvider || fetchedSearch.providers[0];

        dispatch({
          type: 'update-state',
          payload: {
            data: {},
            search: fetchedSearch,
            providers: fetchedSearch.providers,
            provider,
            query: provider.queries[provider.queries.length - 1],
          },
        });
      });
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [search, searchId, projectId, providerId, dispatch]);

  return (
    <Layout
      project={project?.name}
      title={project?.name}
      header=''
      variant='search'
      isScrollable={false}
      breadcrumbs={[
        { label: 'Projects', link: '/projects' },
        { label: project?.name, link: `/projects/${project.id}` },
        { label: search?.name, link: location.pathname },
      ]}
    >
      <>
        <SearchEmitterContainer
          location={location}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {search?.providers && !project?.loading ? (
            <>
              <SearchHeader />
              <SearchNavigation />
              {related ? <SearchRelatedItem /> : <SearchResultsGrid />}
            </>
          ) : (
            <Spinner />
          )}
        </SearchEmitterContainer>
        {showTerms && <AdverseTermsPopout showTerms={showTerms} />}
      </>
    </Layout>
  );
};

export default Search;
