import React from 'react';
import classes from './results.module.scss';
import { TagsList } from './TagsList';
import { SayariHighlightChips } from '../Article/ArticleTemplates/CorporateRecordsArticleTemplate/Sayari';
import { ResultsItemHeader } from './ResultsItemHeader';
import { ResultsItemSubtitle } from './ResultsItemSubtitle';

export const SayariResultsItem = ({
  id,
  title,
  titleTags,
  text,
  category,
  viewed,
  queryString,
  tags,
}) => {
  const tagsArr = tags.filter((tag) => tag !== 'Finding');
  const content = JSON.parse(category);

  return (
    <>
      <ResultsItemHeader
        id={id}
        title={title}
        queryString={queryString}
        isSaved={tags.includes('Finding')}
        viewed={viewed}
      />

      <div className={classes['item-details--compliance']}>
        <ResultsItemSubtitle highlight={text} tags={titleTags} />

        {category && (
          <span className={classes['item-text-tags']}>
            <SayariHighlightChips content={content} />
          </span>
        )}
        <div style={{ display: 'flex', width: '100%' }}>
          {tagsArr && <TagsList tags={tagsArr} />}
        </div>
      </div>
    </>
  );
};

export default SayariResultsItem;
