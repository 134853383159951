import { capitaliseWords, removeSnakeCase } from 'helpers/formatting';
import { Table } from '../ArticleTemplates/Table';

export const KeyValueTable = ({ data }) => {
  return (
    <Table
      rows={Object.keys(data).map((key) => [
        capitaliseWords(removeSnakeCase(key)),
        data[key],
      ])}
    />
  );
};

export default KeyValueTable;
