import {
  ListSubheader,
  ListSubheaderProps,
  MenuItem,
  Select,
} from '@mui/material';
import { Categories } from 'appSrc/constants';
import { IAdverseTerm, IAdverseTermCategory } from 'appSrc/types/adverseTerms';
import AdverseTermsOptions from './AdverseTermsOptions';

const MyListSubheader = (props: ListSubheaderProps) => {
  return <ListSubheader {...props} />;
};

MyListSubheader.muiSkipListHighlight = true;

const AdverseTermsSelect = ({
  terms,
  categories,
  selectedTerm,
  handleChange,
}: {
  terms: IAdverseTerm[];
  categories: IAdverseTermCategory[];
  selectedTerm: number | '';
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Select
      fullWidth
      size='small'
      displayEmpty
      value={selectedTerm}
      onChange={handleChange}
    >
      <MenuItem value={0}>
        <em>- None -</em>
      </MenuItem>
      {[{ id: Categories.PERSONAL, name: 'Personal' }, ...categories].map(
        (category: IAdverseTermCategory) =>
          AdverseTermsOptions({ terms, category })
      )}
    </Select>
  );
};

export default AdverseTermsSelect;
