import React, { useEffect } from 'react';
import Layout from 'layout/Layout';
import { useAppDispatch } from 'hooks/storeHooks';
import fetchAllAdverseTermsDataThunk from 'store/thunks/fetchAllAdverseTerms';
import { Box, Paper, Typography } from '@mui/material';
import Button from 'components/BaseNew/Button/Button';
import AdverseTermsTable from 'components/AdverseTermsTable/AdverseTermsTable';
import { useModals } from 'hooks/ModalManager/useModals';
import { Modals } from 'appSrc/constants';

const AdverseTerms = () => {
  const { open } = useModals();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllAdverseTermsDataThunk());
  }, [dispatch]);

  return (
    <Layout
      title='Adverse Terms'
      header='Settings'
      variant='one-column'
      isScrollable
    >
      <Box style={{ margin: '30px' }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          marginBottom={2}
        >
          <Typography variant='h3'>Adverse Terms</Typography>
          <Button
            variant='contained'
            onClick={() => open(Modals.ADVERSE_TERMS)}
          >
            New term
          </Button>
        </Box>
        <Paper sx={{ mt: 6, mb: 2 }}>
          <AdverseTermsTable />
        </Paper>
      </Box>
    </Layout>
  );
};

export default AdverseTerms;
