import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { deleteAdverseTermThunk } from 'appSrc/store/thunks/deleteAdverseTerm';
import { IAdverseTerm } from 'appSrc/types/adverseTerms';
import { useAppDispatch } from 'hooks/storeHooks';
import React from 'react';

const DeleteDialog = ({ open, term, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useAppDispatch();
  const handleDeleteTerm = (t: IAdverseTerm) => {
    dispatch(deleteAdverseTermThunk(t));
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to delete this term?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDeleteTerm(term)} autoFocus>
          Yes
        </Button>
        <Button onClick={handleClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteDialog;
