import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitRelatedItem = ({ projectId, searchId, urls, provider }) => {
  const request = axios.post(
    `${apiHost}/api/v1/projects/${projectId}/related`,
    JSON.stringify({
      urls,
      search_id: searchId,
      provider,
    }),
    { headers: apiHeaders() }
  );

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitRelatedItem;
