/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { first, last } from 'lodash';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormLabel,
  Popover,
} from '@mui/material';
import DateRange from 'components/Base/DateRange';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
import classes from '../facets.module.scss';

const DateFacet = ({ control, options, handleDateFilter }) => {
  const ref = useRef(null);
  const [state, setState] = useState(
    options
      ? {
          startDate: first(options).value.match(/^\d{4}-\d{2}-\d{2}$/)
            ? new Date(first(options).value)
            : new Date(first(options).value, 0, 1),
          endDate: last(options).value.match(/^\d{4}-\d{2}-\d{2}$/)
            ? new Date(last(options).value)
            : new Date(),
          minDate: new Date(first(options).value),
          maxDate: last(options).value.match(/^\d{4}-\d{2}-\d{2}$/)
            ? new Date(last(options).value)
            : new Date(),
          minYear: parseInt(
            moment(new Date(first(options).value)).format('YYYY'),
            10
          ),
          maxYear: parseInt(
            moment(new Date(last(options).value)).format('YYYY'),
            10
          ),
          locale: { format: 'DD/MM/YYYY' },
          key: 'selection',
        }
      : {
          showDropdowns: true,
          locale: { format: 'DD/MM/YYYY' },
          key: 'selection',
        }
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleDateFilter(state.startDate, state.endDate);
  }, [state.startDate, state.endDate, handleDateFilter]);

  return (
    <div
      key={control}
      className={classNames(classes.facets, classes['facets--date'])}
    >
      <Accordion className={classes.accordion} defaultExpanded>
        <AccordionSummary
          className={classes['accordion-summary']}
          expandIcon={<ExpandMoreIcon />}
          // eslint-disable-next-line react/no-children-prop
          children={
            <FormLabel className={classes.heading} component='legend'>
              Timeline
            </FormLabel>
          }
        />
        <AccordionDetails className={classes['accordion-details']}>
          <div className={classes.dates}>
            <span ref={ref} onClick={handleOpenPopover}>
              {moment(state.startDate).format('YYYY-MM-DD')} -{' '}
              {moment(state.endDate).format('YYYY-MM-DD')}
            </span>
            {ref.current && (
              <Popover
                open={popoverOpen}
                onClose={handleClosePopover}
                anchorEl={anchorEl}
              >
                <DateRange state={state} setState={setState} />
              </Popover>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DateFacet;
