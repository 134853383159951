import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import React, { useState } from 'react';
import { useAppSelector } from 'hooks/storeHooks';
import { IAdverseTerm } from 'appSrc/types/adverseTerms';
import { selectAdverseTerms } from 'appSrc/store/slices/adverseTerms';
import { useSelector } from 'context';
import isAdmin from 'helpers/user';
import AdverseTermHead from './AdverseTermHead';
import AdverseTermRow from './AdverseTermRow';

function descendingComparator<Term extends IAdverseTerm>(
  a: Term,
  b: Term,
  orderBy: keyof Term
) {
  let valueA: string | Term[keyof Term] = a[orderBy];
  let valueB: string | Term[keyof Term] = b[orderBy];
  switch (orderBy) {
    case 'category':
      valueA = a.category?.name;
      valueB = b.category?.name;
      break;
    case 'language':
      valueA = a.language?.code;
      valueB = b.language?.code;
      break;
    default:
      break;
  }
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof IAdverseTerm>(
  order: Order,
  orderBy: Key
): (a, b) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const AdverseTermsTable = () => {
  const user = useSelector((state) => state.user);
  const [order, setOrder] = useState<Order>('asc');
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<keyof IAdverseTerm>('id');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const terms = useAppSelector(selectAdverseTerms);

  const handleRequestSort = (property: keyof IAdverseTerm) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const visibleRows = [...terms.data]
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <TableContainer style={{ margin: '10px' }}>
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
          <AdverseTermHead
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
          />
          <TableBody>
            {visibleRows.map((row) => (
              <AdverseTermRow
                key={row.id}
                row={row}
                canModify={!row.category || isAdmin(user)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={terms.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default AdverseTermsTable;
