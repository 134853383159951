import axios from 'axios';
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const fetchSearch = async (projectId, searchId) => {
  return axios
    .get(`${apiHost}/api/v1/projects/${projectId}/searches/${searchId}`, {
      headers: apiHeaders(),
    })
    .then((res) => {
      console.log('fetch search', res);
      return res.data;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default fetchSearch;
