/* eslint-disable react/no-array-index-key */
import Chip from 'components/Base/Chip';
import classes from '../../../article.module.scss';

export const WeblinksSection = ({ weblinks }) => {
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Weblinks</h4>
      {weblinks.map((link, i) => {
        return (
          <div key={`weblink-${i}`} className={classes['structure-row']}>
            <strong
              className={`${classes['data-link']} ${classes['span-row']}`}
            >
              <a href={link.uri} target='_blank' rel='noreferrer'>
                {link.uri}
              </a>
              {link.tags.map((tag, j) => (
                <Chip
                  label={tag}
                  size='small'
                  variant='grey'
                  key={`weblink-${i}-tag-${j}`}
                />
              ))}
            </strong>
          </div>
        );
      })}
    </div>
  );
};

export default WeblinksSection;
