import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiHeaders, apiHost, handleError } from '..';

export const fetchProjects = async ({
  dateFrom,
  dateTo,
  owner,
  name,
  sort,
  user,
  offset,
  limit,
}) => {
  const params = {
    limit: limit || 5,
    dateFrom,
    dateTo,
    owner: owner?.owner?.toLowerCase() || '',
    sort,
    user,
    offset: offset || 0,
    q: name,
  };
  try {
    const path = `${apiHost}/api/v1/projects`;
    const result = await axios.get(path, {
      headers: apiHeaders(),
      params,
    });

    return {
      total: parseInt(result.headers['x-total-count'], 10),
      projects: result.data,
    };
  } catch (err) {
    handleError(err);
    return null;
  }
};

export default fetchProjects;
