import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const deleteTag = (projectId, docId, tag) => {
  const request = axios.delete(
    `${apiHost}/api/v1/projects/${projectId}/items/${docId}/tag`,
    {
      headers: apiHeaders(),
      data: { tag },
    }
  );

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default deleteTag;
