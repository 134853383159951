import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import classes from './button.module.scss';

type IconButtonProps = {
  id?: string;
  variant?: string;
  onClick?: (e) => void;
  disabled?: boolean;
  className?: string;
  anchorRef?: any;
  children?: any;
  component?: any;
  to?: any;
  ref?: any;
};

const IconButton = ({
  variant,
  onClick,
  disabled,
  className,
  anchorRef,
  ...props
}: IconButtonProps) => {
  const renderIcon = () => {
    switch (variant) {
      case 'delete':
        return <DeleteIcon fontSize='inherit' />;
      case 'close':
        return <CloseIcon fontSize='inherit' />;
      case 'clear':
        return <ClearIcon fontSize='inherit' />;
      case 'arrow-forward':
        return <ArrowForwardIosIcon fontSize='inherit' />;
      case 'arrow-backward':
        // return <ArrowBackIosIcon fontSize="inherit" />;
        return (
          <ArrowForwardIosIcon
            fontSize='inherit'
            style={{ transform: 'rotate(180deg' }}
          />
        );
      case 'options':
        return <MoreHorizIcon fontSize='inherit' />;
      case 'settings':
        return <SettingsIcon fontSize='inherit' />;
      case 'add':
        return <AddIcon fontSize='inherit' />;
      case 'info':
        return <InfoIcon fontSize='inherit' />;
      case 'download':
        return <DownloadIcon />;
      default:
        return <EditIcon fontSize='inherit' />;
    }
  };

  return (
    <MuiIconButton
      className={classNames(classes['icon-button'], className)}
      onClick={onClick}
      disabled={disabled}
      ref={anchorRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {renderIcon()}
    </MuiIconButton>
  );
};

export default IconButton;
