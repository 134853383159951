/* eslint-disable react/no-array-index-key */
import classes from '../article.module.scss';

type Props = {
  headCells?: string[];
  rows: any[];
};

export const Table = ({ headCells, rows }: Props) => {
  return (
    <table>
      {headCells && (
        <thead>
          <tr className={classes['row-head']}>
            {headCells.map((cell, i) => (
              <th key={`th-${i}`}>{cell}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((row, i) => (
          <tr key={`row-${i}`}>
            {row.map((cell, ci) => (
              <td key={`${i}-cell-${ci}`}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
