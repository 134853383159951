import { useHighlighter } from 'hooks/useHighlighter';
import React from 'react';
import useHandleTagFinding from 'hooks/useHandleTagFinding';
import classes from './results.module.scss';
import SearchViewCount from '../SearchViewCount';
import { FindingControl } from './FindingControl';

export const ResultsItemHeader = ({
  id,
  title,
  queryString,
  isSaved,
  viewed,
}) => {
  const tagFinding = useHandleTagFinding();

  return (
    <div className={classes['item-header']}>
      <span
        className={classes['item-title--compliance']}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: useHighlighter({
            query: queryString,
            content: title,
          }),
        }}
      />{' '}
      <div className={classes['item-header__actions']}>
        <SearchViewCount viewed={viewed} size='small' />
        <FindingControl id={id} isSaved={isSaved} tagFinding={tagFinding} />
      </div>
    </div>
  );
};

export default ResultsItemHeader;
