export const ARACHNYS_PROVIDER_ID = 'news.arachnys';
export const FACTIVA_PROVIDER_ID = 'news.factiva';
export const WORLDCHECK_PROVIDER_ID = 'compliance.worldcheck';
export const WORLDCOMPLIANCE_PROVIDER_ID = 'compliance.worldcompliance';
export const DOWJONES_PROVIDER_ID = 'compliance.dowjones';
export const COMBINED_COMPLIANCE_PROVIDER_ID = 'compliance.combined';
export const LANDREG_PROVIDER_ID = 'records.landreg';
export const SAYARI_PROVIDER_ID = 'corporate_records.sayari';

export enum ProviderId {
  ARACHNYS = 'news.arachnys',
  FACTIVA = 'news.factiva',
  WORLDCHECK = 'compliance.worldcheck',
  WORLDCOMPLIANCE = 'compliance.worldcompliance',
  DOWJONES = 'compliance.dowjones',
  LANDREG = 'records.landreg',
  SAYARI = 'corporate_records.sayari',
}

export enum ProviderType {
  NEWS = 'news',
  COMPLIANCE = 'compliance',
  RECORDS = 'records',
  CORPORATE_RECORDS = 'corporate_records',
}

export const ProviderData = {
  [ProviderId.ARACHNYS]: {
    label: 'Arachneys',
    id: ARACHNYS_PROVIDER_ID,
    providerType: ProviderType.NEWS,
  },
  [ProviderId.FACTIVA]: {
    label: 'Factiva',
    id: FACTIVA_PROVIDER_ID,
    providerType: ProviderType.NEWS,
  },
  [ProviderId.WORLDCHECK]: {
    label: 'Refinitiv World-Check',
    id: WORLDCHECK_PROVIDER_ID,
    providerType: ProviderType.COMPLIANCE,
  },
  [ProviderId.WORLDCOMPLIANCE]: {
    label: 'World Compliance',
    id: WORLDCOMPLIANCE_PROVIDER_ID,
    providerType: ProviderType.COMPLIANCE,
  },
  [ProviderId.DOWJONES]: {
    label: 'Dow Jones R&C',
    id: DOWJONES_PROVIDER_ID,
    providerType: ProviderType.COMPLIANCE,
  },
  [ProviderId.LANDREG]: {
    label: 'HM Land Registry archive',
    id: LANDREG_PROVIDER_ID,
    providerType: ProviderType.RECORDS,
  },
  [ProviderId.SAYARI]: {
    label: 'Sayari',
    id: SAYARI_PROVIDER_ID,
    providerType: ProviderType.CORPORATE_RECORDS,
  },
};
