/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { IAdverseTerm, IAdverseTermsSlice } from 'types/adverseTerms';
import deleteAdverseTerm from 'api/adverseTerms/deleteAdverseTerm';

export const deleteAdverseTermThunk = createAsyncThunk(
  'adverseTerms/deleteAdverseTerm',
  async (term: IAdverseTerm) => {
    return deleteAdverseTerm(term);
  }
);

export const deleteAdverseTermExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(deleteAdverseTermThunk.fulfilled, (state, action) => {
    const term = action.payload.data;
    state.terms.data = state.terms.data.filter((t) => t.id !== term.id);
  });
};
