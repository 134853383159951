import React from 'react';
import { useDispatch, useSelector } from 'context';
import { Snackbar, SnackbarContent } from '@mui/material';
import { HIDE_TOAST } from 'constants/actionTypes';
import classes from './toast.module.scss';

const ToastManager = () => {
  const toast = useSelector((state) => state.toast);
  const dispatch = useDispatch();
  return (
    toast && (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toast}
        onClose={() => dispatch({ type: HIDE_TOAST })}
        autoHideDuration={8000}
      >
        <SnackbarContent
          className={classes.container}
          message={toast.message}
        />
      </Snackbar>
    )
  );
};
export default ToastManager;
