import { ButtonProps } from '@mui/material';
import { LoadingButtonProps } from '@mui/lab';
import StyledButton, { StyledLoadingButton } from './styled';

interface ButtonComponentProps extends ButtonProps {
  children: React.ReactNode;
}

const Button = ({ children, ...rest }: ButtonComponentProps) => {
  return <StyledButton {...rest}>{children}</StyledButton>;
};

export const LoadingButton = ({ children, ...rest }: LoadingButtonProps) => (
  <StyledLoadingButton {...rest}>{children}</StyledLoadingButton>
);

export default Button;
