/* eslint-disable no-plusplus */
import { useDispatch, useSelector } from 'context';
import { findIndex } from 'lodash';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { UPDATE_STATE } from 'constants/actionTypes';
import { submitAllTags } from './useFetch';

export default function useHandleTagFinding() {
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.state.data);
  const article = useSelector((state) => state.state.article);
  const findings = useSelector((state) => state.state.findings);
  const project = useSelector((state) => state.state.project);

  return ({ item }) => {
    const index = findIndex(data.items, { _id: item });
    const current = data.items[index];

    let currentTags = [];
    if (current !== undefined) {
      currentTags = current._source.tags || [];
    } else if (article?.id === item) {
      currentTags = article.tags || [];
    } else {
      return;
    }

    const arr = current ? data.items : [];

    let findingsCount = findings || 0;
    if (currentTags.includes('Finding')) {
      currentTags = currentTags.filter((tag) => tag !== 'Finding');
      if (findingsCount > 0) findingsCount--;
    } else {
      trackEvent({
        // TODO - this was with props.match.url, location.pathname is a hack
        // category: props.match.url.includes("findings") ? "findings" : "search",
        category:
          window.location.pathname.indexOf('/findings') !== -1
            ? 'findings'
            : 'search',
        action: 'save_finding',
        // @ts-ignore
        customDimensions: [{ id: 'article_id', value: item }],
      });
      currentTags.push('Finding');
      findingsCount++;
    }

    if (current) {
      arr.splice(index, 1, {
        ...current,
        _source: {
          ...current._source,
          tags: currentTags,
        },
      });
    }

    if (item === article.id) {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          findings: findingsCount,
          article: { ...article, tags: currentTags },
        },
      });
    } else if (current) {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          findings: findingsCount,
          data: { ...data, items: [...arr] },
        },
      });
    }

    submitAllTags(project.id, item, currentTags);
  };
}
