import { Box, ListItem, ListItemText } from '@mui/material';
import Checkbox from 'components/BaseNew/Checkbox/Checkbox';
import Chip from 'components/BaseNew/Chip/Chip';
import Select from 'components/BaseNew/Select/Select';
import { StyledMenuItem } from 'components/BaseNew/Select/styled';
import { ProviderId } from 'constants/providers';
import { useSelector } from 'context';
import { getProviderLabel } from 'helpers/providers/providers';
import { InputLabel } from './styled';

const ProviderSelect = ({ onChange, selectedProviders }) => {
  const config = useSelector((state) => state.config);
  const providers = config?.providers || [];
  const supportedProviders = [
    ProviderId.WORLDCHECK,
    ProviderId.WORLDCOMPLIANCE,
    ProviderId.SAYARI,
    ProviderId.DOWJONES,
    ProviderId.FACTIVA,
  ];
  const compatibleProviders = providers
    .filter((provider) => {
      return provider.enabled && supportedProviders.includes(provider.id);
    })
    .sort((a, b) => {
      const nameA = a?.label.toLowerCase();
      const nameB = b?.label.toLowerCase();
      return nameA.localeCompare(nameB);
    });

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const providerOptions = compatibleProviders.map((provider) => ({
    id: provider.id,
    label: getProviderLabel(provider.id as ProviderId),
  }));

  return (
    <>
      <InputLabel>
        Select providers<span className='required'>*</span>
      </InputLabel>
      <Select
        size='small'
        sx={{ minWidth: 500 }}
        labelId='provider-select-label'
        id='provider-select'
        value={selectedProviders}
        multiple
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
            {selected.map((value) => (
              <Chip
                variant='outlined'
                key={value}
                label={providerOptions.find((p) => p.id === value).label}
              />
            ))}
          </Box>
        )}
      >
        {providerOptions.map(({ id, label }) => (
          <StyledMenuItem key={id} value={id}>
            <ListItem
              secondaryAction={
                <Checkbox checked={!!selectedProviders.find((p) => p === id)} />
              }
            >
              <ListItemText primary={label} />
            </ListItem>
          </StyledMenuItem>
        ))}
      </Select>
    </>
  );
};

export default ProviderSelect;
