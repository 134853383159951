import { Box, Button, Modal, Typography } from '@mui/material';
import { hideRedFlagCheckWarningModal } from 'store/slices/ui';
import { useAppDispatch } from 'hooks/storeHooks';

const RedFlagCheckWarningModal = () => {
  const dispatch = useAppDispatch();

  return (
    <Modal open>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
        }}
      >
        <Typography variant='h6' component='h2'>
          Red Flag Check Warning
        </Typography>
        <Typography mt={2}>
          Red Flag Checks only need 2 of 3 compliance providers. Please review
          your search and confirm if all 3 providers are required.
        </Typography>

        <Button
          sx={{ mt: 2 }}
          variant='contained'
          onClick={() => dispatch(hideRedFlagCheckWarningModal())}
        >
          Review search
        </Button>
      </Box>
    </Modal>
  );
};

export default RedFlagCheckWarningModal;
