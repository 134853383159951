import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const fetchUsersShared = (projectId) => {
  const req = axios.get(`${apiHost}/api/v1/projects/${projectId}/share`, {
    headers: apiHeaders(),
  });

  return req
    .then((res) => {
      return res?.data?.users;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default fetchUsersShared;
