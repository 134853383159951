/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import Chip from 'components/Base/Chip';
import numAbbr from 'number-abbreviate';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'context';
import { useNavigate } from 'react-router-dom';
import { UPDATE_STATE } from 'constants/actionTypes';
import classes from './search-history-table.module.scss';
import { RetryButton } from '../../../components/RetryButton';

export const SearchHistoryTableProvider = ({
  provider,
  prefix,
  retrySearch,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query, setQuery] = useState(
    provider?.queries[provider.queries.length - 1]
  );

  const handleProviderClick = () => {
    const { id } = query;

    dispatch({
      type: UPDATE_STATE,
      payload: {
        queryId: id,
      },
    });
    navigate(`${prefix}/${provider.id}/queries/${id}`);
  };

  useEffect(() => {
    setQuery(provider?.queries[provider.queries.length - 1]);
  }, [provider]);

  return (
    <span className={classes.provider}>
      <span onClick={handleProviderClick}>{provider.label}</span>

      {query?.processed ? (
        query.status !== 'search.error' ? (
          /* @ts-ignore */
          <Chip
            onClick={handleProviderClick}
            label={numAbbr(query?.hit_count)}
            size='small'
            variant='grey'
          />
        ) : (
          <RetryButton
            className={classes['error-icon']}
            retryHandler={() => retrySearch(provider.id, query.id)}
            tooltipText={
              query.status_message
                ? query.status_message
                : 'This provider has an unknown error'
            }
          />
        )
      ) : (
        <CircularProgress
          size={12}
          color='inherit'
          className={classes.spinner}
        />
      )}
    </span>
  );
};

export default SearchHistoryTableProvider;
