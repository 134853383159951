import axios from 'axios';
import { apiHeaders, apiHost, fetchFailure, store } from '../index';

export const downloadSearches = async (projectId) => {
  return axios
    .get(`${apiHost}/api/v1/projects/${projectId}/searches/download`, {
      headers: apiHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default downloadSearches;
