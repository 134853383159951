/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiLoadingStatus } from 'types/api';
import { IAdverseTermsSlice } from 'types/adverseTerms';
import fetchAdverseTermProviders from 'api/adverseTerms/fetchAdverseTermProviders';

export const fetchAdverseTermProvidersThunk = createAsyncThunk(
  'adverseTerms/fetchAdverseTermProviders',
  async () => {
    return fetchAdverseTermProviders();
  }
);

export const fetchAdverseTermProvidersExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(fetchAdverseTermProvidersThunk.pending, (state) => {
    state.providers.status = ApiLoadingStatus.Loading;
    state.providers.error = null;
  });
  builder.addCase(fetchAdverseTermProvidersThunk.fulfilled, (state, action) => {
    state.providers.status = ApiLoadingStatus.Succeeded;
    state.providers.data = action.payload.data;
    state.providers.error = action.payload.error;
  });
  builder.addCase(fetchAdverseTermProvidersThunk.rejected, (state, action) => {
    state.providers.status = ApiLoadingStatus.Failed;
    state.providers.error = action.error.message;
    state.providers.data = [];
  });
};
