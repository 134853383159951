/* eslint-disable @typescript-eslint/naming-convention */
import { format } from 'date-fns';

export const serialiseData = ({ item }) => {
  const {
    provider,
    search,
    headline,
    headline_tags,
    headline_trans: headlineTrans,
    when,
    source,
    preview,
    preview_tags,
    preview_trans: previewTrans,
    content,
    content_trans: contentTrans,
    content_json: contentJSON,
    url,
    end_url,
    tags,
    viewed,
    language,
  } = item._source;

  return {
    id: item._id,
    query_id: provider.query_id,
    search_id: search,
    provider: provider.id,
    provider_item_id: provider.item_id,
    title: headline,
    titleTags: headline_tags || [],
    translatedTitle: headlineTrans?.[0]?.text,
    language,
    raw_date: when,
    date: format(new Date(item._source.when), 'dd MMM yyyy'),
    category: source,
    text: preview,
    textTags: preview_tags || [],
    translatedText: previewTrans?.[0]?.text,
    content,
    translatedContent: contentTrans?.[0]?.text,
    contentJSON,
    url,
    end_url: end_url || '',
    tags: tags || [],
    viewed,
    duplicates: item.inner_hits?.duplicates?.hits?.total?.value
      ? item.inner_hits.duplicates.hits.total.value - 1
      : 0,
  };
};

export default serialiseData;
