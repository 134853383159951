import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitTag = (projectId, docId, tag) => {
  const data = JSON.stringify({
    tag,
  });

  const request = axios.patch(
    `${apiHost}/api/v1/projects/${projectId}/items/${docId}/tag`,
    data,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitTag;
