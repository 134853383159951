import Chip from 'components/Base/Chip';
import classes from './results.module.scss';

export const TagsList = ({ tags }) => (
  <div style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}>
    {tags &&
      (tags.length > 2 ? (
        <>
          {tags.slice(0, 2).map((tag) => (
            <Chip
              key={`results-list-tag--${tag}`}
              label={tag}
              size='small'
              classes={{ root: classes.chip }}
            />
          ))}
          <Chip
            label={`+${tags.length - 2}`}
            size='small'
            classes={{ root: classes.chip }}
          />
        </>
      ) : (
        tags.map((tag) => (
          <Chip
            key={`results-list-tag--${tag}`}
            label={tag}
            size='small'
            classes={{ root: classes.chip }}
          />
        ))
      ))}
  </div>
);

export default TagsList;
