import axios from 'axios';
import { UPDATE_PROJECTS_FILTER, USER_SUCCESS } from 'constants/actionTypes';
import { apiHeaders, apiHost, handleError, store } from 'hooks/useFetch';

export const fetchCurrentUser = async (): Promise<string | boolean> => {
  try {
    const result = await axios.get(`${apiHost}/api/v1/users/whoami`, {
      headers: apiHeaders(),
    });

    store.dispatch({ type: USER_SUCCESS, payload: result.data });
    store.dispatch({
      type: UPDATE_PROJECTS_FILTER,
      payload: {
        owner: {
          owner: result.data.email,
          owner_name: result.data.name,
        },
      },
    });
    return result.data;
  } catch (err) {
    handleError(err);
    return false;
  }
};

export default fetchCurrentUser;
