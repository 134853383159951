import React from 'react';
import numAbbr from 'number-abbreviate';

const ItemsCount = (props) => {
  const {
    page = 1,
    totalResults = 0,
    totalResultsRelation = '' /* , totalResultsNoDuplicates = false */,
  } = props;
  const perPage = 100;
  const from = perPage * page - perPage + 1;
  const maxTo = from + perPage - 1;
  const to = maxTo > totalResults ? totalResults : maxTo;

  const resultRange = () => {
    return `${from} - ${to}`;
  };

  // const label = totalResultsNoDuplicates ? "Results" : "Unique Results"
  const label = 'Unique Results';

  return !totalResults ? (
    <div className='items-count'>
      <strong>&nbsp;</strong> &nbsp;
    </div>
  ) : (
    <div className='items-count'>
      <strong>{resultRange()}</strong> of{' '}
      <strong>
        {numAbbr(totalResults)}
        {totalResultsRelation}
      </strong>{' '}
      {label}
    </div>
  );
};

export default ItemsCount;
