export const addProviderLabels = ({ providers, ...rest }) => {
  if (!providers) {
    return {
      ...rest,
    };
  }
  const providerLabels = Object.keys(providers).reduce((labelMap, key) => {
    return {
      ...labelMap,
      [providers[key].id]: providers[key].label,
    };
  }, {});
  return {
    providers,
    providerLabels,
    ...rest,
  };
};

export const makeRequest = async (asyncRequestFn) => {
  try {
    const result = await asyncRequestFn();
    const { data } = result;
    return { hasError: false, data };
  } catch (err) {
    return { hasError: true, data: null, err };
  }
};

const DEFAULT_MAXIMUM_RETRY_NUMBER = 3;

type AsyncRequestFn = () => Promise<Response>;

export const makeRequestWithRetries = async (
  asyncRequestFn: AsyncRequestFn,
  attempt = 1,
  maxRetryNumber = DEFAULT_MAXIMUM_RETRY_NUMBER
) => {
  const { hasError, data, err } = await makeRequest(asyncRequestFn);

  if (hasError && attempt >= maxRetryNumber) {
    throw new Error(`max number of retries exceeded. Last error: ${err}`);
  }
  if (hasError) {
    return makeRequestWithRetries(asyncRequestFn, attempt + 1, maxRetryNumber);
  }

  return data;
};

export default addProviderLabels;
