import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiHeaders, apiHost } from '..';

export const fetchUsers = () => {
  const request = axios.get(`${apiHost}/api/v1/users`, {
    headers: apiHeaders(),
    params: {
      limit: 1000,
    },
  });

  return request.then((res) => {
    return res.data;
  });
};

export default fetchUsers;
