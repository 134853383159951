import { pickFields } from 'utils';
import { DropdownRow } from 'components/Base/DropdownRow';
import { KeyValueTable } from '../../../ContentTypes/KeyValueTable';
import styles from '../../../article.module.scss';
import { TableList } from '../../TableList';
// eslint-disable-next-line import/no-cycle
import { SayariStructuredData } from './SayariStructuredData';

export const SayariRecordData = ({ data, expanded }) => {
  const record = pickFields(data, [
    'label',
    'publication_date',
    'acquisition_date',
    'references_count',
    'source_url',
  ]);
  const { references } = data;

  return (
    <>
      <TableList
        className={styles['light-tablelist']}
        rows={[
          <>
            <p className={styles['light-tabletitle']}>Record</p>
            <KeyValueTable data={record} />
          </>,
        ]}
      />

      <TableList
        title='References'
        rows={references.data.map((item, index) => (
          <DropdownRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={item.entity.label}
            count={null}
            expanded={expanded}
          >
            <SayariStructuredData data={item.entity} />
          </DropdownRow>
        ))}
      />
    </>
  );
};

export default SayariRecordData;
