/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import fetchAdverseTerms from 'api/adverseTerms/fetchAdverseTerms';
import { ApiLoadingStatus } from 'types/api';
import { IAdverseTermsSlice } from 'types/adverseTerms';
import { PERSONAL_CATEGORY } from 'appSrc/constants';

export const fetchAdverseTermsThunk = createAsyncThunk(
  'adverseTerms/fetchAdverseTerms',
  async () => {
    const { data, error } = await fetchAdverseTerms();
    return {
      data: data.map((t) => ({
        ...t,
        category: t.category || PERSONAL_CATEGORY,
      })),
      error,
    };
  }
);

export const fetchAdverseTermsExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(fetchAdverseTermsThunk.pending, (state) => {
    state.terms.status = ApiLoadingStatus.Loading;
    state.terms.error = null;
  });
  builder.addCase(fetchAdverseTermsThunk.fulfilled, (state, action) => {
    state.terms.status = ApiLoadingStatus.Succeeded;
    state.terms.data = action.payload.data;
    state.terms.error = action.payload.error;
  });
  builder.addCase(fetchAdverseTermsThunk.rejected, (state, action) => {
    state.terms.status = ApiLoadingStatus.Failed;
    state.terms.error = action.error.message;
    state.terms.data = [];
  });
};
