import { handleError } from 'hooks/useFetch';
import apiClient from 'api/apiClient';
import {
  IAdverseTermCategory,
  IAdverseTermCategoryDraft,
} from 'types/adverseTerms';

const createAdverseTermCategory = async (
  category: IAdverseTermCategoryDraft
) => {
  try {
    return await apiClient<IAdverseTermCategory>({
      method: 'POST',
      endpoint: 'terms/category',
      version: 'v2',
      data: category,
    });
  } catch (err) {
    handleError(err);
    return { data: null, error: err };
  }
};

export default createAdverseTermCategory;
