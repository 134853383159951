/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';
import moment from 'moment';
import validateImage from 'hooks/useImageValidator';
import Chip from 'components/Base/Chip';
import classes from '../../article.module.scss';
import { TableList } from '../TableList';
import { Table } from '../Table';
import parseContentToStructuredData from '../utils/parseContentToStructuredData';

const DowJonesStructuredData = ({
  showEmpty,
  handleLinkRelated,
  data,
  printView = false,
}) => {
  const structuredData = parseContentToStructuredData(data.contentJSON);
  const { attributes } = structuredData;
  const {
    basic,
    entity,
    person,
    watchlist,
    state_owned_companies: stateOwnedCompanies,
    relationship,
    adverse_media_entity: adverseMediaEntity,
    list_reference: listReference,
  } = attributes;
  const [profileImg, setProfileImg] = useState('');

  useEffect(() => {
    const url = attributes?.image?.image_details?.[0]?.external_url;
    if (url) validateImage(url).then((res) => res && setProfileImg(url));
  }, [attributes?.image]);

  const formatName = (obj) => {
    return Object.values(obj)
      .filter((item) => typeof item === 'string')
      .join(' ');
  };

  const formatDate = (date) => {
    if (typeof date === 'object') {
      if (date.day)
        return moment({ ...date, month: date.month - 1 }).format('DD/MM/YYYY');
      if (date.year) return date.year;
    } else if (typeof date === 'string' && date.length) {
      return date;
    }
    return null;
  };

  const handleProfileLink = (profileId) => {
    if (profileId === undefined) return;
    const link = `http://api.dowjones.com/riskentities/profiles/${profileId}`;
    if (handleLinkRelated) handleLinkRelated(link);
  };

  const handleSourceLink = (drn) => {
    if (drn === undefined) return;
    const link = `http://api.beta.dowjones.com/content/refs/${drn}`;
    if (handleLinkRelated) handleLinkRelated(link);
  };

  const Row = ({ label, value }) => {
    if (!showEmpty && !value) return null;
    return (
      <div className={classes['structure-row']}>
        <span>{label}</span>
        <span>{value || '-'}</span>
      </div>
    );
  };

  const ComplianceHeader = ({ name, icons }) => {
    return (
      <div className={classes['image-wrapper']}>
        {attributes.image && profileImg ? (
          <div className={classes.image}>
            <img src={profileImg} alt='' />
          </div>
        ) : (
          <div className={classes['image-unavailable']}>No image</div>
        )}
        <div className={classes['image-inner']}>
          <span>{attributes.basic.type}</span>
          <h4>{name}</h4>
          <div className={classes.tags}>
            {icons?.map((icon) => (
              <Chip
                label={icon}
                size='small'
                variant='grey'
                key={`image-icon--${icon}`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const ComplianceAddress = () => {
    return basic.address_details.map((address, index) => {
      return (
        <div key={`address-${index}`} className={classes.structure}>
          <h4 className={classes['structure-title']}>
            Address Details{' '}
            {basic.address_details.length > 0 ? index + 1 : null}
          </h4>
          <Row label='Address' value={address.address} />
          <Row label='Region' value={address.region} />
          <Row label='Postal Code' value={address.post_code} />
          <Row label='Country' value={address.country?.descriptor} />
          <Row
            label='URL'
            value={
              address.country?.url ? (
                <a href={address.country?.url} target='_blank' rel='noreferrer'>
                  {address.country?.url}
                </a>
              ) : null
            }
          />
        </div>
      );
    });
  };

  const ComplianceWatchlistAddress = () => {
    return watchlist.address_details.map((address, index) => {
      return (
        <div key={`watchlist-address-${index}`} className={classes.structure}>
          <h4 className={classes['structure-title']}>
            Address Details{' '}
            {watchlist.address_details.length > 0 ? index + 1 : null}
          </h4>
          <Row label='Address' value={address.address} />
          <Row label='Region' value={address.region} />
          <Row label='Postal Code' value={address.post_code} />
          <Row label='Country' value={address.country?.descriptor} />
        </div>
      );
    });
  };

  const ComplianceStateOwnedCompanies = () => {
    const rolesState = [
      // used in person mode
      stateOwnedCompanies.role_details?.primary,
      stateOwnedCompanies.role_details?.other,
      stateOwnedCompanies.role_details?.previous,
    ]
      .filter((arr) => arr)
      .flat();

    return (
      <div className={classes.structure}>
        <h4 className={classes['structure-title']}>State Owned Companies</h4>
        <Row
          label='Active Status'
          value={stateOwnedCompanies?.content_type_details?.active_status}
        />
        {stateOwnedCompanies?.content_type_details?.content_types ? (
          <ComplianceStateOwnedCoContentTypes />
        ) : null}
        <Row
          label='Last Reviewed Date'
          value={formatDate(stateOwnedCompanies?.last_reviewed_date) || '-'}
        />
        {stateOwnedCompanies?.country_territory_details ? (
          <>
            <h5 className={classes['structure-sub-title']}>
              Country/Territory Details
            </h5>
            {stateOwnedCompanies?.country_territory_details?.current_ownership?.map(
              (row, i) => (
                <Row
                  key={`row--${i}`}
                  label='Current Ownership'
                  value={row.descriptor}
                />
              )
            )}
            {stateOwnedCompanies?.country_territory_details?.past_ownership?.map(
              (row, i) => (
                <Row
                  key={`row--${i}`}
                  label='Past Ownership'
                  value={row.descriptor}
                />
              )
            )}
          </>
        ) : null}
        {stateOwnedCompanies?.state_owned_company_detail ? (
          <>
            <h5 className={classes['structure-sub-title']}>Control</h5>
            <Row
              label='Board Appointing Control'
              value={
                stateOwnedCompanies?.state_owned_company_detail
                  ?.board_appointing_control?.value
              }
            />
            <Row
              label='Voting Control'
              value={
                stateOwnedCompanies?.state_owned_company_detail?.voting_control
                  ?.value
              }
            />
            <Row
              label='Is Subsidiary'
              value={
                // eslint-disable-next-line no-nested-ternary
                typeof (
                  stateOwnedCompanies?.state_owned_company_detail?.is_subsidiary
                  // eslint-disable-next-line valid-typeof
                ) !== undefined
                  ? stateOwnedCompanies.state_owned_company_detail.is_subsidiary
                    ? 'Yes'
                    : 'No'
                  : '-'
              }
            />
            {stateOwnedCompanies?.state_owned_company_detail
              ?.current_ownership && (
              <>
                <h5 className={classes['structure-sub-title']}>
                  Current Ownership
                </h5>
                <ComplianceStateOwnedCoOwnership
                  ownership={
                    stateOwnedCompanies.state_owned_company_detail
                      .current_ownership
                  }
                />
              </>
            )}
            {stateOwnedCompanies?.state_owned_company_detail?.past_ownerships &&
              // eslint-disable-next-line max-len
              stateOwnedCompanies?.state_owned_company_detail?.past_ownerships.map(
                (row, i) => (
                  <ComplianceStateOwnedCoOwnership
                    ownership={row}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`row--${i}`}
                  />
                )
              )}
          </>
        ) : null}
        {rolesState.length && (
          <div className={classes.structure}>
            <h5 className={classes['structure-sub-title']}>Roles</h5>
            <Table
              headCells={['Title', 'Category', 'Since', 'To']}
              rows={rolesState.map((role) => [
                role.title,
                role.category_type,
                formatDate(role?.since),
                formatDate(role?.to),
              ])}
            />
          </div>
        )}
      </div>
    );
  };

  const ComplianceStateOwnedCoContentTypes = () => {
    return stateOwnedCompanies.content_type_details.content_types.map(
      (item, i) => {
        return (
          <div key={`item--${i}`}>
            <Row
              key={`row--${i}`}
              label={`Category ${item?.level}`}
              value={item?.description}
            />
            {item?.sub_content_types?.map((sub, i2) => (
              <Row
                key={`row-sub--${i2}`}
                label={`Category ${sub?.level}`}
                value={sub?.description}
              />
            ))}
          </div>
        );
      }
    );
  };

  const ComplianceStateOwnedCoOwnership = ({ ownership }) => {
    return (
      <>
        <h5 className={classes['structure-sub-title']}>Past Ownership</h5>
        {ownership?.countries_territories_of_ownership?.map((row, i) => (
          <Row
            key={`row--${i}`}
            label='Countries/Territories'
            value={row?.descriptor}
          />
        ))}
        <Row
          label='Ownership Level'
          value={
            // eslint-disable-next-line no-unsafe-optional-chaining
            ownership?.ownership_level +
            (ownership?.value ? ` - ${ownership?.value}%` : '')
          }
        />
        <Row
          label='Government Levels'
          value={ownership?.government_levels?.join(', ')}
        />
        {ownership?.ownership_note ? (
          <div className={classes.structure}>
            <div>{ownership.ownership_note}</div>
          </div>
        ) : null}
      </>
    );
  };

  const ComplianceRelationships = () => {
    return (
      <div className={classes.structure}>
        <h4 className={classes['structure-title']}>Relationships</h4>
        <Table
          headCells={['Name', 'Type', 'Relation']}
          rows={relationship?.connection_details?.map((relation, i) => [
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <strong
              key={`item--${i}`}
              className={classes['data-link']}
              onClick={() => handleProfileLink(relation?.profile_id)}
            >
              {formatName(relation.name_detail)}{' '}
              {relation.icon_hints.map((icon) => (
                <Chip
                  label={icon}
                  size='small'
                  variant='grey'
                  key={`icon--${icon}`}
                />
              ))}
            </strong>,
            relation.type,
            relation.connection_type,
          ])}
        />
      </div>
    );
  };

  const ComplianceAdverseMediaContentTypes = ({ level }) => {
    const items = [];
    const f = (ob, n, l) => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < ob.length; i++) {
        if (n < l && ob[i]?.sub_content_types) {
          f(ob[i].sub_content_types, n + 1, l);
        }
        if (n === l) {
          items.push(ob[i].description);
        }
      }
    };
    f(adverseMediaEntity.content_type_details.content_types, 1, level);
    return items ? (
      <Row label={`Category ${level}`} value={items.sort().join(', ')} />
    ) : null;
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const ComplianceAdverseMediaSourceDetails = ({ data }) => {
    return data?.map((item, i) => (
      <div key={`item--${i}`}>
        <strong
          className={classes['data-link']}
          onClick={() => handleSourceLink(item?.drn)}
        >
          {item.name}
        </strong>
        , {formatDate(item?.date)}{' '}
        <small className={classes['structure-mute']}>({item.language})</small>
      </div>
    ));
  };

  const ComplianceAdverseMedia = () => {
    return (
      <div className={classes.structure}>
        <h4 className={classes['structure-title']}>Adverse Media</h4>
        <Row
          label='Status'
          value={adverseMediaEntity?.content_type_details?.active_status}
        />
        <ComplianceAdverseMediaContentTypes level={1} />
        <ComplianceAdverseMediaContentTypes level={2} />
        <ComplianceAdverseMediaContentTypes level={3} />
        {adverseMediaEntity?.adverse_media_details?.length && (
          <>
            <h5 className={classes['structure-sub-title']}>Details</h5>
            <Table
              headCells={[
                'Item',
                'Categories Reported',
                'Govt. Action',
                'Sources',
              ]}
              rows={adverseMediaEntity?.adverse_media_details?.map((item) => [
                <strong>{item.number}</strong>,
                item.case_row_details?.map((caseRowItem, i) => (
                  <div key={`item--${i}`}>
                    {caseRowItem.parent_content_type},{' '}
                    {caseRowItem.content_type}
                  </div>
                )),
                item?.government_action ||
                  // eslint-disable-next-line no-nested-ternary
                  (typeof item?.is_government_action_e_c === 'boolean'
                    ? item?.is_government_action_e_c
                      ? 'Yes'
                      : 'No'
                    : null),
                item?.is_adverse_media_source_e_c ? (
                  <ComplianceAdverseMediaSourceDetails
                    data={item?.adverse_media_source_details}
                  />
                ) : (
                  item?.case_row_details?.map((caseRowItem, i) => (
                    <ComplianceAdverseMediaSourceDetails
                      key={i}
                      data={caseRowItem?.adverse_media_source_details}
                    />
                  ))
                ),
              ])}
            />
          </>
        )}
      </div>
    );
  };

  const ComplianceSources = ({ sources }) => {
    return (
      <TableList
        title='Source Links'
        rows={sources.map((row) => {
          const title = row.title ?? row.external_link;

          return row.external_link ? (
            <a href={row.external_link} target='_blank' rel='noreferrer'>
              <h5>{title}</h5>
            </a>
          ) : (
            <h5>{title}</h5>
          );
        })}
      />
    );
  };

  switch (structuredData?.attributes?.basic.type) {
    case 'Person':
      const name = attributes.basic.name_details.primary_name;
      const dates = person.date_details;
      const birthday = dates?.birth?.[0]?.date;
      const deceasedDate = dates?.deceased?.[0]?.date;
      const birthplace = person.places_of_birth?.[0];

      const roles = [
        watchlist.role_details?.primary,
        watchlist.role_details?.other,
        watchlist.role_details?.previous,
      ]
        .filter((arr) => arr)
        .flat();

      const aka = [
        attributes.basic.name_details.low_quality_aka,
        attributes.basic.name_details.also_known_as,
      ]
        .flat()
        .filter((item) => item !== undefined)
        .map((origName) => formatName(origName))
        .join(', ');

      const personIdentity = [
        { label: 'ID', value: structuredData.id },
        { label: 'First Name', value: name.first_name },
        { label: 'Middle Name', value: name.middle_name },
        {
          label: 'Last Name',
          value: name.last_name || name.surname,
        },
        { label: 'Also known as', value: aka },
        { label: 'Gender', value: person.gender },
        {
          label: 'Deceased',
          value: person.is_deceased ? 'Yes' : 'No',
        },
      ];
      const personCountryDetails = [
        {
          label: 'Citizenship',
          value: person.country_territory_details?.citizenship?.[0].descriptor,
        },
        {
          label: 'Jurisdiction',
          value: person.country_territory_details?.jurisdiction?.[0].descriptor,
        },
        {
          label: 'Residence',
          value: person.country_territory_details?.residence?.[0].descriptor,
        },
        {
          label: 'Place of birth',
          value: [
            birthplace?.city,
            birthplace?.region,
            birthplace?.country.descriptor,
          ]
            .filter((item) => item !== undefined)
            .join(', '),
        },
        {
          label: 'Reported allegation',
          value:
            person.country_territory_details?.reported_allegation?.[0]
              .descriptor,
        },
      ];

      return (
        <div
          className={
            printView ? classes['media-print'] : classes['media-screen']
          }
        >
          <ComplianceHeader name={formatName(name)} icons={person.icon_hints} />
          <div className={classes.structure}>
            <h4 className={classes['structure-title']}>Identity</h4>
            {personIdentity.map((row, i) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Row {...row} key={`row--${i}`} />
            ))}
          </div>
          <div className={classes.structure}>
            <h4 className={classes['structure-title']}>Country / Territory</h4>
            {personCountryDetails.map((row, i) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Row {...row} key={`row--${i}`} />
            ))}
          </div>

          {basic.address_details ? <ComplianceAddress /> : null}
          {watchlist.address_details ? <ComplianceWatchlistAddress /> : null}

          <div className={classes.structure}>
            <h4 className={classes['structure-title']}>Dates</h4>
            <Row label='Birth' value={birthday ? formatDate(birthday) : null} />
            <Row
              label='Deceased'
              value={deceasedDate ? formatDate(deceasedDate) : null}
            />
          </div>

          {watchlist?.comment_details ? (
            <div className={classes.structure}>
              {Object.values(watchlist?.comment_details).map((comment, i) => (
                <div key={`item--${i}`}>{comment}</div>
              ))}
            </div>
          ) : null}

          {watchlist?.reported_allegation_details?.length ? (
            <div className={classes.structure}>
              <h4 className={classes['structure-title']}>
                Reported allegations
              </h4>
              <Table
                headCells={['Type', 'Monetary Value']}
                rows={watchlist?.reported_allegation_details?.map((details) => {
                  const allegation = details.case_row_details[0];
                  return [
                    <strong>{allegation.content_type || '-'}</strong>,
                    [
                      allegation.reported_monetary_value_detail
                        ?.reported_monetary_type,
                      allegation.reported_monetary_value_detail
                        ?.from_reported_monetary_value,
                      allegation.reported_monetary_value_detail?.currency?.code,
                    ].join(' '),
                  ];
                })}
              />
            </div>
          ) : null}

          {roles.length ? (
            <div className={classes.structure}>
              <h4 className={classes['structure-title']}>Role</h4>
              <Table
                headCells={['Title', 'Category', 'Since', 'To']}
                rows={roles.map((role) => [
                  role.title,
                  role.category_type,
                  formatDate(role?.since),
                  formatDate(role?.to),
                ])}
              />
            </div>
          ) : null}

          {stateOwnedCompanies ? <ComplianceStateOwnedCompanies /> : null}

          {relationship?.connection_details.length ? (
            <ComplianceRelationships />
          ) : null}
          {watchlist.sources?.length && (
            <ComplianceSources sources={watchlist.sources} />
          )}
        </div>
      );
    case 'Entity':
      const entityName = attributes.basic.name_details.primary_name;
      return (
        <div>
          <div className={classes.structure}>
            <ComplianceHeader
              name={[entityName.name, entityName.suffix].join(', ')}
              icons={entity.icon_hints}
            />
          </div>
          <div className={classes.structure}>
            <h4 className={classes['structure-title']}>Identity</h4>
            <Row label='ID' value={structuredData.id} />
            <Row label='Primary Name' value={entityName.name} />
            <Row label='Suffix' value={entityName.suffix} />
            <Row
              label='Formerly known as'
              value={[
                attributes.basic.name_details.formerly_known_as
                  ?.map((nameObj) => `${nameObj.name} ${nameObj.suffix}`)
                  .join(', '),
              ]}
            />
            {entity.identification_details?.length
              ? entity.identification_details.map((id, i) => (
                  <Row label={id.type} value={id.value} key={`row--${i}`} />
                ))
              : null}
          </div>
          <div className={classes.structure}>
            <h4 className={classes['structure-title']}>Country / Territory</h4>
            <Row
              label='Registration'
              value={entity.country_territory_details?.registration
                ?.map((reg) => reg.descriptor)
                .join(', ')}
            />
          </div>
          {basic?.address_details ? <ComplianceAddress /> : null}
          {watchlist?.address_details ? <ComplianceWatchlistAddress /> : null}

          <div className={classes.structure}>
            <h4 className={classes['structure-title']}>Dates</h4>
            <Row
              label='Registration'
              value={formatDate(entity.date_details?.registration?.[0]?.date)}
            />
          </div>
          {watchlist?.comment_details ? (
            <div className={classes.structure}>
              {Object.values(watchlist?.comment_details).map((comment, i) => (
                <div key={`item--${i}`}>{comment}</div>
              ))}
            </div>
          ) : null}
          {listReference ? (
            <div className={classes.structure}>
              <h4 className={classes['structure-title']}>List Reference</h4>
              <Table
                headCells={['Title', 'From', 'To']}
                rows={[listReference.other_official_lists].flat().map((ref) => {
                  return !ref
                    ? []
                    : [
                        ref.name,
                        formatDate(ref?.from_date) || '-',
                        formatDate(ref?.to_date) || '-',
                      ];
                })}
              />
            </div>
          ) : null}

          {stateOwnedCompanies ? <ComplianceStateOwnedCompanies /> : null}

          {relationship?.connection_details.length ? (
            <ComplianceRelationships />
          ) : null}
          {adverseMediaEntity ? <ComplianceAdverseMedia /> : null}
          {watchlist?.sources?.length && (
            <ComplianceSources sources={watchlist.sources} />
          )}
        </div>
      );
    default:
      return <div>No data to display.</div>;
  }
};

export default DowJonesStructuredData;
