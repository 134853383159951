import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const fetchProjectItemLink = (project, item) => {
  const request = axios.get(
    `${apiHost}/api/v1/projects/${project}/items/${item}/link`,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      if (res) res.ItemId = item;
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default fetchProjectItemLink;
