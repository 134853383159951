import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const editProject = (projectId, name) => {
  const data = JSON.stringify({
    name,
  });

  const request = axios.patch(`${apiHost}/api/v1/projects/${projectId}`, data, {
    headers: apiHeaders(),
  });

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default editProject;
