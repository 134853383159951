import { useEffect } from 'react';
import Spinner from 'components/Base/Spinner';
import logoutAuth from 'api/auth/logoutAuth';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    logoutAuth({ onLogout: () => navigate('/') });
  }, [navigate]);

  return <Spinner />;
};

export default Logout;
