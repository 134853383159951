import { createContext, useEffect, useRef } from 'react';
import AsyncMessageProcessor from './AsyncMessageProcessor';

export const AsyncMessageContext = createContext(null);

export const AsyncMessageProvider = ({ websocketConfig, children }) => {
  const processorRef = useRef(new AsyncMessageProcessor('Develop'));

  useEffect(() => {
    const processor = processorRef.current;
    if (processor?.wsClientConnected) {
      console.log('disconnecting from websocket');
      processor.wsClient.disconnect();
      processor.wsClientConnected = false;
    }
    if (websocketConfig) {
      processor.setupClient(websocketConfig);
    }
  }, [websocketConfig]);

  return (
    <AsyncMessageContext.Provider value={processorRef.current}>
      {children}
    </AsyncMessageContext.Provider>
  );
};
