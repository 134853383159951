// Use this component when you want to avoid the 'flash' of a loading state. For example,
// when viewing a search that has no results the user is shown the loading skeleton for
// a split second. This makes the application appear slower than it actually is (and just
// looks bad in general).
import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { noop } from 'lodash';

type LoaderProps = {
  loading: boolean;
  component?: JSX.Element;
  children: JSX.Element;
};

const Loader = ({ loading, component, children }: LoaderProps) => {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    if (!loading) return noop;
    const timeout = setTimeout(() => {
      setDisplay(true);
    }, 150);
    return () => {
      setDisplay(false);
      clearTimeout(timeout);
    };
  }, [loading]);

  if (!loading || display)
    return (
      <Fade in>
        <div>{children}</div>
      </Fade>
    );
  return component || null;
};

export default Loader;
