import React, { useState, useRef, FunctionComponent } from 'react';
import InputText from 'components/Base/Fields/InputText';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import { debounce } from 'lodash';
import classes from '../projects-table.module.scss';
import { ProjectsFilter } from '../types';

interface NameFilterProps {
  name: string;
  onChange: (filters: Partial<ProjectsFilter>) => void;
}

const NameFilter: FunctionComponent<NameFilterProps> = ({ name, onChange }) => {
  const [value, setValue] = useState(name);
  const debouncedSearch = useRef(
    debounce((valueToUpdate) => onChange({ name: valueToUpdate, page: 0 }), 300)
  ).current;
  // if changed externally
  React.useEffect(() => {
    if (name === null) {
      setValue('');
    }
  }, [name]);

  React.useEffect(() => {
    if (value || value === null) {
      debouncedSearch(value);
    }
  }, [debouncedSearch, value]);

  return (
    <InputText
      name='name-filter'
      size='small'
      value={value}
      className={classNames(classes.filter, classes['name-filter'])}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value || null)
      }
      handleClear={() => {
        setValue('');
        onChange({ name: '', page: 0 });
      }}
      InputProps={{
        style: { fontSize: '12px' },
        startAdornment: (
          <InputAdornment position='start' style={{ width: '18px' }}>
            <SearchIcon
              style={{
                fontSize: '14px',
                marginLeft: '6px',
                marginRight: '4px',
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default NameFilter;
