/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import SimpleBar from 'simplebar-react';
import Button from 'components/Base/Button';
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp';
import DateFacet from './FacetTypes/DateFacet';
import IncludeExcludeFacet from './FacetTypes/IncludeExcludeFacet';
import CheckboxFacet from './FacetTypes/CheckboxFacet';
import TreeFacet from './FacetTypes/TreeFacet';
import classes from './facets.module.scss';

const Facets = ({
  items,
  handleSubmit,
  disabled,
  isFiltered = false,
  ...props
}) => {
  return (
    <div className={classes.container} {...props}>
      <div className={classes.header}>
        <span>Filters</span>
        {isFiltered && <FilterListSharpIcon fontSize='small' />}
      </div>
      <SimpleBar className={classes.wrapper}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {items?.map((item, i) => {
            switch (item.filter) {
              case 'date':
                return <DateFacet key={`facet-${i}`} {...item} />;
              case 'include-exclude':
                return <IncludeExcludeFacet key={`facet-${i}`} {...item} />;
              case 'checkbox':
                return <CheckboxFacet key={`facet-${i}`} {...item} />;
              case 'tree':
                return <TreeFacet key={`facet-${i}`} {...item} />;
              default:
                return null;
            }
          })}
        </div>
      </SimpleBar>
      <div className={classes['button-container']}>
        <Button
          type='submit'
          label='Apply Filter'
          onClick={handleSubmit}
          size='small'
          fullWidth
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default Facets;
