import { IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IAdverseTerm } from 'appSrc/types/adverseTerms';
import React from 'react';
import classes from '../adverse-terms.module.scss';
import DeleteDialog from './DeleteDialog';

const DeleteTerm = ({ term }: { term: IAdverseTerm }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <DeleteDialog open={open} term={term} setOpen={setOpen} />
      <IconButton className={classes.button} onClick={() => handleClickOpen()}>
        <DeleteOutlinedIcon style={{ color: 'black' }} />
      </IconButton>
    </>
  );
};

export default DeleteTerm;
