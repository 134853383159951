export const LOGIN = `LOGIN`;
export const LOGOUT = `LOGOUT`;
export const USER_SUCCESS = `USER_SUCCESS`;
export const LOAD_CONFIG = `LOAD_CONFIG`;
export const LOAD_SINGLE_PROVIDER_CONFIG = `LOAD_SINGLE_PROVIDER_CONFIG`;
export const FETCH_FAILURE = `FETCH_FAILURE`;
export const FETCH_FAILURE_RETRIEVE = `FETCH_FAILURE_RETRIEVE`;
export const FETCH_FAILURE_TRANSLATE = `FETCH_FAILURE_TRANSLATE`;
export const FETCH_FAILURE_TRANSLATE_ITEMS = `FETCH_FAILURE_TRANSLATE_ITEMS`;
export const LOGIN_FAILURE = `LOGIN_FAILURE`;
export const CREATE_FAILURE = `CREATE_FAILURE`;
export const ERROR_DISPLAYED = `ERROR_DISPLAYED`;
export const SHOW_TOAST = `SHOW_TOAST`;
export const HIDE_TOAST = `HIDE_TOAST`;
export const SHOW_MODAL = `SHOW_MODAL`;
export const HIDE_MODAL = `HIDE_MODAL`;
export const REFRESH_MODAL = `REFRESH_MODAL`;
export const UPDATE_PROJECTS_FILTER = 'UPDATE_PROJECTS_FILTER';
export const RESET_PROJECT = `reset-project`; // Not used
export const SET_STATE = `set-state`; // Not used
export const UPDATE_STATE = `update-state`;
export const UPDATE_NAV = `update-nav`;
export const UPDATE_NAVIGATION = `update-navigation`;
export const UPDATE_FINDINGS_FILTER = `update-findings-filter`;
export const UPDATE_RELATED_ITEMS = `update-related-items`;
export const UPDATE_ITEMS_VIEWED = `update-items-viewed`;
export const UPDATE_ARTICLE_END_URL = `update-article-end-url`;
export const UPDATE_SET_RELATED = `update-set-related`;
export const UPDATE_TRANSLATE_DOCUMENTS_UPDATED = `update-translate-documents-updated`;
export const UPDATE_FETCH_DOCUMENTS_UPDATED = `update-fetch-documents-updated`;
export const UPDATE_TRANSLATE_ITEMS_UPDATED = `update-translate-items-updated`;
export const UPDATE_RETRIEVE_DOCUMENTS_UPDATED = `update-retrieve-documents-updated`;
export const UPDATE_SEARCH_PROVIDERS = 'update-search-providers';
