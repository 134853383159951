import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitArticleTranslation = ({ id, projectId, providerId }) => {
  const data = JSON.stringify({
    doc_id: id,
    project_id: projectId,
    provider: providerId,
  });

  const request = axios.post(`${apiHost}/api/v1/translate/content`, data, {
    headers: apiHeaders(),
  });

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitArticleTranslation;
