import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'context';
import Spinner from 'components/Base/Spinner';
import fetchCurrentUser from 'api/auth/fetchCurrentUser';
import signinRedirect from 'api/auth/signinRedirect';
import useFetchConfig from 'hooks/useFetch/config/useFetchConfig';

export const ProtectedRoute: FunctionComponent<{
  children?: ReactElement;
}> = ({ children }): ReactElement => {
  const hasToken = !!localStorage.getItem('jwt');
  const hasUser = !!useSelector((state) => state.user?.email);
  const isLoadingConfig = useSelector((state) => state.config.processing);
  const navigate = useNavigate();
  const config = useSelector((state) => state.config);
  const [isAuthenticated, setIsAuthenticated] = useState(hasToken && hasUser);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const requiresUserData = hasToken && !hasUser;

  useFetchConfig(config.providers, isAuthenticated);

  const authenticateUser = async () => {
    setIsAuthenticating(true);

    const hasFetchedUser = await fetchCurrentUser();

    if (hasFetchedUser) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setIsAuthenticating(false);
  };

  useEffect(() => {
    if (requiresUserData) {
      authenticateUser();
      // Remove this navigate when we are ready to implement the ability to land
      // on a subroute and use the uri params to load screen from any route.
      // navigate('/');
    } else if (!isAuthenticated) {
      signinRedirect();
    }
  }, [requiresUserData, isAuthenticated, navigate]);

  if (isAuthenticating) {
    return <Spinner />;
  }

  if (isAuthenticated && !isLoadingConfig) {
    return children || <Outlet />;
  }

  return <Spinner />;
};

export default ProtectedRoute;
