import axios from 'axios';
import refreshAuth from './auth/refreshAuth';

export const setupAxiosIntercept = () => {
  const refreshAction = { active: false };

  axios.interceptors.response.use(
    (response) => {
      if (response?.headers && response.headers['x-refresh-before']) {
        if (!refreshAction.active) {
          refreshAction.active = true;
          refreshAuth();
          setTimeout(() => {
            refreshAction.active = false;
          }, 30000);
        }
      }
      return response;
    },
    // eslint-disable-next-line consistent-return
    (error) => {
      if (error.response?.status === 401) {
        window.localStorage.removeItem('jwt');
        if (
          window.location.pathname + window.location.search ===
          '/login?local'
        ) {
          return Promise.reject(error);
        }
        window.location = '/login';
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default setupAxiosIntercept;
