/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactNode } from 'react';
import { TextField } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import classNames from 'classnames';
import classes from './fields-base.module.scss';

type AutocompleteProps = {
  variant?: string;
  size?: string;
  theme?: string;
  required?: boolean;
  label: string;
  name: string;
  value: any;
  options: any;
  renderOption?: (option: any, state: any) => ReactNode;
  getOptionLabel?: (option: any) => string;
  onChange: (value: any) => void;
  InputProps?: any;
};

const Autocomplete = ({
  variant,
  size,
  theme,
  required,
  label,
  name,
  value,
  options,
  renderOption,
  getOptionLabel,
  onChange,
  InputProps,
  ...props
}: AutocompleteProps) => {
  return (
    <>
      {label && <label className={classes.label}>{label}</label>}
      <MuiAutocomplete
        freeSolo
        value={value}
        options={options}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onChange={onChange}
        classes={{
          paper: classes['dropdown-menu'],
        }}
        renderInput={(params) => (
          <TextField
            required={required}
            variant='outlined'
            name={name}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            size='small'
            className={classNames(classes.input, {
              [classes[`input--${variant}`]]: variant,
              [classes[`input--${size}`]]: size,
            })}
            InputProps={{
              // disableUnderline: true,
              ...params.InputProps,
              ...InputProps,
            }}
            onChange={onChange}
          />
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  );
};

export default Autocomplete;
