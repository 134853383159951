export const ProviderStatus = {
  NONE: 0,
  LOADING: 1,
  ERROR: 2,
  COMPLETE: 3,
};

export const calculateProviderStatus = (providers) => {
  if (!providers || providers.length <= 0) {
    return ProviderStatus.NONE;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const provider of providers) {
    if (!provider?.queries || provider.queries.length <= 0) {
      return ProviderStatus.NONE;
    }

    const query = provider.queries[provider.queries.length - 1];

    if (query.processed) {
      // Always return errors
      if (query.status === 'search.error') {
        return ProviderStatus.ERROR;
      }
    } else {
      return ProviderStatus.LOADING;
    }
  }

  // If we have reached here it means there are no other providers loading/errored.
  return ProviderStatus.COMPLETE;
};
