import { StructuredRow } from '../StructuredRow';
import classes from '../../../article.module.scss';

const formatDate = (dateToFormat) => {
  return dateToFormat
    .split('-')
    .filter((datePart) => parseInt(datePart, 10) > 0)
    .join('-');
};

export const DateOfBirth = ({ datesOfBirth }) => {
  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Date Of Birth</h4>
      {datesOfBirth.length > 0 ? (
        datesOfBirth.map((dateOfBirth) => (
          <StructuredRow
            key={`dob-${dateOfBirth.full_date}`}
            label='Date of Birth'
            value={formatDate(dateOfBirth.full_date)}
          />
        ))
      ) : (
        <StructuredRow
          key='dob-unknown'
          label='Date of Birth'
          value='Unknown'
        />
      )}
    </div>
  );
};

export default DateOfBirth;
