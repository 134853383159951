/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useDispatch, useSelector } from 'context';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { find } from 'lodash';
import { deleteQuery } from 'hooks/useFetch';
import classNames from 'classnames';
import { calculateProviderStatus } from 'helpers/providers/status';
import { UPDATE_SEARCH_PROVIDERS } from 'constants/actionTypes';
import classes from './search-navigation.module.scss';
import Modal from '../Modal/Modal';
import { StatusIcon } from './StatusIcon';

const SearchProviderItem = ({
  id,
  label,
  selected,
  provider,
  handleRedirect,
  queries,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.state.project);
  const currentSearch = useSelector((state) => state.state.search);
  const currentQuery = useSelector((state) => state.state.query);
  const query = selected
    ? find(queries, { id: currentQuery.id })
    : queries[queries.length - 1];
  const [isOpen, setIsOpen] = useState(false);
  const providerStatus = calculateProviderStatus([provider]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const removeProvider = () => {
    deleteQuery(project.id, currentSearch.id, query.id, id).then(() => {
      handleClose();
      if (currentSearch.providers.length === 1) {
        navigate(`/projects/${project.id}`);
      } else {
        const remainingProviders = currentSearch.providers.filter(
          (p) => p.id !== id
        );

        // The new provider that will be shown/redirected to after the
        // current one is deleted.
        const targetProvider = remainingProviders[0];

        handleRedirect({
          queryId: targetProvider.queries[targetProvider.queries.length - 1].id,
          providerId: targetProvider.id,
          searchId: currentSearch.id,
          useProjectSearches: false,
        });

        dispatch({
          type: UPDATE_SEARCH_PROVIDERS,
          payload: remainingProviders,
        });
      }
    });
  };

  const redirect = (e) => {
    e.preventDefault();
    if (!isOpen && !selected) {
      handleRedirect({
        queryId: query.id,
        providerId: id,
        searchId: currentSearch.id,
      });
    }
  };

  return (
    <>
      <Modal
        variant='alert'
        type='provider'
        name={label}
        isOpen={isOpen}
        handleClose={handleClose}
        handleSubmit={removeProvider}
      />
      <div
        onClick={redirect}
        className={classNames(classes.provider, {
          [classes['provider--active']]: selected,
        })}
      >
        <span className={classes.label}>
          <span>{label.replace(' archive', '')}</span>
        </span>

        <StatusIcon providerStatus={providerStatus} count={query?.hit_count} />

        <IconButton
          className={classes.remove}
          onClick={handleOpen}
          style={{
            height: '1rem',
            width: '1rem',
            marginLeft: 'auto',
            fontSize: '14px',
            marginTop: '1px',
          }}
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
      </div>
    </>
  );
};

export default SearchProviderItem;
