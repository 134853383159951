import React from 'react';
import Chip from 'components/Base/Chip';
import { TagsList } from './TagsList';
import { ResultsItemHeader } from './ResultsItemHeader';
import { ResultsItemSubtitle } from './ResultsItemSubtitle';
import classes from './results.module.scss';

const formatLastUpdatedDate = (timestampString) => {
  const timestamp = new Date(timestampString);
  return `Last Updated: ${timestamp.toLocaleDateString('en-GB')}`;
};
export const WorldcheckResultsItem = ({
  id,
  title,
  titleTags,
  text,
  textTags,
  category: entityModificationDate, // what actually appears in this mapping location
  tags,
  viewed,
  queryString,
}) => {
  const tagsArr = tags.filter((tag) => tag !== 'Finding');
  return (
    <>
      <ResultsItemHeader
        id={id}
        title={title}
        queryString={queryString}
        isSaved={tags.includes('Finding')}
        viewed={viewed}
      />

      <div className={classes['item-details--compliance']}>
        <ResultsItemSubtitle highlight={text} tags={titleTags} />

        {textTags ? (
          <span className={classes['item-text-tags']}>
            {textTags.map((tag) => (
              <Chip
                key={`item-text-tag--${tag}`}
                label={tag}
                size='small'
                classes={{ root: classes.chip }}
              />
            ))}
          </span>
        ) : (
          ''
        )}
        <div style={{ display: 'flex', width: '100%' }}>
          {entityModificationDate && (
            <div className={classes['item-source']}>
              {formatLastUpdatedDate(entityModificationDate)}
            </div>
          )}
          {tagsArr && <TagsList tags={tagsArr} />}
        </div>
      </div>
    </>
  );
};

export default WorldcheckResultsItem;
