import moment from 'moment';
import { InputAdornment } from '@mui/material';
import InputText from 'components/Base/Fields/InputText';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import classNames from 'classnames';
import classes from '../projects-table.module.scss';

const DateFilter = ({ date, onChange }) => {
  const handleDateFilter = (e, picker) => {
    const { startDate, endDate } = picker;
    const sd = moment(startDate).format('YYYY-MM-DD');
    const ed = moment(endDate).format('YYYY-MM-DD');
    onChange({
      date: { s: sd, e: ed },
      dateActive: true,
      page: 0,
    });
  };

  const handleDateFilterClear = () => {
    onChange({
      date: { s: '', e: '' },
      dateActive: false,
      page: 0,
    });
  };

  return (
    <DateRangePicker
      onApply={handleDateFilter}
      onCancel={handleDateFilterClear}
      initialSettings={{
        ranges: {
          Today: [moment().toDate(), moment().toDate()],
          Yesterday: [
            moment().subtract(1, 'days').toDate(),
            moment().subtract(1, 'days').toDate(),
          ],
          'Last 7 Days': [
            moment().subtract(6, 'days').toDate(),
            moment().toDate(),
          ],
          'Last 30 Days': [
            moment().subtract(29, 'days').toDate(),
            moment().toDate(),
          ],
          'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
          ],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
          ],
        },
      }}
    >
      <span>
        <InputText
          size='small'
          value={
            date.s
              ? `${moment(date.s).format('DD MMM YYYY')} - ${moment(
                  date.e
                ).format('DD MMM YYYY')}`
              : ''
          }
          className={classNames(classes.filter, classes['date-filter'])}
          // classes={{
          //   endAdornment: classes.adornment,
          //   clearIndicatorDirty: classes.indicator,
          // }}
          handleClear={handleDateFilterClear}
          InputProps={{
            style: { fontSize: '12px' },
            startAdornment: (
              <InputAdornment position='start' style={{ width: '20px' }}>
                <CalendarTodayIcon
                  style={{
                    fontSize: '14px',
                    marginLeft: '6px',
                    marginRight: '4px',
                  }}
                />
              </InputAdornment>
            ),
            // endAdornment: listFilter.date.s && (
            //   <InputAdornment>
            //     <IconButton
            //       variant="clear"
            //       className={classes["date-filter--clear"]}
            //       onClick={handleDateFilterClear}
            //     />
            //   </InputAdornment>
            // ),
          }}
        />
      </span>
    </DateRangePicker>
  );
};

export default DateFilter;
