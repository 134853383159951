import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Button from 'components/Base/Button';
import IconPrint from 'components/Base/Icons/IconPrint';
import { useSelector } from 'context';
import CopyReference from '../../ArticleSections/CopyReference';
import ArticleTagsControl from '../../ArticleSections/TagsControl';
import classes from '../../article.module.scss';
import { ComplianceHeader } from '../ComplianceHeader';
import { SayariFetch, SayariStructuredData } from './Sayari';
import { RawDataToggle } from './RawDataToggle';
import parseContentToStructuredData from '../utils/parseContentToStructuredData';

const CorporateRecordsArticleTemplate = ({
  data,
  providerConfig,
  isSaved,
  handleSave,
  availableTags,
  updateTags,
  handleLinkRelated,
  ...props
}) => {
  const { trackEvent } = useMatomo();
  const navigate = useNavigate();

  const json = parseContentToStructuredData(data?.contentJSON);
  const isFullEntity = typeof json?.attributes !== 'undefined';
  const [expanded, setExpanded] = useState(false);
  const project = useSelector((state) => state.state.project);

  const expandAllDropdowns = (value) => {
    setExpanded(value);
  };

  const providerLabel = providerConfig.label;

  return (
    <div>
      <ComplianceHeader
        isSaved={isSaved}
        handleSave={handleSave}
        providerLabel={providerLabel}
        viewed={data.viewed}
        toggleValue={expanded}
        toggleTitle='Expand all dropdowns'
        setToggleValue={expandAllDropdowns}
      />

      <div className={classes.content}>
        {/* TODO - temporary code while waiting for new designs */}
        <div
          style={{
            padding: '0 0 1rem 0',
            zIndex: -1,
            display: 'flex',
            flexWrap: 'wrap',
            gap: '6px',
          }}
        >
          {/* @ts-ignore */}
          <ArticleTagsControl
            isSaved={isSaved}
            projectId={props.project.id}
            docId={data.id}
            tags={data.tags}
            availableTags={availableTags}
            updateTags={updateTags}
          />

          <Button
            onClick={() => {
              trackEvent({ category: 'article', action: 'print_article' });
              navigate(
                `/projects/${props.project.id}/searches/${
                  data.search_id || 'related'
                }/providers/${data.provider}/queries/${data.query_id}/items/${
                  data.id
                }/export`
              );
            }}
            target='_blank'
            variant='secondary'
            size='small'
            label={
              <>
                <IconPrint />
                &nbsp; Print version
              </>
            }
          />
          <CopyReference data={data} />
        </div>
        <SayariStructuredData
          data={json}
          expanded={expanded}
          handleLinkRelated={handleLinkRelated}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />

        {!isFullEntity &&
          !(json.related_type && json.related_type === 'record') && (
            <SayariFetch
              projectId={project.id}
              providerId={data.provider}
              providerItemId={data.provider_item_id}
            />
          )}

        {(process.env.NODE_ENV === 'development' || 'staging') && (
          <RawDataToggle data={data} />
        )}
      </div>
    </div>
  );
};

export default CorporateRecordsArticleTemplate;
