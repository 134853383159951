import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog } from '@mui/material';
import { CloseButton, DialogueActionsContainer } from './styled';

const StandardModal = ({
  titleContent,
  mainContent,
  actionContent,
  onClose,
  isOpen,
}) => {
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth='xl'>
      <DialogTitle style={{ fontWeight: 600 }}>
        {titleContent}{' '}
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent dividers>{mainContent}</DialogContent>
      <DialogueActionsContainer>{actionContent}</DialogueActionsContainer>
    </Dialog>
  );
};

export default StandardModal;
