import axios from 'axios';
import { apiHeaders, apiHost } from 'hooks/useFetch';

export const refreshAuth = (): Promise<void> => {
  const req = axios
    .post(`${apiHost}/api/v1/auth/refresh`, {}, { headers: apiHeaders() })
    .then((res) => {
      console.log('refreshed');
      window.localStorage.setItem('jwt', res.data.access_token);
    })
    .catch(() => {
      window.localStorage.removeItem('jwt');
      // @ts-ignore
      window.location = '/logout';
    });

  return req;
};

export default refreshAuth;
