import { IconButton, PaginationItem } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import classes from './results.module.scss';

const makeRange = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

const PageNumbers = ({
  page,
  totalPages,
  maxPages,
  handleClick,
  loading,
  disabled,
}) => {
  let range;
  if (totalPages > 8) {
    if (page < 5) range = makeRange(1, 9);
    else if (page + 4 > totalPages)
      range = makeRange(totalPages - 8, totalPages);
    else range = makeRange(page - 4, page + 4);
  } else range = makeRange(1, totalPages);

  return (
    <div>
      {range.map((item) => (
        <PaginationItem
          key={`page-number-${item}`}
          size='small'
          style={{ margin: '0px 6px' }}
          page={item}
          selected={item === page}
          disabled={loading || !totalPages || disabled || item > maxPages}
          onClick={(e) => handleClick(e, item)}
        />
      ))}
    </div>
  );
};

const ResultsPagination = ({
  loading,
  page,
  setPage,
  totalResults,
  perPage = 100,
  maxPages,
  disabled = false,
  ...props
}) => {
  const totalPages = Math.ceil(totalResults / perPage);

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const handleClick = (e, item) => {
    setPage(item);
  };

  return (
    <div className={classes.pagination}>
      <IconButton
        size='small'
        className={classes['pagination-button']}
        onClick={prevPage}
        disabled={page === 1 || loading || !totalPages}
      >
        <ArrowBackIosIcon
          className={classes['pagination-icon']}
          fontSize='small'
        />
      </IconButton>
      <PageNumbers
        page={page}
        totalPages={totalPages}
        maxPages={maxPages}
        handleClick={handleClick}
        loading={loading}
        disabled={disabled}
      />
      <IconButton
        size='small'
        className={classes['pagination-button']}
        onClick={nextPage}
        disabled={
          loading || !totalPages || page === totalPages || props.disabled
        }
      >
        <ArrowForwardIosIcon className={classes['pagination-icon']} />
      </IconButton>
    </div>
  );
};

export default ResultsPagination;
