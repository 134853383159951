import { TableCell, TableHead, TableRow } from './styled';

const SubjectTableHeader = ({ isFactivaSelected }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: 200 }} size='small'>
          Subject type <span className='required'>*</span>
        </TableCell>
        <TableCell sx={{ width: 280 }} size='small'>
          Name <span className='required'>*</span>
        </TableCell>
        <TableCell sx={{ width: 200 }} size='small'>
          Country
        </TableCell>
        <TableCell sx={{ width: 220 }} size='small'>
          Date of birth
        </TableCell>
        <TableCell sx={{ width: 200 }} size='small'>
          Gender
        </TableCell>
        {isFactivaSelected && (
          <TableCell sx={{ width: 200 }} size='small'>
            Adverse Terms
          </TableCell>
        )}

        <TableCell sx={{ width: 150 }} size='small' />
      </TableRow>
    </TableHead>
  );
};

export default SubjectTableHeader;
