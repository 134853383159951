/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import classNames from 'classnames';
import classes from './fields-base.module.scss';

type InputTextProps = {
  variant?: string;
  size?: string;
  type?: string;
  className?: string;
  required?: boolean;
  label?: string;
  name: string;
  value: string;
  error?: boolean;
  onChange: (value: any) => void;
  onBlur?: (value: any) => void;
  onKeyDown?: (value: any) => void;
  handleClear?: () => void;
  InputProps?: any;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  active?: boolean;
};

const InputText = ({
  variant,
  size,
  className,
  required = false,
  label,
  name,
  value,
  error,
  onChange,
  onBlur,
  onKeyDown,
  handleClear,
  InputProps,
  multiline,
  rows,
  placeholder,
  type,
  active,
  ...props
}: InputTextProps) => {
  // eslint-disable-next-line no-param-reassign
  if (value === null) value = '';
  return (
    <>
      {label && <label className={classes.label}>{label}</label>}
      <TextField
        placeholder={placeholder}
        variant='outlined'
        required={required}
        multiline={multiline}
        rows={rows}
        name={name}
        error={error}
        classes={{
          root: classNames(
            { [classes['input--active']]: active },
            classes.input,
            classes[`input--${variant}`],
            classes[`input--${size}`],
            classes[`input--${type}`],
            className
          ),
          // input: classes["input-base"],
        }}
        autoComplete='off'
        size='small'
        InputProps={{
          classes: {
            adornedStart: classes['input__start-adornment'],
            adornedEnd: classes['input__end-adornment'],
          },
          startAdornment:
            type === 'search' ? (
              <span className={classes.input__adornment}>
                <SearchIcon fontSize='inherit' style={{ marginTop: '2px' }} />
              </span>
            ) : null,
          endAdornment: handleClear && value && (
            <InputAdornment className={classes['clear-wrapper']} position='end'>
              <IconButton className={classes.clear} onClick={handleClear}>
                <ClearIcon fontSize='inherit' />
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  );
};

export default InputText;
