import { TableCell, TableRow, Tooltip } from '@mui/material';
import Chip from 'components/Base/Chip';
import { IAdverseTerm } from 'appSrc/types/adverseTerms';
import { PERSONAL_CATEGORY } from 'appSrc/constants';
import EditTerm from './AdverseTermsActions/EditTerm';
import DeleteTerm from './AdverseTermsActions/DeleteTerm';

const AdverseTermRow = ({
  row,
  canModify,
}: {
  row: IAdverseTerm;
  canModify: boolean;
}) => {
  const query = row.queries?.[0]?.query;
  const category = row.category || PERSONAL_CATEGORY;
  return (
    <TableRow key={row.id}>
      <TableCell>{row.name}</TableCell>
      <TableCell>
        <Chip
          label={row.language?.code?.toUpperCase()}
          size='small'
          variant='grey'
          onClick={null}
        />
      </TableCell>
      <TableCell align='left'>
        {query && (
          <Tooltip title={query} placement='bottom'>
            <div>{`${query.substring(0, 50)}...`}</div>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{category.name}</TableCell>
      <TableCell>
        {canModify && (
          <>
            <EditTerm term={row} />
            <DeleteTerm term={row} />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
export default AdverseTermRow;
