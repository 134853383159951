import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const createUsersShared = (projectId, userId) => {
  const data = JSON.stringify({
    user_shared: userId,
  });

  const req = axios.patch(
    `${apiHost}/api/v1/projects/${projectId}/share`,
    data,
    { headers: apiHeaders() }
  );

  return req
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default createUsersShared;
