import React from 'react';
import Table, { TableRow, TableCell } from 'components/Base/Table';
import { getProviderVariant } from 'utils';
import classes from '../article.module.scss';

const RecordsTable = ({ data }) => {
  const struc = JSON.parse(data.contentJSON);

  const keys = [
    'title_number',
    'tenure',

    'property_address',
    'associated_property_description_id',

    'paon',
    'saon',
    'lead_info',
    'sub_building_desc',
    'sub_building_no',
    'house_desc',
    'house_no',
    'house_alpha',
    'second_house_no',
    'second_house_alpha',
    'trail_info',
    'street_name',
    'street_name_2',
    'local_name',
    'local_name_2',
    'town_name',

    'district',
    'county',
    'region',

    'postcode',
    'postcode_area',
    'postcode_district',
    'postcode_sector',

    'multiple_address_indicator',
    'os_uprn',
    'price_paid',
    'reg_order',
    'date_proprietor_added',
    'date_of_lease',
    'additional_proprietor_indicator',
    'term',

    'proprietor_name_1',
    'company_registration_no_1',
    'proprietorship_category_1',
    'country_incorporated_1',
    'proprietor_1_address_1',
    'proprietor_1_address_2',
    'proprietor_1_address_3',

    'proprietor_name_2',
    'company_registration_no_2',
    'proprietorship_category_2',
    'country_incorporated_2',
    'proprietor_2_address_1',
    'proprietor_2_address_2',
    'proprietor_2_address_3',

    'proprietor_name_3',
    'company_registration_no_3',
    'proprietorship_category_3',
    'country_incorporated_3',
    'proprietor_3_address_1',
    'proprietor_3_address_2',
    'proprietor_3_address_3',

    'proprietor_name_4',
    'company_registration_no_4',
    'proprietorship_category_4',
    'country_incorporated_4',
    'proprietor_4_address_1',
    'proprietor_4_address_2',
    'proprietor_4_address_3',

    'alienation_clause_indicator',
    'change_indicator',
    'data_change_date',
    'data_deletion_date',

    'source_type',
    'source',
    'source_date',
  ];
  return (
    <div className={classes.table}>
      <Table
        paginate={false}
        headCells={[]}
        rows={keys.map((key, i) => {
          let label = key;
          if (struc.source_type === 'LEASES') {
            switch (key) {
              case 'title_number':
                label = 'Unique Identifier';
                break;
              case 'property_address':
                label = 'Associated Property Desc.';
                break;
              case 'os_uprn':
                label = 'OS UPRN';
                break;
              case 'reg_order':
                label = 'Reg. Order';
                break;
              case 'associated_property_description_id':
                label = 'Associated Property Desc. ID';
                break;
              default:
              // return;
            }
          } else {
            if (struc?.postcode_area && key === 'property_address') {
              return '';
            }
            switch (key) {
              case 'paon':
                label = 'PAON';
                break;
              case 'saon':
                label = 'SAON';
                break;
              case 'house_desc':
              case 'house_no':
              case 'lead_info':
              case 'second_house_no':
              case 'sub_building_desc':
              case 'sub_building_no':
                label = `${key}.`;
                break;
              case 'company_registration_no_1':
                label = 'company_registration_no._1';
                break;
              case 'company_registration_no_2':
                label = 'company_registration_no._2';
                break;
              case 'company_registration_no_3':
                label = 'company_registration_no._3';
                break;
              case 'company_registration_no_4':
                label = 'company_registration_no._4';
                break;
              default:
              // return "";
            }
          }
          return struc[key] ? (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow variant='tight' key={i}>
              <TableCell variant='nowrap' size='small'>
                {getProviderVariant(label, true)}
              </TableCell>
              <TableCell size='small' variant='bold'>
                {Array.isArray(struc[key]) ? struc[key].join(', ') : struc[key]}
              </TableCell>
            </TableRow>
          ) : null;
        })}
      />
    </div>
  );
};

export default RecordsTable;
