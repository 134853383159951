/* eslint-disable react/button-has-type */
import Chip from 'components/Base/Chip';
import { Table } from '../../Table';
import classes from '../../../article.module.scss';
import worldcheckClasses from './worldcheckStyles.module.scss';
import { capitaliseFirstLetters } from './helpers';

const AssociateRelatedLink = ({
  entityId,
  primaryName,
  category,
  handleLinkRelated,
}) => {
  const fetchRelated = () => {
    const linkRelatedArgs = JSON.stringify({ primaryName, entityId });
    handleLinkRelated(linkRelatedArgs);
  };
  return (
    <div>
      <button
        onClick={fetchRelated}
        className={worldcheckClasses['related-link']}
      >
        {primaryName}
      </button>
      <Chip
        label={category}
        size='small'
        variant='grey'
        key={`category-${entityId}-${primaryName}`}
      />
    </div>
  );
};

export const AssociatesSection = ({ associates, handleLinkRelated }) =>
  associates ? (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>associates</h4>
      <Table
        headCells={['Primary Name', 'Associate Type']}
        rows={associates.map((associate) => {
          const { targetPrimaryName, targetEntityId, type, category } =
            associate;
          return [
            <AssociateRelatedLink
              entityId={targetEntityId}
              primaryName={targetPrimaryName}
              category={category}
              handleLinkRelated={handleLinkRelated}
            />,
            capitaliseFirstLetters(type, '_'),
          ];
        })}
      />
    </div>
  ) : null;

export default AssociatesSection;
