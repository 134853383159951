import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const TopHeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 25px;

  .hidden {
    display: none;
  }

  .flex-item-3 {
    text-align: right;
  }

  .flex-item-2 {
    padding: 0 20px;
    flex: 0 0 500px;
  }

  @media (max-width: 1000px) {
    .flex-item-1,
    .flex-item-3 {
      order: 1;
    }

    .flex-item-2 {
      padding: 0;
      order: 2;
      flex: 0 0 100%;
      margin-top: 20px;
    }
  }
`;

export const HeaderText = styled('h1')`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const BottomHeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-right: 25px;
`;

export const PageLabel = styled('div')`
  display: flex;
  align-items: center;
  color: #8e8e8e;

  background: #f5f6f7;
  padding: 5px 20px 5px 25px;

  h2 {
    font-size: 1.2rem;
    margin-left: 10px;
  }
`;

export const FindingsButton = styled(Link)`
  display: flex;
  align-items: center;
  color: #8e8e8e;

  div {
    background: #f5f6f7;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    line-height: 0;
  }
`;
