import { Table } from '../../Table';
import classes from '../../../article.module.scss';
import { capitaliseFirstLetters } from './helpers';

export const CountryLinksSection = ({ countryLinks }) => {
  // List of acronymns that appear within countryLinks.#.type. These are displayed in all caps.
  const knownTypeAcronymns = ['POB'];
  return countryLinks ? (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Country Links</h4>
      <Table
        headCells={['Country Name', 'Country Code', 'Link Type']}
        rows={countryLinks.map((countryLink) => {
          const { country, type } = countryLink;
          const formattedType = knownTypeAcronymns.includes(type)
            ? type
            : capitaliseFirstLetters(type);
          return [country.name, country.code, formattedType];
        })}
      />
    </div>
  ) : null;
};

export default CountryLinksSection;
