import axios from 'axios';
import { apiHost, apiHeaders, store, fetchFailure } from 'hooks/useFetch';

export const logoutRequestAuth = (): Promise<void> => {
  const request = axios
    .post(
      `${apiHost}/api/v1/auth/logout/request`,
      {},
      {
        headers: apiHeaders(),
      }
    )
    .then((res) => {
      window.location = res.data?.URI || '/logout';
    })
    .catch((error) => {
      store.dispatch(fetchFailure(error));
    });

  return request;
};

export default logoutRequestAuth;
