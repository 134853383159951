import { useState, useEffect } from 'react';
import axios from 'axios';
import QueryString from 'query-string';
import Spinner from 'components/Base/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiHost } from 'hooks/useFetch';

const LoginVerify = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = QueryString.parse(
    location.hash ? location.hash : location.search
  );

  useEffect(() => {
    const hasToken = !!window.localStorage.getItem('jwt');

    if (parsed.code && !isRequesting && !hasToken) {
      setIsRequesting(true);
      axios
        .post(
          `${apiHost}/api/v1/auth/token`,
          JSON.stringify({
            code: parsed.code,
          }),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          const accessToken = data.access_token;
          window.localStorage.setItem('jwt', accessToken);
          setIsRequesting(false);
          navigate('/');
        });
    } else if (!isRequesting) {
      navigate('/');
    }
  }, [isRequesting, parsed.code, navigate]);

  return <Spinner />;
};

export default LoginVerify;
