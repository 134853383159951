import { useNavigate, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { useIsFeatureEnabled } from 'hooks/useIsFeatureEnabled';
import LocalLogin from './LocalLogin';

const Login = () => {
  const location = useLocation();
  const q = QueryString.parse(location.search);
  const features = useIsFeatureEnabled(['login.localLoginEnabled']);
  const isLocalLoginEnabled = features['login.localLoginEnabled'];
  const isLocalLogin = typeof q.local !== 'undefined' && isLocalLoginEnabled;
  const navigate = useNavigate();

  if (isLocalLogin) {
    return <LocalLogin />;
  }
  setTimeout(() => {
    navigate('/');
  }, 0);
  return null;
};

export default Login;
