import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultState, useDispatch, useSelector } from 'context';
import { reverse, isEmpty } from 'lodash';
import { reverse as url } from 'named-urls';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Layout from 'layout/Layout';
import { fetchProject } from 'hooks/useFetch';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import Spinner from 'components/Base/Spinner';
import { SearchHistoryTable } from 'pages/Project/SearchHistoryTable';
import { UPDATE_STATE } from 'constants/actionTypes';
import ProjectActions from 'components/ProjectActions/ProjectActions';
import { useIsFeatureEnabled } from 'hooks/useIsFeatureEnabled';
import SearchHeader from 'components/SearchHeader';
import fetchAllAdverseTermsDataThunk from 'store/thunks/fetchAllAdverseTerms';
import AdverseTermsPopout from 'components/SearchBar/AdverseTermsPopout';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import {
  selectAdverseTermsPopoutVisible,
  selectRedFlagCheckWarningModalVisible,
} from 'store/slices/ui';
import RedFlagCheckWarningModal from 'components/SearchBar/RedFlagCheckWarningModal';
import { useModals } from 'hooks/ModalManager/useModals';
import { Modals } from 'constants';
import ProjectPageHeader from './ProjectPageHeader/ProjectPageHeader';

const Project = () => {
  const { open } = useModals();
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const pathParams = useParams();
  const projectId = pathParams.project_id;
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const currentUser = useSelector((state) => state.user);
  const project = useSelector(
    (state) => state.state.project || { isLoading: true }
  );
  const showTermsPopout = useAppSelector(selectAdverseTermsPopoutVisible);
  const showRedFlagCheckWarningModal = useAppSelector(
    selectRedFlagCheckWarningModalVisible
  );
  const [pageTitle, setPageTitle] = useState('');
  const [isProjectPrivate, setIsProjectPrivate] = useState();
  const pageViewed = useRef(false);
  const { subjectSearch: isSubjectSearchEnabled } = useIsFeatureEnabled([
    'subjectSearch',
  ]);
  useEffect(() => {
    appDispatch(fetchAllAdverseTermsDataThunk());
  }, [appDispatch]);
  useEffect(() => {
    if (pageTitle && !pageViewed.current) {
      pageViewed.current = true; // trackPageView dependency updates a few times
      trackPageView({ documentTitle: pageTitle });
    }
  }, [pageTitle, trackPageView]);

  useEffect(() => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        project: { loading: true },
        queryId: '',
      },
    });

    const status = { ok: false };
    fetchProject(projectId)
      .then((res) => {
        if (!res?.id) return;
        status.ok = true;
        dispatch({
          type: UPDATE_STATE,
          payload: {
            ...defaultState,
            project: { ...res, loading: false },
            searches: reverse(res?.searches) || [],
          },
        });
      })
      .finally(() => {
        if (!status.ok) navigate(url(appRoutingPaths.projects.all));
      });
  }, [dispatch, navigate, projectId]);

  useEffect(() => {
    if (project?.name) {
      setPageTitle(project.name);
      setIsProjectPrivate(project.private);
    }
  }, [project]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const Actions = () => {
    return currentUser.email.toLowerCase() === project?.owner?.toLowerCase() ? (
      <ProjectActions
        project={project}
        setProject={(data) =>
          dispatch({ type: UPDATE_STATE, payload: { project: data } })
        }
        pageTitle={pageTitle}
        setPageTitle={setPageTitle}
        isProjectPrivate={isProjectPrivate}
        setIsProjectPrivate={setIsProjectPrivate}
        currentUserId={currentUser?.id}
      />
    ) : (
      ''
    );
  };

  return (
    <Layout
      title={pageTitle}
      variant='table'
      isScrollable
      breadcrumbs={[
        { label: 'Projects', link: '/projects' },
        { label: project?.name, link: `/projects/${project.id}` },
      ]}
      menuItems={[
        {
          label: 'Searches',
          active: true,
          link: url(appRoutingPaths.projects.project.show, {
            project_id: projectId,
          }),
        },
        {
          label: 'Findings',
          active: false,
          link: url(appRoutingPaths.projects.project.findings, {
            project_id: projectId,
          }),
        },
      ]}
    >
      {isEmpty(project) || project.loading ? (
        <Spinner />
      ) : (
        <>
          {isSubjectSearchEnabled ? (
            <ProjectPageHeader
              onClickCreateNewSearch={() => open(Modals.SUBJECT_SEARCH)}
              Actions={Actions}
            />
          ) : (
            <SearchHeader projectCtrls={Actions} />
          )}

          <SearchHistoryTable initialSearches={project.searches} />
        </>
      )}
      {showTermsPopout && <AdverseTermsPopout showTerms={showTermsPopout} />}
      {showRedFlagCheckWarningModal && <RedFlagCheckWarningModal />}
    </Layout>
  );
};

export default Project;
