/* eslint-disable no-param-reassign */
import React from 'react';
import classes from '../article.module.scss';

// This is a placeholder view of structured data (JSON tree)
// Initially for Dow Jones but can evolve as a generic view

const GenericStructuredData = ({ data }) => {
  const struc =
    typeof data.contentJSON === 'object'
      ? data.contentJSON
      : JSON.parse(data.contentJSON);

  console.log(data);
  console.log(struc);

  const prTitle = (s) => {
    s = s.toLowerCase().replaceAll('_', ' ').split(' ');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < s.length; i++) {
      s[i] = s[i].charAt(0).toUpperCase() + s[i].slice(1);
    }
    return s.join(' ');
  };

  const prContent = (k, s) => {
    if (k === 'external_url' || k === 'external_link')
      return (
        <a href={s} target='_blank' rel='noreferrer'>
          {s}
        </a>
      );
    return s;
  };

  const pr = (obj) => {
    console.log(obj);
    const keys = Object.keys(obj);
    return (
      <ul>
        {keys.map((k) => {
          return (
            <li>
              {typeof k === 'string' && Number.isNaN(k) ? (
                <b>{prTitle(k)}</b>
              ) : (
                ''
              )}
              {typeof obj[k] === 'string' ? (
                <span>{prContent(k, obj[k])}</span>
              ) : (
                ''
              )}
              {typeof obj[k] === 'object' && obj[k] !== null ? pr(obj[k]) : ''}
            </li>
          );
        })}
      </ul>
    );
  };

  return <div className={classes['generic-structured-data']}>{pr(struc)}</div>;
};

export default GenericStructuredData;
