import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitTranslationById = ({ projectId, providerId, docs }) => {
  const data = JSON.stringify({
    project_id: projectId,
    provider: providerId,
    doc_ids: docs,
  });

  const request = axios.post(`${apiHost}/api/v1/translate/ids`, data, {
    headers: apiHeaders(),
  });

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitTranslationById;
