import React, { useState } from 'react';
import { Modal, Button, Typography, SvgIcon } from '@mui/material';
import classes from './modal.module.scss';

const CustomModal = (props) => {
  const {
    type,
    name,
    title,
    isOpen,
    handleClose,
    handleSubmit,
    disableDelete,
    content,
    buttonLabel,
    variant = 'primary',
  } = props;

  switch (variant) {
    case 'alert':
      return (
        <Modal className={classes.modal} open={isOpen} onClose={handleClose}>
          <div className={classes.modal_wrap}>
            <div className={classes.modal_content}>
              <div className={classes.modal_icon_wrap}>
                <SvgIcon className={classes.modal_icon}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                    />
                  </svg>
                </SvgIcon>
              </div>
              <div>
                <Typography className={classes.modal_title}>
                  Remove {type || 'item'}
                </Typography>
                <Typography className={classes.modal_text}>
                  Are you sure you want to remove this {type || 'item'}? All of
                  your data will be permanently removed. This action cannot be
                  undone.
                </Typography>
              </div>
            </div>
            <div className={classes.modal_controls}>
              <Button onClick={handleClose} className={classes.button}>
                Cancel
              </Button>
              {!disableDelete && (
                <Button
                  id='matomo-delete-item-btn'
                  onClick={handleSubmit}
                  className={[classes.button, classes.button_alert].join(' ')}
                >
                  Remove {name || 'item'}
                </Button>
              )}
            </div>
          </div>
        </Modal>
      );
    case 'noform':
      return (
        <Modal className={classes.modal} open={isOpen} onClose={handleClose}>
          <div className={classes.modal_wrap}>
            <div className={classes.modal_content}>
              <div className={classes.modal_inner}>
                <Typography className={classes.modal_title}>{title}</Typography>
                {content}
              </div>
            </div>
          </div>
        </Modal>
      );
    default:
      return (
        <Modal className={classes.modal} open={isOpen} onClose={handleClose}>
          <form className={classes.modal_wrap} onSubmit={handleSubmit}>
            <div className={classes.modal_content}>
              <div className={classes.modal_inner}>
                <Typography className={classes.modal_title}>{title}</Typography>
                {content}
              </div>
            </div>
            <div className={classes.modal_controls}>
              <Button onClick={handleClose} className={classes.button}>
                Cancel
              </Button>
              <Button
                type='submit'
                className={[classes.button, classes.button_primary].join(' ')}
              >
                {buttonLabel || 'Save'}
              </Button>
            </div>
          </form>
        </Modal>
      );
  }
};

export const withModalProps = (Component) =>
  // eslint-disable-next-line func-names
  function (props) {
    const [isOpen, setIsOpen] = useState();
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    return (
      <Component
        isOpen={isOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  };

export default CustomModal;
