import Button from 'components/BaseNew/Button/Button';

// eslint-disable-next-line import/no-cycle
import SearchBar from 'components/SearchBar';
import { useSelector } from 'context';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useAppSelector } from 'hooks/storeHooks';
import { selectSearchMode } from 'store/slices/ui';
import { SearchMode } from 'appSrc/constants';
import SubjectSearchBar from 'components/SearchBar/SubjectSearchBar';
import classNames from 'classnames';
import {
  BottomHeaderContainer,
  HeaderText,
  PageLabel,
  TopHeaderContainer,
} from './styled';
import FindingsLink from './FindingsLink';

const ProjectPageHeader = ({ onClickCreateNewSearch, Actions }) => {
  const project = useSelector((state) => state?.state?.project);
  const searchMode = useAppSelector(selectSearchMode);

  return (
    <div style={{ background: 'white', paddingBottom: 20 }}>
      <TopHeaderContainer>
        <div className='flex-item-1'>
          <HeaderText>SWAN Project: {project?.name}</HeaderText>
          <Actions />
        </div>
        <div className='flex-item-2'>
          {searchMode === SearchMode.SUBJECT_SEARCH ? (
            <SubjectSearchBar />
          ) : (
            <SearchBar />
          )}
        </div>
        <div className='flex-item-3'>
          <Button
            startIcon={<TuneOutlinedIcon />}
            onClick={onClickCreateNewSearch}
            className={classNames({
              hidden: searchMode === SearchMode.SUBJECT_SEARCH,
            })}
          >
            Subject search
          </Button>
        </div>
      </TopHeaderContainer>
      <BottomHeaderContainer>
        <PageLabel>
          <SearchOutlinedIcon />
          <h2>Search History</h2>
        </PageLabel>
        <FindingsLink />
      </BottomHeaderContainer>
    </div>
  );
};

export default ProjectPageHeader;
