import React from 'react';
import { useSelector } from 'context';
import Modal from './Modal';

const ModalManager = () => {
  const modal = useSelector((state) => state.modal);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return modal && <Modal {...modal} />;
};

export default ModalManager;
