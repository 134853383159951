import { useCallback } from 'react';
import { UPDATE_RETRIEVE_DOCUMENTS_UPDATED } from 'constants/actionTypes';
import { AsyncMessage } from '../types';
import useAsyncMessageHandler from '../useAsyncMessageHandler';

const RetrieveDocumentsUpdatedHandler = (articleId, dispatch) =>
  useAsyncMessageHandler(
    'retrieve.documents_updated',
    useCallback(
      (message: AsyncMessage) => {
        if (
          articleId &&
          message.attributes?.es_ids?.indexOf(articleId) !== -1
        ) {
          dispatch({
            type: UPDATE_RETRIEVE_DOCUMENTS_UPDATED,
            payload: {},
          });
        }
      },
      [articleId, dispatch]
    )
  );

export default RetrieveDocumentsUpdatedHandler;
