import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import Layout from 'layout/Layout';
import { SvgIcon } from '@mui/material';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import classes from './ErrorBoundary.module.scss';

const ErrorBoundary = () => {
  const error = useRouteError();
  const { trackEvent } = useMatomo();

  useEffect(() => {
    trackEvent({
      category: 'errors',
      action: 'error_page_shown',
      customDimensions: [{ id: 'error', value: error }],
    });
  }, [error, trackEvent]);

  return (
    <Layout variant='table' showNavActions={false} isScrollable>
      <div className={classes.wrap}>
        <article className={classes.container}>
          <header className={classes.header}>
            <h1>Oops! Something went wrong, the page could not load.</h1>

            <SvgIcon viewBox='0 0 1200 1200'>
              <path d='m1136.4 979.2-477.6-826.8c-26.398-45.602-92.398-45.602-118.8 0l-477.6 826.8c-26.398 45.602 6 103.2 58.801 103.2h955.2c54.004 0 86.406-57.598 60.004-103.2zm-552-573.6c28.801-7.1992 57.602 7.1992 70.801 33.602 4.8008 9.6016 6 20.398 4.8008 30-2.3984 30-3.6016 60-4.8008 91.199-2.3984 46.801-6 94.801-8.3984 141.6-1.1992 14.398-1.1992 28.801-2.3984 44.398-1.1992 25.199-20.398 44.398-45.602 44.398-24 0-44.398-19.199-45.602-43.199-3.6016-73.199-8.3984-146.4-12-219.6-1.1992-19.199-2.3984-39.602-3.6016-58.801 0.003906-28.801 19.203-56.398 46.801-63.598zm15.602 549.6c-33.602 0-60-27.602-60-61.199 0-33.602 27.602-61.199 61.199-61.199 33.602 0 60 27.602 60 62.398-1.1992 32.402-28.801 60-61.199 60z' />
            </SvgIcon>
          </header>

          <div className={classes.content}>
            <ol>
              <li>Try refreshing the page and see if it loads correctly.</li>
              <li>
                If the issue persists, wait a few minutes and try again. It
                might be a temporary problem.
              </li>
              <li>
                If the problem continues, contact the Digital Support Team for
                assistance. We&apos;re here to help!
              </li>
            </ol>
          </div>
        </article>
      </div>
    </Layout>
  );
};

export default ErrorBoundary;
