/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiLoadingStatus } from 'types/api';
import { IAdverseTermsSlice } from 'types/adverseTerms';
import fetchAdverseTermCategories from 'api/adverseTerms/fetchAdverseTermCategories';

export const fetchAdverseTermCategoriesThunk = createAsyncThunk(
  'adverseTerms/fetchAdverseTermCategories',
  async () => {
    return fetchAdverseTermCategories();
  }
);

export const fetchAdverseTermCategoriesExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(fetchAdverseTermCategoriesThunk.pending, (state) => {
    state.categories.status = ApiLoadingStatus.Loading;
    state.categories.error = null;
  });
  builder.addCase(
    fetchAdverseTermCategoriesThunk.fulfilled,
    (state, action) => {
      state.categories.status = ApiLoadingStatus.Succeeded;
      state.categories.data = action.payload.data;
      state.categories.error = action.payload.error;
    }
  );
  builder.addCase(fetchAdverseTermCategoriesThunk.rejected, (state, action) => {
    state.categories.status = ApiLoadingStatus.Failed;
    state.categories.error = action.error.message;
    state.categories.data = [];
  });
};
