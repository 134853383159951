import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'simplebar-react/dist/simplebar.min.css';
import { useFetchConfig } from 'hooks/useFetch/config/useFetchConfig';
import { AppProvider, useSelector } from 'context';
import { SwanRouter } from 'router';
import { FeatureEnabledProvider } from 'hooks/useIsFeatureEnabled';
import {
  AsyncMessageProvider,
  GlobalAsyncMessageHandlers,
} from 'hooks/AsyncMessages';

import './styles/globals.scss';
import { Provider } from 'react-redux';
import { store } from 'store';
import MaterialContainer from './containers/Material';

const App = () => {
  Bugsnag.start({
    plugins: [new BugsnagPluginReact()],
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
  });

  const auth = useSelector((state) => state.auth);
  const config = useSelector((state) => state.config);

  useFetchConfig(config.providers, auth.isAuthUser);

  return (
    <MaterialContainer>
      <FeatureEnabledProvider>
        <AsyncMessageProvider websocketConfig={config.websocket}>
          <GlobalAsyncMessageHandlers>
            <SwanRouter />
          </GlobalAsyncMessageHandlers>
        </AsyncMessageProvider>
      </FeatureEnabledProvider>
    </MaterialContainer>
  );
};

export const EnhancedApp = () => {
  return (
    <Provider store={store}>
      <AppProvider>
        <App />
      </AppProvider>
    </Provider>
  );
};

export default EnhancedApp;
