import { Outlet } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { useEffect } from 'react';

const Matomo = ({ user }) => {
  const instance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_URL,
    srcUrl: '/js/tracking.js', // static copy of ${urlBase}matomo.js
    siteId: process.env.REACT_APP_MATOMO_ID,
    userId: user.email,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _mtm = window._mtm || [];
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = process.env.REACT_APP_MATOMO_TAG_MANAGER_CONTAINER_URL;
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <MatomoProvider value={instance}>
      <Outlet />
    </MatomoProvider>
  );
};

export default Matomo;
