import {
  IconButton,
  Table as MUITable,
  styled,
  TableHead as MUITableHead,
  TableRow as MUITableRow,
  TableCell as MUITableCell,
} from '@mui/material';
import Button from 'components/BaseNew/Button/Button';
import DialogActions from '@mui/material/DialogActions';
import { StyledMenuItem } from 'components/BaseNew/Select/styled';

export const TitleText = styled('div')`
  font-weight: 600;
  font-size: 1.25rem;
`;

export const AlertWrapper = styled('div')`
  margin-top: 15px;
`;

export const InputLabel = styled('div')`
  font-weight: 100;
  font-size: 0.875rem;
  margin-bottom: 10px;

  display: flex;
  align-items: center;

  .required {
    color: #ff0000;
  }

  .info {
    margin-left: 5px;
    color: #526988;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  color: #f8f8f8;
`;

export const DialogueContentContainer = styled('div')`
  height: 800px;
`;

export const ProviderSelectContainer = styled('div')`
  margin-bottom: 30px;
`;

export const SubjectCounter = styled('div')`
  margin-left: 10px;
  display: inline-block;
`;

export const AddSubjectButton = styled(Button)`
  margin-left: 30px;
`;

export const SubjectTableTitle = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const DialogueActionsContainer = styled(DialogActions)`
  padding: 20px !important;
`;

export const Table = styled(MUITable)`
  min-width: 650px;
  border-radius: 5px;
  overflow: hidden;
`;

export const TableHead = styled(MUITableHead)`
  background: #f8f8f8;
  .MuiTableCell-root {
    font-weight: 100 !important;
    color: #878484;
  }
`;

export const TableRow = styled(MUITableRow)`
  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

export const TableCell = styled(MUITableCell)`
  .required {
    color: #ff0000;
  }
`;

export const DefaultMenuItem = styled(StyledMenuItem)`
  font-style: italic;
`;

export const TableActionsContainer = styled('div')`
  display: flex;
  justify-content: end;
  align-items: center;
`;
