import { LoadingButton } from 'components/BaseNew/Button/Button';
import InputLabel from 'components/BaseNew/InputLabel/InputLabel';
import StandardModal from 'components/BaseNew/StandardModal/StandardModal';
import TextField from 'components/BaseNew/TextField/TextField';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from 'api/apiClient';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import { reverse as url } from 'named-urls';
import { useModals } from 'hooks/ModalManager/useModals';
import ContentContainer from './styled';

const CreateProjectModal = () => {
  const { close } = useModals();
  const [projectName, setProjectName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleCreateProject = async () => {
    setIsLoading(true);

    const { data, error }: { data?: { id: string }; error?: unknown } =
      await apiClient({
        method: 'POST',
        endpoint: 'projects-new',
        data: { name: projectName, private: true },
      });

    setIsLoading(false);

    if (error) return;

    navigate(
      url(appRoutingPaths.projects.project.show, {
        project_id: data.id as string,
      })
    );

    close();
  };

  return (
    <StandardModal
      isOpen
      onClose={close}
      titleContent='Create New Project'
      mainContent={
        <ContentContainer>
          <InputLabel required>Name</InputLabel>
          <TextField
            value={projectName}
            onChange={handleProjectNameChange}
            placeholder='Enter project name'
            fullWidth
            size='small'
          />
        </ContentContainer>
      }
      actionContent={
        <LoadingButton
          loading={isLoading}
          onClick={handleCreateProject}
          disabled={!projectName.length}
          variant='contained'
        >
          Create Project
        </LoadingButton>
      }
    />
  );
};

export default CreateProjectModal;
