import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const fetchFindingsCounts = async (id) => {
  const request = axios.get(`${apiHost}/api/v2/projects/${id}/findings/count`, {
    headers: apiHeaders(),
  });
  return request
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default fetchFindingsCounts;
