import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useDispatch, useSelector, defaultState } from 'context';
import { useNavigate } from 'react-router-dom';
import { submitQuery } from 'hooks/useFetch';
import { find, findIndex, first, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { reverse as url } from 'named-urls';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths as routes } from 'router';
import Chip from 'components/Base/Chip';
import languagesData from 'constants/languages.json';

import { UPDATE_STATE } from 'constants/actionTypes';
import { useAppSelector } from 'hooks/storeHooks';
import { selectAdverseTerms } from 'store/slices/adverseTerms';
import Facets from '../Facets';
import classes from './search.module.scss';

function currentFacetsReducer(state, action) {
  switch (action.type) {
    case 'init':
      return action.payload;
    case 'set':
      // eslint-disable-next-line no-case-declarations
      const setArr = state.filter(
        (item) => item.providerControl !== action.payload.providerControl
      );
      setArr.push(action.payload);
      return [...setArr];
    case 'add':
      return [...state, action.payload];
    case 'change':
      // eslint-disable-next-line no-case-declarations
      const chgArr = [...state];
      chgArr.splice(findIndex(chgArr, { value: action.payload.value }), 1);
      return [...chgArr];
    case 'remove':
      // eslint-disable-next-line no-case-declarations
      const remArr = [...state];
      remArr.splice(findIndex(remArr, action.payload), 1);
      return [...chgArr];
    case 'reset':
      return state.filter(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (state) =>
          state.providerControl !== action.payload.providerControl &&
          state.providerControl !== `${action.payload.providerControl}x`
      );
    default:
      return state;
  }
}

const SearchFacets = (/* { } */) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.state.project);
  const search = useSelector((state) => state.state.search);
  const provider = useSelector((state) => state.state.provider);
  const query = useSelector((state) => state.state.query);
  const filters = useSelector((state) => state.state.filters);
  const config = useSelector((state) => state.config);
  const [currentFacets, dispatchCurrentFacets] = useReducer(
    currentFacetsReducer,
    []
  );
  const [totalActiveFacets, setTotalActiveFacets] = useState(0);
  const { trackEvent } = useMatomo();
  const [isFiltered, setIsFiltered] = useState(false);

  const { data } = useAppSelector(selectAdverseTerms);

  const terms = data.reduce(
    (acc, item) => ({
      ...acc,
      [item.category.name]: [...(acc[item.category.name] || []), item],
    }),
    {}
  );

  const adverseTerms = Object.keys(terms).map((category) => {
    return {
      label: category,
      options: sortBy(terms[category], 'language').map((item) => {
        return {
          ...item,
          label: (
            <span className={classes.term}>
              {item.name}
              <Chip
                label={item.language.code.toUpperCase()}
                size='small'
                variant='grey'
              />
            </span>
          ),
          value: item.name,
        };
      }),
      collapsible: true,
      isExpanded: query?.query_facets?.terms?.some((termId) => {
        return terms[category]
          .map((item) => item.id.toString())
          .includes(termId);
      }),
    };
  });

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log({ CURRENT_FACETS: currentFacets });
    }
  }, [currentFacets]);

  useEffect(() => {
    const n = search?.providers?.filter((p) => p.id === provider.id)?.[0]
      ?.queries?.length;
    if (n !== undefined) setIsFiltered(n > 1);
  }, [search?.providers, provider.id]);

  useEffect(() => {
    if (filters?.filter) setIsFiltered(true);
  }, [filters?.filter]);

  useEffect(() => {
    if (query?.query_facets) {
      const arr = [];
      Object.keys(query.query_facets).forEach((facet) =>
        query.query_facets[facet].forEach((item) => {
          arr.push({
            providerControl: facet,
            value: item,
            isExcluded: facet.slice(-1) === 'x',
          });
        })
      );
      setTotalActiveFacets(arr.length);
      dispatchCurrentFacets({ type: 'init', payload: arr });
    }
  }, [query]);

  useEffect(() => {
    // if is an active "local filter" do an init based on those values
    if (!isEmpty(filters?.filter)) {
      const arr = [];
      Object.keys(filters.filter).forEach((facet) =>
        filters.filter[facet].forEach((item) => {
          arr.push({
            providerControl: facet,
            value: item,
            isExcluded: facet.slice(-1) === 'x',
          });
        })
      );
      setTotalActiveFacets(arr.length);
      dispatchCurrentFacets({ type: 'init', payload: arr });
    }
  }, [filters]);

  const handleDateFilter = useCallback((startDate, endDate) => {
    dispatchCurrentFacets({
      type: 'set',
      payload: {
        providerControl: 'publication_date',
        value: `${moment(startDate).format('YYYY-MM-DD')}::${moment(
          endDate
        ).format('YYYY-MM-DD')}`,
      },
    });
  }, []);

  const handleDateFilterCancel = () => {
    const facets = find(query.facets, {
      provider_control: 'publication_date',
    });
    dispatchCurrentFacets({
      type: 'set',
      payload: {
        providerControl: 'publication_date',
        value: `${first(facets.buckets).value}-01-01::${
          first(facets.buckets).value
        }-01-01`,
      },
    });
  };

  const handleChange = (facet, val) => {
    dispatchCurrentFacets({
      type: 'change',
      payload: {
        providerControl: facet,
        value: val,
      },
    });
  };

  const handleSet = (facet, val) => {
    dispatchCurrentFacets({
      type: 'set',
      payload: {
        providerControl: facet,
        value: val,
      },
    });
  };

  const handleInclude = (facet, val) => {
    dispatchCurrentFacets({
      type: 'add',
      payload: {
        providerControl: facet,
        value: val,
      },
    });
  };

  const handleExclude = (facet, val) => {
    dispatchCurrentFacets({
      type: 'add',
      payload: {
        providerControl: `${facet}x`,
        value: val,
        isExcluded: true,
      },
    });
  };

  const handleRemoveExclude = (facet, val) => {
    dispatchCurrentFacets({
      type: 'remove',
      payload: {
        providerControl: `${facet}x`,
        value: val,
      },
    });
  };

  const handleReset = (control) => {
    dispatchCurrentFacets({
      type: 'reset',
      payload: {
        providerControl: control,
      },
    });
  };

  // Local Filter (querying local Elastic database)
  const handleFilter = (facetVals) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        // ...defaultState ?
        page: 1,
        translate: { items: false, article: false },
        article: { loading: true },
        data: { items: [], loadedItems: 0, loading: true },
        filters: {
          ...filters,
          filter: facetVals,
          changed: Math.random(),
        },
        expecting: { items: true, article: true },
        retrieving: false,
      },
    });
  };

  const handleSubmit = () => {
    trackEvent({
      category: 'search',
      action: 'apply_filter',
    });
    const facetsObject = {};
    // eslint-disable-next-line no-return-assign
    currentFacets.forEach((currentFacet) =>
      facetsObject[currentFacet.providerControl]
        ? facetsObject[currentFacet.providerControl].push(currentFacet.value)
        : (facetsObject[currentFacet.providerControl] = [currentFacet.value])
    );

    // TODO - tidy up, this is "if (use local filter) handleFilter() else submitQuery()"
    if (find(config.providers, { id: provider.id })?.dispatch?.local_filter) {
      console.log('handleFilter call', facetsObject);
      handleFilter(facetsObject);
      return;
    }
    submitQuery(
      project.id,
      search.id,
      provider.id,
      query.query_string,
      facetsObject
    ).then((res) => {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          ...defaultState,
          filters: {
            ...filters,
            filter: facetsObject,
          },
          queryId: res?.query_id,
        },
      });
      navigate(
        url(routes.projects.project.searches.search.provider.query.show, {
          project_id: project.id,
          search_id: search.id,
          provider_id: provider.id,
          query_id: res?.query_id,
        })
      );
    });
  };

  const renderCheckboxFacet = (facet) => {
    return {
      filter: 'checkbox',
      control: facet.provider_control,
      name: facet.name,
      options: facet.buckets.map((item) => ({
        ...item,
        label: item.display,
      })),
      selected: currentFacets,
      isSelected: (item) =>
        !!find(currentFacets, {
          providerControl: facet.provider_control,
          value: item.value,
        }),
      handleChange: (e) => {
        if (e.target.checked) {
          handleInclude(facet.provider_control, e.target.value);
        } else {
          handleChange(facet.provider_control, e.target.value);
        }
      },
    };
  };

  const facets = filters?.facets?.length
    ? filters.facets.map((facet) => {
        if (facet.buckets === null) {
          // eslint-disable-next-line no-param-reassign
          facet.buckets = [];
        }
        switch (facet.provider_control) {
          case 'publication_date':
            return {
              filter: 'date',
              control: facet.provider_control,
              name: facet.name,
              options: facet.buckets,
              handleDateFilter,
              handleDateFilterCancel,
            };
          case 'terms':
            return {
              filter: 'tree',
              name: 'Adverse Terms',
              control: 'terms',
              options: adverseTerms,
              selected: currentFacets,
              isSelected: (sel) => {
                return currentFacets.some(
                  (item) => item.value === sel.toString()
                );
              },
              handleChange: (e) => {
                if (e.target.checked) {
                  handleSet('terms', e.target.value);
                } else {
                  handleChange('terms', e.target.value);
                }
              },
              className: classes['facets--terms'],
            };
          case 'language':
            return {
              filter: 'checkbox',
              control: facet.provider_control,
              name: facet.name,
              options: languagesData
                .filter((item) => item.common || false)
                .map((lang) => {
                  return {
                    id: lang.iso2,
                    value: lang.iso2,
                    label: lang.english_name,
                  };
                }),
              selected: currentFacets,
              isSelected: (sel) => {
                return currentFacets.some((item) => item.value === sel.id);
              },
              handleChange: (e) => {
                if (e.target.checked) {
                  handleInclude(facet.provider_control, e.target.name);
                } else {
                  handleChange(facet.provider_control, e.target.name);
                }
              },
              handleInclude,
              handleReset,
              className: classes['facets--language'],
            };
          case 'tenure':
            return renderCheckboxFacet(facet);
          case 'multiple_address_indicator':
            return renderCheckboxFacet(facet);
          case 'additional_proprietor_indicator':
            return renderCheckboxFacet(facet);
          default:
            return {
              filter: 'include-exclude',
              control: facet.provider_control,
              name: facet.name,
              options: facet.buckets,
              selected: currentFacets,
              handleChange,
              handleInclude,
              handleExclude,
              handleRemoveExclude,
              handleReset,
            };
        }
      })
    : null;

  return (
    <Facets
      handleSubmit={handleSubmit}
      disabled={
        !handleFilter && !currentFacets.length > 0 && !totalActiveFacets > 0
      }
      items={facets}
      isFiltered={isFiltered}
    />
  );
};

export default SearchFacets;
