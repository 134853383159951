import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitArticleView = (projectId, docId, tags) => {
  const request = axios.put(
    `${apiHost}/api/v1/projects/${projectId}/items/${docId}/viewed`,
    {},
    {
      headers: apiHeaders(),
    }
  );

  return request.catch((err) => {
    store.dispatch(fetchFailure(err));
  });
};

export default submitArticleView;
