import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitItemFetch = async (
  project_id,
  provider_item_id,
  provider_id
) => {
  const data = JSON.stringify({
    for: 'items',
    ids: [provider_item_id],
    provider: provider_id,
  });

  const postUrl = `${apiHost}/api/v1/projects/${project_id}/fetches`;
  await axios
    .post(postUrl, data, {
      headers: apiHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitItemFetch;
