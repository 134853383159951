import React from 'react';

export const Row = ({ label, value, className }) => (
  <div className={className}>
    <span>{label}</span>
    <span>{value || '-'}</span>
  </div>
);

export default Row;
