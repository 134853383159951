/**
 * Breaks out the hasFetched boolean from the given JSON.
 * @param {string} worldcheckContentJSON
 * @returns {boolean} for whether the worldcheck document has been fetched
 */
export const worldCheckGetHasFetched = (worldcheckContentJSON) => {
  try {
    const content = JSON.parse(worldcheckContentJSON);
    if (content.hasFetched === undefined) {
      return false;
    }
    return content.hasFetched;
  } catch (err) {
    return false;
  }
};

export default worldCheckGetHasFetched;
