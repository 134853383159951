import { useCallback } from 'react';
import { FETCH_FAILURE_RETRIEVE } from 'constants/actionTypes';
import { AsyncMessage } from '../types';
import useMultiAsyncMessageHandler from '../useMultiAsyncMessageHandler';

const RetrieveErrorHandler = (articleId, dispatch) =>
  useMultiAsyncMessageHandler(
    ['retrieve.processing.error', 'retrieve.error'],
    useCallback(
      (message: AsyncMessage) => {
        if (
          articleId &&
          message.attributes?.es_ids?.indexOf(articleId) !== -1
        ) {
          dispatch({
            type: FETCH_FAILURE_RETRIEVE,
            payload: {
              error: {
                message:
                  'No content could be retrieved. Please follow the link to read the article on the source website instead.',
                detail: message?.attributes?.message,
              },
            },
          });
        }
      },
      [articleId, dispatch]
    )
  );

export default RetrieveErrorHandler;
