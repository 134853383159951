/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiLoadingStatus } from 'types/api';
import { IAdverseTermsSlice } from 'types/adverseTerms';
import fetchFavouriteAdverseTerms from 'api/adverseTerms/fetchFavouriteAdverseTerms';

export const fetchFavouriteAdverseTermsThunk = createAsyncThunk(
  'adverseTerms/fetchFavouriteAdverseTerms',
  async () => {
    return fetchFavouriteAdverseTerms();
  }
);

export const fetchFavouriteAdverseTermsExtraReducers = (
  builder: ActionReducerMapBuilder<IAdverseTermsSlice>
) => {
  builder.addCase(fetchFavouriteAdverseTermsThunk.pending, (state) => {
    state.favourites.status = ApiLoadingStatus.Loading;
    state.favourites.error = null;
  });
  builder.addCase(
    fetchFavouriteAdverseTermsThunk.fulfilled,
    (state, action) => {
      state.favourites.status = ApiLoadingStatus.Succeeded;
      state.favourites.data = action.payload.data;
      state.favourites.error = action.payload.error;
    }
  );
  builder.addCase(fetchFavouriteAdverseTermsThunk.rejected, (state, action) => {
    state.favourites.status = ApiLoadingStatus.Failed;
    state.favourites.error = action.error.message;
    state.favourites.data = [];
  });
};
