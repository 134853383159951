import { Table } from '../../Table';
import classes from '../../../article.module.scss';

export const WatchlistSection = ({ mappedSources }) =>
  mappedSources ? (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Watchlist</h4>
      <Table
        headCells={['Abbreviation', 'Watchlist Name']}
        rows={mappedSources.map((mappedSource) => {
          const { abbreviation, name } = mappedSource;
          return [abbreviation, name];
        })}
      />
    </div>
  ) : null;

export default WatchlistSection;
