import Article from '../Article';
import classes from './search-related-item.module.scss';

const SearchRelatedItem = () => (
  <div className={classes.container}>
    <div className={classes.inner}>
      <Article />
    </div>
  </div>
);

export default SearchRelatedItem;
