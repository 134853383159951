import { Checkbox } from '@mui/material';
import classes from '../projects-table.module.scss';

const MyProjectsFilter = ({ user, owner, onChange }) => {
  const userIsOwner = owner.owner === user.email;

  const handleMyProjectsFilter = () => {
    onChange({
      owner: {
        owner: userIsOwner ? '' : user.email,
        owner_name: userIsOwner ? '' : user.name,
      },
      ownerActive: !userIsOwner,
      page: 0,
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classes['my-projects-filter']}
      size='small'
      onClick={handleMyProjectsFilter}
    >
      <Checkbox checked={userIsOwner} />
      Only my projects
    </div>
  );
};

export default MyProjectsFilter;
