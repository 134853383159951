/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeView from 'components/Base/Treeview';
import classNames from 'classnames';
import classes from '../facets.module.scss';

const TreeFacet = ({ className, name, options, isSelected, handleChange }) => {
  return (
    <div className={classNames(classes.facets, className)}>
      <Accordion className={classes.accordion} defaultExpanded>
        <AccordionSummary
          className={classes['accordion-summary']}
          expandIcon={<ExpandMoreIcon />}
          children={
            <FormLabel className={classes.heading} component='legend'>
              {name}
            </FormLabel>
          }
        />
        <AccordionDetails className={classes['accordion-details']}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {options.map((item) => {
              return (
                <TreeView
                  key={item.label}
                  label={item.label}
                  handleChange={handleChange}
                  isSelected={isSelected}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...item}
                />
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TreeFacet;
