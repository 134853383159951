import styled from '@emotion/styled';
import { TextField as MUITextField } from '@mui/material';

const StyledTextField = styled(MUITextField)`
  .MuiInputBase-root.Mui-focused {
    fieldset {
      border-color: #526988;
    }
  }
`;

export default StyledTextField;
