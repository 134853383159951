import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import { fetchFindingQueryItems } from 'hooks/useFetch';

import {
  SkeletonExportArticle,
  FullExportArticle,
} from 'components/Export/ExportArticle';
import { serialiseData } from 'hooks/useSerialiser';

const ExportFindings = () => {
  const [items, setItems] = useState([]);
  const { project_id: projectId } = useParams();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page');
  const sort = new URLSearchParams(location.search).get('sort');
  const dateFrom = new URLSearchParams(location.search).get('from');
  const dateTo = new URLSearchParams(location.search).get('to');
  const tags = new URLSearchParams(location.search).get('tags');
  const sources = new URLSearchParams(location.search).get('sources');
  const searches = new URLSearchParams(location.search).get('searches');

  useEffect(() => {
    const date = [];
    if (dateFrom && !Number.isNaN(Date.parse(dateFrom))) {
      date.push(dateFrom);
      if (dateTo && !Number.isNaN(Date.parse(dateTo))) {
        date.push(dateTo);
      }
    }
    fetchFindingQueryItems({
      projectId,
      sort: sort || '',
      page,
      date,
      tags: tags || [],
      sources: sources || [],
      searches: searches || [],
      full: true,
    }).then((res) => {
      if (!res) {
        // TODO - handle error
        return;
      }
      if (res?.hits?.hits) setItems(res.hits.hits);
    });
  }, [dateFrom, dateTo, page, projectId, searches, sort, sources, tags]);

  return (
    <div className='print-theme'>
      {isEmpty(items) ? (
        <SkeletonExportArticle />
      ) : (
        items.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <FullExportArticle key={i} item={serialiseData({ item })} />
        ))
      )}
    </div>
  );
};

export default ExportFindings;
