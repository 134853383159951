/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from 'components/Base/Button/IconButton';
import Autocomplete from 'components/Base/Fields/Autocomplete';
import Menu, { MenuList } from 'components/Base/Menu';
import InputText from 'components/Base/Fields/InputText';
import Spinner from 'components/Base/Spinner';
import {
  editProject,
  editProjectPrivacy,
  deleteProject,
  fetchUsers,
  createUsersShared,
  fetchUsersShared,
  deleteUsersShared,
} from 'hooks/useFetch';
import Toggle from 'components/Base/Fields/Toggle';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
} from '@mui/material';
import SimpleBar from 'simplebar-react';
import { downloadSearches } from 'hooks/useFetch/searches/downloadSearches';
import { makeStyles } from '../../containers/Material';
import classes from './project-actions.module.scss';
import Modal from '../Modal/Modal';

const useStyles = makeStyles({
  shareContent: {
    minHeight: '165px',
  },

  shareLoading: {
    paddingTop: '50px',
  },

  shareDtls: {
    paddingBottom: '10px',
  },

  shareListContainer: {
    overflowX: 'hidden',
    minHeight: '1px',
    maxHeight: '200px',
    minWidth: '100%',
    maxWidth: '100%',
  },

  shareListName: {
    paddingLeft: 0,
  },

  shareListCtrls: {
    textAlign: 'right',
    paddingRight: '1em',
  },

  shareCtrl: {
    marginTop: '10px',
    marginBottom: '16px',
  },

  modalText: {
    fontSize: '14px',
    marginBottom: '4px',
  },

  button: {
    height: '2rem',
    minWidth: '2rem',
    textTransform: 'none',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    padding: '10px 12px',
    lineHeight: '1',
    fontSize: '14px',
  },

  buttonPrimary: {
    color: '#fff',
    border: 'none',
    width: '100%',
    marginTop: '0.4rem',
    backgroundColor: '#2389e4',
    '&:hover': {
      backgroundColor: '#0f68b7',
    },
  },
});

const EditModal = (props) => {
  const {
    project,
    setProject,
    pageTitle,
    setPageTitle,
    isProjectPrivate,
    isOpen,
    handleClose,
  } = props;
  const [name, setName] = useState(pageTitle);
  const [isPrivate, setIsPrivate] = useState(false);

  const handlePrivacy = () => {
    setIsPrivate(!isPrivate);
  };

  useEffect(() => {
    if (!name) setName(pageTitle);
  }, [name, pageTitle]);

  useEffect(() => {
    if (project.id) {
      if (!isProjectPrivate) setIsPrivate(project.private);
      if (isProjectPrivate) setIsPrivate(isProjectPrivate);
      setPageTitle(project.name);
    }
  }, [project, isProjectPrivate, setProject, setPageTitle]);

  const handleEditProject = (e) => {
    e.preventDefault();
    if (name.length > 1) {
      setProject({ ...project, name, private: isPrivate });
      setPageTitle(name);
      if (name !== project.name) editProject(project.id, name);
      if (isPrivate !== project.private)
        editProjectPrivacy(project.id, isPrivate);
    }
    handleClose(); // no re-render if value not changing so ensure closes
  };

  return (
    <Modal
      title='Edit project'
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleEditProject}
      content={
        <>
          <InputText
            label='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Toggle
            label='Privacy'
            toggleLabel='Mark project as private'
            checked={isPrivate}
            onClick={handlePrivacy}
          />
        </>
      }
    />
  );
};

const DeleteModal = (props) => {
  const navigate = useNavigate();
  const { project, pageTitle, isOpen, handleClose } = props;

  const removeProject = () => {
    deleteProject(project.id).then(() => {
      handleClose();
      navigate('/projects');
    });
  };

  return (
    <Modal
      variant='alert'
      name={pageTitle}
      type='project'
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={removeProject}
    />
  );
};

const ShareModal = (props) => {
  const { project, isOpen, handleClose, currentUserId } = props;
  const [usersFilterOptions, setUsersFilterOptions] = useState([]);
  const [usersShared, setUsersShared] = useState([]);
  const [shareUser, setShareUser] = useState({ id: '', name: '' });
  const [readyOpts, setReadyOpts] = useState(false);
  const [readyList, setReadyList] = useState(false);
  const classes = useStyles();

  const fetchUsersCallback = useCallback(() => {
    fetchUsers().then((users) => {
      if (users?.length)
        setUsersFilterOptions(
          users.filter((user) => user?.id !== currentUserId)
        );
    });
  }, [currentUserId]);

  const fetchUsersSharedCallback = useCallback(() => {
    fetchUsersShared(project.id).then((users) => {
      if (users?.length) setUsersShared(users);
      setReadyList(true);
    });
  }, [project.id]);

  const unshareUsersCallback = useCallback(
    (projectId, userId) => {
      setUsersShared(usersShared.filter((user) => user.id !== userId));
      deleteUsersShared(projectId, userId).then(() => {
        fetchUsersSharedCallback();
      });
    },
    [usersShared, fetchUsersSharedCallback]
  );

  useEffect(() => {
    if (usersFilterOptions.length) setReadyOpts(true);
  }, [usersFilterOptions?.length]);

  useEffect(() => {
    if (!isOpen || usersFilterOptions.length) return;
    fetchUsersCallback();
  }, [isOpen, usersFilterOptions?.length, fetchUsersCallback]);

  useEffect(() => {
    if (!isOpen || !project?.id) return;
    fetchUsersSharedCallback();
  }, [isOpen, project.id, fetchUsersSharedCallback]);

  const handleShareProject = () => {
    if (shareUser?.id && project?.id) {
      const shareUserId = shareUser.id;
      setReadyList(false);
      setShareUser({ id: '', name: '' });
      createUsersShared(project.id, shareUserId).then((r) =>
        fetchUsersSharedCallback()
      );
    }
  };

  const handleUnshareProject = (projectId, userId) => {
    unshareUsersCallback(projectId, userId);
  };

  const handleUsersFilter = (e, val) => {
    setShareUser(val || { id: '', name: '' });
  };

  return (
    <Modal
      title='Share project'
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleShareProject}
      variant='noform'
      content={
        !readyOpts || !readyList ? (
          <div className={classes.shareContent}>
            <div className={classes.shareLoading}>
              <Spinner />
            </div>
          </div>
        ) : (
          <div className={classes.shareContent}>
            <div className={classes.shareCtrl}>
              <Autocomplete
                InputProps={{
                  placeholder: 'Start typing a name…',
                }}
                limitTags={10}
                onChange={handleUsersFilter}
                options={usersFilterOptions}
                getOptionLabel={(option) => option?.name || ''}
              />
              {shareUser?.id && (
                <Button
                  id='share-project-btn'
                  onClick={handleShareProject}
                  className={[classes.button, classes.buttonPrimary].join(' ')}
                >
                  Share
                </Button>
              )}
            </div>
            <div className={classes.shareDtls}>
              {!usersShared.length ? (
                <Typography className={classes.modalText}>
                  This project is not shared with other users.
                </Typography>
              ) : (
                <>
                  <Typography className={classes.modalText}>
                    {usersShared.length === 1
                      ? 'Currently shared one user:'
                      : `Currently shared with ${usersShared.length} users:`}
                  </Typography>
                  <SimpleBar className={classes.shareListContainer}>
                    <Table size='small'>
                      <TableBody>
                        {usersShared.map((user, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <TableRow key={i}>
                            <TableCell className={classes.shareListName}>
                              {user.name}
                            </TableCell>
                            <TableCell className={classes.shareListCtrls}>
                              <IconButton
                                onClick={() =>
                                  handleUnshareProject(project.id, user.id)
                                }
                                variant='delete'
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </SimpleBar>
                </>
              )}
            </div>
          </div>
        )
      }
    />
  );
};

const ProjectActions = (props) => {
  const { project } = props;
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const downloadExport = () => {
    downloadSearches(project.id).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${project.name
          .toLowerCase()
          .replace(' ', '-')}-searches-${Date.now()}.csv`
      ); // or any other extension
      link.click();
    });
  };

  return (
    <>
      <IconButton
        onClick={handleToggle}
        anchorRef={anchorRef}
        className={classes['icon-button']}
      />

      <IconButton
        id='matomo-download-searches'
        onClick={downloadExport}
        anchorRef={anchorRef}
        className={classes['icon-button']}
        variant='download'
      />

      <Menu
        open={open}
        anchorRef={anchorRef}
        handleClose={handleClose}
        role={undefined}
        transition
        disablePortal
      >
        <MenuList
          options={[
            { label: 'Edit project', onClick: handleEdit },
            { label: 'Share project', onClick: handleShare },
            { label: 'Delete project', onClick: handleDelete },
          ]}
        />
      </Menu>
      <EditModal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        isOpen={showEditModal}
        handleClose={() => setShowEditModal(false)}
      />
      <ShareModal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        isOpen={showShareModal}
        handleClose={() => setShowShareModal(false)}
      />
      <DeleteModal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        isOpen={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default ProjectActions;
