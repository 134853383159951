// This should only be used for UI changes. ALl privileges must be checked on the

import { IUser } from 'appSrc/types/user';

// server side as this value could be changed by the user.
export const isAdmin = (user: IUser) => {
  return user.roles && user.roles.length > 0 && user.roles.includes('ADMIN');
};

export default isAdmin;
