import React from 'react';
import Spinner from 'components/Base/Spinner';
import { useSelector } from 'context';
import {
  calculateProviderStatus,
  ProviderStatus as Status,
} from 'helpers/providers/status';
import classNames from 'classnames';
// eslint-disable-next-line import/no-cycle
import SearchFacets from '../SearchFacets';
import SearchResults from '../SearchResults';
import SearchArticle from '../Article';
import classes from './search-results-grid.module.scss';

const SearchResultsGrid = ({ ...props }) => {
  const provider = useSelector((state) => state.state.provider);
  const search = useSelector((state) => state.state.search);

  const getChildren = () => {
    if (!provider) {
      return <Spinner />;
    }

    const isProviderGroup = search.providers.some(
      (searchProvider) =>
        searchProvider.id.startsWith(provider.id) &&
        searchProvider.id !== provider.id
    );
    const providerStatus = isProviderGroup
      ? calculateProviderStatus(search.providers)
      : calculateProviderStatus([provider]);

    switch (providerStatus) {
      case Status.NONE:
      case Status.LOADING:
        return <Spinner />;

      default:
        return (
          <>
            <SearchFacets
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
            <SearchResults
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
            {/* @ts-ignore */}
            <SearchArticle
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          </>
        );
    }
  };

  return (
    <div
      className={classNames(classes.container, {
        [classes['news-container']]: provider.id.startsWith('news'),
      })}
    >
      {getChildren()}
    </div>
  );
};

export default SearchResultsGrid;
