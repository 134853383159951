/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { find } from 'lodash';
import Chip from 'components/Base/Chip';
import { useHighlighter } from 'hooks/useHighlighter';
import { FindInPage } from '@mui/icons-material';
import languagesData from 'constants/languages.json';
import classNames from 'classnames';
import IconAngleDown from 'components/Base/Icons/IconAngleDown';
import IconAngleRight from 'components/Base/Icons/IconAngleRight';
import classes from './results.module.scss';
import { SayariResultsItem } from './SayariResultsItem';
import { TagsList } from './TagsList';
import { ResultsItemHeader } from './ResultsItemHeader';
import { ResultsItemSubtitle } from './ResultsItemSubtitle';
import { WorldcheckResultsItem } from './WorldcheckResultsItem';
import CombinedComplianceResultsItem from './CombinedComplianceResultsItem';

const Original = ({ title, text }) => {
  return (
    <div className={classes.original}>
      <Typography className={classes['item-title']}>{title}</Typography>
      <Typography className={classNames(classes['item-text'])}>
        {text}
      </Typography>
    </div>
  );
};

const ResultsItem = ({
  id,
  title,
  titleTags,
  translatedTitle,
  text,
  textTags,
  translatedText,
  language,
  date,
  category,
  tags,
  articleId,
  setArticle,
  viewed,
  currentUser,
  queryString,
  duplicates,
  translate,
  style,
  registerChild,
  measure,
  provider,
  providerLabel,
  isFindings,
  isCombinedList,
}) => {
  const isSelected = articleId === id;
  const isTranslated = translate.items && translatedTitle && language !== 'en';
  const tagsArr = tags.filter((tag) => tag !== 'Finding');
  const viewedByCurrentUser = viewed?.some((user) => user.user === currentUser);
  const [showOriginal, setShowOriginal] = useState(false);
  const variant = provider.split('.')[0];
  const displayTitle =
    isTranslated && translatedTitle ? translatedTitle : title;

  const renderContent = () => {
    switch (provider) {
      case 'compliance.dowjones':
      case 'compliance.worldcompliance':
        return (
          <>
            <ResultsItemHeader
              id={id}
              title={displayTitle}
              queryString={queryString}
              isSaved={tags.includes('Finding')}
              viewed={viewed}
            />

            <div className={classes['item-details--compliance']}>
              <ResultsItemSubtitle highlight={text} tags={titleTags} />

              {textTags ? (
                <span className={classes['item-text-tags']}>
                  {textTags.map((tag) => (
                    <Chip
                      key={`item-text-tag--${tag}`}
                      label={tag}
                      size='small'
                      classes={{ root: classes.chip }}
                    />
                  ))}
                </span>
              ) : (
                ''
              )}
              <div style={{ display: 'flex', width: '100%' }}>
                {category && (
                  <div className={classes['item-source']}>{category}</div>
                )}
                {tagsArr && <TagsList tags={tagsArr} />}
              </div>
            </div>
          </>
        );
      case 'compliance.worldcheck':
        return (
          <WorldcheckResultsItem
            id={id}
            title={displayTitle}
            titleTags={titleTags}
            text={text}
            textTags={textTags}
            category={category}
            viewed={viewed}
            queryString={queryString}
            tags={tags}
          />
        );
      case 'compliance.combined':
        return (
          <CombinedComplianceResultsItem
            id={id}
            title={displayTitle}
            titleTags={titleTags}
            text={text}
            textTags={textTags}
            viewed={viewed}
            queryString={queryString}
            tags={tags}
          />
        );
      case 'corporate_records.sayari':
        return (
          <SayariResultsItem
            id={id}
            title={displayTitle}
            titleTags={titleTags}
            text={text}
            textTags={textTags}
            category={category}
            viewed={viewed}
            queryString={queryString}
            tags={tags}
          />
        );
      default:
        return (
          <>
            <ResultsItemHeader
              id={id}
              title={displayTitle}
              queryString={queryString}
              isSaved={tags.includes('Finding')}
              viewed={viewed}
            />

            <Typography
              className={classes['item-text']}
              dangerouslySetInnerHTML={{
                __html: useHighlighter({
                  query: queryString,
                  content:
                    translate.items && translatedText ? translatedText : text,
                }),
              }}
            />
            {isTranslated && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={classes.translated}
                onClick={() => {
                  setShowOriginal(!showOriginal);
                }}
              >
                {!showOriginal ? <IconAngleRight /> : <IconAngleDown />}
                Translated from{' '}
                {find(languagesData, { iso2: language }).english_name}
              </div>
            )}
            {showOriginal && <Original title={title} text={text} />}
            <div className={classes['item-details']}>
              <div style={{ display: 'flex' }}>
                <Typography className={classes['item-text']}>
                  {variant === 'records' ? (
                    `Date retrieved: ${date}`
                  ) : (
                    <>
                      {date} {date && category && '–'}
                      <span style={{ fontWeight: 600 }}>
                        &nbsp;{category}
                      </span>{' '}
                    </>
                  )}
                </Typography>{' '}
                {duplicates > 0 && (
                  <Typography className={classes['item-text']}>
                    &nbsp;{' '}
                    {`+ ${duplicates} duplicate${duplicates > 1 ? 's' : ''}`}{' '}
                  </Typography>
                )}
              </div>
              {tagsArr && <TagsList tags={tagsArr} />}
            </div>
          </>
        );
    }
  };

  return (
    <Card
      className={classNames(classes.item, {
        [classes['item--read']]: viewedByCurrentUser,
        [classes['item--selected']]: isSelected,
      })}
      elevation={0}
      style={style}
      ref={registerChild}
      onLoad={measure}
    >
      <div
        className={classes.button}
        onClick={(e) => {
          e.preventDefault();
          setArticle(id);
        }}
      >
        <CardContent
          classes={{
            root:
              classes['item-content'] +
              (isFindings ? ` ${classes[`item-content--${variant}`]}` : ''),
          }}
        >
          {renderContent()}
          {isFindings && (
            <small className={classes['item--provider']}>
              <FindInPage fontSize='inherit' /> {providerLabel || provider}
            </small>
          )}
          {isCombinedList && (
            <div className={classes['item--provider-label']}>
              <small>{providerLabel}</small>
            </div>
          )}
        </CardContent>
      </div>
    </Card>
  );
};

export default ResultsItem;
