import parseContentToStructuredData from '../../utils/parseContentToStructuredData';
import DateOfBirth from './DateOfBirth';
import ProviderRecordLinks from './ProviderRecordLinks';
import StandardSchemaSection from './StandardSchema';

const CombinedComplianceStructuredData = ({
  data,
  showEmpty,
  handleLinkRelated,
  handleArticleNavigate,
  ...props
}) => {
  const structuredData = parseContentToStructuredData(data.contentJSON);
  const { srm_schema: schemaData, items } = structuredData;
  const { type, name, countries } = schemaData;
  return (
    <>
      <StandardSchemaSection
        entityType={type}
        name={name}
        countries={countries}
      />

      {(type === 'Individual' || showEmpty) && (
        <DateOfBirth
          datesOfBirth={schemaData.individual_attributes?.dates_of_birth || []}
        />
      )}

      <ProviderRecordLinks
        records={items}
        handleArticleNavigate={handleArticleNavigate}
      />
    </>
  );
};

export default CombinedComplianceStructuredData;
