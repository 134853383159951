import { useCallback } from 'react';
import { find } from 'lodash';
import { fetchRelatedItems } from 'hooks/useFetch';
import { UPDATE_RELATED_ITEMS, UPDATE_STATE } from 'constants/actionTypes';
import useAsyncMessageHandler from '../useAsyncMessageHandler';
import { AsyncMessage } from '../types';

const getFetchRelatedItems = async (projectId, searchId) => {
  const fetchRelatedItemsResult = await fetchRelatedItems(projectId, searchId);
  if (!fetchRelatedItemsResult) {
    throw new Error('fetch related items result void');
  }

  const items = fetchRelatedItemsResult.data.map((item) => {
    return {
      id: item.id,
      label: item.label,
      relatedUrl: item.related_url,
    };
  });

  console.log(items);
  return items;
};

const RelatedPageInsertedHandler = (
  projectId: string,
  searchId: string,
  relatedItems,
  dispatch
) => {
  useAsyncMessageHandler(
    'related.page_inserted',
    useCallback(
      async (message: AsyncMessage) => {
        console.log(message);
        if (
          !find(relatedItems, {
            relatedUrl: message.attributes?.related_urls[0],
          })
        ) {
          return;
        }
        try {
          const items = await getFetchRelatedItems(projectId, searchId);
          dispatch({
            type: UPDATE_RELATED_ITEMS,
            payload: {
              items,
            },
          });
        } catch (err) {
          dispatch({
            type: UPDATE_STATE,
            payload: {
              relatedItems: [],
            },
          });
        }
      },
      [projectId, searchId, relatedItems, dispatch]
    )
  );
};

export default RelatedPageInsertedHandler;
