import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow as MuiTableRow,
  Skeleton,
} from '@mui/material';

import classNames from 'classnames';
import classes from './table.module.scss';

type TableProps = {
  headCells: any[];
  rows: any[];
  page?: number;
  paginate?: boolean;
  rowsPerPage?: number;
  rowsPerPageOptions?: any[];
  rowHeight?: number;
  loading?: boolean;
  count?: number;
  onChangePage?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  className?: string;
};

type TableRowProps = {
  children: JSX.Element[];
  variant?: string;
  animate?: boolean;
  className?: string;
  onClick?: (any) => void;
};

type TableCellProps = {
  children: string | JSX.Element;
  variant?: string;
  size?: string;
  className?: string;
};

const Table = ({
  headCells,
  rows,
  page,
  paginate = true,
  rowsPerPage = 10,
  rowsPerPageOptions = [],
  rowHeight = 56,
  loading,
  count,
  onChangePage,
  className,
}: TableProps) => {
  return (
    <div>
      <TableContainer>
        <MuiTable className={classNames(classes.container, className)}>
          <TableHead>
            <MuiTableRow>
              {headCells.map((cell, i) => (
                <MuiTableCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${cell}-${i}`}
                  classes={{
                    root: classNames(classes.label, cell.parentClassName),
                  }}
                >
                  {cell.component || cell}
                </MuiTableCell>
              ))}
            </MuiTableRow>
          </TableHead>
          <TableBody className={classNames(classes['table-body'])}>
            {loading && rows.length === 0
              ? // @ts-ignore
                [...new Array(rowsPerPage).keys()].map((row) => (
                  <TableRow key={`skeleton-${row}`} animate={false}>
                    {headCells.map((_, col) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <TableCell key={`skeleton-col-${col}-row-${row}`}>
                        <Skeleton style={{ width: '60%', height: '24px' }} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : rows}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {paginate && (
        // @ts-ignore
        <TablePagination
          component='div'
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          labelDisplayedRows={({ from, to, count }) => {
            return count ? `${from}-${to} of ${count}` : `No results`;
          }}
          onPageChange={onChangePage}
          classes={{ actions: classes.pagination }}
        />
      )}
    </div>
  );
};

export const TableRow = ({
  children,
  variant,
  animate = true,
  className,
  onClick,
}: TableRowProps) => {
  return (
    <MuiTableRow
      onClick={onClick}
      className={classNames(
        classes.row,
        {
          [classes[`row--${variant}`]]: variant,
          [classes['row--animate']]: animate,
          [classes['row--clickable']]: onClick,
        },
        className
      )}
    >
      {children}
    </MuiTableRow>
  );
};

export const TableCell = ({
  children,
  size,
  variant,
  className,
}: TableCellProps) => {
  return (
    <MuiTableCell
      className={classNames(
        classes.cell,
        {
          [classes[`cell--${size}`]]: size,
          [classes[`cell--${variant}`]]: variant,
        },
        className
      )}
    >
      {children}
    </MuiTableCell>
  );
};

export const TableCellActions = ({ children }) => {
  return <div className={classes.actions}>{children}</div>;
};

export default Table;
