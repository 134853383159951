import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitAllTags = (projectId, docId, tags) => {
  const data = JSON.stringify({
    tags: tags.map((tag) => {
      return { tag };
    }),
  });

  const request = axios.put(
    `${apiHost}/api/v1/projects/${projectId}/items/${docId}/tags`,
    data,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitAllTags;
