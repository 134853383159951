import { Checkbox as MUICheckbox, styled } from '@mui/material';

export const StyledCheckbox = styled(MUICheckbox)`
  &.MuiButtonBase-root {
    &.Mui-checked {
      color: #526988;
    }
  }
`;

export default StyledCheckbox;
