import {
  AsyncMessage,
  useAsyncMessageFilter,
  useMultiAsyncMessageHandler,
} from 'hooks/AsyncMessages';
import fetchSearch from 'hooks/useFetch/searches/fetchSearch';
import { useCallback } from 'react';

const SearchHistoryTableAsyncMessageHandler = (
  projectId: string,
  searches,
  setSearches
) => {
  useAsyncMessageFilter((message: AsyncMessage) => {
    return [
      'search.created',
      'search.noresults',
      'search.results_received',
      'search.error',
    ].includes(message.status);
  });

  const handlerCallback = useCallback(
    (message) => {
      if (message?.attributes?.project !== projectId) {
        // check if the project is the same as the current one
        return;
      }
      // All accepted events have these attributes set.
      const search = searches.find((s) => s.id === message.attributes.search);
      const provider = search?.providers.find(
        (p) => p.id === message.attributes.provider
      );
      const query = provider?.queries.find(
        (q) => q.id === message.attributes.query
      );

      if (!search || !provider || !query) {
        fetchSearch(projectId, message.attributes.search).then(
          (fetchedSearch) => {
            setSearches(
              search
                ? searches.map((s) =>
                    s.id === fetchedSearch.id ? fetchedSearch : s
                  )
                : [fetchedSearch, ...searches]
            );
          }
        );
        return;
      }

      if (
        ['search.noresults', 'search.results_received'].includes(message.status)
      ) {
        query.processed = true;
        query.hit_count = message.attributes.count;
      } else if (message.status === 'search.error') {
        query.processed = true;
        query.status = message.status;
        query.status_message = message.attributes.message;
      }
      setSearches(searches.map((s) => (s.id === search.id ? search : s)));
    },
    [projectId, searches, setSearches]
  );

  useMultiAsyncMessageHandler(
    ['search.noresults', 'search.results_received', 'search.error'],
    handlerCallback
  );
};

export default SearchHistoryTableAsyncMessageHandler;
