/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/rules-of-hooks */
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHighlighter } from 'hooks/useHighlighter';
import { isEmpty } from 'lodash';
import { emptyContentMsg } from 'hoc/withSearchResults';
import isUrl from 'is-url';
import Button from 'components/Base/Button';
import IconAngleDoubleDown from 'components/Base/Icons/IconAngleDoubleDown';
import classes from '../article.module.scss';
import RecordsTable from '../ContentTypes/RecordsTable';
import GenericStructuredData from '../ContentTypes/GenericStructuredData';
import { SkeletonArticleBody } from '../SkeletonArticle';

const Content = ({
  providerConfig,
  data,
  highlight,
  translate,
  retrieving,
  handleArticleRetrieve,
}) => {
  const { trackEvent } = useMatomo();
  const includesPreview = data?.content?.includes(
    data.text
      .replace(/^\.\.\./, '')
      .replace(/\.\.\.$/, '')
      .trim()
  );

  const renderContent = (layout) => {
    if (
      translate &&
      !data.translatedContent &&
      data.translatedTitle &&
      data.content !== emptyContentMsg &&
      !isEmpty(data.content)
    )
      return <SkeletonArticleBody />;

    /**
     * Nested list to display structured data - temporary, needs refactor
     */
    if (layout === 'content_json_tree' && !isEmpty(data.contentJSON)) {
      return <GenericStructuredData data={data} />;
    }

    /**
     * Table to display land reg data - temporary, needs refactor
     */
    if (!isEmpty(data.contentJSON)) {
      return <RecordsTable data={data} />;
    }

    if (!isEmpty(data.content) && data.content !== emptyContentMsg)
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: useHighlighter({
              query: highlight,
              content:
                translate && data.translatedContent
                  ? data.translatedContent
                  : data.content,
            }),
          }}
        />
      );

    if (isUrl(data.url) || isUrl(data.end_url))
      return (
        <div className={classes['no-content']}>
          No content was provided, but an original source URL exists for this
          item.
          {providerConfig.dispatch?.retrieve_enabled && (
            <>
              <div className={classes['content-links']}>
                <Button
                  variant='secondary'
                  size='small'
                  onClick={() => {
                    trackEvent({
                      category: 'article',
                      action: 'retrieve_article',
                    });
                    // setRetrieving(true);
                    handleArticleRetrieve();
                  }}
                  loading={retrieving}
                  label={
                    <>
                      <IconAngleDoubleDown />
                      Retrieve content
                    </>
                  }
                />
              </div>
              {(retrieving && (
                <div className={classes['content-links-caption']}>
                  Retrieving content from remote source, this might take a few
                  moments…
                </div>
              )) ||
                null}
            </>
          )}
        </div>
      );

    if (isEmpty(data.content)) return <SkeletonArticleBody />;

    return null;
  };

  switch (providerConfig.layouts?.content) {
    case 'conditional_content_only':
      return (
        <div className={classes.content}>
          {(data.content === emptyContentMsg || !includesPreview) && (
            <div
              className={classes.snippet}
              dangerouslySetInnerHTML={{
                __html: useHighlighter({
                  query: highlight,
                  content:
                    translate && data.translatedText
                      ? data.translatedText
                      : data.text,
                }),
              }}
            />
          )}
          {renderContent()}
        </div>
      );
    case 'content_only':
      return <div className={classes.content}>{renderContent()}</div>;
    case 'content_json_tree':
      return (
        <div className={classes.content}>
          {renderContent('content_json_tree')}
        </div>
      );
    default:
      return (
        <div className={classes.content}>
          {(data.content === emptyContentMsg || includesPreview) &&
            !isEmpty(data.content) && (
              <div
                className={classes.snippet}
                dangerouslySetInnerHTML={{
                  __html: useHighlighter({
                    query: highlight,
                    content:
                      translate && data.translatedText
                        ? data.translatedText
                        : data.text,
                  }),
                }}
              />
            )}
          {renderContent()}
        </div>
      );
  }
};

export default Content;
