import classes from 'components/SearchBar/search-bar.module.scss';
import Checkbox from 'components/Base/Fields/Checkbox';
import { uniq } from 'lodash';
import cheatSheets from 'constants/cheatSheets.json';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useAppSelector } from 'hooks/storeHooks';
import { selectAdverseTerms } from 'store/slices/adverseTerms';
import AdverseTermChip from 'components/SearchBar/AdverseTermChip';

const ProviderItem = ({ items, provider, category, value, setValue }) => {
  const { data: terms } = useAppSelector(selectAdverseTerms);

  const hasAdverseTerms = terms.some((t) =>
    t.queries?.some((q) => q.provider.name === provider.id)
  );

  return (
    <div className={classes['provider-item']} key={provider.id}>
      <div className={classes['provider-item__label-icon']}>
        <Checkbox
          label={provider.label}
          value={provider.id}
          checked={value.providers.includes(provider.id)}
          onClick={(e) => {
            if (e.target.checked) {
              const p = uniq([...value.providers, provider.id]);
              setValue({
                ...value,
                providers: p,
                categories: {
                  ...value.categories,
                  [category]:
                    p.filter((_p) => _p.indexOf(category) === 0).length >=
                    items.length,
                },
              });
            } else {
              const p = uniq([
                ...value.providers.filter((item) => item !== provider.id),
              ]);
              setValue({
                ...value,
                providers: p,
                categories: {
                  ...value.categories,
                  [category]: false,
                },
              });
            }
          }}
          labelClassName={classes['provider-item__label']}
        />
        {(cheatSheets[provider.id] && (
          <a
            className={classes['cheat-sheet']}
            target='_blank'
            rel='noopener noreferrer'
            href={cheatSheets[provider.id]}
          >
            <InfoIcon fontSize='inherit' />
          </a>
        )) ||
          null}
      </div>

      {hasAdverseTerms && <AdverseTermChip />}
    </div>
  );
};

export default ProviderItem;
