import TableCell from '@mui/material/TableCell';
import { IconButton } from '@mui/material';
import Select from 'components/BaseNew/Select/Select';
import TextField from 'components/BaseNew/TextField/TextField';
import DatePicker from 'components/BaseNew/DatePicker/DatePicker';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { StyledMenuItem } from 'components/BaseNew/Select/styled';

import { IAdverseTerm, IAdverseTermCategory } from 'appSrc/types/adverseTerms';
import { DefaultMenuItem, TableActionsContainer, TableRow } from './styled';
import { entityTypes, genders } from './data/formData';
import getCountryOptions from './utils/getCountryOptions';
import AdverseTermsSelect from './AdverseTermsSelect';
import { Subject } from './types';

type SubjectChange = {
  id: string;
  field: string;
  value: string;
};
const SubjectTableRow = ({
  subject,
  onChange,
  onDelete,
  terms,
  termsCategories,
  isFactivaSelected,
}: {
  subject: Subject;
  onChange: (subject: SubjectChange) => void;
  onDelete: (id: string) => void;
  terms: IAdverseTerm[];
  termsCategories: IAdverseTermCategory[];
  isFactivaSelected: boolean;
}) => {
  const isIndividual = subject.type === 'Individual';

  return (
    <TableRow key={subject.id}>
      <TableCell component='th' scope='row'>
        <Select
          fullWidth
          size='small'
          value={subject.type}
          displayEmpty
          onChange={(e) =>
            onChange({
              id: subject.id,
              field: 'type',
              value: e.target.value as string,
            })
          }
        >
          <DefaultMenuItem value=''>- None -</DefaultMenuItem>
          {entityTypes.map((entityType) => (
            <StyledMenuItem key={entityType.value} value={entityType.value}>
              {entityType.label}
            </StyledMenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          size='small'
          value={subject.name}
          onChange={(e) =>
            onChange({
              id: subject.id,
              field: 'name',
              value: e.target.value,
            })
          }
        />
      </TableCell>
      <TableCell>
        <Select
          fullWidth
          size='small'
          value={subject.country}
          displayEmpty
          onChange={(e) =>
            onChange({
              id: subject.id,
              field: 'country',
              value: e.target.value as string,
            })
          }
        >
          <DefaultMenuItem value=''>- None -</DefaultMenuItem>
          {getCountryOptions().map((country) => (
            <StyledMenuItem key={country.value} value={country.value}>
              {country.label}
            </StyledMenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        {!isIndividual ? (
          '-'
        ) : (
          <DatePicker
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            onChange={(value) => {
              onChange({
                id: subject.id,
                field: 'dob',
                // @ts-ignore
                value: value?.format('YYYY-MM-DD') || '',
              });
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {!isIndividual ? (
          '-'
        ) : (
          <Select
            fullWidth
            size='small'
            value={subject.gender}
            displayEmpty
            onChange={(e) =>
              onChange({
                id: subject.id,
                field: 'gender',
                value: e.target.value as string,
              })
            }
          >
            <DefaultMenuItem value=''>- None -</DefaultMenuItem>
            {genders.map((gender) => (
              <StyledMenuItem key={gender.value} value={gender.value}>
                {gender.label}
              </StyledMenuItem>
            ))}
          </Select>
        )}
      </TableCell>
      {isFactivaSelected && (
        <TableCell>
          <AdverseTermsSelect
            terms={terms}
            categories={termsCategories}
            selectedTerm={subject.termId}
            handleChange={(e) =>
              onChange({
                id: subject.id,
                field: 'termId',
                value: e.target.value,
              })
            }
          />
        </TableCell>
      )}
      <TableCell>
        <TableActionsContainer>
          <IconButton onClick={() => onDelete(subject.id)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </TableActionsContainer>
      </TableCell>
    </TableRow>
  );
};

export default SubjectTableRow;
