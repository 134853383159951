import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'context';
import SimpleBar from 'simplebar-react';
import { find, findIndex, isEmpty, uniq, noop } from 'lodash';
import {
  fetchProjectItem,
  fetchProjectItemLink,
  submitArticleTranslation,
  submitArticleView,
  submitItemFetch,
  submitRelatedItem,
  submitRetrieveContent,
} from 'hooks/useFetch';
import { serialiseData } from 'hooks/useSerialiser';
import { emptyContentMsg } from 'hoc/withSearchResults';
import Loader from 'components/Base/Loader';
import useHandleTagFinding from 'hooks/useHandleTagFinding';
import useHandleRelatedItems from 'hooks/useHandleRelatedItems';
import logAddArticleToFindings from 'api/log/logAddArticleToFindings';
import logRemoveArticleFromFindings from 'api/log/logRemoveArticleFromFindings';
import { isNewsProvider } from 'helpers/providers/providers';

import LinearProgress from '@mui/material/LinearProgress';
import {
  UPDATE_ARTICLE_END_URL,
  UPDATE_FETCH_DOCUMENTS_UPDATED,
  UPDATE_ITEMS_VIEWED,
  UPDATE_STATE,
} from 'constants/actionTypes';
import {
  ARACHNYS_PROVIDER_ID,
  DOWJONES_PROVIDER_ID,
  FACTIVA_PROVIDER_ID,
  LANDREG_PROVIDER_ID,
  WORLDCHECK_PROVIDER_ID,
  WORLDCOMPLIANCE_PROVIDER_ID,
  COMBINED_COMPLIANCE_PROVIDER_ID,
  SAYARI_PROVIDER_ID,
} from 'constants/providers';
import RecordsArticleTemplate from './ArticleTemplates/RecordsArticleTemplate';
import ComplianceArticleTemplate from './ArticleTemplates/ComplianceArticlesTemplate';
import GenericArticleTemplate from './ArticleTemplates/GenericArticleTemplate';
import classes from './article.module.scss';
import { SkeletonArticle } from './SkeletonArticle';
import CorporateRecordsArticleTemplate from './ArticleTemplates/CorporateRecordsArticleTemplate';
import { worldCheckGetHasFetched } from './worldCheckGetHasFetched';

const Article = ({
  disableRelated = false,
  ...props
}: {
  disableRelated?: boolean;
}) => {
  const article = useSelector((state) => state.state.article);
  const project = useSelector((state) => state.state.project);
  const translate = useSelector((state) => state.state.translate);
  const data = useSelector((state) => state.state.data);
  const query = useSelector((state) => state.state.query);
  const expecting = useSelector((state) => state.state.expecting);
  const retrieving = useSelector((state) => state.state.retrieving);
  const availableTags = useSelector((state) => state.state.availableTags);
  const relatedItems = useSelector((state) => state.state.relatedItems);
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);

  const dispatch = useDispatch();

  const isSaved = article?.tags?.includes('Finding');
  const providerConfig = find(config.providers, { id: article.provider });
  const tagFinding = useHandleTagFinding();
  const handleRelatedItems = useHandleRelatedItems();
  const [passiveLoading, setPassiveLoading] = useState(false);
  const retries = useRef(0);

  console.log('article state', article);

  const requestEndUrl = useCallback(
    (projectId, articleId) => {
      fetchProjectItemLink(projectId, articleId).then((res) => {
        // @ts-ignore
        if (res?.ItemId === articleId) {
          // @ts-ignore
          if (res?.data?.URI) {
            dispatch({
              type: UPDATE_ARTICLE_END_URL,
              payload: {
                // @ts-ignore
                id: res?.ItemId,
                end_url: res.data.URI,
              },
            });
          }
        }
      });
    },
    [dispatch]
  );

  const articleViewed = useCallback(
    (projectId, articleId, viewed) => {
      submitArticleView(projectId, articleId);
      dispatch({
        type: UPDATE_ITEMS_VIEWED,
        payload: { _id: articleId, viewed },
      });
    },
    [dispatch]
  );

  const fetchProjectItemCallback = useCallback(
    (projectId, articleId) => {
      fetchProjectItem(projectId, articleId).then((res) => {
        const hasNoResult = !res || !res?.hits?.hits?.[0];
        const hasWrongIndex = res?.hits?.hits?.[0]?._index !== projectId;
        const hasWrongId = res?.hits?.hits?.[0]?._id !== articleId;

        if (hasNoResult || hasWrongIndex || hasWrongId) return;

        const serialisedData = serialiseData({
          item: res.hits.hits[0],
          // @ts-ignore
          translate: translate.article,
        });

        // Conditionals
        const worldCheckFetchRequired =
          serialisedData.provider === WORLDCHECK_PROVIDER_ID &&
          !worldCheckGetHasFetched(serialisedData.contentJSON);
        const fetchRequired =
          isEmpty(serialisedData.content) || worldCheckFetchRequired;
        const isMissingTranslation =
          translate.article && hasNoTranslation(serialisedData);

        if (fetchRequired) {
          submitItemFetch(
            projectId,
            serialisedData.provider_item_id,
            serialisedData.provider
          );
        }

        if (isMissingTranslation) {
          submitArticleTranslation({
            id: serialisedData.id,
            projectId,
            providerId: serialisedData.provider,
          });
        }

        if (user?.email) {
          if (serialisedData?.viewed === undefined) {
            serialisedData.viewed = [];
          }
          if (!serialisedData.viewed.some((u) => u.user === user.email)) {
            serialisedData.viewed.push({ user: user.email, when: Date.now() });
            articleViewed(projectId, articleId, serialisedData.viewed);

            if (
              (isEmpty(serialisedData.content) ||
                serialisedData.content === emptyContentMsg) &&
              serialisedData.url &&
              !serialisedData.end_url &&
              serialisedData?.provider?.indexOf('news.') === 0
            ) {
              requestEndUrl(projectId, serialisedData.id);
            }
          }
        }

        const payload = {
          article: { ...serialisedData, loading: false },
          expecting: { ...expecting, article: false },
        };
        dispatch({
          type: UPDATE_STATE,
          payload,
        });
      });
    },
    [
      translate.article,
      user.email,
      requestEndUrl,
      articleViewed,
      dispatch,
      expecting,
    ]
  );

  useEffect(() => {
    // We call fetchProjectItem() by setting expecting.items = true or
    // article.loadingPassive = true
    // i.e. loading a new page or search, or clicking in UI we expect a result.
    // On occasion we passively refresh, e.g. emitter reports article fetched

    if (!article?.id) return noop;
    if (!project?.id) return noop;
    if (!expecting.article && !article.loadingPassive) return noop;
    const timeout = setTimeout(() => {
      fetchProjectItemCallback(project.id, article.id);
    }, 10);
    return () => clearTimeout(timeout);
  }, [
    article,
    expecting,
    article?.id,
    project?.id,
    expecting.article,
    article.loadingPassive,
    fetchProjectItemCallback,
  ]);

  useEffect(() => {
    setPassiveLoading(translate.article && hasNoTranslation(article));
  }, [translate.article, article]);

  useEffect(() => {
    // failover
    if (!article?.id) return noop;
    if (!project?.id) return noop;
    const timeout = setTimeout(() => {
      if (!isEmpty(article?.content)) return;
      if (!isEmpty(article?.url) || !isEmpty(article?.end_url)) return;
      if (retries.current > 3) return;
      retries.current += 1;
      dispatch({
        type: UPDATE_FETCH_DOCUMENTS_UPDATED,
        payload: {},
      });
    }, 5000);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    project?.id,
    article?.id,
    article?.content,
    article?.url,
    article?.end_url,
  ]);

  const hasNoTranslation = (dataParams) => {
    if (dataParams.language === 'en') return false;
    if (isEmpty(dataParams.translatedTitle)) return true;
    if (
      !isEmpty(dataParams.content) &&
      dataParams.content !== emptyContentMsg &&
      !dataParams.translatedContent
    )
      return true;
    return false;
  };

  const handleArticleNavigate = (articleId) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        translate: {
          ...translate,
          article: false,
        },
        article: { id: articleId, loading: true },
        expecting: {
          ...expecting,
          article: true,
        },
        retrieving: false,
      },
    });
  };

  const handleArticleRetrieve = () => {
    dispatch({
      type: UPDATE_STATE,
      payload: { retrieving: true },
    });
    if (article.end_url)
      submitRetrieveContent(project.id, article.id, article.provider);
    else {
      fetchProjectItemLink(project.id, article.id).then((res) => {
        // @ts-ignore
        if (!res || res?.ItemId !== article.id) {
          dispatch({
            type: UPDATE_STATE,
            payload: { retrieving: false },
          });
          return;
        }
        if (res?.data?.URI) {
          dispatch({
            type: UPDATE_STATE,
            payload: { article: { ...article, end_url: res.data.URI } },
          });
          submitRetrieveContent(project.id, article.id, article.provider);
        }
      });
    }
  };

  const handleArticleTranslate = () => {
    const trans = !translate.article;

    if (trans && hasNoTranslation(article)) {
      submitArticleTranslation({
        id: article.id,
        projectId: project.id,
        providerId: article.provider,
      }).then((res) => {
        // @ts-ignore
        if (res?.status === 204) {
          dispatch({
            type: UPDATE_STATE,
            payload: {
              article: { ...article, loadingPassive: true },
            },
          });
        }
      });
    }

    dispatch({
      type: UPDATE_STATE,
      payload: {
        translate: { ...translate, article: trans },
      },
    });
  };

  const updateTags = (tags) => {
    if (!article?.id) return;
    const items = data?.items || [];
    const i = findIndex(data.items, { _id: article.id });
    if (i !== -1) {
      items[i]._source.tags = tags;
    }
    dispatch({
      type: UPDATE_STATE,
      payload: {
        data: { ...data, items },
        article: { ...article, tags },
        availableTags: uniq([...availableTags, ...tags]),
      },
    });
  };

  const handleSave = useCallback(() => {
    const { id, provider: providerId } = article;
    tagFinding({ item: id });

    /*
     * For reporting to CLA
     * https://s-rminform.atlassian.net/browse/SWAN-45
     */
    const sourceObj = find(data.items, { _id: id });
    const publisher = isNewsProvider(providerId)
      ? sourceObj._source.source
      : '';
    const url = isNewsProvider(providerId) ? article.url : '';

    const { tags } = sourceObj._source;
    // Tag already exists by the time this function is hit
    if (!tags.includes('Finding')) {
      logRemoveArticleFromFindings(project.id, id, providerId, publisher, url);
    } else {
      logAddArticleToFindings(project.id, id, providerId, publisher, url);
    }
  }, [article, data.items, project.id, tagFinding]);

  const openRelatedTab = (relatedItem) => {
    if (relatedItem.id) {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          related: relatedItem.id,
          relatedItems: [...relatedItems],
          article: { id: relatedItem.id, loading: true },
          expecting: { ...expecting, article: true },
          retrieving: false,
        },
      });
    }
  };

  const handleLinkRelated = (items, shouldEncode = false) => {
    if (disableRelated) {
      return false;
    }

    const link = Array.isArray(items) ? items[0] : items;
    const relatedItem = relatedItems.find((i) => i.relatedUrl === link);

    // If the related item has already been fetched, open the tab.
    if (relatedItem) {
      return openRelatedTab(relatedItem);
    }

    // Add a temporary loading tab while we fetch
    dispatch({
      type: UPDATE_STATE,
      payload: {
        related: shouldEncode ? encodeURIComponent(link) : link,
        relatedItems: [
          {
            id: '',
            label: 'Loading...',
            relatedUrl: shouldEncode ? encodeURIComponent(link) : link,
          },
          ...relatedItems,
        ],
        article: { loading: true },
        expecting: { ...expecting, article: false },
        retrieving: false,
      },
    });

    submitRelatedItem({
      projectId: project.id,
      searchId: article.search_id,
      urls: Array.isArray(items) ? items : [items],
      provider: article.provider,
    }).then((res) => {
      // @ts-ignore
      if (res?.status === 200) {
        // exists already
        handleRelatedItems(project.id, article.search_id);
      }
    });

    return false;
  };

  const renderContent = () => {
    if (!article.provider) return null;
    switch (article.provider) {
      case WORLDCHECK_PROVIDER_ID:
      case DOWJONES_PROVIDER_ID:
      case WORLDCOMPLIANCE_PROVIDER_ID:
      case COMBINED_COMPLIANCE_PROVIDER_ID:
        return (
          // @ts-ignore
          <ComplianceArticleTemplate
            providerLabel={providerConfig.label}
            project={project}
            highlight={query.query_string}
            translate={translate.article}
            providerConfig={providerConfig}
            user={user}
            data={article}
            isSaved={isSaved}
            handleSave={handleSave}
            retrieving={retrieving}
            handleArticleRetrieve={handleArticleRetrieve}
            availableTags={availableTags}
            updateTags={updateTags}
            handleLinkRelated={handleLinkRelated}
            handleArticleNavigate={handleArticleNavigate}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        );
      case LANDREG_PROVIDER_ID:
        return (
          <RecordsArticleTemplate
            project={project}
            highlight={query.query_string}
            translate={translate.article}
            providerConfig={providerConfig}
            user={user}
            data={article}
            isSaved={isSaved}
            handleSave={handleSave}
            retrieving={retrieving}
            handleArticleRetrieve={handleArticleRetrieve}
            availableTags={availableTags}
            updateTags={updateTags}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        );
      case SAYARI_PROVIDER_ID:
        return (
          // @ts-ignore
          <CorporateRecordsArticleTemplate
            project={project}
            highlight={query.query_string}
            translate={translate.article}
            providerConfig={providerConfig}
            user={user}
            data={article}
            isSaved={isSaved}
            handleSave={handleSave}
            retrieving={retrieving}
            handleArticleRetrieve={handleArticleRetrieve}
            availableTags={availableTags}
            updateTags={updateTags}
            handleLinkRelated={handleLinkRelated}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        );
      case FACTIVA_PROVIDER_ID:
      case ARACHNYS_PROVIDER_ID:
      default:
        return (
          // TODO - refactor article template to fetch data from context
          <GenericArticleTemplate
            project={project}
            highlight={query.query_string}
            translate={translate.article}
            providerConfig={providerConfig}
            user={user}
            data={article}
            isSaved={isSaved}
            handleSave={handleSave}
            retrieving={retrieving}
            handleArticleTranslate={handleArticleTranslate}
            handleArticleRetrieve={handleArticleRetrieve}
            availableTags={availableTags}
            updateTags={updateTags}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        );
    }
  };

  return (
    <SimpleBar className={classes.container}>
      <Loader loading={data.loading || article.loading}>
        {data.loading || article.loading ? (
          <SkeletonArticle />
        ) : (
          <div className={classes.article}>
            {passiveLoading && (
              <div className={classes.loading}>
                <div>
                  <LinearProgress color='inherit' />
                </div>
              </div>
            )}
            {renderContent()}
          </div>
        )}
      </Loader>
    </SimpleBar>
  );
};

export default Article;
