import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import StyledDatePicker from './styled';

const DatePicker = (props: DatePickerProps<Date>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker {...props} />
    </LocalizationProvider>
  );
};

export default DatePicker;
