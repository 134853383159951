import { Box, Divider, List, ListSubheader, Typography } from '@mui/material';
import { IAdverseTermCategory } from 'types/adverseTerms';
import AdverseTermsList from './AdverseTermsList';

const AdverseTermCategory = ({
  filteredCategories,
  selectedLang,
  searchedTerm,
  isFavouriteSelected,
}) => {
  const hasSubcat = (category) => {
    return category?.subcategories?.length > 0;
  };
  return filteredCategories.map((category: IAdverseTermCategory) => {
    return (
      <Box
        key={category.id}
        sx={{
          width: '100%',
          maxWidth: 800,
          minWidth: 600,
          bgcolor: 'background.paper',
          padding: '10px',
        }}
      >
        <List
          component='nav'
          aria-label='main mailbox folders'
          subheader={
            !hasSubcat ? (
              <ListSubheader>
                <Typography variant='subtitle2'>{category.name}</Typography>
              </ListSubheader>
            ) : (
              <ListSubheader>
                {' '}
                <Typography variant='subtitle1'>{category.name}</Typography>
              </ListSubheader>
            )
          }
        >
          <AdverseTermsList
            category={category}
            selectedLang={selectedLang}
            searchedTerms={searchedTerm}
            isFavouriteSelected={isFavouriteSelected}
          />
          {hasSubcat &&
            category?.subcategories?.map((subcat) => (
              <List
                key={subcat.id}
                component='nav'
                aria-label='main mailbox folders'
                subheader={
                  <div>
                    <ListSubheader>{subcat.name}</ListSubheader>
                  </div>
                }
              >
                <AdverseTermsList
                  category={subcat}
                  selectedLang={selectedLang}
                  searchedTerms={searchedTerm}
                  isFavouriteSelected={isFavouriteSelected}
                />
              </List>
            ))}
        </List>
        <Divider />
      </Box>
    );
  });
};
export default AdverseTermCategory;
