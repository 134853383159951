import { useSelector, useDispatch } from 'context';
import { useAsyncMessageFilter, AsyncMessage } from 'hooks/AsyncMessages';
import {
  FetchDocumentsUpdatedHandler,
  FetchErrorHandler,
  RetrieveDocumentsUpdatedHandler,
  RetrieveErrorHandler,
  TranslateDocumentsUpdatedHandler,
  TranslateErrorHandler,
} from 'hooks/AsyncMessages/commonHandlers';
import RelatedPageInsertedHandler from 'hooks/AsyncMessages/commonHandlers/RelatedPageInsertedHandler';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

const FindingsAsyncMessageHandlers = ({ children }) => {
  const article = useSelector((state) => state.state.article);
  const data = useSelector((state) => state.state.data);
  const translate = useSelector((state) => state.state.translate);
  const relatedItems = useSelector((state) => state.state.relatedItems);
  const dispatch = useDispatch();
  const { project_id: projectId } = useParams();

  const {
    id: articleId,
    search_id: searchId,
    query_id: queryId,
    provider: providerId,
  } = article || {};

  useAsyncMessageFilter(
    useCallback(
      (message: AsyncMessage) => {
        if (!message.status) return false;
        if (
          message?.attributes?.provider &&
          message.status !== 'related.page_inserted' &&
          message.status !== 'fetch.documents_updated' &&
          message.status !== 'translate.documents_updated' &&
          (message.attributes.provider.startsWith('news.') ||
            message.attributes.provider.startsWith('records.') ||
            message.attributes.provider.startsWith('compliance.')) &&
          message.attributes.provider !== providerId
        ) {
          return false;
        }
        if (
          message?.attributes?.project &&
          message.attributes.project !== projectId
        ) {
          return false;
        }
        if (
          message?.attributes?.search &&
          message.attributes.search !== searchId
        ) {
          return false;
        }
        if (
          message?.attributes?.query &&
          message.attributes.query !== queryId
        ) {
          return false;
        }
        return true;
      },
      [searchId, providerId, queryId, projectId]
    )
  );

  FetchDocumentsUpdatedHandler(articleId, dispatch);
  FetchErrorHandler(articleId, dispatch);
  RetrieveDocumentsUpdatedHandler(articleId, dispatch);
  RetrieveErrorHandler(articleId, dispatch);
  TranslateDocumentsUpdatedHandler(articleId, translate, data, dispatch);
  TranslateErrorHandler(articleId, translate, data, dispatch);
  RelatedPageInsertedHandler(projectId, searchId, relatedItems, dispatch);

  return children;
};

export default FindingsAsyncMessageHandlers;
