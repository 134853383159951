import React, { useState, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import { isEmpty } from 'lodash';
import Button from 'components/Base/Button';
import ErrorManager from 'components/ErrorManager';
import ModalManager from 'components/ModalManager';
import ToastManager from 'components/ToastManager';
import Helmet from 'react-helmet';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import IconSignOut from 'components/Base/Icons/IconSignOut';
import logoutRequestAuth from 'api/auth/logoutRequestAuth';
import { PlaylistAdd } from '@mui/icons-material';
import classes from './layout.module.scss';

type LayoutProps = {
  children: JSX.Element;
  breadcrumb?: any;
  breadcrumbs?: any[];
  project?: any;
  title?: any;
  header?: any;
  variant?: any;
  isScrollable?: boolean;
  logout?: any;
  logoLink?: any;
  showNavActions?: any;
};

const Layout = ({
  children,
  breadcrumb = null,
  project = {},
  title = null,
  header,
  variant = 'primary',
  isScrollable,
  logout = true,
  logoLink = '/projects',
  showNavActions = true,
  breadcrumbs,
}: LayoutProps) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const Header = (props) => {
    const [redirect /* , setRedirect */] = useState(null);
    const [open, setOpen] = useState(false);
    const {
      /* breadcrumb, */ /* headerContent, */ /* project, */ menuItems = [],
    } = props;
    const anchorRef = useRef(null);

    // const handleToggle = () => {
    //   setOpen((prevOpen) => !prevOpen);
    // };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };

    const handleLogout = () => {
      logoutRequestAuth();
    };

    if (redirect) {
      return <Navigate to={redirect} />;
    }

    const showLogout =
      logout &&
      (isEmpty(process.env.REACT_APP_LOGOUT) ||
        process.env.REACT_APP_LOGOUT !== 'no');

    return (
      <header className={classes.header}>
        <nav className={classes.nav}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={logoLink} className={classes.logo}>
              <svg viewBox='0 0 570.05 184.72' style={{ height: '14px' }}>
                <path
                  d='M570,.18H516.28L479,64.6,441.77.18H387.05l.19,183.95-.17.22,40.73,0V60.53L479.22,142l49.87-81.11v123H570ZM207.76,76.82V.57h74.7c32.4,0,67.14,16.49,67.14,55.3C349.6,78,338.54,95.26,318,105l67,79.36H332.16L273,112.72H248.06v70.82l-40.47-39.19V117.57l40.94-40.75H208.95m67.72,0c14,0,30.64-3.49,30.85-20.18,0-12.81-10.09-21.15-25.81-21.15L267,35.3H248.34V76.82h28.33ZM71.57,70.42C53.91,64.21,43,59.75,43,50.63,43,39.38,53.53,35.5,63.42,35.5a47.15,47.15,0,0,1,24.84,8l22.51-28.91A89.65,89.65,0,0,0,61.67.57c-28.91,0-58,15.91-58,51.81,0,33.76,27.94,44.44,50.45,53C73.32,112.72,85,117.77,85,129.41c0,14.94-13.58,20.18-25.22,20.18-15.33,0-24.64-5.24-36.67-16.3L0,163c17.46,15.14,36.28,21.93,59.57,21.93,32,0,66.55-17.85,66.55-57,0-38.23-30.27-48.9-54.52-57.43'
                  transform='translate(0.03 -0.18)'
                  style={{ fill: '#fff' }}
                />
                <polyline
                  points='207.81 80.18 124.18 80.18 150.37 112.27 208.97 80.64'
                  style={{ fill: '#FB6020' }}
                />
              </svg>
              <span>SWAN</span>
            </Link>

            <div className={classes['breadcrumb-wrapper']}>
              <div className={classes.breadcrumbs}>
                {breadcrumbs?.map(
                  ({ label, link }, i) =>
                    (label && (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className={classes.breadcrumb} key={`link-${i}`}>
                        <Tooltip
                          title={label}
                          disableHoverListener={label.length < 45}
                        >
                          <Link to={link}>{label}</Link>
                        </Tooltip>
                      </div>
                    )) ||
                    ''
                )}
              </div>
            </div>
            {/* {!isEmpty(headerContent) && (
              <div className={classes["breadcrumb-wrapper"]}>
                <div className={classes.breadcrumbs}>
                  <div className={classes.breadcrumb}>{headerContent}</div>
                </div>
              </div>
            )}
            {!isEmpty(project) && isEmpty(headerContent) && (
              <div>
                <Breadcrumbs project={project}>{breadcrumb}</Breadcrumbs>
              </div>
            )} */}
          </div>
          <div className={classes['nav-actions']}>
            {showNavActions && (
              <IconButton
                className={classes['settings-button']}
                component={Link}
                to='/settings/adverse-terms'
              >
                <PlaylistAdd />
              </IconButton>
            )}
            <Button
              onClick={handleLogout}
              variant='header'
              className={showLogout ? '' : classes['hidden-button']}
              label={
                <span>
                  <IconSignOut />
                  &nbsp; Logout
                </span>
              }
            />
            {/* // Added to solve Staging build error.
            // @ts-ignore */}
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              placement='bottom-end'
            >
              {({ TransitionProps }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id='menu-list-grow'>
                        <MenuItem
                          component={Link}
                          to='/settings/adverse-terms'
                          className={classes.menuItem}
                        >
                          Settings
                        </MenuItem>
                        <MenuItem
                          onClick={handleLogout}
                          className={classes.menuItem}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </nav>
        <section className={classes['sub-nav']}>
          {!isEmpty(menuItems) && (
            <ul className='menu'>
              {menuItems.map((item, key) => (
                <li
                  className={`menu-item-wrapper${item.active ? ' active' : ''}`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`menu_${key}`}
                >
                  <Link to={item.link} className='menu-item'>
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </section>
      </header>
    );
  };

  //   const Breadcrumbs = (props) => {
  //     const { children, project = { name: "Project Name" } } = props;
  //     return (
  //       <div className={classes.breadcrumbs}>
  //         <Link
  //           to={url(routes.projects.project.show, { project_id: project.id })}
  //           className={classes.breadcrumb}
  //         >
  //           <span>{project.name}</span>
  //         </Link>
  //         {children}
  //       </div>
  //     );
  //   };

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title ? `${title} - ` : ''} Swan</title>
      </Helmet>
      <Header
        breadcrumb={breadcrumb}
        project={project}
        headerContent={header}
        // menuItems={menuItems}
        breadcrumbs={breadcrumbs}
      />
      {isScrollable ? (
        <SimpleBar style={{ minHeight: 0 }}>
          <main
            className={classNames(classes.main, {
              //               "content side-padding": variant === "primary",
              [classes[`main--${variant}`]]: variant !== 'primary',
            })}
          >
            {children}
          </main>
        </SimpleBar>
      ) : (
        <main
          className={classNames(classes.main, {
            //             "content side-padding": variant === "primary",
            [classes[`main--${variant}`]]: variant !== 'primary',
          })}
        >
          {children}
        </main>
      )}
      <ErrorManager />
      <ModalManager />
      <ToastManager />
    </div>
  );
};

export default Layout;
