import ResultsPagination from '../Results/ResultsPagination';

type PaginationProps = {
  loading: boolean;
  page: number;
  setPage: any;
  totalResults: any;
  maxPages: number;
  disabled?: boolean;
};

const SearchResultsPagination = ({ ...props }: PaginationProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ResultsPagination {...props} />;
};

export default SearchResultsPagination;
