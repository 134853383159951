import axios from 'axios';
import { apiHeaders, apiHost, handleError } from 'hooks/useFetch';
import { ApiError } from 'types/api';

export type ApiVersion = 'v1' | 'v2';

interface ApiClient {
  method: string;
  endpoint: string;
  data?: any;
  headers?: any;
  version?: ApiVersion;
}

const apiClient = <T = Record<string, unknown>>({
  method,
  endpoint,
  data,
  headers,
  version = 'v1',
}: ApiClient): Promise<{ data?: T; error: ApiError }> => {
  const isFullUrl = endpoint.includes('http');

  return new Promise((resolve) => {
    axios({
      method,
      url: isFullUrl ? endpoint : `${apiHost}/api/${version}/${endpoint}`,
      data,
      headers: headers || apiHeaders(),
    })
      .then((response) => {
        resolve({ data: response.data, error: null });
      })
      .catch((error) => {
        handleError(error);
        resolve({ data: null, error });
      });
  });
};

export default apiClient;
