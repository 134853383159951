import { LoadingButton } from 'components/BaseNew/Button/Button';
import InputLabel from 'components/BaseNew/InputLabel/InputLabel';
import StandardModal from 'components/BaseNew/StandardModal/StandardModal';
import TextField from 'components/BaseNew/TextField/TextField';
import { useEffect, useState } from 'react';
import CategorySelector from 'components/SearchBar/CategorySelector';
import LanguageSelector from 'components/SearchBar/LanguageSelector';
import { Categories } from 'appSrc/constants';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import {
  selectAdverseTermCategories,
  selectAdverseTermCategoryById,
  selectAdverseTermLanguages,
  selectAdverseTermProviderByKey,
} from 'store/slices/adverseTerms';
import fetchAllAdverseTermsDataThunk from 'store/thunks/fetchAllAdverseTerms';
import { ApiLoadingStatus } from 'types/api';
import { useSelector } from 'context';
import isAdmin from 'helpers/user';
import { Box, Typography } from '@mui/material';
import { useModals } from 'hooks/ModalManager/useModals';
import { IAdverseTerm } from 'types/adverseTerms';
import { upsertAdverseTermThunk } from 'store/thunks/upsertAdverseTerm';
import ContentContainer from './styled';

const AdverseTermsModal = ({ term }: { term?: IAdverseTerm }) => {
  const { close } = useModals();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [name, setName] = useState(term?.name || '');
  const [query, setQuery] = useState(term?.queries?.[0]?.query || '');
  const [categoryId, setCategoryId] = useState(
    term ? term.category.parent_id || term.category.id : Categories.PERSONAL
  );
  const [subCategoryId, setSubCategoryId] = useState<number | null>(
    term?.category.id || null
  );
  const [languageId, setLanguageId] = useState<number | null>(
    term?.language.id || null
  );

  const user = useSelector((state) => state.user);
  const { data: categories } = useAppSelector(selectAdverseTermCategories);
  const { data: languages, status } = useAppSelector(
    selectAdverseTermLanguages
  );
  const provider = useAppSelector((state) =>
    selectAdverseTermProviderByKey(state, 'news.factiva')
  );
  const category = useAppSelector((state) =>
    selectAdverseTermCategoryById(state, categoryId)
  );
  const subCategory = useAppSelector((state) =>
    selectAdverseTermCategoryById(state, subCategoryId)
  );

  const isEditingTerm = !!term;
  const hasSubcategories = category?.subcategories?.length > 0;

  useEffect(() => {
    if (status === ApiLoadingStatus.Idle) {
      dispatch(fetchAllAdverseTermsDataThunk());
    }
  }, [dispatch, languages, status]);

  const handleUpsertAdverseTerm = async () => {
    setIsLoading(true);

    dispatch(
      upsertAdverseTermThunk({
        id: term?.id,
        name,
        language: languages.find((l) => l.id === languageId),
        category: category ? subCategory || category : null,
        queries: [
          {
            query,
            provider,
          },
        ],
      })
    ).then(() => {
      setIsLoading(false);
      close();
    });
  };

  return (
    <StandardModal
      isOpen
      onClose={close}
      titleContent={`${isEditingTerm ? 'Edit' : 'New'} adverse term`}
      mainContent={
        <ContentContainer>
          <InputLabel required>Name</InputLabel>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder='Enter name for the adverse term'
            fullWidth
            size='small'
          />

          <InputLabel sx={{ marginTop: 2 }} required>
            Factiva Query
          </InputLabel>
          <TextField
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder='Enter name for the adverse term'
            fullWidth
            size='small'
            multiline
            rows={3}
          />

          <CategorySelector
            selected={categoryId}
            categories={isAdmin(user) ? categories : []}
            onChange={(event) => setCategoryId(Number(event.target.value))}
            otherCategories={[{ id: Categories.PERSONAL, name: 'Personal' }]}
            sx={{
              width: '100%',
              marginTop: 2,
            }}
          />

          {hasSubcategories && (
            <CategorySelector
              selected={subCategoryId}
              categories={category.subcategories}
              onChange={(event) => setSubCategoryId(Number(event.target.value))}
              sx={{
                width: '100%',
                marginTop: 2,
              }}
            />
          )}

          <LanguageSelector
            selected={languageId}
            languages={languages}
            onChange={(event) => setLanguageId(event.target.value)}
            sx={{
              width: '100%',
              marginTop: 2,
            }}
          />
        </ContentContainer>
      }
      actionContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography color='red'>{error}</Typography>
          <LoadingButton
            loading={isLoading}
            onClick={handleUpsertAdverseTerm}
            disabled={
              !name.length || !query.length || !languageId || !categoryId
            }
            variant='contained'
          >
            {isEditingTerm ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      }
    />
  );
};

export default AdverseTermsModal;
