import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const deleteSearch = (projectId, searchId) => {
  const request = axios.delete(
    `${apiHost}/api/v1/projects/${projectId}/searches/${searchId}`,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default deleteSearch;
