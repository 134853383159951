import { useCallback } from 'react';
import { LOAD_SINGLE_PROVIDER_CONFIG } from 'constants/actionTypes';
import { fetchFailure } from 'hooks/useFetch';
import { fetchSingleProviderConfig } from 'hooks/useFetch/config/fetchSingleProviderConfig';
import useAsyncMessageHandler from '../useAsyncMessageHandler';

const ProviderUpdatedHandler = (dispatch) => {
  return useAsyncMessageHandler(
    'provider.config_updated',
    useCallback(
      (message) => {
        const { updatedId } = message.attributes;
        fetchSingleProviderConfig(updatedId).then((result) => {
          const { providerId, hasError, config, err } = result;
          if (hasError || !config) {
            dispatch(fetchFailure(err));
          }
          dispatch({
            type: LOAD_SINGLE_PROVIDER_CONFIG,
            payload: {
              providerId,
              config,
            },
          });
        });
      },
      [dispatch]
    )
  );
};

export default ProviderUpdatedHandler;
