import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitQuery = (
  project_id,
  search_id,
  provider,
  query_string,
  query_facets = {},
  sort = ''
) => {
  console.log('Submit Query', {
    project_id,
    search_id,
    provider,
    query_string,
    query_facets,
    sort,
  });
  const data = JSON.stringify({
    provider,
    query_struct: [
      {
        type: 'string',
        value: query_string,
      },
    ],
    query_facets,
    sort,
  });

  const request = axios.post(
    `${apiHost}/api/v1/projects/${project_id}/searches/${search_id}/queries`,
    data,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitQuery;
