import { get } from 'lodash';
import { createContext, useContext, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
// @ts-ignore
import { features } from 'swan-static-config';

const FeatureEnabledContext = createContext(features);

export const FeatureEnabledProvider = ({ children }) => {
  const contextValue = useMemo(
    () => ({
      ...features,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [features]
  );

  // User state can go here

  return (
    <FeatureEnabledContext.Provider value={contextValue}>
      {children}
    </FeatureEnabledContext.Provider>
  );
};

/**
 * Returns an object containing the feature settings for the requested feature strings.
 * Reference by path is possible. Result is resolved using lodash `get`.
 * @param {Array<string>} featuresToCheck
 * @returns Object in the shape of {[featureKey] : featureValue}
 */
export const useIsFeatureEnabled = (
  featuresToCheck = []
): { [key: string]: boolean } => {
  const context = useContext(FeatureEnabledContext);
  if (!context) {
    throw new Error(
      'useIsFeatureEnabled called from outside FeatureEnabledProvider'
    );
  }

  const result = useMemo((): { [key: string]: boolean } => {
    return featuresToCheck.reduce((acc, featureKey) => {
      return {
        ...acc,
        [featureKey]: get(context, featureKey),
      };
    }, {});
  }, [featuresToCheck, context]);

  return result;
};
