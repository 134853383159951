import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const fetchMoreQueryItems = ({ projectId, searchId, queryId, page }) => {
  console.log({ projectId, searchId, queryId, page });
  const data = JSON.stringify({
    size: page * 100,
  });

  const request = axios.post(
    // eslint-disable-next-line max-len
    `${apiHost}/api/v1/projects/${projectId}/searches/${searchId}/queries/${queryId}/more`,
    data,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default fetchMoreQueryItems;
