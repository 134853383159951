import axios from 'axios';
import { apiHost, handleError, handleLogin } from 'hooks/useFetch';

export const authUser = async (
  email: string,
  password: string
): Promise<string | boolean> => {
  try {
    const result = await axios.post(`${apiHost}/api/v1/auth/login`, {
      email,
      password,
    });
    handleLogin(result.data);
    return result.data;
  } catch (err) {
    handleError(err);
    return false;
  }
};

export default authUser;
