export const Categories = {
  ALL: -1,
  FAVOURITES: -2,
  PERSONAL: -3,
};

export const Languages = {
  ALL: -1,
};

export const PERSONAL_CATEGORY = {
  id: Categories.PERSONAL,
  name: 'Personal',
};

export const Modals = {
  ADVERSE_TERMS: 'adverse-terms-modal',
  SUBJECT_SEARCH: 'subject-search-modal',
  CREATE_PROJECT: 'create-project-modal',
};

export const SearchMode = {
  SUBJECT_SEARCH: 'subject-search',
  CLASSIC_SEARCH: 'classic-search',
} as const;
