/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { find } from 'lodash';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from 'components/Base/Fields/Checkbox';
import classNames from 'classnames';
import classes from '../facets.module.scss';

const CheckboxFacet = ({
  className,
  control,
  name,
  options,
  selected,
  isSelected,
  handleChange,
  handleReset,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpansion = () => {
    setIsExpanded(true);
  };

  const handleHide = () => {
    setIsExpanded(false);
  };

  const props = {
    control,
    options,
    selected,
    isExpanded,
    handleExpansion,
    handleHide,
    handleReset,
  };

  const includedFacets = selected.filter(
    (item) => !item.isExcluded && item.providerControl === control
  );

  const availableFacets = options.filter((bucket) => {
    if (typeof bucket === 'string') {
      return selected.indexOf(bucket) === -1;
    }
    return (
      !find(selected, {
        providerControl: control,
        value: bucket.value,
      }) &&
      !find(selected, {
        providerControl: `${control}x`,
        value: bucket.value,
      })
    );
  });

  const checkboxValue = (item) => {
    return typeof item === 'string' ? item : item.value;
  };

  return (
    <div className={classNames(classes.facets, className)}>
      <Accordion className={classes.accordion} defaultExpanded>
        <AccordionSummary
          className={classes['accordion-summary']}
          expandIcon={<ExpandMoreIcon />}
          // eslint-disable-next-line react/no-children-prop
          children={
            <FormLabel className={classes.heading} component='legend'>
              {name}
            </FormLabel>
          }
        />
        <AccordionDetails className={classes['accordion-details']}>
          {includedFacets.length > 0 && (
            <div className={classes.selected}>
              {/* <span className={classes["facet-label"]}>Included</span> */}
              {includedFacets.map((option) => {
                const item =
                  typeof option === 'string'
                    ? option
                    : find(options, { value: option.value });

                return (
                  <Checkbox
                    key={item.id || item.label || item}
                    label={item.label || item}
                    name={item.name || item.id || item.label || item}
                    value={checkboxValue(item)}
                    onClick={handleChange}
                    checked={isSelected && isSelected(item)}
                    variant='facet'
                  />
                );
              })}
            </div>
          )}{' '}
          {availableFacets.length > 9 && !isExpanded ? (
            <>
              {availableFacets.slice(0, 9).map((item) => (
                <Checkbox
                  key={item.id || item.label || item}
                  label={item.label || item}
                  name={item.name || item.id || item.label || item}
                  value={checkboxValue(item)}
                  onClick={handleChange}
                  checked={isSelected && isSelected(item)}
                  variant='facet'
                />
              ))}
            </>
          ) : (
            <>
              {availableFacets.map((item) => (
                <Checkbox
                  key={item.id || item.label || item}
                  label={item.label || item}
                  name={item.name || item.id || item.label || item}
                  value={checkboxValue(item)}
                  onClick={handleChange}
                  checked={isSelected && isSelected(item)}
                  variant='facet'
                />
              ))}
            </>
          )}
          <AccordionControls {...props} selected={includedFacets} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const AccordionControls = ({
  control,
  options,
  selected,
  isExpanded,
  handleExpansion,
  handleHide,
  handleReset,
}) => {
  return (
    <div className={classes['checkbox-controls']}>
      {options.length > 9 &&
        (!isExpanded ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={classes['checkbox-control']}
            onClick={handleExpansion}
          >
            <p>Show more</p>
          </div>
        ) : (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div className={classes['checkbox-control']} onClick={handleHide}>
            <p>Show less</p>
          </div>
        ))}
      {selected.length > 0 && handleReset && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={classes['checkbox-control']}
          onClick={() => {
            handleReset(control);
          }}
        >
          <span>Reset all</span>
        </div>
      )}
    </div>
  );
};

export default CheckboxFacet;
