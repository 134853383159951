import { OutlinedFlag } from '@mui/icons-material';
import Chip from 'components/Base/Chip';
import classes from './results.module.scss';

export const ResultsItemSubtitle = ({ highlight, tags }) => {
  return (
    <>
      <span className={classes['item-text']}>
        <OutlinedFlag fontSize='inherit' />
        {highlight}
      </span>

      {tags && (
        <span className={classes['item-title-tags']}>
          {tags.map((tag) => (
            <Chip
              key={`item-title-tag--${tag}`}
              label={tag}
              size='small'
              classes={{ root: classes.chip }}
            />
          ))}
        </span>
      )}
    </>
  );
};

export default ResultsItemSubtitle;
