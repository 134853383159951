const useImageValidator = (url) => {
  const loadImage = (_url) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', (err) => reject(err));
      img.src = _url;
    });

  return loadImage(url)
    .then((img) => {
      return img.complete;
    })
    .catch((err) => {
      return false;
    });
};

export default useImageValidator;
