import { StructuredRow } from '../StructuredRow';
import {
  convertNameObjectToStringForDisplay,
  parseNameArrayForRendering,
} from './helpers';
import classes from '../../../article.module.scss';

export const IdentitySection = ({
  showEmpty,
  primaryName,
  gender,
  names,
  category,
  entityType,
}) => {
  const { otherNames } = parseNameArrayForRendering(names);

  return (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Identity</h4>
      <StructuredRow label='Primary Name' value={primaryName} />
      <StructuredRow
        label='Other Names'
        value={otherNames.map((name, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`other-name-${i}`}>
            {convertNameObjectToStringForDisplay(name)}
          </span>
        ))}
        classNames={[classes['list-row']]}
      />
      {gender || showEmpty ? (
        <StructuredRow label='Gender' value={gender} />
      ) : null}
      <StructuredRow label='Entity Type' value={entityType} />
      <StructuredRow label='Record Category' value={category} />
    </div>
  );
};

export default IdentitySection;
