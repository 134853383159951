import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Button from 'components/Base/Button';
import IconPrint from 'components/Base/Icons/IconPrint';
import {
  COMBINED_COMPLIANCE_PROVIDER_ID,
  DOWJONES_PROVIDER_ID,
  WORLDCHECK_PROVIDER_ID,
  WORLDCOMPLIANCE_PROVIDER_ID,
} from 'constants/providers';
import CopyReference from '../../ArticleSections/CopyReference';
import ArticleTagsControl from '../../ArticleSections/TagsControl';
import { SkeletonArticleBody } from '../../SkeletonArticle';
import classes from '../../article.module.scss';
import DowJonesStructuredData from './DowJonesStructuredData';
import WorldComplianceStructuredData from './WorldComplianceStructuredData';
import WorldCheckStructuredData from './WorldCheck';
import CombinedComplianceStructuredData from './CombinedCompliance';
import { ComplianceHeader } from '../ComplianceHeader';
import { RawDataToggle } from '../CorporateRecordsArticleTemplate/RawDataToggle';

type ProviderStructuredDataComponentFunction = (
  props: ComplianceArticleTemplateProps
) => JSX.Element;

interface ComplianceArticleTemplateProps {
  data: unknown;
  showEmpty: boolean;
  handleLinkRelated: (items: Array<unknown>, shouldEncode: boolean) => void;
  handleArticleNavigate: (articleId: string) => void;
  [key: string]: unknown;
}

const getProviderStructuredDataComponent = (
  providerId
): ProviderStructuredDataComponentFunction => {
  switch (providerId) {
    case WORLDCOMPLIANCE_PROVIDER_ID:
      return WorldComplianceStructuredData;
    case DOWJONES_PROVIDER_ID:
      return DowJonesStructuredData;
    case WORLDCHECK_PROVIDER_ID:
      return WorldCheckStructuredData;
    case COMBINED_COMPLIANCE_PROVIDER_ID:
      return CombinedComplianceStructuredData;
    default:
      return null;
  }
};

const ComplianceArticleTemplate = ({
  data,
  providerLabel,
  isSaved,
  handleSave,
  availableTags,
  updateTags,
  handleLinkRelated,
  handleArticleNavigate,
  ...props
}) => {
  const [showEmpty, setShowEmpty] = useState(false);
  const { trackEvent } = useMatomo();

  const ProviderStructuredDataComponent = getProviderStructuredDataComponent(
    data.provider
  );

  const navigate = useNavigate();
  return (
    <div>
      <ComplianceHeader
        isSaved={isSaved}
        handleSave={handleSave}
        providerLabel={providerLabel}
        viewed={data.viewed}
        toggleValue={showEmpty}
        setToggleValue={setShowEmpty}
      />

      <div className={classes.content}>
        {/* TODO - temporary code while waiting for new designs */}
        <div
          style={{
            padding: '0 0 1rem 0',
            zIndex: -1,
            display: 'flex',
            flexWrap: 'wrap',
            gap: '6px',
          }}
        >
          {/* @ts-ignore */}
          <ArticleTagsControl
            isSaved={isSaved}
            projectId={props.project.id}
            docId={data.id}
            tags={data.tags}
            availableTags={availableTags}
            updateTags={updateTags}
          />

          {data.provider !== COMBINED_COMPLIANCE_PROVIDER_ID && (
            <Button
              onClick={() => {
                trackEvent({ category: 'article', action: 'print_article' });
                navigate(
                  `/projects/${props.project.id}/searches/${
                    data.search_id || 'related'
                  }/providers/${data.provider}/queries/${data.query_id}/items/${
                    data.id
                  }/export`
                );
              }}
              variant='secondary'
              size='small'
              label={
                <>
                  <IconPrint />
                  &nbsp; Print version
                </>
              }
            />
          )}

          <CopyReference data={data} />
        </div>
        {!isEmpty(data.content) ? (
          // @ts-ignore
          <>
            <ProviderStructuredDataComponent
              data={data}
              showEmpty={showEmpty}
              handleLinkRelated={handleLinkRelated}
              handleArticleNavigate={handleArticleNavigate}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />

            {(process.env.NODE_ENV === 'development' || 'staging') && (
              <RawDataToggle data={data} />
            )}
          </>
        ) : (
          <SkeletonArticleBody />
        )}
      </div>
    </div>
  );
};

export default ComplianceArticleTemplate;
