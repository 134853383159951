/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import classNames from 'classnames';
import classes from './fields-base.module.scss';

type ToggleProps = {
  id?: string;
  variant?: string;
  size?: string;
  label?: string;
  toggleLabel: string | JSX.Element;
  toggleLabelBefore?: string;
  checked?: boolean;
  onClick: (any) => void;
  disabled?: boolean;
};

const Toggle = ({
  id,
  variant,
  size,
  label,
  toggleLabel,
  toggleLabelBefore,
  checked,
  onClick,
  disabled,
}: ToggleProps) => {
  return (
    <>
      {label && <label className={classes.label}>{label}</label>}
      <FormControlLabel
        label=''
        classes={{
          root: classes['toggle-wrapper'],
          label: classNames(
            classes['toggle-label'],
            classes[`toggle-label--${variant}`]
          ),
        }}
        value='end'
        control={
          <Grid
            component='label'
            container
            alignItems='center'
            spacing={1}
            className={classNames(
              classes['toggle-label'],
              classes[`toggle-label--${variant}`]
            )}
          >
            {toggleLabelBefore && <Grid item>{toggleLabelBefore}</Grid>}
            <Grid item>
              <Switch
                id={id}
                classes={{
                  root: classNames(classes.toggle, classes[`toggle--${size}`]),
                  sizeSmall: classNames(
                    classes.toggle,
                    classes[`toggle--${size}`]
                  ),
                  switchBase: classes['toggle-inner'],
                  checked: classes['toggle-checked'],
                  thumb: classes['toggle-thumb'],
                  track: classes['toggle-track'],
                }}
                color='primary'
                size='small'
                checked={checked}
                onClick={onClick}
                disabled={disabled}
              />
            </Grid>
            {toggleLabel && <Grid item>{toggleLabel}</Grid>}
          </Grid>
        }
        labelPlacement='end'
      />
    </>
  );
};

export default Toggle;
