import classes from './icons-base.module.scss';

// <i class="fal fa-sign-out"></i>
// https://fontawesome.com/v5.15/icons/sign-out?style=light
// SVGs in download from https://fontawesome.com/download

const IconSignOut = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fal'
      data-icon='sign-out'
      className={classes['fa-inline-icon--bigger']}
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <path
        fill='currentColor'
        d='M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z'
      />
    </svg>
  );
};

export default IconSignOut;
