/* eslint-disable no-dupe-else-if */
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const fetchFindingQueryItems = ({
  projectId,
  sort: sortValue,
  page = 1,
  date = [],
  tags = [],
  searches = [],
  sources = [],
  reqId,
  full = false,
}) => {
  const perPage = 100;
  const from = perPage * page - perPage;
  const request = axios.request({
    method: 'post',
    url: `${apiHost}/api/v2/projects/${projectId}/findings`,
    data: {
      size: 100,
      is_full_source: full,
      date_from: date?.[0],
      date_to: date?.[1],
      from,
      sort_by: sortValue,
      tags,
      providers: sources,
      search_ids: searches,
    },
    headers: apiHeaders(),
  });
  return request
    .then((res) => {
      res.data.reqId = reqId;
      return res.data;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default fetchFindingQueryItems;
