import { find } from 'lodash';
import { useContext } from 'use-context-selector';
import AppContext from 'context';
import { useIsFeatureEnabled } from 'hooks/useIsFeatureEnabled';
import { isCombinedProvider } from 'helpers/providers/providers';
import { calculateProviderStatus } from 'helpers/providers/status';
import { getProviderVariant } from 'utils';
import classes from './search-navigation.module.scss';
import { StatusIcon } from './StatusIcon';

export const SearchNavigationHeaderTab = ({
  selected,
  providerType,
  providers,
  handleRedirect,
}) => {
  const { state: appState } = useContext(AppContext);
  const { combinedResultsList } = useIsFeatureEnabled(['combinedResultsList']);
  const { state } = appState;
  const { search: currentSearch } = state;

  const providersInGroup = currentSearch.providers.filter(
    (searchProvider) =>
      searchProvider.id.startsWith(providerType) &&
      !isCombinedProvider(searchProvider.id)
  );

  const combinedProvider = currentSearch.providers.filter(
    (searchProvider) =>
      searchProvider.id.startsWith(providerType) &&
      isCombinedProvider(searchProvider.id)
  );

  const selectable =
    combinedResultsList &&
    combinedProvider.length >= 1 &&
    providersInGroup.length > 1;

  const providerStatus = calculateProviderStatus(combinedProvider);
  const count = combinedProvider[0]
    ? combinedProvider?.[0].queries?.[combinedProvider[0].queries.length - 1]
        .hit_count
    : 0;

  const handleHeaderClick = (e) => {
    e.preventDefault();
    console.log('handleHeaderClick', {
      providerType,
      selectable,
      selected,
      providers,
    });
    if (selectable && !selected) {
      const combinedPseudoProvider =
        selectable &&
        find(providers[providerType], {
          id: `${providerType}.combined`,
        });

      handleRedirect({
        providerId: `${providerType}.combined`,
        searchId: currentSearch.id,
        queryId:
          combinedPseudoProvider?.queries[0].id ||
          `all-${providerType}-providers`, // Temporary while compliance provider does noe exist
      });
    }
  };

  const label = getProviderVariant(providerType, true);
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      key={providerType}
      className={`${classes['tab-header']} ${
        selectable && classes.selectable
      } ${selected && classes.selected}`}
      onClick={handleHeaderClick}
    >
      <span className={`${classes.label}`}>{label}</span>
      {selectable && (
        <StatusIcon providerStatus={providerStatus} count={count} />
      )}
    </span>
  );
};

export default SearchNavigationHeaderTab;
