import { IUserInterfaceSlice } from 'types/ui';
import { IAdverseTermsSlice } from 'types/adverseTerms';
import { ApiLoadingStatus } from 'types/api';
import { SearchMode } from 'appSrc/constants';

export interface IRootInitialState {
  ui: IUserInterfaceSlice;
  adverseTerms: IAdverseTermsSlice;
}

export const rootInitialState: IRootInitialState = {
  ui: {
    adverseTermsPopout: {
      visible: false,
    },
    searchBarPopout: {
      visible: false,
    },
    redFlagCheckWarningModal: {
      visible: false,
    },
    searchMode: SearchMode.SUBJECT_SEARCH,
  },
  adverseTerms: {
    terms: {
      data: [],
      status: ApiLoadingStatus.Idle,
      error: null,
    },
    favourites: {
      data: [],
      status: ApiLoadingStatus.Idle,
      error: null,
    },
    categories: {
      data: [],
      status: ApiLoadingStatus.Idle,
      error: null,
    },
    languages: {
      data: [],
      status: ApiLoadingStatus.Idle,
      error: null,
    },
    providers: {
      data: [],
      status: ApiLoadingStatus.Idle,
      error: null,
    },
    selectedTerm: {
      term: null,
      parentCategory: null,
    },
  },
};

export default rootInitialState;
