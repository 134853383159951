export const getTranslatedCount = (arr) => {
  return !arr ? 0 : arr.filter((item) => !!item._source.headline_trans).length;
};

export const getTranslatedReqCount = (arr) => {
  return !arr ? 0 : arr.filter((item) => item._source.language !== 'en').length;
};

export const getProviderVariant = (provider, replaceUnderscores = false) => {
  return replaceUnderscores
    ? provider.split('.')[0].replace('_', ' ')
    : provider.split('.')[0];
};

export const pickFields = (data, fields) => {
  return Object.fromEntries(
    fields
      .filter((key) => key in data) // line can be removed to make it inclusive
      .map((key) => [key, data[key]])
  );
};
