import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const submitRelatedInactive = async (project_id, doc_id) => {
  const data = JSON.stringify({
    ids: [doc_id],
  });

  await axios
    .patch(`${apiHost}/api/v1/projects/${project_id}/related/inactive`, data, {
      headers: apiHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default submitRelatedInactive;
