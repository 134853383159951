/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import classes from '../../article.module.scss';
import RawJSONData from '../../ContentTypes/RawJSONData';

export const RawDataToggle = ({ data }) => {
  const [showData, setShowData] = useState(false);

  return (
    <>
      <span
        onClick={() => setShowData(!showData)}
        className={classes['view-data-control']}
      >
        {showData ? 'Hide raw data' : 'Show raw data'}
      </span>

      {(showData && <RawJSONData data={data} />) || <div>&nbsp;</div>}
    </>
  );
};

export default RawDataToggle;
