import Button from 'components/Base/Button';
import { FindInPage, PlaylistAdd, PlaylistAddCheck } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import Toggle from 'components/Base/Fields/Toggle';
import SearchViewCount from '../../SearchViewCount';
import classes from '../article.module.scss';

type Props = {
  isSaved: boolean;
  handleSave: () => void;
  providerLabel: string;
  viewed: object[];
  toggleValue?: boolean;
  toggleTitle?: string;
  setToggleValue?: (boolean) => void;
};

export const ComplianceHeader = ({
  isSaved,
  handleSave,
  providerLabel,
  viewed,
  toggleValue,
  toggleTitle,
  setToggleValue,
}: Props) => {
  return (
    <div className={classes['header--compliance']}>
      <div>
        <Button
          id={
            isSaved
              ? 'matomo-remove-from-findings-btn'
              : 'matomo-add-to-findings-btn'
          }
          variant={isSaved ? 'finding-active' : 'finding'}
          label={
            isSaved ? (
              <>
                <PlaylistAddCheck fontSize='inherit' />
                In findings
              </>
            ) : (
              <>
                <PlaylistAdd fontSize='inherit' />
                Add to findings
              </>
            )
          }
          removeLabel={
            isSaved && (
              <>
                <SvgIcon className={classes['remove-icon']}>
                  <path
                    d='M2 6v2h12V6H2m0 4v2h9v-2H2m12.17.76l-1.41 1.41L15.59 15l-2.83 2.83l1.41 1.41L17 16.41l2.83 2.83l1.41-1.41L18.41 15l2.83-2.83l-1.41-1.41L17 13.59l-2.83-2.83M2 14v2h9v-2H2z'
                    fill='currentColor'
                  />
                </SvgIcon>
                Remove
              </>
            )
          }
          onClick={handleSave}
        />
      </div>

      {setToggleValue && (
        <Toggle
          size='small'
          toggleLabel={
            <span style={{ fontSize: '14px' }}>
              {toggleTitle ?? 'Show empty fields'}
            </span>
          }
          checked={toggleValue}
          onClick={() => setToggleValue(!toggleValue)}
        />
      )}

      <div className={classes['header-details']}>
        <span>
          <FindInPage fontSize='inherit' /> {providerLabel}
        </span>

        <SearchViewCount viewed={viewed} />
      </div>
    </div>
  );
};

export default ComplianceHeader;
