import { handleError } from 'hooks/useFetch';
import apiClient from 'api/apiClient';
import { IAdverseTerm } from 'types/adverseTerms';

const fetchFavouriteAdverseTerms = async () => {
  try {
    return await apiClient<IAdverseTerm[]>({
      method: 'GET',
      endpoint: 'terms/favourites',
      version: 'v2',
    });
  } catch (err) {
    handleError(err);
    return { data: [] as IAdverseTerm[], error: err };
  }
};

export default fetchFavouriteAdverseTerms;
