import React, {
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
} from 'react';
import { useDispatch, useSelector } from 'context';
import { Fade } from '@mui/material';
import { deleteProject, fetchProjects } from 'hooks/useFetch';
import Table from 'components/Base/Table';
import { FolderOpen } from '@mui/icons-material';
import { SHOW_MODAL, UPDATE_PROJECTS_FILTER } from 'constants/actionTypes';
import classes from './projects-table.module.scss';
// TODO - resolve sharing common UI, just pulling this for now
import NameFilter from './ProjectTableFilters/NameFilter';
import DateFilter from './ProjectTableFilters/DateFilter';
import MyProjectsFilter from './ProjectTableFilters/MyProjectsFilter';
import ClearFilters from './ProjectTableFilters/ClearFilters';
import { SortableHeadCell } from './SortableHeadCell';
import { ProjectsFilter } from './types';
import ProjectTableRow from './ProjectTableRow';
import projectTableHeaders from './data/projectTableHeaders';

const EmptyNode = () => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

const ProjectTable: FunctionComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const listFilter: ProjectsFilter = useSelector(
    (state) => state.projectsFilter
  );
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [hasFetchedInitialProjects, setHasFetchedInitialProjects] =
    useState(false);

  const setListFilter = useCallback(
    ({
      disableFetch,
      ...filters
    }: { disableFetch?: boolean } & Partial<ProjectsFilter>): void => {
      dispatch({
        type: UPDATE_PROJECTS_FILTER,
        payload: {
          ...filters,
          shouldFetch: !disableFetch,
        },
      });
    },
    [dispatch]
  );

  const handleFetchProjects = useCallback(() => {
    const { date, owner, page, limit, name, sort } = listFilter;
    setLoading(true);
    fetchProjects({
      dateFrom: date.s,
      dateTo: date.e,
      owner,
      sort,
      name,
      offset: page * limit,
      limit,
      user,
    }).then((res) => {
      setHasFetchedInitialProjects(true);
      setLoading(false);
      setListFilter({ total: res.total, disableFetch: true });
      setProjects(res.projects);
    });
  }, [listFilter, user, setListFilter]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !hasFetchedInitialProjects && handleFetchProjects();

    if (listFilter.shouldFetch) {
      dispatch({
        type: UPDATE_PROJECTS_FILTER,
        payload: {
          shouldFetch: false,
        },
      });
      handleFetchProjects();
    }
  }, [
    dispatch,
    hasFetchedInitialProjects,
    handleFetchProjects,
    listFilter.shouldFetch,
  ]);

  const handleDelete = (id) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        variant: 'alert',
        name: 'project',
        type: 'project',
        handleSubmit: () => removeProject(id),
      },
    });
  };

  const removeProject = (id) => {
    deleteProject(id).then(() => {
      const shouldResetPage = projects.length === 1 && listFilter.page > 0;
      setListFilter({ page: shouldResetPage ? 0 : listFilter.page });
    });
  };

  const handleSort = (_, property) => {
    const isAsc = listFilter.orderBy === property && listFilter.order === 'asc';

    setListFilter({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
      sort: (isAsc ? '-' : '') + property,
    });
  };

  return (
    <Fade in timeout={250}>
      <div style={{ padding: '32px 20px' }}>
        <h2 className={classes.heading}>
          <FolderOpen />
          All Projects
        </h2>
        <div className={classes.filters}>
          <NameFilter
            name={listFilter.name}
            onChange={(updated) => setListFilter(updated)}
          />
          <DateFilter
            date={listFilter.date}
            onChange={(updated) => setListFilter(updated)}
          />
          <MyProjectsFilter
            user={user}
            owner={listFilter.owner}
            onChange={(updated) => setListFilter(updated)}
          />
          {(listFilter.date.s ||
            listFilter?.owner?.owner ||
            listFilter?.name) && (
            <ClearFilters onChange={(updated) => setListFilter(updated)} />
          )}
        </div>

        <Table
          headCells={[
            ...projectTableHeaders.map((cell) => ({
              parentClassName: cell.className,
              component: (
                <SortableHeadCell
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...cell}
                  order={listFilter.order}
                  orderBy={listFilter.orderBy}
                  handleSort={handleSort}
                />
              ),
            })),
            {
              parentClassName: classes['control-cell'],
              component: <EmptyNode />,
            },
          ]}
          rows={projects?.map((row, i) => {
            return (
              <ProjectTableRow
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...row}
                handleDelete={handleDelete}
                user={user?.email}
              />
            );
          })}
          page={listFilter.page}
          count={listFilter.total}
          loading={loading}
          onChangePage={(e, page) => setListFilter({ ...listFilter, page })}
        />
      </div>
    </Fade>
  );
};

export default ProjectTable;
