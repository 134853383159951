import { useEffect } from 'react';
import { useDispatch, useSelector } from 'context';
import { useLocation } from 'react-router-dom';
import { UPDATE_NAV, UPDATE_STATE } from 'constants/actionTypes';
import { fetchFindingsCounts } from 'hooks/useFetch';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import { reverse as url } from 'named-urls';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import { FindingsButton } from './styled';

const FindingsLink = () => {
  const project = useSelector((state) => state?.state?.project);
  const findings = useSelector((state) => state?.state?.findings);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (project?.id && findings == null) {
      fetchFindingsCounts(project.id).then((res) => {
        dispatch({
          type: UPDATE_STATE,
          payload: {
            findings: res?.hits?.total?.value || 0,
          },
        });
      });
    }
  }, [project?.id, dispatch, findings]);

  return (
    <FindingsButton
      id='matomo-findings-btn'
      to={url(appRoutingPaths.projects.project.findings, {
        project_id: project?.id,
      })}
      onClick={(e) => {
        dispatch({
          type: UPDATE_STATE,
          payload: {
            data: { loading: true },
            article: { loading: true },
          },
        });
        dispatch({
          type: UPDATE_NAV,
          payload: { returnTo: location.pathname },
        });
      }}
    >
      <SortOutlinedIcon />
      <h2>Findings</h2>
      <div>{findings || 0}</div>
    </FindingsButton>
  );
};

export default FindingsLink;
