import axios from 'axios';
import { apiHost, apiHeaders, fetchFailure } from 'hooks/useFetch';
import { store } from 'context';

export const logoutAuth = async ({
  onLogout,
}: {
  onLogout: () => void;
}): Promise<void> => {
  try {
    await axios.post(
      `${apiHost}/api/v1/auth/logout`,
      {},
      {
        headers: apiHeaders(),
      }
    );
    window.localStorage.removeItem('jwt');
    onLogout();
  } catch (error) {
    store.dispatch(fetchFailure(error));
  }
};

export default logoutAuth;
