import { useCallback, useMemo, useState } from 'react';
import AdverseTermsModal from 'pages/Projects/CreateAdverseTermModal/AdverseTermsModal';
// eslint-disable-next-line import/no-cycle
import SubjectSearchModal from 'components/SubjectSearch';
import CreateProjectModal from 'pages/Projects/CreateProjectModal/CreateProjectModal';
import { Modals } from 'appSrc/constants';
import { IAdverseTerm } from 'types/adverseTerms';
import { ModalContext } from './useModals';

const modals = {
  [Modals.ADVERSE_TERMS]: AdverseTermsModal,
  [Modals.SUBJECT_SEARCH]: SubjectSearchModal,
  [Modals.CREATE_PROJECT]: CreateProjectModal,
};

const ModalManagerProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState<any>(null);
  const [modal, setModal] = useState<string | null>(null);

  // Opens the modal with the given key and props
  const open = useCallback((key: string, props?: any) => {
    document.body.classList.add('no-scroll');
    setModal(key);
    if (props) setModalProps(props);
  }, []);

  // Closes the modal
  const close = useCallback(() => {
    document.body.classList.remove('no-scroll');
    setModal(null);
    setModalProps(null);
  }, []);

  const openAdverseTermsModal = useCallback(
    (term: IAdverseTerm) => {
      open(Modals.ADVERSE_TERMS, { term });
    },
    [open]
  );

  const value = useMemo(
    () => ({
      open,
      close,
      openAdverseTermsModal,
    }),
    [open, close, openAdverseTermsModal]
  );

  const Modal = modals[modal];

  return (
    <ModalContext.Provider value={value}>
      {children}
      {Modal && <Modal {...modalProps} />}
    </ModalContext.Provider>
  );
};

export default ModalManagerProvider;
