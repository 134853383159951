/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Calendar, createStaticRanges, DefinedRange } from 'react-date-range';
import Select from '../Fields/Select';
import InputText from '../Fields/InputText';
import IconButton from '../Button/IconButton';
import classes from './date-range.module.scss';
import 'react-date-range/dist/styles.css';
import './date-picker.css';

const DateRange = ({ state, setState }) => {
  return (
    <div className={classes.container}>
      <DefinedRange
        inputRanges={[]}
        onChange={({ selection }) =>
          setState({
            ...state,
            startDate: selection.startDate,
            endDate: selection.endDate,
          })
        }
        ranges={[
          {
            key: 'selection',
            startDate: state.startDate,
            endDate: state.endDate,
          },
        ]}
        staticRanges={createStaticRanges([
          {
            label: 'All time',
            range: () => ({
              startDate: new Date('1900-01-01'),
              endDate: state.maxDate,
            }),
          },
          {
            label: 'Last 10 years',
            range: () => ({
              startDate: moment(new Date()).subtract(10, 'year').toDate(),
              endDate: moment(new Date()).toDate(),
            }),
          },
          {
            label: 'Last 5 years',
            range: () => ({
              startDate: moment(new Date()).subtract(5, 'year').toDate(),
              endDate: moment(new Date()).toDate(),
            }),
          },
          {
            label: 'Last year',
            range: () => ({
              startDate: moment(new Date()).subtract(1, 'year').toDate(),
              endDate: moment(new Date()).toDate(),
            }),
          },
        ])}
      />
      <Calendar
        date={state.startDate}
        minDate={new Date('1900-01-01')}
        maxDate={state.endDate}
        onChange={(val) => setState({ ...state, startDate: val })}
        // eslint-disable-next-line react/no-unstable-nested-components
        navigatorRenderer={(shownDate, changeShownDate) => {
          return (
            <>
              <DateInput
                value={state.startDate}
                onChange={(val) => {
                  setState({ ...state, startDate: val });
                }}
              />
              <div className={classes['date-month']}>
                <IconButton
                  variant='arrow-backward'
                  onClick={() => {
                    changeShownDate(
                      moment(shownDate).subtract(1, 'month').toDate()
                    );
                  }}
                />
                <div className={classes['date-month__inner']}>
                  <Select
                    className={classes.dropdown}
                    variant='transparent'
                    value={moment(shownDate).format('MMMM')}
                    onChange={(e) =>
                      changeShownDate(
                        moment(shownDate)
                          .set({ month: e.target.value })
                          .toDate()
                      )
                    }
                    options={moment.months().map((month) => ({
                      value: month,
                      label: month,
                    }))}
                  />
                  <Select
                    className={classes.dropdown}
                    variant='transparent'
                    value={moment(shownDate).format('YYYY')}
                    onChange={(e) =>
                      changeShownDate(
                        moment(new Date(shownDate))
                          .set({ year: e.target.value })
                          .toDate()
                      )
                    }
                    options={[
                      ...new Array(
                        1 +
                          new Date().getFullYear() -
                          new Date('1900-01-01').getFullYear()
                      ).keys(),
                    ].map((_, i) => ({
                      value: 1900 + i,
                      label: 1900 + i,
                    }))}
                  />
                </div>
                <IconButton
                  size='small'
                  variant='arrow-forward'
                  onClick={() => {
                    changeShownDate(moment(shownDate).add(1, 'month').toDate());
                  }}
                />
              </div>
            </>
          );
        }}
      />
      <Calendar
        date={state.endDate}
        minDate={state.startDate}
        maxDate={new Date()}
        onChange={(val) => setState({ ...state, endDate: val })}
        // eslint-disable-next-line react/no-unstable-nested-components
        navigatorRenderer={(shownDate, changeShownDate) => {
          return (
            <>
              <DateInput
                value={state.endDate}
                onChange={(val) => {
                  setState({ ...state, endDate: val });
                }}
              />
              <div className={classes['date-month']}>
                <IconButton
                  variant='arrow-backward'
                  onClick={() => {
                    changeShownDate(
                      moment(shownDate).subtract(1, 'month').toDate()
                    );
                  }}
                />
                <div className={classes['date-month__inner']}>
                  <Select
                    className={classes.dropdown}
                    variant='transparent'
                    value={moment(shownDate).format('MMMM')}
                    onChange={(e) =>
                      changeShownDate(
                        moment(new Date(shownDate))
                          .set({ month: e.target.value })
                          .toDate()
                      )
                    }
                    options={moment.months().map((month) => ({
                      value: month,
                      label: month,
                    }))}
                  />
                  <Select
                    className={classes.dropdown}
                    variant='transparent'
                    value={moment(shownDate).format('YYYY')}
                    onChange={(e) =>
                      changeShownDate(
                        moment(new Date(shownDate))
                          .set({ year: e.target.value })
                          .toDate()
                      )
                    }
                    options={[
                      ...new Array(1 + state.maxYear - state.minYear).keys(),
                    ].map((_, i) => ({
                      value: state.minYear + i,
                      label: state.minYear + i,
                    }))}
                  />
                </div>
                <IconButton
                  size='small'
                  variant='arrow-forward'
                  onClick={() => {
                    changeShownDate(moment(shownDate).add(1, 'month').toDate());
                  }}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

const DateInput = ({ value, onChange }) => {
  const [date, setDate] = useState(moment(value).format('YYYY-MM-DD'));

  useEffect(() => {
    const formattedVal = moment(value).format('YYYY-MM-DD');
    if (date !== formattedVal) setDate(formattedVal);
  }, [value]);

  return (
    <div>
      <InputText
        className={classes.input}
        value={date}
        onChange={(e) => setDate(e.target.value)}
        error={!moment(date).isValid()}
        onBlur={() => {
          if (moment(date).isValid()) {
            setDate(moment(date).format('YYYY-MM-DD'));
            onChange(moment(date).toDate());
          } else setDate(value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (moment(date).isValid()) {
              setDate(moment(date).format('YYYY-MM-DD'));
              onChange(moment(date).toDate());
            } else setDate(value);
          }
        }}
      />
    </div>
  );
};

export default DateRange;
