import { StructuredRow } from '../StructuredRow';
import classes from '../../../article.module.scss';
import { capitaliseFirstLetters } from './helpers';

export const FurtherInformationSection = ({ details }) =>
  details ? (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Further Information</h4>
      {details.map((detail, i) => {
        const { title, text } = detail;
        return (
          <StructuredRow
            // eslint-disable-next-line react/no-array-index-key
            key={`detailType-${i}`}
            label={capitaliseFirstLetters(title)}
            value={text}
          />
        );
      })}
    </div>
  ) : null;

export default FurtherInformationSection;
