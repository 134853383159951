import { Table } from '../../Table';
import classes from '../../../article.module.scss';
import { capitaliseFirstLetters, convertAddressToString } from './helpers';

export const EventsSection = ({ showEmpty, events }) =>
  events && (showEmpty || events.length) > 0 ? (
    <div className={classes.structure}>
      <h4 className={classes['structure-title']}>Events</h4>
      <Table
        headCells={['Event', 'Date', 'Location']}
        rows={events.map((event) => {
          const { type, fullDate, address } = event;
          return [
            capitaliseFirstLetters(type),
            fullDate,
            convertAddressToString(address),
          ];
        })}
      />
    </div>
  ) : null;

export default EventsSection;
