import { handleError } from 'hooks/useFetch';
import apiClient from 'api/apiClient';
import { IAdverseTermCategory } from 'types/adverseTerms';

const fetchAdverseTermCategories = async () => {
  try {
    return await apiClient<IAdverseTermCategory[]>({
      method: 'GET',
      endpoint: 'terms/categories',
      version: 'v2',
    });
  } catch (err) {
    handleError(err);
    return { data: [] as IAdverseTermCategory[], error: err };
  }
};

export default fetchAdverseTermCategories;
