export const useHighlighter = ({ query, content }) => {
  if (query) {
    try {
      let str = query;
      let highlightedContent = content;
      let phraseMatches;

      // Remove operators
      str = str.replaceAll(/(AND)|(OR)|(NOT)/g, '');

      // Build array of phrase matches
      phraseMatches = str.match(/("(\s|\S)*?")|(\*\/?(\s|\S)*?\*)/g);
      phraseMatches = phraseMatches?.map((match) => match.replaceAll('"', ''));

      // Highlight phrase matches
      if (phraseMatches?.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < phraseMatches.length; i++) {
          let match = phraseMatches[i];
          str = str.replace(match, '');
          match = match.replaceAll(' ', '\\s');
          const regex = new RegExp(`(${match})`, 'gi');
          // eslint-disable-next-line @typescript-eslint/no-shadow
          highlightedContent = highlightedContent.replaceAll(regex, (match) => {
            return `<mark>${match}</mark>`;
          });
        }
        str = str.replaceAll(/(")|(\*)/g, '');
      }

      // Highlight word matches
      if (str) {
        const regex = new RegExp(
          str
            .split(/\s|\b/)
            .reduce((arr, item) => {
              if (item.length) {
                arr.push(item.trim());
              }
              return arr;
            }, [])
            .join('|'),
          'gi'
        );
        highlightedContent = highlightedContent.replaceAll(regex, (match) => {
          return `<mark>${match}</mark>`;
        });
      }
      return highlightedContent;
    } catch (err) {
      // Do nothing
    }
  }
  return content;
};

export default useHighlighter;
