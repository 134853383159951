/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import rootInitialState, { IRootInitialState } from 'store/rootInitialState';
import { SearchMode } from 'appSrc/constants';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: rootInitialState.ui,
  reducers: {
    hideSearchbarPopout: (state) => {
      state.searchBarPopout.visible = false;
    },
    showSearchbarPopout: (state) => {
      state.searchBarPopout.visible = true;
    },
    showAdverseTermsPopout: (state) => {
      state.adverseTermsPopout.visible = true;
    },
    hideAdverseTermsPopout: (state) => {
      state.adverseTermsPopout.visible = false;
    },
    showRedFlagCheckWarningModal: (state) => {
      state.redFlagCheckWarningModal.visible = true;
    },
    hideRedFlagCheckWarningModal: (state) => {
      state.redFlagCheckWarningModal.visible = false;
    },
    switchToClassicSearch: (state) => {
      state.searchMode = SearchMode.CLASSIC_SEARCH;
      state.searchBarPopout.visible = true;
    },
    switchToSubjectSearch: (state) => {
      state.searchMode = SearchMode.SUBJECT_SEARCH;
      state.searchBarPopout.visible = false;
    },
  },
});

export const {
  showAdverseTermsPopout,
  hideAdverseTermsPopout,
  showSearchbarPopout,
  hideSearchbarPopout,
  showRedFlagCheckWarningModal,
  hideRedFlagCheckWarningModal,
  switchToClassicSearch,
  switchToSubjectSearch,
} = uiSlice.actions;

export const selectAdverseTermsPopoutVisible = (state: IRootInitialState) =>
  state.ui.adverseTermsPopout.visible;
export const selectSearchbarPopoutVisible = (state: IRootInitialState) =>
  state.ui.searchBarPopout.visible;
export const selectRedFlagCheckWarningModalVisible = (
  state: IRootInitialState
) => state.ui.redFlagCheckWarningModal.visible;
export const selectSearchMode = (state: IRootInitialState) =>
  state.ui.searchMode;

export default uiSlice.reducer;
