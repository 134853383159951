import { Skeleton } from '@mui/material';
import classes from './article.module.scss';

export const SkeletonArticle = () => {
  return (
    <>
      <Skeleton className={classes.title} />
      <Skeleton style={{ width: '160px' }} className={classes.subtitle} />
      <div className={classes.content}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton style={{ width: '40%' }} />
      </div>
      <div className={classes.content}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton style={{ width: '40%' }} />
      </div>
      <div className={classes.content}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton style={{ width: '40%' }} />
      </div>
    </>
  );
};

export const SkeletonArticleBody = () => {
  return (
    <>
      <div className={classes.content}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton style={{ width: '40%' }} />
      </div>
      <div className={classes.content}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton style={{ width: '40%' }} />
      </div>
    </>
  );
};
