import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import classNames from 'classnames';
import classes from './chip.module.scss';

const Chip = ({ label, variant, size, onClick = undefined }) => {
  return (
    <MuiChip
      label={label}
      size='small'
      onClick={onClick}
      classes={{
        root: classNames(
          classes.chip,
          classes[`chip--${variant}`],
          classes[`chip--${size}`]
          // { [className]: className }
        ),
        label: classes.label,
        clickable: classes.clickable,
      }}
    />
  );
};
export default Chip;
