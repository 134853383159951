import PersonAddIcon from '@mui/icons-material/PersonAdd';
import React, { useState } from 'react';
import { useDispatch } from 'context';
import { UPDATE_STATE } from 'constants/actionTypes';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styles from './AddToSearch.module.scss';

export const AddToSearch = ({ term }) => {
  const [added, setAdded] = useState(false);
  const dispatch = useDispatch();

  const addToSearch = () => {
    setAdded(true);
    dispatch({
      type: UPDATE_STATE,
      payload: {
        newSearchTerms: [term],
      },
    });
  };

  return added ? (
    <CheckCircleOutlineIcon className={styles.complete} />
  ) : (
    <PersonAddIcon className={styles.add} onClick={addToSearch} />
  );
};

export default AddToSearch;
