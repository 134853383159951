import React from 'react';
import classes from './icons-base.module.scss';

// <i className="fal fa-angle-right"></i>
// https://fontawesome.com/v5.15/icons/angle-right
// SVGs in download from https://fontawesome.com/download

const IconAngleRight = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fal'
      data-icon='angle-right'
      className={classes['fa-inline-icon--angle-arrow']}
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <path
        fill='currentColor'
        d='M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z'
      />
    </svg>
  );
};

export default IconAngleRight;
