import React from 'react';
import { useDispatch } from 'context';
import { Modal as MuiModal, Button, Typography, SvgIcon } from '@mui/material';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import { HIDE_MODAL } from 'constants/actionTypes';
import classes from './modal.module.scss';

const Modal = (props) => {
  const dispatch = useDispatch();
  const {
    type,
    name,
    title,
    isOpen = true,
    handleClose = () => {
      dispatch({ type: HIDE_MODAL });
    },
    handleSubmit,
    disableDelete,
    content,
    renderContent,
    buttonLabel,
    variant = 'primary',
    size,
    data,
  } = props;

  switch (variant) {
    case 'alert':
      return (
        <MuiModal
          className={classes.container}
          open={isOpen}
          onClose={handleClose}
        >
          <div
            className={classNames(
              classes.modal,
              classes[`modal--${size}`],
              classes['modal--alert']
            )}
          >
            <div className={classes.content}>
              <div className={classes.icon__wrap}>
                <SvgIcon className={classes.icon}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                    />
                  </svg>
                </SvgIcon>
              </div>
              <div>
                <Typography className={classes.title}>
                  Remove {type || 'item'}
                </Typography>
                <Typography className={classes.text}>
                  Are you sure you want to remove this {type || 'item'}? All of
                  your data will be permanently removed. This action cannot be
                  undone.
                </Typography>
              </div>
            </div>
            <div className={classes.controls}>
              <Button onClick={handleClose} className={classes.button}>
                Cancel
              </Button>
              {!disableDelete && (
                <Button
                  id='matomo-delete-item-btn'
                  onClick={() => {
                    handleSubmit();
                    dispatch({ type: HIDE_MODAL });
                  }}
                  className={classNames(
                    classes.button,
                    classes['button--alert']
                  )}
                >
                  Remove {name || 'item'}
                </Button>
              )}
            </div>
          </div>
        </MuiModal>
      );
    default:
      return (
        <MuiModal
          className={classes.container}
          open={isOpen}
          onClose={handleClose}
        >
          <form
            className={classNames(classes.modal, classes[`modal--${size}`])}
            onSubmit={(e) => {
              handleSubmit(e, data);
              dispatch({ type: HIDE_MODAL });
            }}
          >
            <Typography className={classes.title}>{title}</Typography>
            <div className={classes.content}>
              <SimpleBar style={{ width: '100%' }}>
                <div className={classes.inner}>
                  {content || renderContent(data)}
                </div>
              </SimpleBar>
            </div>
            <div className={classes.controls}>
              <Button onClick={handleClose} className={classes.button}>
                Cancel
              </Button>
              <Button
                type='submit'
                className={classNames(
                  classes.button,
                  classes['button--primary']
                )}
              >
                {buttonLabel || 'Save'}
              </Button>
            </div>
          </form>
        </MuiModal>
      );
  }
};

export default Modal;
