/* eslint-disable import/no-cycle */
import { FETCH_FAILURE, LOGIN } from 'constants/actionTypes';
import { store } from 'context';

export * from 'context';

export const apiHost = process.env.REACT_APP_API_HOST;
export const apiHeaders = () => ({
  Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
  'Content-Type': 'application/json',
});

export const fetchFailure = (error) => ({
  type: FETCH_FAILURE,
  payload: {
    error,
  },
});

export const handleError = (error) => {
  store.dispatch(fetchFailure(error));
};

export const handleLogin = (authResponse) => {
  const accessToken = authResponse.access_token;
  window.localStorage.setItem('jwt', accessToken);
  store.dispatch({ type: LOGIN });
};

export * from './projects/createProject';
export * from './projects/fetchProject';
export * from './projects/fetchProjects';
export * from './projects/editProjectPrivacy';
export * from './projects/editProject';
export * from './projects/deleteProject';
export * from './projects/createUsersShared';
export * from './projects/fetchUsersShared';
export * from './projects/deleteUsersShared';

export * from './queries/submitQuery';
export * from './queries/fetchItems';
export * from './queries/fetchProjectItem';
export * from './queries/fetchProjectItemLink';
export * from './queries/fetchQueryItems';
export * from './queries/fetchMoreQueryItems';
export * from './queries/deleteQuery';
export * from './queries/submitTranslation';
export * from './queries/submitTranslationById';
export * from './queries/submitArticleTranslation';

export * from './searches/submitSearch';
export * from './searches/editSearch';
export * from './searches/deleteSearch';

export * from './findings/fetchFindingsQueryItems';
export * from './findings/fetchFindingsCounts';
export * from './findings/submitTag';
export * from './findings/submitAllTags';
export * from './findings/fetchTags';
export * from './findings/deleteTag';

export * from './articles/submitArticleView';
export * from './articles/submitItemFetch';
export * from './articles/submitRetrieveContent';
export * from './articles/fetchRelatedItems';
export * from './articles/submitRelatedActive';
export * from './articles/submitRelatedInactive';
export * from './articles/submitRelatedItem';

export * from './users/fetchCurrentUser';
export * from './users/fetchUsers';

export * from './config/fetchConfig';
