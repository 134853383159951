/* eslint-disable jsx-a11y/no-static-element-interactions */
import { defaultState, useSelector, useDispatch } from 'context';
import { Skeleton } from '@mui/material';
import { serialiseData } from 'hooks/useSerialiser';
import uris from 'constants/uris.json';
import QueryString from 'query-string';
import { submitQuery } from 'hooks/useFetch';
import { reverse as url } from 'named-urls';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import { useNavigate } from 'react-router-dom';
import { UPDATE_STATE } from 'constants/actionTypes';
import classes from './results.module.scss';
import ResultsItem from './ResultsItem';

const ResultsPendingMessage = () => {
  return (
    <>
      We&apos;re still waiting for a response to your search query.{' '}
      <span
        style={{
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={() => window.location.reload()}
      >
        Click here
      </span>{' '}
      to refresh.
    </>
  );
};

const ResultsErrorMessage = ({
  message = 'An error occured',
  retrySearchFn,
  project = { name: 'default-set-project', id: 'default-set-project-id' },
  query = { id: 'default-set-query-id' },
  provider = { id: 'default-set-provider-id' },
}) => {
  return (
    <>
      <div className={classes['no-results-detail']}>
        An error occured which prevented results being returned:
      </div>
      <div className={classes['no-results-msg']}>”{message}”</div>
      <div>
        You can{' '}
        <span className={classes['retry-search']} onClick={retrySearchFn}>
          {' '}
          try your search again{' '}
        </span>
        or{' '}
        <a href={emailSupport(message, project, query, provider)}>
          report any persistent issues
        </a>
        .
      </div>
    </>
  );
};

const NoResultsMessage = () => {
  return <div>Your search query did not return any results.</div>;
};

const emailSupport = (message, project, query, provider) => {
  return `${uris['email.support']}?${QueryString.stringify({
    subject: `S-RM SWAN error: ${message}`,
    body:
      `Project Name: ${project.name}\r\n` +
      `Project ID: ${project.id}\r\n` +
      `Query ID: ${query.id}\r\n` +
      `Provider ID: ${provider.id}\r\n` +
      `Error Description: ${message}`,
  })}`;
};

const ResultsList = ({ newQuery = false, ...props }) => {
  const navigate = useNavigate();
  const isFindings = !!props?.isFindings;
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const user = useSelector((state) => state.config);
  const article = useSelector((state) => state.state.article);
  const project = useSelector((state) => state.state.project);
  const search = useSelector((state) => state.state.search);
  const data = useSelector((state) => state.state.data);
  const provider = useSelector((state) => state.state.provider);
  const query = useSelector((state) => state.state.query);
  const translate = useSelector((state) => state.state.translate);
  const emitter = useSelector((state) => state.state.emitter);
  const expecting = useSelector((state) => state.state.expecting);

  const isCombinedList = () => {
    const providersInList = search.providers.filter((searchProvider) =>
      searchProvider.id.startsWith(provider.id)
    );
    return providersInList.length > 1;
  };

  const navigateToArticle = (id) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        translate: {
          ...translate,
          article: false,
        },
        article: { id, loading: true },
        expecting: {
          ...expecting,
          article: true,
        },
        retrieving: false,
      },
    });
  };

  const retrySearch = async () => {
    const queryData = await submitQuery(
      project.id,
      search.id,
      provider.id,
      query.query_string,
      query.query_facets
    );
    dispatch({
      type: UPDATE_STATE,
      payload: {
        ...defaultState,
        queryId: queryData.query_id,
      },
    });
    navigate(
      url(
        appRoutingPaths.projects.project.searches.search.provider.query.show,
        {
          project_id: project.id,
          search_id: search.id,
          provider_id: provider.id,
          query_id: queryData.query_id,
        }
      )
    );
  };

  const renderMessage = () => {
    if (newQuery) return <ResultsPendingMessage />;
    if (
      emitter?.status === 'search.error' ||
      emitter?.status === 'search.processing.error'
    )
      return (
        <ResultsErrorMessage
          message={emitter.message}
          retrySearchFn={() => {
            retrySearch();
          }}
          project={project}
          query={query}
          provider={provider}
        />
      );

    if (query?.status_message)
      return (
        <ResultsErrorMessage
          message={query.status_message}
          retrySearchFn={() => {
            retrySearch();
          }}
          project={project}
          query={query}
          provider={provider}
        />
      );
    // if (queryData?.processed === true && totalResults === 0)
    return <NoResultsMessage />;
  };

  if (!data.items?.[0])
    return (
      <div>
        <div className={classes['no-results']}>{renderMessage()}</div>
        <div className={classes.borders} />
      </div>
    );
  return (
    <div className={classes.container}>
      {data.items.map((item) => (
        <ResultsItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...serialiseData({ item, translate })}
          articleId={article.id}
          setArticle={(id) =>
            item._id !== article.id ? navigateToArticle(id) : null
          }
          projectId={project.id}
          key={item._id}
          // articleFinding={articleFinding}
          currentUser={user.email}
          queryString={query.query_string}
          translate={translate}
          providerLabel={config?.providerLabels?.[item?._source?.provider?.id]}
          isFindings={isFindings}
          isCombinedList={isCombinedList}
        />
      ))}
      <div className={classes.borders} />
    </div>
  );
};

const SkeletonListItem = () => {
  return (
    <div className={classes.skeleton}>
      <Skeleton style={{ width: '50%', height: '21px' }} />
      <Skeleton style={{ width: '100%', height: '21px' }} />
      <Skeleton style={{ width: '100%', height: '21px' }} />
      <Skeleton style={{ width: '25%', height: '21px' }} />
    </div>
  );
};

export const SkeletonList = ({ total = 25 }) => {
  return (
    <>
      {Array(total)
        .fill(null)
        .map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <SkeletonListItem key={`skeleton-list-item${i}`} />
        ))}
    </>
  );
};

export default ResultsList;
