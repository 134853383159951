import { TableSortLabel } from '@mui/material';
import React from 'react';
import classes from './projects-table.module.scss';

export const SortableHeadCell = ({ handleSort, order, orderBy, id, label }) => {
  return (
    <TableSortLabel
      active={orderBy === id}
      direction={orderBy === id ? order : 'desc'}
      onClick={(e) => handleSort(e, id)}
      key={`${id}-th`}
    >
      {label}
      {orderBy === id ? (
        <span className={classes.hidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </span>
      ) : null}
    </TableSortLabel>
  );
};

export default SortableHeadCell;
