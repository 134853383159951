import React, { useEffect, useCallback, useState } from 'react';
import { defaultState, useDispatch, useSelector } from 'context';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { reverse as url } from 'named-urls';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import Select from 'components/Base/Fields/Select';
import Toggle from 'components/Base/Fields/Toggle';
import { submitQuery } from 'hooks/useFetch';
import { getTranslatedCount, getTranslatedReqCount } from 'utils';
import LinearProgress from '@mui/material/LinearProgress';
import useHandleTranslation from 'hooks/useHandleTranslation';
import { UPDATE_STATE } from 'constants/actionTypes';
import classes from './search-results.module.scss';
import ItemsCount from './ItemsCount';

const SearchResultsHeader = ({ counts }) => {
  const navigate = useNavigate();
  const config = useSelector((state) => state.config);
  const page = useSelector((state) => state.state.page);
  const project = useSelector((state) => state.state.project);
  const search = useSelector((state) => state.state.search);
  const provider = useSelector((state) => state.state.provider);
  const query = useSelector((state) => state.state.query);
  const data = useSelector((state) => state.state.data);
  const translate = useSelector((state) => state.state.translate);

  const variant = provider.id.split('.')[0];
  const providerConfig = config.providers.find((cfg) => cfg.id === provider.id);
  const {
    queryItemCounts,
    queryItemCountsRelation,
    queryItemCountsNoDuplicates,
  } = counts;
  const [options, setOptions] = useState([]);
  const [passiveLoading, setPassiveLoading] = useState(false);
  const dispatch = useDispatch();

  const handleTranslation = useHandleTranslation('search');

  const handleQuerySort = useCallback(
    (value) => {
      const selectedQuery = find(provider.queries, {
        query_string: query.query_string,
        query_facets: query.query_facets,
        sort: value,
      });

      if (selectedQuery) {
        dispatch({
          type: UPDATE_STATE,
          payload: {
            ...defaultState,
            project,
            search,
            provider,
            expecting: { items: true, article: true },
            query: selectedQuery,
            queryId: selectedQuery.id,
          },
        });
        navigate(
          url(
            appRoutingPaths.projects.project.searches.search.provider.query
              .show,
            {
              project_id: project.id,
              search_id: search.id,
              provider_id: provider.id,
              query_id: selectedQuery.id,
            }
          )
        );
      } else {
        submitQuery(
          project.id,
          search.id,
          provider.id,
          query.query_string,
          query.query_facets,
          value
        ).then((responseData) => {
          // TODO - set up common dispatch for new queries
          dispatch({
            type: UPDATE_STATE,
            payload: {
              ...defaultState,
              newQuery: true,
              expecting: { items: true, article: true },
              queryId: responseData.query_id,
            },
          });
          navigate(
            `${url(
              appRoutingPaths.projects.project.searches.search.provider.query
                .show,
              {
                project_id: project.id,
                search_id: search.id,
                provider_id: provider.id,
                query_id: responseData.query_id,
              }
            )}?new_query=1`
          );
        });
      }
    },
    [
      dispatch,
      navigate /* project.id, provider.id, provider.queries, */,
      query.query_facets,
      query.query_string /* search.id */,
      project,
      search,
      provider,
    ]
  );

  useEffect(() => {
    const sortOptions = [
      {
        value: 'when-desc',
        label: 'Newest First',
        onClick: () => handleQuerySort('when-desc'),
      },
      {
        value: 'when-asc',
        label: 'Oldest First',
        onClick: () => handleQuerySort('when-asc'),
      },
      {
        value: 'relevance-desc',
        label: 'Most Relevant',
        onClick: () => handleQuerySort('relevance-desc'),
      },
    ].filter((opt) => {
      if (providerConfig?.dispatch?.sort_default === '') return true;
      if (providerConfig?.dispatch?.sort_default === opt.value) return true;
      if (!providerConfig?.dispatch?.sort_options) return true;
      if (providerConfig?.dispatch?.sort_options.indexOf(opt.value) !== -1)
        return true;
      return false;
    });
    setOptions(sortOptions);
  }, [handleQuerySort, providerConfig]);

  useEffect(() => {
    const loading =
      translate.items &&
      getTranslatedCount(data.items) < getTranslatedReqCount(data.items);
    setPassiveLoading(loading);
  }, [data.items, translate.items]);

  return (
    <>
      {passiveLoading && (
        <div className={classes.loading}>
          <LinearProgress color='inherit' />
        </div>
      )}
      <div className={classes.header}>
        <div>
          {query.sort && options.length > 1 && (
            <Select
              variant='grey-uppercase'
              value={query.sort}
              options={options}
            />
          )}
        </div>
        <div>
          {variant === 'news' && (
            <Toggle
              id='matomo-translate-results-btn'
              toggleLabel='Translate results to English'
              size='small'
              checked={translate?.items}
              onClick={(e) => {
                e.preventDefault();
                handleTranslation();
              }}
            />
          )}
        </div>
        <div>
          <ItemsCount
            page={page}
            totalResults={queryItemCounts}
            totalResultsRelation={queryItemCountsRelation}
            totalResultsNoDuplicates={queryItemCountsNoDuplicates}
          />
        </div>
      </div>
    </>
  );
};

export default SearchResultsHeader;
