/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { SvgIcon } from '@mui/material';
import { useDispatch } from 'context';
import Button from 'components/Base/Button';
import { SHOW_TOAST } from 'constants/actionTypes';
// import classes from "./article.module.scss";

const CopyReference = ({ data }) => {
  const { trackEvent } = useMatomo();
  const dispatch = useDispatch();
  const copyRef = useRef(null);

  const handleCopy = () => {
    trackEvent({ category: 'article', action: 'copy_reference' });

    const str = copyRef.current.innerHTML;
    function listener(e) {
      e.clipboardData.setData('text/html', str);
      e.clipboardData.setData('text/plain', str);
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    dispatch({
      type: SHOW_TOAST,
      payload: {
        variant: 'reference',
        message: (
          <>
            Reference copied: '{data.title}',{' '}
            {data.end_url &&
              ((
                <a href={data.end_url}>
                  <em>{data.category}</em>
                </a>
              ) || <em>{data.category}</em>)}
            , {data.date}
          </>
        ),
      },
    });
  };

  return (
    <>
      <Button
        label={
          <>
            <SvgIcon fontSize='inherit'>
              <path d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z' />
            </SvgIcon>
            &nbsp;Copy reference
          </>
        }
        variant='secondary'
        size='small'
        onClick={handleCopy}
      />
      <span ref={copyRef} style={{ display: 'none' }}>
        '{data.title}',{' '}
        {(data.end_url && (
          <a href={data.end_url}>
            <em>{data.category}</em>
          </a>
        )) || <em>{data.category}</em>}
        , {data.date}
      </span>
    </>
  );
};

export default CopyReference;
