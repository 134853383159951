import { useCallback } from 'react';
import {
  UPDATE_TRANSLATE_DOCUMENTS_UPDATED,
  UPDATE_TRANSLATE_ITEMS_UPDATED,
} from 'constants/actionTypes';
import { AsyncMessage } from '../types';
import useAsyncMessageHandler from '../useAsyncMessageHandler';

const TranslateDocumentsUpdatedHandler = (
  articleId: string,
  translate,
  data,
  dispatch
) =>
  useAsyncMessageHandler(
    'translate.documents_updated',
    useCallback(
      (message: AsyncMessage) => {
        if (
          articleId &&
          message.attributes?.es_ids?.indexOf(articleId) !== -1 &&
          translate.article
        ) {
          dispatch({
            type: UPDATE_TRANSLATE_DOCUMENTS_UPDATED,
            payload: {},
          });
        }
        if (
          message.attributes?.es_ids?.length > 0 &&
          translate.items &&
          data?.items
        ) {
          const ids = data.items
            .filter(
              (item) =>
                !item._source.headline_trans && item._source.language !== 'en'
            )
            .map((item) => item._id);

          const x = ids.some(
            (id) => message.attributes?.es_ids?.indexOf(id) !== -1
          );

          if (x) {
            dispatch({
              type: UPDATE_TRANSLATE_ITEMS_UPDATED,
              payload: {},
            });
          }
        }
      },
      [articleId, translate, data, dispatch]
    )
  );

export default TranslateDocumentsUpdatedHandler;
