import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store, apiHeaders, apiHost, fetchFailure } from '..';

export const editProjectPrivacy = (projectId, isPrivate) => {
  const data = JSON.stringify({
    private: isPrivate,
  });

  const request = axios.patch(
    `${apiHost}/api/v1/projects/${projectId}/private`,
    data,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      return res;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default editProjectPrivacy;
