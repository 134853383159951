import { useDispatch } from 'context';
import ProviderUpdatedHandler from './ProviderUpdatedHandler';

const GlobalAsyncMessageHandlers = ({ children }) => {
  const dispatch = useDispatch();

  ProviderUpdatedHandler(dispatch);

  return children;
};

export default GlobalAsyncMessageHandlers;
