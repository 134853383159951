import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiHeaders, apiHost, handleError } from '..';

export const fetchProject = async (id) => {
  const response = await axios
    .get(`${apiHost}/api/v1/projects/${id}`, {
      headers: apiHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      handleError(err);
    });

  return response;
};

export default fetchProject;
