import Button from 'components/BaseNew/Button/Button';
import { useModals } from 'hooks/ModalManager/useModals';
import { Modals } from 'appSrc/constants';
import { HeaderText, SubheaderText, HeaderContainer } from './styled';

const ProjectsPageHeader = () => {
  const { open } = useModals();

  return (
    <HeaderContainer>
      <div>
        <HeaderText>SWAN Projects</HeaderText>
        <SubheaderText>Select project or create new project</SubheaderText>
      </div>
      <div>
        <Button onClick={() => open(Modals.CREATE_PROJECT)} variant='contained'>
          Create new project
        </Button>
      </div>
    </HeaderContainer>
  );
};

export default ProjectsPageHeader;
