import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SvgIcon } from '@mui/material';
import { TableRow, TableCell, TableCellActions } from 'components/Base/Table';
import IconButton from 'components/Base/Button/IconButton';
import classes from './projects-table.module.scss';

export const ProjectTableRow = ({
  id,
  name,
  private: isPrivate,
  owner,
  owner_name: ownerName,
  created,
  shared,
  handleDelete,
  user,
}) => {
  const SharedWith = ({ shared: sharedList }) => {
    if (!sharedList?.length) return null;
    const limit = 10;
    if (sharedList.length <= limit)
      return sharedList.map((item) => item.name).join(', ');

    let list = `${sharedList
      .slice(0, limit - 1)
      .map((item) => item.name)
      .join(', ')}…`;
    const n = sharedList.length - limit + 1;
    // eslint-disable-next-line no-return-assign
    return (list += ` and ${n} others`);
  };

  return (
    <TableRow key={`row-${id}`} className={classes.row}>
      <TableCell key={1} className={classes['project-column']}>
        <div className={classes['cell-inner']}>
          <Link to={`/projects/${id}`}>{name}</Link>{' '}
          {isPrivate && (
            <SvgIcon className={classes.private}>
              <path d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z' />{' '}
            </SvgIcon>
          )}
        </div>
      </TableCell>
      <TableCell key={2}>{ownerName}</TableCell>
      <TableCell key={3}>
        {!isPrivate ? (
          <i className={classes.everyone}>All users</i>
        ) : (
          <SharedWith shared={shared} />
        )}
      </TableCell>
      <TableCell key={4}>
        {moment(created).diff(moment(), 'days') < -5
          ? moment(created).format('DD/MM/YYYY')
          : moment(created).fromNow()}
      </TableCell>
      <TableCell key={5}>
        <TableCellActions>
          {user && owner && user.toLowerCase() === owner.toLowerCase() ? (
            <IconButton onClick={() => handleDelete(id)} variant='delete' />
          ) : null}
        </TableCellActions>
      </TableCell>
    </TableRow>
  );
};

export default ProjectTableRow;
