import { useEffect, useRef } from 'react';
import { defaultInitState, useSelector, useDispatch } from 'context';
import Layout from 'layout/Layout';
import Spinner from 'components/Base/Spinner';
import { useMatomo } from '@datapunt/matomo-tracker-react';
// eslint-disable-next-line import/no-cycle
import { UPDATE_STATE } from 'constants/actionTypes';
import { useIsFeatureEnabled } from 'hooks/useIsFeatureEnabled';
// eslint-disable-next-line import/no-cycle
import SearchHeader from 'components/SearchHeader';
import ProjectTable from './ProjectsTable/ProjectTable';
import ProjectsPageHeader from './ProjectsPageHeader/ProjectsPageHeader';

const Projects = () => {
  const { trackPageView } = useMatomo();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const pageViewed = useRef(false);

  const { subjectSearch: isSubjectSearchEnabled } = useIsFeatureEnabled([
    'subjectSearch',
  ]);

  useEffect(() => {
    if (!pageViewed.current) {
      pageViewed.current = true; // trackPageView dependency updates a few times
      trackPageView();
    }
  }, [trackPageView]);

  useEffect(() => {
    dispatch({
      type: UPDATE_STATE,
      payload: { ...defaultInitState },
    });
  }, [dispatch]);

  return (
    <Layout title='Projects' header='Projects' variant='table' isScrollable>
      {user.email ? (
        <>
          {isSubjectSearchEnabled ? (
            <ProjectsPageHeader />
          ) : (
            // Old header without subject search feature
            <SearchHeader variant='projects' />
          )}
          <ProjectTable />
        </>
      ) : (
        <Spinner />
      )}
    </Layout>
  );
};

export default Projects;
