import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import Chip from 'components/Base/Chip';
import CheckIcon from '@mui/icons-material/Check';
import { IAdverseTerm } from 'types/adverseTerms';
import React from 'react';
import {
  selectAdverseTerm,
  selectSelectedAdverseTerm,
} from 'appSrc/store/slices/adverseTerms';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import AdverseTermsFavourite from './AdverseTermsFavourite';

interface AdverseTermItemProps {
  term: IAdverseTerm;
}

const AdverseTermItem: React.FC<AdverseTermItemProps> = ({ term }) => {
  const selectedTerm = useAppSelector(selectSelectedAdverseTerm);
  const dispatch = useAppDispatch();
  const [openTooltip, setOpenTooltip] = React.useState<number | null>(null);

  return (
    <Tooltip
      key={term.id}
      open={openTooltip === term.id}
      title={term?.queries?.[0]?.query}
      placement='left'
      arrow
    >
      <ListItemButton
        key={term.id}
        selected={selectedTerm.term?.id === term.id}
        onClick={() => dispatch(selectAdverseTerm(term))}
      >
        {selectedTerm.term?.id === term.id && (
          <ListItemIcon>
            <CheckIcon color='primary' />
          </ListItemIcon>
        )}
        <ListItemText primary={term.name} />
        <ListItemIcon>
          <Tooltip title={term.language.name} placement='bottom'>
            <div>
              <Chip
                label={term.language.code.toUpperCase()}
                size='small'
                variant='grey'
                onClick={null}
              />
            </div>
          </Tooltip>
        </ListItemIcon>
        <AdverseTermsFavourite term={term} />
        <ListItemIcon
          aria-label='comments'
          onMouseEnter={() => setOpenTooltip(term.id)}
          onMouseLeave={() => setOpenTooltip(null)}
        >
          <CommentIcon fontSize='small' />
        </ListItemIcon>
      </ListItemButton>
    </Tooltip>
  );
};
export default AdverseTermItem;
