import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import uiReducer from './slices/ui';
import adverseTermsReducer from './slices/adverseTerms';

export const rootReducer = {
  ui: uiReducer,
  adverseTerms: adverseTermsReducer,
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
