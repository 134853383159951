import React, { useCallback } from 'react';
import moment from 'moment';
import { UPDATE_FINDINGS_FILTER, UPDATE_STATE } from 'constants/actionTypes';
import { useDispatch, useSelector } from 'context';
import Facets from '../Facets';

// type FindingFacetsProps = {};

const FindingsFacets = (/* {}: FindingFacetsProps */) => {
  const config = useSelector((state) => state.config);
  const searches = useSelector((state) => state.state.searches);
  const availableTags = useSelector((state) => state.state.availableTags);
  const findingsFilter = useSelector(
    (state) =>
      state.state.findingsFilter || {
        tags: [],
        sources: [],
        searches: [],
        date: {},
      }
  );
  const dispatch = useDispatch();

  const defaultDate = () => {
    return {
      from: '1900-01-01',
      to: new Date().toISOString().slice(0, 10),
    };
  };

  const defaultDateOptions = () => {
    const date = defaultDate();
    return [
      {
        value: date.from,
        display: date.from,
        count: -1,
      },
      {
        value: date.to,
        display: date.to,
        count: -1,
      },
    ];
  };

  const handleFilter = () => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        page: 1,
        findingsFilter: { ...findingsFilter, apply: Math.random() },
      },
    });
  };

  const handleCheckboxFilter = (name, val) => {
    let ar = findingsFilter[name];
    if (ar.indexOf(val) === -1) ar.unshift(val);
    else ar = ar.filter((el) => el !== val);
    dispatch({
      type: UPDATE_STATE,
      payload: { findingsFilter: { ...findingsFilter, [name]: ar } },
    });
  };

  const handleTagFilter = (e) => {
    handleCheckboxFilter('tags', e.target.value);
  };

  const handleSourceFilter = (e) => {
    handleCheckboxFilter('sources', e.target.value);
  };

  const handleSearchFilter = (e) => {
    handleCheckboxFilter('searches', e.target.value);
  };

  const handleDateFilter = useCallback(
    (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
      dispatch({
        type: UPDATE_FINDINGS_FILTER,
        payload: {
          date: {
            from: moment(startDate).format('YYYY-MM-DD'),
            to: moment(endDate).format('YYYY-MM-DD'),
          },
        },
      });
    },
    [dispatch]
  );

  const handleDateFilterCancel = () => {
    dispatch({
      type: UPDATE_STATE,
      payload: { findingsFilter: { ...findingsFilter, date: defaultDate() } },
    });
  };

  return (
    <Facets
      handleSubmit={handleFilter}
      items={[
        {
          filter: 'date',
          name: 'Timeline',
          handleDateFilter,
          handleDateFilterCancel,
          options: defaultDateOptions(),
        },
        {
          filter: 'checkbox',
          name: 'Tags',
          options:
            availableTags?.filter((items: string) => items !== 'Finding') || [],
          selected: findingsFilter.tags,
          handleChange: handleTagFilter,
          isSelected: (sel: any) => {
            return findingsFilter.tags.indexOf(sel) > -1;
          },
        },
        {
          filter: 'checkbox',
          name: 'Source',
          control: 'source',
          options: config.providers.map((provider: { id: any; label: any }) => {
            return {
              label: provider.label,
              id: provider.id,
              value: provider.id,
            };
          }),
          selected: findingsFilter.sources.map((source: any) => {
            return { label: source, value: source, providerControl: 'source' };
          }),
          isSelected: (sel: { id: any }) => {
            return findingsFilter.sources.includes(sel.id);
          },
          handleChange: handleSourceFilter,
        },
        {
          filter: 'checkbox',
          name: 'Search',
          control: 'search',
          options: searches.map((search: { name: any; id: any }) => {
            return {
              label: search.name,
              id: search.id,
              value: search.id,
            };
          }),
          selected: findingsFilter.searches.map((search: any) => {
            return { label: search, value: search, providerControl: 'search' };
          }),
          isSelected: (sel: { id: any }) => {
            return findingsFilter.searches.includes(sel.id);
          },
          handleChange: handleSearchFilter,
        },
      ]}
      disabled={undefined}
    />
  );
};

export default FindingsFacets;
