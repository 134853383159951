/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import {
  ClickAwayListener,
  Grow,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
  Paper,
  Popper as MuiPopper,
} from '@mui/material';
import classes from './menu.module.scss';

const Menu = ({ open, handleClose, anchorRef, children }) => {
  return (
    <>
      {/* // Added to solve Staging build error.
    // @ts-ignore */}
      <MuiPopper
        open={open}
        anchorEl={anchorRef?.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper classes={{ root: classes.container }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>{children}</div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </MuiPopper>
    </>
  );
};

export const MenuList = ({ children, options }) => {
  return (
    <MuiMenuList>
      {options?.map((item, i) => <MenuItem key={i} {...item} />) || children}
    </MuiMenuList>
  );
};

export const MenuItem = ({ children, label, onClick, ...props }) => {
  return (
    <MuiMenuItem onClick={onClick} className={classes.item} {...props}>
      {children || label}
    </MuiMenuItem>
  );
};

export default Menu;
