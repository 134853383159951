import { v4 as uuid } from 'uuid';
import { useContext, useEffect } from 'react';
import { AsyncMessageContext } from './AsyncMessageProvider';
import { HandlerFunc } from './types';

const useAsyncMessageHandler = (
  messageStatus: string,
  handlerFunc: HandlerFunc
): void => {
  const context = useContext(AsyncMessageContext);

  if (!context) {
    throw new Error(
      `useAsyncMessageHandler called from outside AsyncMessageProvider`
    );
  }

  useEffect(() => {
    const handlerId = uuid();
    const thisContext = context;
    thisContext.registerHandler(messageStatus, handlerId, handlerFunc);
    return () =>
      thisContext.deregisterHandler(messageStatus, handlerId, handlerFunc);
  }, [context, messageStatus, handlerFunc]);
};

export default useAsyncMessageHandler;
