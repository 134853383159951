import axios from 'axios';
import { apiHeaders, apiHost } from '..';
import { makeRequestWithRetries } from './helpers';

export const fetchSingleProviderConfig = async (providerId) => {
  const request = () =>
    axios.get(`${apiHost}/api/v1/provider-config/frontend/${providerId}`, {
      headers: apiHeaders(),
    });
  return makeRequestWithRetries(request);
};

export default fetchSingleProviderConfig;
