import axios from 'axios';
import { store, apiHeaders, apiHost, fetchFailure } from '..';

/**
 * Note:
 * queries parameter is an array of objects containing a 'provider_id' and 'query_id'.
 *
 * queries = [
 *   {
 *     "provider_id": "XXX",
 *     "query_id": "YYY"
 *   }
 * ]
 *
 * See Postman requests for better examples.
 */

export const retryQueries = (projectId, searchId, queries) => {
  const request = axios.post(
    // eslint-disable-next-line max-len
    `${apiHost}/api/v1/projects/${projectId}/searches/${searchId}/queries/retry`,
    queries,
    {
      headers: apiHeaders(),
    }
  );

  return request
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      store.dispatch(fetchFailure(err));
    });
};

export default retryQueries;
