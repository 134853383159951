/* eslint-disable jsx-a11y/no-static-element-interactions */
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Chip from './Chip';
import styles from './DropdownRow.module.scss';

export const DropdownRow = ({
  title,
  count,
  children,
  expanded,
  noIndent = false,
}) => {
  const [open, setOpen] = useState(expanded);

  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  return (
    <>
      <div className={styles.dropdown}>
        {title}
        <div className={styles.controls} onClick={() => setOpen(!open)}>
          {count && (
            <Chip
              label={count}
              size='small'
              variant={open ? 'blue' : 'grey'}
              key={`${title}_image-icon--pep`}
            />
          )}

          {open ? (
            <ArrowDropUpIcon fontSize='small' />
          ) : (
            <ArrowDropDownIcon fontSize='small' />
          )}
        </div>
      </div>

      {open && (
        <div
          className={classNames(styles.content, {
            [styles['no-indent']]: noIndent,
          })}
        >
          {children}
        </div>
      )}
    </>
  );
};
export default DropdownRow;
