export const NA_VAL = 'N/A';

export const DEFAULT_COLLAPSE_FIELD_VALUE = 'hash.headline_preview';

export const SOURCE_ARRAY = [
  'provider.query_id',
  'provider.item_id',
  'search',
  'provider.id',
  'headline',
  'headline_tags',
  'headline_trans',
  'when',
  'source',
  'preview',
  'preview_tags',
  'preview_trans',
  'url',
  'end_url',
  'tags',
  'viewed',
  'language',
];
