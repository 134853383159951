import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { IAdverseTermCategory } from 'types/adverseTerms';

const CategorySelector = ({
  categories,
  selected,
  onChange,
  otherCategories = [],
  ...props
}: {
  selected: number | null;
  categories: IAdverseTermCategory[];
  otherCategories?: IAdverseTermCategory[];
  onChange: (event: SelectChangeEvent, child: ReactNode) => void;
  [_: string]: any;
}) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 200 }} size='small' {...props}>
      <InputLabel id='adverse_terms_category'>Category</InputLabel>
      <Select
        labelId='adverse_terms_category'
        id='adverse_terms_category'
        sx={{ width: '100%' }}
        value={selected?.toString()}
        label='Category'
        onChange={onChange}
      >
        {[...otherCategories, ...categories].map((category) => {
          return (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default CategorySelector;
