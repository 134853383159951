/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Select as MuiSelect, MenuItem } from '@mui/material';
import classNames from 'classnames';
import classes from './fields-base.module.scss';

type SelectProps = {
  className?: string;
  label?: string;
  value: any;
  name?: string;
  options: SelectItemProps[];
  variant?: 'outlined' | 'search' | 'grey-uppercase';
  disablePortal?: boolean;
  startAdornment?: JSX.Element;
  onChange?: (value: any) => void;
};

type SelectItemProps = {
  value: string;
  label: string;
  onClick: (value: any) => void;
};

const Select = ({
  className,
  label,
  value,
  name,
  options,
  variant = 'outlined',
  disablePortal,
  startAdornment,
  onChange,
}: SelectProps) => {
  return (
    <>
      {label && <label className={classes.label}>{label}</label>}
      <MuiSelect
        required
        value={value}
        onChange={onChange}
        name={name}
        variant='outlined'
        // disablePortal={true}
        className={classNames(
          classes.dropdown,
          {
            [classes[`dropdown--${variant}`]]: variant,
          },
          className
        )}
        classes={{
          select: classes['dropdown-inner'],
          icon: classes['dropdown-icon'],
        }}
        startAdornment={startAdornment}
        MenuProps={{
          // disablePortal: true,
          classes: { list: classes.paper, paper: classes['dropdown-menu'] },
          elevation: 0,
          // getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
      >
        {options.map(({ value, label, onClick }) => (
          <MenuItem
            key={`menu-item--${value}`}
            className={classes.dropdownItem}
            value={value}
            onClick={onClick}
          >
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </>
  );
};

export default Select;
