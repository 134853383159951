// This is a fallback login form, mainly for testing
// The main login is a redirect to a URL returned from the API

import React, { useState } from 'react';
import InputText from 'components/Base/Fields/InputText';
import Button from 'components/Base/Button';
import Layout from 'layout/Layout';
import authUser from 'api/auth/authUser';

const LocalLogin = () => {
  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    authUser(email, password).then((res) => {
      if (res?.access_token) {
        window.location.href = '/';
      } else {
        setProcessing(false);
      }
    });
  };

  return (
    <Layout
      title='Login'
      header='Please login to proceed'
      logout={false}
      showNavActions={false}
      logoLink='/'
    >
      <div className='island-wrapper'>
        <div className='island'>
          <div className='white-box'>
            <form
              onSubmit={handleSubmit}
              className='login'
              style={{ maxWidth: '16rem' }}
            >
              <InputText
                onChange={handleEmailChange}
                value={email}
                InputProps={{
                  placeholder: 'Email',
                }}
              />
              <InputText
                onChange={handlePasswordChange}
                value={password}
                InputProps={{
                  type: 'password',
                  placeholder: 'Password',
                }}
              />
              <div className='form-buttons'>
                <Button
                  type='submit'
                  disabled={processing}
                  onClick={handleSubmit}
                  label='Log In'
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LocalLogin;
